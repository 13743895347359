import axios from 'axios';
import { createBrowserHistory } from 'history';
import { Store } from '../redux/store';
import authActions from '../redux/auth/actions';
import { useHistory } from 'react-router-dom'
import { Cookies } from "react-cookie";
import { notification } from 'antd';

const history = createBrowserHistory();

const BASE_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
  baseURL: BASE_URL
});

apiClient.interceptors.request.use(
  (request) => {
    const { auth } = Store.getState();

    const accessToken = auth?.token;

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
    }
  }
);

apiClient.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === 'development') {
    }
    return res;
  },
  (error) => {
    const { response } = error;
    if (!response) {
    }
    const { data } = response;
    if (data) {
    }

    if (data?.code === 429 || data?.status_code === 429) {
      history.push('/429');
    }

    if (data?.code === 408) {
      Store.dispatch({ type: authActions.LOGOUT, payload: { history } });
    }

    if(data?.logout){
      notification.error({  message:data?.message || 'Unauthorized'});
      localStorage.setItem('logoutError', 'true');
      history.push('/')

    }

    throw error;
  }
);
export { history };
export default apiClient;
