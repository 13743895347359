import moment from "moment";
import React, { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface ICreatedInterview {
  item: any;
}
const DeletedInterview: FC<ICreatedInterview> = ({ item }) => {
  return (
    <div>
      <div className="flex">
        <div className="bg-star-red-secondary p-3 rounded-full mr-4">
          <AiOutlineClose className="w-6 h-6 font-[900] text-star-red" />
        </div>
        <div className="text-base pt-2 relative w-full capitalize">
          <h3>
            Interview <span className="text-light-blue">deleted</span>
          </h3>
          <p className="absolute text-sm p-0 m-0 bottom-[-0.25rem] text-blue-radic opacity-50">
            {moment(item.created_at).format(" MMMM Do | h:mm A")}
          </p>
        </div>
      </div>
      <div>
        <div className="h-9 w-4 border-l-[1.5px] border border-r-0 border-t-0 border-b-0 translate-x-[1.5rem] border-light-blue-secondary"></div>
      </div>
    </div>
  );
};

export default DeletedInterview;
