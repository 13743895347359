import { history } from '../utilities/configureAxios';
import { INTERVIEW_SCHEDULE_URL } from './RouteConstants';

type Status = {
  [key: number]: string;
};
type StatusIcons = {
  [key: number]: any;
};
export enum USER_ROLE {
  SUPER_ADMIN = 1,
  ORGNIZATION_ADMIN = 2,
  HR = 3,
  INTERVIEWER = 4,
  EMPLOYEE = 5,
  HR_LEAD = 6
}
export const INTERVIEW_CONFIRMATION: { [key: string]: number } = {
  YES: 1,
  NO: 2,
  RESCHEDULE: 3
};
export const interviewScheduleURl = (
  applicationId: string,
  interviewConcentId: string,
  mr: string,
  sdt: string
) => {
  window.location.replace(
    `${process.env.REACT_APP_WEB_URL}${INTERVIEW_SCHEDULE_URL.replace(
      ':id',
      applicationId
    )
      .replace(':interviewConcentId', interviewConcentId)
      .replace(':mrId', String(INTERVIEW_CONFIRMATION[mr]))
      .replace(':sdtTime', sdt)}`
  );
};

export const APPLICATION_STATUS: Status = {
  2: 'shortlisted',
  3: 'rejected',
  4: 'rejected',
  5: 'Interview Round-1',
  6: 'Rescheduled',
  7: 'absent',
  8: 'Task',
  9: 'task has not been submitted',
  10: 'Review',
  11: 'task discussion round',
  12: 'Interview Round-2',
  13: 'HR/Founder',
  14: 'selected',
  15: 'LOI(Letter of Intent)',
  16: 'LOI(Letter of Intent)',
  17: 'LOI(Letter of Intent)',
  18: 'OL(Offer Letter)',
  19: 'accepted',
  20: 'declined',
  21: 'joined',
  22: 'Deferred',
  23: 'Save For Later',
  24: 'Duplicate Application'
};

export const STATUS_ICON: StatusIcons = {
  1: '/Icons/Absent.svg',
  2: '/Icons/Shortlisted.svg',
  3: '/Icons/Reject.svg',
  4: '/Icons/Reject.svg',
  5: '/Icons/Round1.svg',
  6: '/Icons/Rescheduled.svg',
  7: '/Icons/Absent.svg',
  8: '/Icons/Task.svg',
  9: '/Icons/TaskNotSubmitted.svg',
  10: '/Icons/Review.svg',
  11: '/Icons/TaskDiscussion.svg',
  12: '/Icons/Round1.svg',
  13: '/Icons/FounderRound.svg',
  14: '/Icons/Selected.svg',
  15: '/Icons/LOI(Sent).svg',
  16: '/Icons/LOI(Accepted).svg',
  17: '/Icons/LOI(Declined).svg',
  18: '/Icons/OLSent.svg',
  19: '/Icons/OLAccepted.svg',
  20: '/Icons/Reject.svg',
  21: '/Icons/Joined.svg',
  22: '/Icons/Rescheduled.svg',
  23: '/Icons/Rescheduled.svg',
  24: '/Icons/Rescheduled.svg'
};
export const TIMLINE_SPLITTER = '<--Action-->';
export const EMAIL_MAINREGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const JOB_REGREX =
  /^[A-Za-z0-9][a-zA-Z0-9_\-/.(),:|#]+( [a-zA-Z0-9_\-/.(),:|#]+)*$/;
export const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
export const PHONENUM_REGEX = /^[6-9]\d{9}$/g;
export const APPLICATION_STATUS_SELECT: Status = {
  2: 'Shortlisted',
  3: 'CV rejected',
  4: 'Rejected',
  5: 'Interview Round-1',
  6: 'Rescheduled',
  7: 'Absent',
  8: 'Task',
  9: 'Task not submitted',
  10: 'Review',
  11: 'Task Discussion',
  12: 'Interview Round-2',
  13: 'HR/Founder',
  14: 'Selected',
  15: 'LOI Sent',
  16: 'LOI Accepted',
  17: 'LOI Declined',
  18: 'Offer Letter Sent',
  19: 'Offer Letter Accepted',
  20: 'Offer Letter Declined',
  21: 'Joined',
  22: 'Deferred',
  23: 'Save For Later',
  24: 'Duplicate Application',
  25: 'Interview Round-3',
  26: 'Interview Round-4',
  27: 'Interview Round-5'
};

export const APPLICATION_STATUS_SELECT_FOR_INTERVIEW: Status = {
  5: 'Interview Round - 1',
  6: 'Rescheduled',
  8: 'Task',
  9: 'Task not submitted',
  10: 'Review',
  11: 'Task Discussion',
  12: 'Interview Round - 2',
  13: 'HR/Founder',
  25: 'Interview Round - 3',
  26: 'Interview Round - 4',
  27: 'Interview Round - 5'
};
export const ACTION_TAKEN_BY: Status = {
  1: 'HR',
  2: 'Candidate'
};
export const APPLICATION_STATUS_COLOR: Status = {
  2: '2d94f3',
  3: '6C757D',
  4: 'DE0220',
  5: '2d94f3',
  6: '6C757D',
  7: 'FD7E14',
  8: '6f42c1',
  9: 'DE0220',
  10: 'E83E8C',
  11: 'FD7E14',
  12: 'E83E8C',
  13: 'DE0220',
  14: '28A745',
  15: '6f42c1',
  16: '28A745',
  17: 'DE0220',
  18: 'E83E8C',
  19: '28A745',
  20: 'DE0220',
  21: '28A745',
  22: '6C757D',
  23: '2d94f3',
  24: 'DE0220'
};

export const referralType = [
  { title: 'Cold referral - I do not know the candidate from before', id: '1' },
  {
    title:
      'I know the candidate personally but I have not worked with him professionlly',
    id: '2'
  },
  {
    title:
      'I have worked with this candidate professionally and I can moderately vouch for his skills',
    id: '3'
  },
  {
    title:
      'I have worked with this candidate professionally and I highly recommend the candidate',
    id: '4'
  }
];

export const socialLinks = {
  linkedin: 'https://www.linkedin.com/company/squareboat',
  youtube: 'https://www.youtube.com/channel/UCyUzPvikgKBYTfp4XBIbbOg',
  instagram: 'https://www.instagram.com/square.boat/',
  facebook: 'https://www.facebook.com/squareboat/',
  twitter: 'https://twitter.com/square_boat/'
};

export const statusArray = [
  { name: 'Pending', value: 1 },
  {
    name: 'Shortlisted',
    value: 2
  },
  {
    name: 'CV Rejected',
    value: 3
  },
  {
    name: 'Rejected',
    value: 4
  },
  {
    name: 'Interview Round 1',
    value: 5
  },
  {
    name: 'Rescheduled',
    value: 6
  },
  {
    name: 'Absent',
    value: 7
  },
  {
    name: 'Task Sent',
    value: 8
  },
  {
    name: 'Task not submitted',
    value: 9
  },
  {
    name: 'Review',
    value: 10
  },
  {
    name: 'Task Discussion',
    value: 11
  },
  {
    name: 'Interview Round-2',
    value: 12
  },
  {
    name: 'HR/Founder',
    value: 13
  },
  {
    name: 'Selected',
    value: 14
  },
  {
    name: 'LOI Sent',
    value: 15
  },
  {
    name: 'LOI Accepted',
    value: 16
  },
  {
    name: 'LOI Declined',
    value: 17
  },
  {
    name: 'Offer Letter Sent',
    value: 18
  },
  {
    name: 'Offer Letter Accepted',
    value: 19
  },
  {
    name: 'Offer Letter Declined',
    value: 20
  },
  {
    name: 'Joined',
    value: 21
  },
  {
    name: 'Deferred',
    value: 22
  },
  {
    name: 'Save For Later',
    value: 23
  },
  {
    name: 'Duplicate Application',
    value: 24
  },
  {
    name: 'Interview Round-3',
    value: 25
  },
  {
    name: 'Interview Round-4',
    value: 26
  },
  {
    name: 'Interview Round-5',
    value: 27
  }
];

export const coolOffArray = [
  {
    label: 'Cool Off Status',
    value: null
  },
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
];

export const INTERVIEW_HISTORY_STATUS = {
  SCHEDULED: 1,
  RESCHEDULED: 2,
  CANCELLED: 3
};
export const HISTORY_META_TYPE = {
  CREATED: 1,
  TITLE_CHANGE: 2,
  SCHEDULED_AT_CHANGE: 3,
  END_AT_CHANGE: 4,
  LOCATION_CHANGE: 5,
  INTERVIEWERS_CHANGE: 6
};

export const ThankYou = (
  <div className=''>
    <h2 className=''>Thank You</h2>
    <p className='text-light-blue font-medium'>Squareboat Team</p>
  </div>
);

export const Copyrights = (
  <div className='flex justify-center mt-5 md:text-[1rem] lg:text-[0.75rem]'>
    Copyright © {new Date().getFullYear()} . All rights reserved | Powered By
    {'  '}
    <div className='text-light-blue ml-2'>
      <a href='https://squareboat.com/careers' rel='noreferrer' target='_blank'>
        Squareboat
      </a>
    </div>
  </div>
);
export const imageTypeURl = (type: string, url: string) => {
  if (type.includes('image')) {
    return url;
  } else if (
    type ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
      url
    )}`;
  } else {
    return url;
  }
};
export const PER_PAGE = 10;
export const handleTableFilterClear = () => {
  history.push({
    pathname: window.location.pathname,
    search: `?page=1`
  });
};

export const sortByOptions = [
  {
    label: 'Select Sort Entity',
    value: null
  },
  {
    label: 'CTC: High to Low',
    value: 'ctc_in_rupees,desc'
  },
  {
    label: 'CTC: Low to High',
    value: 'ctc_in_rupees,asc'
  },
  {
    label: 'Date Applied: High to Low',
    value: 'created_at,desc'
  },
  {
    label: 'Date Applied: Low to High',
    value: 'created_at,asc'
  },
  {
    label: 'CV Rating: High to Low',
    value: 'cv_rating,desc'
  },
  {
    label: 'CV Rating: Low to High',
    value: 'cv_rating,asc'
  }
];

export const sortOrder = [
  {
    label: 'High To Low',
    value: 'desc'
  },
  {
    label: 'Low To High',
    value: 'asc'
  }
];

export const TEMPLATE_OPTIONS = [
  // { value: '0', label: 'Select Email Template' },
  { value: '1', label: 'Send JD mail' },
  { value: '2', label: 'Request Applicant Details mail' },
  { value: '3', label: 'Documents for LOI mail' },
  { value: '4', label: 'Send LOI mail' }
];
