export const LANDING_PAGE = '/';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_ALL_CANDIDATES = '/dashboard/all-candidates';
export const INDIVIDUAL_CANDIDATES = '/dashboard/all-candidates/:id';
export const DASHBOARD_TASKS = '/dashboard/tasks';
// export const DASHBOARD_JOBS = "/dashboard/all-jobs";
export const DASHBOARD_VIEW_QUESTION_SET = '/dashboard/view-question-set/:id';
export const DASHBOARD_INTERVIEWER = '/dashboard/interviewer';
export const DASHBOARD_REFERRALS = '/dashboard/all-referrals';
export const ADMIN = '/admin';
export const ADMIN_HR_MANAGEMENT = '/admin/hr-management';
export const DASHBOARD_HIRING_TRACKER = '/dashboard/hiring-tracker';
export const EMPLOYEE = '/employee';
export const EMPLOYEE_HOME = '/employee/home';
export const EMPLOYEE_REFERRALS = '/employee/referrals';
export const STATUS_TIMELINE = '/status/:slug';
export const INTERVIEW_RESPONSE = '/applications/:id/interviews-concent/:uuid';
export const DASHBOARD_ANALYTICS = '/dashboard/analytics';
export const INTERVIEW_GENERATION = '/dashboard/interview-generation';
export const DASHBOARD_CATEGORIES = '/dashboard/categories';
export const DASHBOARD_QUESTIONS = '/dashboard/questions';
export const DASHBOARD_INTERVIEW_GENERATION = '/dashboard/interview-generation';
export const DASHBOARD_INTERVIEWS = '/dashboard/interviews';
export const DASHBOARD_INTERVIEW_ADD = '/dashboard/interviews/:id';
export const DASHBOARD_EMAILS = '/dashboard/email-templates';
// export const DASHBOARD_INTERVIEW_ADD = '/dashboard/interviews/:id';
export const DASHBOARD_FEEDBACK = '/dashboard/feedback';
export const DASHBOARD_SINGLE_FEEDBACK = '/dashboard/feedback/:id';
export const DASHBOARD_ALL_FEEDBACK = '/dashboard/all-feedbacks';
export const DASHBOARD_INTERVIEW_VIEW = '/dashboard/view-feedback/:id';
export const DASHBOARD_INTERVIEW_EDIT = '/dashboard/edit-feedback/:id';
export const DASHBOARD_JOB_LOCATON = '/dashboard/job-location';
export const DASHBOARD_JOB_DEPARTMENT = '/dashboard/job-department';
export const DASHBOARD_JOBS = '/dashboard/jobs';
export const DASHBOARD_ADD_JOB = '/dashboard/jobs/new';
export const DASHBOARD_EDIT_JOB = '/dashboard/jobs/:id';

// export const EMPLOYEE_INTERVIEWS = "/employee/interviews";
// export const INDIVIDUAL_INTERVIEW = "/employee/interviews/:id";
// export const INDIVIDUAL_INTERVIEW_VIEW = "/employee/view-feedback/:id";
export const VIEW_FEEDBACK = '/view-feedback/:id';
export const VIEW_QUESTION_SET_FOR_ADMIN = '/view-question-set/:id';

/** Interviewer Routes */
export const INTERVIEWER = '/interviewer';
export const INTERVIEWER_HOME = '/interviewer/home';
export const INTERVIEWER_REFERRALS = '/interviewer/referrals';
export const INTERVIEWER_INTERVIEWS = '/interviewer/interviews';
export const INTERVIEWER_INTERVIEW = '/interviewer/interviews/:id';
export const INTERVIEWER_INTERVIEW_VIEW = '/interviewer/view-feedback/:id';
export const INTERVIEWER_INTERVIEW_EDIT = '/interviewer/edit-feedback/:id';
export const INTERVIEW_SCHEDULE_URL =
  '/applications/:id/interviews-concent/:interviewConcentId?mr=:mrId&sdt=:sdtTime';
