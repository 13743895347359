import { Button, Tooltip } from "antd";
import React, { FC } from "react";
interface ButtonProps {
  onClick?: () => void;
  isSoftDelete?: boolean;
  isTextLabel?: string;
  disabled?: boolean
}
const DangerButton: FC<ButtonProps> = ({ onClick, isSoftDelete, isTextLabel, disabled }) => {
  return (
    <>
      <Button disabled={disabled} className="delete-button" type="primary" onClick={onClick}>
         { isTextLabel ?? "Delete" }
      </Button>
    </>
  );
};

export default DangerButton;
