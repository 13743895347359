import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class QuestionServices {
  constructor() {
    this.client = apiClient;
  }

  PostAQuestion = (payload) => {
    return this.client
      .post(APIs.POST_A_QUESTION, {
        categories: payload.categories,
        content: payload.content,
        answer: payload.answer,
        level: payload.level,
        question_type: payload.question_type,
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetAllQuestions = (payload) => {
    return this.client
      .get(APIs.GET_ALL_QUESTIONS, { params: payload })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllQuestionsPaginated = (payload) => {
    return this.client
      .get(APIs.GET_ALL_QUESTIONS_PAGINATED, { params: payload })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PatchAQuestionDetail = (payload) => {
    const uuid = payload.uuid;
    return this.client
      .patch(APIs.EDIT_A_QUESTION.replace(":id", uuid), {
        categories: payload.categories,
        content: payload.content,
        answer: payload.answer,
        level: payload.level,
        question_type: payload.question_type,
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  DeleteAQuestion = (payload) => {
    const uuid = payload.uuid;
    return this.client
      .patch(APIs.DELETE_A_QUESTION.replace(":id", uuid), {
        status: payload.status,
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GenerateAQuestion = (payload) => {
    return this.client
      .post(APIs.GENERATE_A_QUESTION, {
        job: payload.job,
        mappings: payload.mappings,
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllQuestionMappings = (payload) => {
    return (
      this.client
        .get(APIs.GET_ALL_QUESTION_MAPPINGS, { params: { ...payload } })
        .then((res) => res)
        .catch((error) => {
          return error;
        })
    );
  };

  DeleteAQuestionMapping = (payload) => {
    const uuid = payload.uuid;
    return this.client
      .put(APIs.DELETE_A_QUESTION_MAPPING.replace(":id", uuid), {
        status: payload.status,
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  FilterAQuestionMapping = (payload) => {
    const jobId = payload.jobId;
    return this.client
      .get(APIs.FILTER_A_QUESTION_MAPPING_BY_JOB.replace("id", jobId))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  EditAQuestionMapping = (payload) => {
    const UUID = payload.uuid;
    return this.client
      .put(APIs.EDIT_A_QUESTION_MAPPING.replace(":id", UUID), {
        job: payload.job,
        mappings: payload.mappings,
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllInterviewsForInterviewers = async (payload) => {
    return this.client
      .get(APIs.GET_ALL_INTERVIEWS_FOR_INTERVIEWERS, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetSingleInterviewInfo = async (payload) => {
    const UUID = payload.uuid;
    return this.client
      .get(APIs.GET_SINGLE_INTERVIEW.replace(":id", UUID))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetInterviewQuestionSet = (payload) => {
    return this.client
      .get(APIs.GET_INTERVIEW_QUESTION_SET.replace(":uuid", payload?.uuid))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostOverallFeedback = (payload) => {
    return this.client
      .post(
        APIs.POST_OVERALL_FEEDBACK.replace(":interviewId", payload.interviewId),
        {
          question_feedbacks: payload.question_feedbacks,
          interview_feedback: payload.interview_feedback,
        }
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetFeedbackUrlForUpload = (num_files) => {
    return this.client
      .post(APIs.GET_FEEDBACK_UPLOAD_URL,{num_files})
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostQuestionSetFeedback = (payload) => {
    return this.client
      .post(
        APIs.POST_QUESTION_SET_FEEDBACK.replace(
          ":interviewId",
          payload.interviewId
        ),
        {
          question_feedbacks: payload.question_feedbacks,
        }
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetInterviewFeedback = (payload) => {
    return this.client
      .get(
        APIs.GET_INTERVIEW_FEEDBACK.replace(
          ":interviewId",
          payload?.interviewId
        )
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetInterviewOverallFeedback = (payload) => {
    return this.client
      .get(
        APIs.GET_INTERVIEW_OVERALL_FEEDBACK.replace(
          ":interviewId",
          payload?.interviewId
        )
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetInterviewSetFeedback = (payload) => {
    return this.client
      .get(
        APIs.GET_INTERVIEW_SET_FEEDBACK.replace(
          ":interviewId",
          payload?.interviewId
        )
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllInterviewForHr = (payload) => {
    return this.client
      .get(APIs.GET_ALL_INTERVIEW_FOR_HR, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetASingleQuestionMapping = (payload) => {
    return this.client
      .get(APIs.GET_SINGLE_QUESTION_MAPPING.replace(":uuid", payload?.uuid))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  EditAInterviewFeedback = async (payload) => {
    return this.client
      .put(
        APIs.PUT_EDIT_A_INTERVIEW_FEEDBACK.replace(
          ":interviewId",
          payload.interviewId
        ),
        {
          question_feedbacks: payload.question_feedbacks,
          interview_feedback: { ...payload.interview_feedback },
        }
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default QuestionServices;
