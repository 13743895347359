import { Button, Input, Modal, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md';
import DangerButton from '../../Buttons/Danger';
import CancelBtn from '../../Buttons/Cancel';
import { connect, useDispatch } from 'react-redux';
import referralActions from '../../../redux/referrals/actions';
import jobActions from '../../../redux/jobs/actions';
import TextArea from 'antd/lib/input/TextArea';
import { USER_ROLE } from '../../../constants/HelperConstants';

const mapStateToProps = (state: any) => ({
  role: state.users.role
});
interface DeleteProps {
  heading: string;
  desc: string;
  userId?: any;
  page?: any;
  status?: any;
  flag?: string;
  role?: Number;
  data?: any;
}

const DeleteReferral: FC<DeleteProps> = ({
  heading,
  desc,
  userId,
  page,
  status,
  flag,
  role,
  data
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState<any>('');
  const dispatch = useDispatch();
  const showModal = () => {
    if (status === 0) {
      setIsOpen(true);
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    setDescription('');
  };

  const onDelete = () => {
    if (
      role === USER_ROLE.ORGNIZATION_ADMIN ||
      role === USER_ROLE.HR ||
      role === USER_ROLE.HR_LEAD
    ) {
      // soft delete
      dispatch({
        type: referralActions.SOFT_DELETE_EMPLOYEE_REFERRAL,
        payload: {
          userId,
          body: {
            ...data,
            status: 2,
            description: description
          }
        }
      });
    }
    if (role === USER_ROLE.INTERVIEWER || role === USER_ROLE.EMPLOYEE) {
      // hard delete
      dispatch({
        type: referralActions.DELETE_EMPLOYEE_REFERRAL,
        payload: {
          userId,
          page,
          flag
        }
      });
      //to refresh the data on the table, fetch again for employees
    }
    handleCancel();
  };

  function isDescriptionFieldEmpty() {
    if (description.length === 0) {
      return true;
    } else return false;
  }

  return (
    <>
      <Tooltip
        placement='topLeft'
        title={
          role === USER_ROLE.ORGNIZATION_ADMIN ||
          role === USER_ROLE.HR ||
          role === USER_ROLE.HR_LEAD
            ? 'Mark candidate as Invalid'
            : 'Delete'
        }
      >
        <div
          className={`w-8 h-8 bg-background-red rounded-md flex items-center justify-center ${
            status === 0
              ? 'text-tamato cursor-pointer'
              : 'text-gray-light cursor-not-allowed'
          }`}
          onClick={showModal}
        >
          <MdDeleteOutline className='w-5 h-5' />
        </div>
      </Tooltip>
      <Modal
        className='delete-modal'
        visible={isOpen}
        onCancel={handleCancel}
        centered
      >
        <div className='flex justify-between'>
          <div className='px-4 md:px-10'>
            <h3 className='text-xl uppercase font-semibold text-blue-radic'>
              {heading}
            </h3>
          </div>

          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleCancel}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>
        <div className='mt-10 md:mt-10 px-4 md:px-10'>
          <p className='text-base text-blue-radic text-center lg:text-left'>
            {desc}
          </p>
        </div>
        {/* ----------added a description------------ */}
        {role === USER_ROLE.ORGNIZATION_ADMIN ||
        role === USER_ROLE.HR ||
        role === USER_ROLE.HR_LEAD ? (
          <div style={{ padding: '0px 30px' }}>
            <div className='flex-grow'>
              <div className='applicant-modal-label'>
                {'Description'}
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <TextArea
                className='edit-input text-base'
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                placeholder='Please enter a description explaining why'
                rows={4}
              />
            </div>
          </div>
        ) : null}
        {/* ----------------------------------------- */}
        <div className='flex justify-center md:px-12 pt-4 pb-6 space-x-4 lg:space-x-7 mt-7'>
          {/* <DangerButton  onClick={onDelete} /> */}
          <Button
            disabled={
              (role === USER_ROLE.EMPLOYEE || role === USER_ROLE.INTERVIEWER) &&
              status === 0
                ? false
                : isDescriptionFieldEmpty()
            }
            className={
              (role === USER_ROLE.ORGNIZATION_ADMIN ||
                role === USER_ROLE.HR ||
                role === USER_ROLE.HR_LEAD) &&
              isDescriptionFieldEmpty()
                ? 'disableyesBtn delete-width'
                : 'delete-button'
            }
            type='primary'
            onClick={onDelete}
          >
            Delete
          </Button>
          <CancelBtn onClick={handleCancel} />
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(DeleteReferral);
