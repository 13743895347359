import { FC } from 'react';
import { IInputWrapperProps } from '../../Interfaces/common';

const InputWrapper: FC<IInputWrapperProps> = ({
  className,
  label,
  helperText,
  required,
  children
}) => {
  return (
    <>
      <div className='relative pb-4'>
        <label htmlFor={label} className={'applicant-modal-label'}>
          {label}
          {required && <span className='text-lg text-star-red pl-1'>*</span>}
        </label>
        {children}
        <span
          className={'text-star-red absolute mt-4 right-0 bottom-[-0.5rem]'}
        >
          {helperText}
        </span>
      </div>
    </>
  );
};

export default InputWrapper;
