import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { history } from '../utilities/configureAxios';

const useRouteChangeEffect = (callback: any) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      callback();
    });

    return () => {
      unlisten();
    };
  }, [history, callback]);
  return;
};

export default useRouteChangeEffect;
