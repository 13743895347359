import { useFormik } from 'formik';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import jobActions from '../../../redux/jobs/actions';
import { addjobSchema } from './schema/index';
import Header from '../../../components/Header';
import { Helmet } from 'react-helmet';
import { Checkbox, Form, Select, Spin, Tag, Tooltip } from 'antd';
import type { SelectProps } from 'antd';
import PrimaryButton from '../../../components/Buttons/Primary';
import Inputfield from '../../../components/InputField';
import atsJobs from '../../../redux/atsJobs/actions';
import { formatsForRichText, modulesForRichText } from '../../../helper';
import InputWrapper from '../../../components/InputWrapper';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import atsJobService from '../../../services/atsJobs';
import { jobStatusOptions } from '../../../helper';
import RecatQuilEditor from '../../../@common/ReactQuil/ReactQuil';

const mapStateToProps = (state: any) => ({
  job: state.atsJobsReducers.singleJob,
  loading: state.auth.loading,
  formErrors: state.jobs.formErrors,
  skills: state.jobs.skills
});

type IAddJob = {
  jobs?: any;
  loading: boolean;
  department: any;
  location: any;
  job?: any;
  orgs_data?: any;
  formErrors: any;
  skills: any;
};

interface FormValues {
  department: string;
  status: string;
  location: string;
  job_title: string;
  short_description: string;
  skill_description: string;
  required_skill: string;
  skillset: string[];
  expectation: string;
  portfolio_required: boolean;
  is_internship: boolean;
  organization: string;
  skillset_string: string;
}

const AddJob = ({ loading, job, formErrors, skills }: IAddJob) => {
  const dispatch = useDispatch();
  const jobService = new atsJobService();
  const [short, setShort] = useState<string>('dummy');
  const queryArray = window.location?.pathname.split('/');
  const q = queryArray[queryArray.length - 1];
  const selectRef = useRef<any>();
  const customJSX = (item: any): ReactNode => {
    return (
      <Select.Option
        label={
          <Tooltip title={item.name}>
            <div className='truncate'>{item.name}</div>
          </Tooltip>
        }
        value={`${item.name}`}
        data={item}
      >
        <div className='flex justify-between'>
          <div className='truncate w-1/2'>
            <Tooltip title={item.name}>{item.name}</Tooltip>
          </div>
          <div>
            <Tag
              color={item?.status === 1 ? 'success' : 'error'}
              className='statusTag'
            >
              {jobStatusOptions[item?.status]?.label}
            </Tag>
          </div>
        </div>
      </Select.Option>
    );
  };
  useEffect(() => {
    if (q !== 'new') {
      dispatch({
        type: atsJobs.GET_SINGLE_JOB,
        payload: q
      });
    }
    dispatch({
      type: jobActions.GET_ALL_SKILLS,
      payload: {}
    });
    return () => {
      dispatch({
        type: jobActions.RESET_STATE,
        payload: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job && job?.location_obj?.id && job?.department_obj?.id) {
      let skills = job?.skillset?.length ? job?.skillset : [];
      if (job?.skillset) {
        try {
          skills = JSON.parse(job?.skillset);
        } catch (e) {
          const validJSONString = job?.skillset.replace(/'/g, '"');
          skills = JSON.parse(validJSONString);
        }
      }
      formik.setValues(
        {
          department: job?.department_obj?.id
            ? `${job?.department_obj?.id}~${job?.department_obj?.name}`
            : '',
          location: job?.location_obj?.id
            ? `${job?.location_obj?.id}~${job?.location_obj?.name}`
            : '',
          job_title: job?.job_title || '',
          short_description: job?.short_description || '',
          skill_description: job?.skill_description || '',
          required_skill: job?.required_skill || '',
          skillset: skills,
          expectation: job?.expectation || '',
          portfolio_required: job?.portfolio_required || false,
          is_internship: job?.is_internship || false,
          organization: job?.organization_id
            ? `${job?.organization_id}~${job?.organization_name}`
            : '',
          status: job?.status || '0',
          skillset_string: ''
        },
        true
      );
      setShort('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);
  useEffect(() => {
    if (short?.length) {
      formik.validateField('required_skill');
      formik.validateField('skill_description');
      formik.validateField('short_description');
      formik.validateField('expectation');
    }
  }, [short]);
  useEffect(() => {
    if (formErrors !== null && formErrors !== undefined) {
      if (Object.keys(formErrors)) {
        formik.setErrors(formErrors);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  let initialValues = {
    department: job?.department_obj?.id
      ? `${job?.department_obj?.id}~${job?.department_obj?.name}`
      : '',
    location: job?.location_obj?.id
      ? `${job?.location_obj?.id}~${job?.location_obj?.name}`
      : '',
    job_title: job?.job_title || '',
    short_description: job?.short_description || '',
    skill_description: job?.skill_description || '',
    required_skill: job?.required_skill || '',
    skillset: [],
    expectation: job?.expectation || '',
    portfolio_required: job?.portfolio_required || false,
    is_internship: job?.is_internship || false,
    organization: job?.organization_id
      ? `${job?.organization_id}~${job?.organization_name}`
      : '',
    status: job?.status || '0',
    skillset_string: ''
  };
  const checkPatchValues = (
    val: string | number | undefined,
    prevValue: string | number | undefined
  ) => {
    if (q === 'new') {
      return val;
    }
    return String(val) === String(prevValue) ? undefined : val;
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: addjobSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      let skills_arr = JSON.stringify(values.skillset);

      let body = {
        location: values.location?.split('~')[0],
        department: values.department?.split('~')[0],
        required_skill: values.required_skill,
        skill_description: values.skill_description,
        short_description: values.short_description,
        expectation: values.expectation,
        job_title: checkPatchValues(values.job_title, job?.job_title),
        skillset: skills_arr,
        portfolio_required: values.portfolio_required,
        is_internship: values.is_internship,
        organization: Number(values.organization?.split('~')[0]),
        status: checkPatchValues(values.status, job?.status)
          ? Number(checkPatchValues(values.status, job?.status))
          : undefined
      };
      if (q === 'new') {
        dispatch({ type: jobActions.POST_NEW_JOB, payload: body });
      } else {
        dispatch({
          type: jobActions.PATCH_JOB,
          payload: { id: q, body: { ...body } }
        });
      }
    }
  });
  const options: SelectProps['options'] = [];
  return (
    <>
      <div className='w-full  h-screen  overflow-y-auto bg-ghost-white relative'>
        <Helmet>
          <meta charSet='utf-8' />
          {q === 'new' ? <title>Add Job</title> : <title>Edit Job</title>}
        </Helmet>
        <Header />

        <Spin size='large' className='hrTable-loader' spinning={loading}>
          <main>
            <section
              style={{ minHeight: '100vh' }}
              className='bg-white rounded-lg p-5 m-8'
            >
              <div className='text-[20px] text-light-blue font-semibold'>
                {q === 'new' ? 'Add New job' : 'Edit Jobs'}
              </div>
              <br />
              <Form
                onFinish={formik.handleSubmit}
                layout='vertical'
                name='addJob-form'
              >
                <div className='flex flex-col'>
                  <div>
                    <p className='applicant-modal-label'>
                      Job Title
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <div className='flex-grow '>
                      <Inputfield
                        placeholder='Enter Job Name'
                        className={`edit-input  rounded-input-feild  ${
                          formik.errors.job_title && formik.touched.job_title
                            ? 'edit-inputError'
                            : ''
                        }`}
                        // label="Job Title"
                        // required
                        name='job_title'
                        type='text'
                        autocomplete='off'
                        value={formik.values.job_title}
                        onChange={(e) => {
                          formik.setFieldTouched('job_title', true);
                          formik.handleChange(e);
                        }}
                        helperText={
                          formik.errors.job_title && formik.touched.job_title
                            ? formik.errors.job_title
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-x-4'>
                    <div>
                      <p className='applicant-modal-label'>
                        Organization
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </p>
                      <InputWrapper
                        // label={"Organization"}
                        // required
                        helperText={
                          formik.errors.organization &&
                          formik.touched.organization
                            ? formik.errors.organization
                            : null
                        }
                      >
                        <AutoComplete
                          apiService={jobService.GetAllOrgs}
                          key='org_id'
                          edit={Boolean(q !== 'new')}
                          placeholder='Search organization'
                          setselectedValue={(data: any) => {
                            if (!data) {
                              formik.setFieldValue('organization', '');
                            } else {
                              formik.setFieldValue(
                                'organization',
                                `${data?.id!}~${data?.name}`
                              );
                            }

                            formik.setFieldValue('department', '');
                            formik.setFieldValue('location', '');
                          }}
                          defaultValue={
                            formik.values.organization
                              ? String(formik.values.organization)
                              : ''
                          }
                          className={`w-full filter-select edit-input ${
                            formik.errors.organization &&
                            formik.touched.organization
                              ? 'edit-inputError'
                              : ''
                          }`}
                        />
                      </InputWrapper>
                    </div>
                    <div>
                      <p className='applicant-modal-label'>
                        Status
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </p>
                      <InputWrapper
                        // label={"Organization"}
                        // required={true}
                        helperText={
                          formik.errors.status && formik.touched.status
                            ? formik.errors.status
                            : null
                        }
                      >
                        <div ref={selectRef}>
                          <Select
                            id='status'
                            className={`w-full filter-select edit-input ${
                              formik.errors.status && formik.touched.status
                                ? 'edit-inputError'
                                : ''
                            }`}
                            value={
                              formik.values.status
                                ? String(formik.values.status)
                                : undefined
                            }
                            defaultValue={
                              formik.values.status
                                ? String(formik.values.status)
                                : undefined
                            }
                            onChange={(value) => {
                              formik.setFieldTouched('status', true);
                              formik.setFieldValue('status', value);
                            }}
                            placeholder='Select status'
                            getPopupContainer={() => selectRef?.current}
                          >
                            {jobStatusOptions?.map((org: any, i: any) => (
                              <Select.Option
                                className='text-base filter-select-option'
                                value={String(org?.value)}
                                key={String(org?.value)}
                                label={org?.label}
                              >
                                <Tooltip title={org?.label}>
                                  {`${org?.label.slice(0, 50)}${
                                    org?.label?.length > 50 ? '...' : ''
                                  }`}
                                </Tooltip>
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </InputWrapper>
                    </div>
                  </div>

                  <div className='grid grid-cols-2 gap-x-4'>
                    <div>
                      <p className='applicant-modal-label'>
                        Team
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </p>
                      <InputWrapper
                        // label={"Team"}
                        // required={true}
                        helperText={
                          formik.errors.department && formik.touched.department
                            ? formik.errors.department
                            : null
                        }
                      >
                        <AutoComplete
                          apiService={jobService.GetAllDepartments}
                          key='department_id'
                          placeholder='Search department'
                          edit={Boolean(q !== 'new')}
                          dependentApiCall={formik.values.organization}
                          setselectedValue={(data: any) => {
                            if (!data) {
                              formik.setFieldValue('department', '');
                            } else {
                              formik.setFieldValue(
                                'department',
                                `${data?.id}~${data?.name}`
                              );
                            }
                          }}
                          customJSX={customJSX}
                          defaultValue={
                            formik.values.department
                              ? String(formik.values.department)
                              : ''
                          }
                          extraPayload={
                            formik?.values?.organization?.length
                              ? {
                                  org_id:
                                    formik?.values?.organization?.split('~')[0]
                                }
                              : {}
                          }
                          disabled={
                            formik.values.organization !== '' ? false : true
                          }
                          style={{}}
                          className={`w-full filter-select edit-input ${
                            formik.errors.department &&
                            formik.touched.department
                              ? 'edit-inputError'
                              : ''
                          }`}
                        />
                      </InputWrapper>
                    </div>
                    <div>
                      <p className='applicant-modal-label'>
                        Location
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </p>
                      <InputWrapper
                        // label={"Location"}
                        // required={true}
                        helperText={
                          formik.errors.location && formik.touched.location
                            ? formik.errors.location
                            : null
                        }
                      >
                        <AutoComplete
                          apiService={jobService.GetAllLocations}
                          key='location_id'
                          placeholder='Search location'
                          edit={Boolean(q !== 'new')}
                          setselectedValue={(data: any) => {
                            if (!data) {
                              formik.setFieldValue('location', '');
                            } else {
                              formik.setFieldValue(
                                'location',
                                `${data?.id}~${data?.name}`
                              );
                            }
                          }}
                          defaultValue={
                            formik.values.location
                              ? String(formik.values.location)
                              : ''
                          }
                          disabled={
                            formik.values.organization !== '' ? false : true
                          }
                          dependentApiCall={formik.values.organization}
                          style={{}}
                          extraPayload={
                            formik?.values?.organization?.length
                              ? {
                                  org_id:
                                    formik?.values?.organization?.split('~')[0]
                                }
                              : {}
                          }
                          customJSX={customJSX}
                          className={`w-full filter-select edit-input ${
                            formik.errors.location && formik.touched.location
                              ? 'edit-inputError'
                              : ''
                          }`}
                        />
                      </InputWrapper>
                    </div>
                  </div>

                  <div className='grid grid-cols-1 gap-x-4'>
                    <p className='applicant-modal-label'>
                      Job Description
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <RecatQuilEditor
                      className='editor'
                      style={{
                        color: '#707070',
                        fontSize: '20px',
                        fontWeight: '500'
                      }}
                      modules={modulesForRichText}
                      formats={formatsForRichText}
                      {...(short?.length === 0 && {
                        value: formik.values.short_description
                      })}
                      onChange={(value) => {
                        setShort('dummy');
                        formik.setFieldTouched('short_description', true);

                        if (
                          value.replace(/<(.|\n)*?>/g, '').trim().length === 0
                        ) {
                          formik.setFieldValue(
                            'short_description',
                            value.replace(/<(.|\n)*?>/g, '')
                          );
                          formik.setErrors({
                            short_description: 'Short description is required'
                          });
                        } else {
                          formik.setFieldValue('short_description', value);
                        }
                      }}
                      key='short_description'
                      placeholder='Add a Description for the job'
                      defaultValue={
                        initialValues?.short_description ||
                        job?.short_description ||
                        formik.values.short_description ||
                        ''
                      }
                      errorJsx={
                        formik.errors.short_description &&
                        formik.touched.short_description && (
                          <p className='text-star-red text-right text-xs sm:text-sm'>
                            {formik.errors.short_description}
                          </p>
                        )
                      }
                    />
                  </div>

                  <div className='mt-[20px]'>
                    <p className='applicant-modal-label'>
                      Skills Description
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <RecatQuilEditor
                      className='editor'
                      style={{
                        color: '#707070',
                        fontSize: '20px',
                        fontWeight: '500'
                      }}
                      modules={modulesForRichText}
                      formats={formatsForRichText}
                      onChange={(value) => {
                        setShort('dummy');
                        formik.setFieldTouched('skill_description', true);
                        if (
                          value.replace(/<(.|\n)*?>/g, '').trim().length === 0
                        ) {
                          formik.setFieldValue(
                            'skill_description',
                            value.replace(/<(.|\n)*?>/g, '')
                          );
                          formik.setErrors({
                            short_description: 'Skills description is required'
                          });
                        } else {
                          formik.setFieldValue('skill_description', value);
                        }
                      }}
                      {...(short?.length === 0 && {
                        value: formik.values.skill_description
                      })}
                      placeholder='Describe the skills needed in job'
                      defaultValue={formik.values.skill_description}
                      errorJsx={
                        formik.errors.skill_description &&
                        formik.touched.skill_description && (
                          <p className='text-star-red text-right text-xs sm:text-sm'>
                            {formik.errors.skill_description}
                          </p>
                        )
                      }
                    />
                  </div>

                  <div className='mt-[20px]'>
                    <p className='applicant-modal-label'>
                      Required Skills
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <RecatQuilEditor
                      className='editor'
                      style={{
                        color: '#707070',
                        fontSize: '20px',
                        fontWeight: '500'
                      }}
                      modules={modulesForRichText}
                      formats={formatsForRichText}
                      {...(short?.length === 0 && {
                        value: formik.values.required_skill
                      })}
                      onChange={(value) => {
                        setShort('dummy');
                        formik.setFieldTouched('required_skill', true);
                        if (
                          value.replace(/<(.|\n)*?>/g, '').trim().length === 0
                        ) {
                          formik.setFieldValue(
                            'required_skill',
                            value.replace(/<(.|\n)*?>/g, '')
                          );
                          formik.setErrors({
                            short_description: 'Skills description is required'
                          });
                        } else {
                          formik.setFieldValue('required_skill', value);
                        }
                      }}
                      placeholder='Skills required for the Job'
                      defaultValue={formik.values.required_skill}
                      errorJsx={
                        formik.errors.required_skill &&
                        formik.touched.required_skill && (
                          <p className='text-star-red text-right text-xs sm:text-sm'>
                            {formik.errors.required_skill}
                          </p>
                        )
                      }
                    />
                  </div>

                  <div className='mt-[20px]'>
                    <p className='applicant-modal-label'>
                      Expectations
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <RecatQuilEditor
                      className='editor'
                      style={{
                        color: '#707070',
                        fontSize: '20px',
                        fontWeight: '500'
                      }}
                      modules={modulesForRichText}
                      formats={formatsForRichText}
                      onChange={(value) => {
                        setShort('dummy');
                        formik.setFieldTouched('expectation', true);
                        if (
                          value.replace(/<(.|\n)*?>/g, '').trim().length === 0
                        ) {
                          formik.setFieldValue(
                            'expectation',
                            value.replace(/<(.|\n)*?>/g, '')
                          );
                          formik.setErrors({
                            short_description: 'Skills description is required'
                          });
                        } else {
                          formik.setFieldValue('expectation', value);
                        }
                      }}
                      placeholder='Skills expected from the candidate'
                      {...(short?.length === 0 && {
                        value: formik.values.expectation
                      })}
                      defaultValue={formik.values.expectation}
                      errorJsx={
                        formik.errors.expectation &&
                        formik.touched.expectation ? (
                          <p className='text-star-red text-right text-xs sm:text-sm'>
                            {formik.errors.expectation}
                          </p>
                        ) : null
                      }
                    />
                  </div>

                  <div className='mt-[20px]'>
                    <p className='applicant-modal-label'>
                      Skill Set
                      <span className='text-lg text-star-red pl-1'>*</span>
                    </p>
                    <InputWrapper
                      // label={"Skill Set"}
                      // required={true}
                      helperText={
                        (formik.errors.skillset_string ||
                          formik.errors.skillset) &&
                        formik.touched.skillset
                          ? formik.errors.skillset ||
                            formik.errors.skillset_string
                          : null
                      }
                    >
                      <Select
                        id='skillset'
                        mode='tags'
                        tokenSeparators={[',']}
                        style={{ width: '100%' }}
                        options={
                          skills?.data?.value?.length
                            ? JSON.parse(skills?.data?.value)?.map(
                                (val: string) => {
                                  return { label: val, value: val };
                                }
                              )
                            : []
                        }
                        placement='bottomLeft'
                        filterOption={(input, option) => {
                          return (
                            ((option?.value as string) || '')
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        className={`w-full tagSelect edit-input ${
                          (formik.errors.skillset_string ||
                            formik.errors.skillset) &&
                          formik.touched.skillset
                            ? 'edit-inputError'
                            : ''
                        }`}
                        value={
                          formik.values.skillset?.length
                            ? formik.values.skillset
                            : undefined
                        }
                        defaultValue={
                          formik.values.skillset?.length
                            ? formik.values.skillset
                            : undefined
                        }
                        onSearch={(value) => {
                          formik.setFieldTouched('skillset', true);
                          formik.setFieldValue('skillset_string', value);
                        }}
                        onChange={(value) => {
                          formik.setFieldTouched('skillset', true);
                          formik.setFieldValue('skillset_string', '');
                          formik.setFieldValue('skillset', value, true);
                        }}
                        placeholder='Enter skills'
                      />
                    </InputWrapper>
                  </div>

                  <div className='mt-[20px]'>
                    <Checkbox
                      onChange={(e) => {
                        formik.setFieldTouched('is_internship', true);

                        formik.setFieldValue('is_internship', e.target.checked);
                      }}
                      checked={formik.values.is_internship}
                      name='is_internship'
                      value={formik.values.is_internship}
                    >
                      Is Internship
                    </Checkbox>
                  </div>

                  <div className='mt-[20px]'>
                    <Checkbox
                      onChange={(e) => {
                        formik.setFieldTouched('portfolio_required', true);
                        formik.setFieldValue(
                          'portfolio_required',
                          e.target.checked
                        );
                      }}
                      name='portfolio_required'
                      checked={formik.values.portfolio_required}
                      value={formik.values.portfolio_required}
                    >
                      Portfolio Required
                    </Checkbox>
                    {/* <p className='text-sm text-left text-blue-streak opacity-50'>
                      Ask applicant to enter portfolio links/files?
                    </p> */}
                  </div>
                  <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
                    <PrimaryButton
                      text={q === 'new' ? 'Add' : 'Save'}
                      type='submit'
                      // onClick={formik.handleSubmit}
                      disabled={!(formik.dirty && formik.isValid)}
                    />
                  </div>
                </div>
              </Form>
            </section>
          </main>
        </Spin>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(AddJob);
