import { Input, Modal, Select, Spin, Tag, Tooltip } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import PrimaryButton from '../../Buttons/Primary';
import { connect, useDispatch } from 'react-redux';
import jobActions from '../../../redux/jobs/actions';
import FileUpload from '../../FileUpload/ReferralFileUpload';
import { USER_ROLE, referralType } from '../../../constants/HelperConstants';
import { MdCancel, MdOutlineModeEditOutline } from 'react-icons/md';
import referralActions from '../../../redux/referrals/actions';
import JobOptionBox from '../../JobOptionBox/JobOptionBox';
import AutoComplete from '../../AutoComplete/AutoComplete';
import JobsServices from '../../../services/jobs';
import { isString } from 'lodash';
// Need to implement the auto complete
const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  jobsForAddApplicant: state.jobs.jobsForAddApplicant,
  singleReferral: state.referrals.singleReferral,
  role: state.users.role
});
interface IJobSelect {
  jobsForAddApplicant: any;
  heading: string;
  id: any;
  singleReferral: any;
  status: any;
  page: any;
  flag?: string;
  role?: any;
  loading?: boolean;
}

const EditReferral: React.FC<IJobSelect> = ({
  jobsForAddApplicant,
  heading,
  id,
  singleReferral,
  status,
  page,
  flag,
  role,
  loading
}) => {
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState<any>();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [fileName, setFileName] = useState<any>('');
  const [fileType, setFileType] = useState<any>('');
  const [referralID, setReferralID] = useState('');
  const [referralTitle, setReferralTitle] = useState<any>();
  const [valid, setValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [attachmentVisible, setAttachmentVisible] = useState(true);
  const [description, setDescription] = useState<string>('');
  const [referredBy, setReferredBy] = useState<string>('');
  const [attachmentURL, setAttachmentURL] = useState<any>('');
  const [isNewResumeUploaded, setIsNewResumeUploaded] =
    useState<boolean>(false);
  const [errors, setErrors] = useState({
    mobileNumber: '',
    email: ''
  });
  useEffect(() => {
    setJobTitle(singleReferral?.data?.job_title);
    setFullName(singleReferral?.data?.full_name);
    setEmail(singleReferral?.data?.email);
    setMobileNumber(singleReferral?.data?.contact);
    setJobId(singleReferral?.data?.job_id);
    setAttachmentURL(singleReferral?.data?.resume_url);
    setFileName(singleReferral?.data?.resume);
    setFileType(singleReferral?.data?.resume_mime_type);
    setReferralID(singleReferral?.data?.type);
    setReferredBy(
      singleReferral?.data?.referred_by ?? singleReferral?.data?.user_name
    );
    setReferralTitle(
      referralType.find((d) => Number(d.id) === singleReferral?.data?.type)
        ?.title
    );
    setIsNewResumeUploaded(false);
  }, [singleReferral?.data]);
  useEffect(() => {
    if (
      jobTitle?.trim().length > 0 &&
      fullName?.trim().length > 0 &&
      email?.trim().length > 0 &&
      mobileNumber?.toString().length > 0 &&
      (fileName?.trim().length > 0 || attachmentURL !== null) &&
      referralTitle !== null
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [fullName, email, mobileNumber, fileName, attachmentURL, referralTitle]);

  useEffect(() => {
    if (isOpen) dispatch({ type: jobActions.GET_ALL_JOBS_FOR_ADD_APPLICANT });
  }, [isOpen]);
  const jobService = new JobsServices();
  const customJSX = (item: any): ReactNode => {
    return JobOptionBox({ data: item });
  };
  const onSave = () => {
    const errorsObject = {
      mobileNumber:
        mobileNumber?.toString().length !== 10 ? 'Invalid Mobile number' : '',
      email: validateEmail(email) ? '' : 'Invalid email'
    };
    setErrors(errorsObject);
    if (!errorsObject.mobileNumber && !errorsObject.email) {
      const body = {
        job_id: jobId,
        full_name: fullName.length < 99 ? fullName : fullName.slice(0, 99),
        contact: Number(mobileNumber),
        email: email,
        resume: fileName,
        resume_mime_type: fileType,
        type: referralID,
        description: description,
        referred_by: referredBy
      };
      if (
        role === USER_ROLE.ORGNIZATION_ADMIN ||
        role === USER_ROLE.HR ||
        role === USER_ROLE.HR_LEAD
      ) {
        // use referral api for admin
        dispatch({
          type: referralActions.EDIT_ADMIN_REFERRAL,
          payload: {
            body,
            id,
            page,
            flag
          }
        });
      } else {
        // use referral/employee api for employees
        dispatch({
          type: referralActions.EDIT_EMPLOYEE_REFERRAL,
          payload: {
            body,
            id,
            page,
            flag
          }
        });
      }
      setJobTitle('All Jobs');
      setEmail('');
      setFullName('');
      setMobileNumber('');
      setIsOpen(false);
      // ===========refresh the data=============
      dispatch({ type: jobActions.GET_ALL_JOBS_FOR_ADD_APPLICANT });
      // ========================================
    }
  };

  const showModal = () => {
    if (status === 0) {
      dispatch({
        type: referralActions.GET_SINGLE_REFERRAL,
        payload: {
          id
        }
      });
      setIsOpen(true);
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    setJobId('');
    setJobTitle('');
    setAttachmentURL(singleReferral?.data?.resume_url);
    setAttachmentVisible(true);
  };
  const dispatch = useDispatch();

  const handleChange = (value: any, key: any) => {
    let [title, id] = getJobTitle(value.toString());
    setJobId(id);
    setJobTitle(value);
  };
  const handleRefType = (value: any, key: any) => {
    setReferralID(value);
    setReferralTitle(key.children);
  };
  const handleAttachmentCancel = () => {
    setAttachmentVisible(false);
    setAttachmentURL(null);
    setFileName(null);
    setFileType(null);
  };
  const getJobTitle = (jobId: any) => {
    let jobTitle = jobsForAddApplicant?.data?.filter(
      (job: any) => jobId === job?.id
    );
    let title = jobTitle[0]?.job_title;
    let id = jobTitle[0]?.job_ref_id;
    return [title, id];
  };
  const { Option } = Select;
  const validateEmail = (email: any) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  return (
    <div>
      <Tooltip placement='topRight' title='Edit Referral Details'>
        <div
          className={`w-8 h-8 bg-background-blue rounded-md flex items-center justify-center ${
            status === 0
              ? 'text-light-blue cursor-pointer'
              : 'text-gray-light cursor-not-allowed'
          }`}
          onClick={showModal}
        >
          <MdOutlineModeEditOutline className='w-5 h-5' />
        </div>
      </Tooltip>
      {!loading && (
        <Modal
          className='edit-task-modal'
          open={isOpen}
          onCancel={handleCancel}
          destroyOnClose={true}
          width='1099px'
          footer={null}
        >
          <div className='bg-[#FAFDFF]'>
            <div className='flex justify-between '>
              <div className='md:px-8'>
                <h3 className='text-xl text-center font-semibold text-blue-zodiac'>
                  {heading}
                </h3>
              </div>
              <div
                className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                onClick={handleCancel}
              >
                <AiOutlineClose className='w-5 h-5 text-gray-light' />
              </div>
            </div>

            <div className='flex md:px-8 flex-col space-y-[30px]'>
              <div className='grid grid-cols-2'>
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    {'Job Title'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <AutoComplete
                    apiService={jobService.GetAllJobsForFilter}
                    key='job_id'
                    placeholder='All Jobs'
                    isPayload
                    edit
                    setselectedValue={(data: any) => {
                      if (!data) {
                        setJobId('');
                        setJobTitle('');
                      } else {
                        setJobId(String(data?.job_ref_id));
                        setJobTitle(data?.job_title);
                      }
                    }}
                    customJSX={customJSX}
                    defaultValue={
                      String(jobId)?.length &&
                      isString(String(jobId)) &&
                      jobTitle?.length
                        ? `${jobId}~${jobTitle}`
                        : ''
                    }
                    // reset={reset}
                    className='edit-input text-base capitalize'
                  />
                </div>
                <div className='ml-2'>
                  <p className='applicant-modal-label'>
                    {'Full Name'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base capitalize'
                    value={fullName}
                    onChange={(e: any) => setFullName(e.target.value)}
                    placeholder='Enter full name'
                  />
                </div>
              </div>
              <div className='flex justify-between space-x-3'>
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    {'Email'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base'
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    placeholder='Enter email'
                  />
                  {errors.email !== '' && (
                    <div className='error-message absolute bottom-[-20px]'>
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    {'Mobile Number'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base capitalize'
                    value={mobileNumber}
                    type='tel'
                    maxLength={10}
                    onChange={(e: any) => setMobileNumber(e.target.value)}
                    placeholder='Enter Mobile Number'
                  />
                  {errors.mobileNumber !== '' && (
                    <div className='error-message absolute bottom-[-20px]'>
                      {errors.mobileNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className='relative'>
                <p className='applicant-modal-label'>
                  {'Attach CV'}
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <div
                  className={`${
                    attachmentURL && attachmentURL !== '' && attachmentVisible
                      ? 'task-input'
                      : ''
                  } relative`}
                >
                  <div className='cursor-pointer'>
                    <FileUpload
                      setFileName={setFileName}
                      fileType={fileType}
                      setIsNewFileUploaded={setIsNewResumeUploaded}
                      setFileType={setFileType}
                    />
                  </div>
                  {attachmentURL &&
                    attachmentURL !== '' &&
                    attachmentVisible &&
                    !isNewResumeUploaded && (
                      <div className='flex absolute top-1 space-x-1 left-48'>
                        <a
                          className=''
                          target='_blank'
                          rel='noopener noreferrer'
                          href={attachmentURL}
                        >
                          Link{' '}
                        </a>
                        <div className='h-full mt-[2px] cursor-pointer'>
                          <MdCancel
                            size={18}
                            onClick={handleAttachmentCancel}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className='flex-grow'>
                <p className='applicant-modal-label'>
                  {'How do you know this candidate'}
                  <span className='text-lg text-star-red pl-1'>*</span>
                </p>
                <Select
                  className='w-full filter-select edit-input'
                  value={referralTitle}
                  onChange={handleRefType}
                  placeholder='Select'
                >
                  {referralType.map((type: any, i: any) => (
                    <Option
                      className='text-base filter-select-option'
                      value={type.id}
                      key={type.id}
                    >
                      {type.title}
                    </Option>
                  ))}
                </Select>
              </div>

              {/* ======description only visible if the role is admin access, wont be visible to the employees====== */}
              {/* {role === 2 || role === 3 ? <div className="flex-grow">
              <p className="applicant-modal-label">
                {"Description"}
                <span className="text-lg text-star-red pl-1">*</span>
              </p>
              <Input
                className="edit-input text-base"
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                placeholder="Enter Description"
              />
            </div> : null} */}
              {/* ===description=== */}

              {/* ===Referred By, for manual entry fo names=== */}
              {role === USER_ROLE.ORGNIZATION_ADMIN ||
              role === USER_ROLE.HR ||
              role === USER_ROLE.HR_LEAD ? (
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    Referred By
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base'
                    value={referredBy}
                    onChange={(e: any) => setReferredBy(e.target.value)}
                    placeholder='Enter a name'
                  />
                </div>
              ) : null}
              {/* ===Referred By, for manual entry fo names=== */}

              <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
                <PrimaryButton disabled={!valid} text='Save' onClick={onSave} />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(EditReferral);
