
const actions = {
  SET_REFERRALS_STATE: "SET_REFERRALS_STATE",
  GET_ALL_HR_REFERRALS: "GET_ALL_HR_REFERRALS",
  GET_EMPLOYEE_REFERRALS: "GET_EMPLOYEE_REFERRALS",
  ADD_HR_REFERRAL: "ADD_HR_REFERRAL",
  ADD_EMPLOYEE_REFERRAL: "ADD_EMPLOYEE_REFERRAL",
  EDIT_EMPLOYEE_REFERRAL: "EDIT_EMPLOYEE_REFERRAL",
  DELETE_EMPLOYEE_REFERRAL: "DELETE_EMPLOYEE_REFERRAL",
  SOFT_DELETE_EMPLOYEE_REFERRAL: "SOFT_DELETE_EMPLOYEE_REFERRAL",
  GET_SINGLE_REFERRAL: "GET_SINGLE_REFERRAL",
  EDIT_ADMIN_REFERRAL: "EDIT_ADMIN_REFERRAL",
  FILTER_REFERRALS: "FILTER_REFERRALS",
  FILTER_REFERRALS_SUCCESS: "FILTER_REFERRALS_SUCCESS"
};

export default actions;
