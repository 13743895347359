import { Input } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { connect, useDispatch } from 'react-redux';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../helper';
import referralActions from '../../redux/referrals/actions';
import { history } from '../../utilities/configureAxios';
import { useLocation } from 'react-router-dom';
import debounce from '../../utilities/debounce';
import categoryActions from '../../redux/questionBank/actions';

const mapStateToProps = (state: any) => ({
  search: state.filters.search,
  filters: state.filters.filters,
  active: state.filters.active,
  filterReferral: state
});

function CategorySearch({ filterReferral }: any) {
  const [value, setValue] = useState<any>('');
  const dispatch = useDispatch();

  const handleSearchChange = (event: any) => {
    if (!!event.target.value) {
      dispatch({
        type: categoryActions.GET_ALL_CATEGORIES,
        payload: {
          page: 1,
          q: event.target.value
        }
      });
      history.push(getSearchParamsUrl({ q: event.target.value, page: 1 }), {});
    } else {
      dispatch({
        type: referralActions.GET_ALL_HR_REFERRALS,
        payload: {
          page: 1,
          q: q
        }
      });
      history.push(getSearchParamsUrl({ q: event.target.value, page: 1 }), {});
    }
  };
  const debounceOnChange = useCallback(debounce(handleSearchChange, 400), []);

  const location = useLocation();
  // const after = location.search.substring(location.search.indexOf('=') + 1);
  const { q } = getCurrentQueryParams();

  useEffect(() => {
    setValue(q);
    if (!!q) {
      dispatch({
        type: categoryActions.GET_ALL_CATEGORIES,
        payload: {
          page: 1,
          q: q
        }
      });
    }
  }, []);

  function handleWrapper(event: any) {
    setValue(() => event.target.value);
    debounceOnChange(event);
  }

  return (
    <>
      <Input
        className='input-search'
        allowClear
        placeholder={'Search Categories'}
        onChange={(e) => handleWrapper(e)}
        value={value}
        prefix={
          <AiOutlineSearch className='w-10 h-10 g:w-7 lg:h-7 text-light-blue' />
        }
      />
    </>
  );
}

export default connect(mapStateToProps)(CategorySearch);
