// Users
export const GET_ALL_USERS = `/users?page=num`;
export const POST_INVITE_MAIL = `/users/invite`;
export const UPDATE_ROLE_AND_STATUS = `/users/:id`;
export const UPDATE_USER_PROFILE = `/users/my-profile/:id`;
export const CREATE_INTERVIEWER_USER = '/users/interviewers';
export const GET_ALL_INTERVIEWER_USER = '/users/interviewers?page=num';
export const UPDATE_INTERVIEWER_DETAILS = '/users/interviewers/:id';
export const DELETE_INTERVIEWER = '/users/interviewers/:id';
export const GET_ALL_HRS = `/users?pagination=false`;

//Authentication
export const POST_LOGIN_REQUEST = `/auth/login`;

//Applications
export const GET_ALL_APPLICATIONS = `/applications`;
export const GET_ALL_APPLICATIONS_WITHOUT_PAGE = `/applications?pagination=false`;
export const GET_SINGLE_APPLICATION = `/applications/:id`;
export const UPDATE_STATUS_OF_APPLICATION = `/applications/:id`;
export const POST_APPLICATION = `/applications/webhook/:id`;
export const CREATE_APPLICATION_NOTE = `/applications/:id/notes`;
export const GET_APPLICATION_NOTE = `/applications/:id/notes`;
export const GET_INTERVIEW_SCHEDULE = 'applications/:id/interviews';
export const POST_INTERVIEW_SCHEDULE = '/applications/:id/interviews';
export const UPDATE_INTERVIEW_SCHEDULE = 'applications/:id/interviews/:id';
export const DELETE_INTERVIEW_SCHEDULE =
  'applications/:id/interviews/:interviewId';
export const GET_NEXT_APPLICATION = `/applications/:id/next`;
export const GET_PREV_APPLICATION = `/applications/:id/prev`;
export const UPDATE_ALLOTED_TASK = `/applications/:id/tasks/:taskId`;
export const GET_UPLOAD_URL = 'applications/upload-resume/s3';
export const GET_PORTFOLIO_UPLOAD_URL = 'applications/upload-portfolio/s3';
export const POST_INTERVIEW_CONCENT =
  '/applications/:id/interviews-concent/:uuid';

// Jobs
export const POST_JOB = `/jobs/webhook/:id`;
export const UPDATE_JOB = `/jobs/:id`;
export const GET_ALL_JOBS = `/jobs`;
export const POST_NEW_JOB = `/jobs`;
export const GET_ALL_JOBS_FOR_FILTER = `/jobs`;
export const GET_ALL_JOBS_FOR_ADD_APPLICANT = `/jobs?pagination=false`;
export const SYNC_JOBS = `/jobs/sync`;
export const PATCH_JOB = `/jobs/:id`;

//Organizations
export const GET_ALL_ORGANIZATIONS = `/users/organizations`;

//Interviewers
export const GET_ALL_INTERVIEWERS = `/users/interviewers?page=num`;
export const POST_INTERVIEWERS = '/users/interviewers';
export const UPDATE_INTERVIEWERS_PROFILE = '/users/interviewers/:id';
export const DELETE_INTERVIEWERS = '/users/interviewers/:id';
export const GET_ALL_INTERVIEWERS_FOR_INTERVIEW = `/users/interviewers?pagination=false`;

// Tasks
export const GET_ALL_TASKS = '/tasks';
export const WITHOUT_PAGINATED_TASK_LIST = '/tasks?pagination=false';
export const GET_SINGLE_TASK = '/tasks/:id';
export const GET_ALL_TASKS_LIST = `applications/:id/tasks`;
export const EDIT_TASK = '/tasks/:id';
export const ADD_TASK = '/tasks';
export const DELETE_TASK = '/tasks/:id';
export const GET_TASK_UPLOAD_URL = 'tasks/upload-task-attachment/s3';

// Referrals
export const ALL_REFERRALS = '/referrals';
export const EMPLOYEE_REFERRALS = '/referrals/employees';
export const GET_REFERRAL_UPLOAD_URL = 'referrals/upload-resume/s3';
export const FILTER_REFERRALS = `/referrals`;

// Patch for Description for employee referrals
export const ADMIN_REFERRALS = '/referrals';

// Category
export const GET_ALL_CATEGORIES = '/question-bank/categories';
export const ADD_A_CATEGORY = '/question-bank/categories';
export const DELETE_A_CATEGORY = '/question-bank/categories/:id';
export const EDIT_A_CATEGORY = '/question-bank/categories/:id';

// Questions
export const POST_A_QUESTION = '/question-bank/questions';
export const GET_ALL_QUESTIONS = '/question-bank/questions?pagination=true';
export const EDIT_A_QUESTION = '/question-bank/questions/:id';
export const DELETE_A_QUESTION = '/question-bank/questions/:id';
export const GET_ALL_QUESTIONS_PAGINATED = '/question-bank/questions';
export const GENERATE_A_QUESTION = '/question-bank/mappings';
export const EDIT_A_QUESTION_MAPPING = 'question-bank/mappings/:id';
// export const GET_ALL_QUESTION_MAPPINGS = "/question-bank/mappings?page=:num";
export const GET_ALL_QUESTION_MAPPINGS = '/question-bank/mappings';
export const DELETE_A_QUESTION_MAPPING = '/question-bank/mappings/:id';
export const FILTER_A_QUESTION_MAPPING_BY_JOB =
  '/question-bank/mappings?job=id';
export const GET_ALL_INTERVIEWS_FOR_INTERVIEWERS = '/interviews/interviewers';
export const GET_SINGLE_INTERVIEW = '/interviews/interviewers/:id';
export const GET_SINGLE_QUESTION_MAPPING = '/question-bank/mappings/:uuid';

// interviews
export const GET_INTERVIEW_QUESTION_SET = '/interviews/:uuid/interview-set';
export const POST_OVERALL_FEEDBACK =
  '/interviews/:interviewId/question-feedbacks';
export const GET_FEEDBACK_UPLOAD_URL =
  '/interviews/upload-feedback-attachment/s3';
export const POST_QUESTION_SET_FEEDBACK =
  '/interviews/:interviewId/question-feedbacks';
export const GET_INTERVIEW_FEEDBACK =
  '/interviews/:interviewId/question-feedbacks';
export const GET_INTERVIEW_OVERALL_FEEDBACK =
  '/interviews/:interviewId/interview-feedbacks';
export const GET_INTERVIEW_SET_FEEDBACK =
  '/interviews/:interviewId/interview-set?pagination=false';
export const GET_ALL_INTERVIEW_FOR_HR = '/interviews?pagination=true';
export const POST_INTERVIEW_QUESTION_SET = '/interviews/:id/interview-set';
export const PUT_EDIT_A_INTERVIEW_FEEDBACK =
  '/interviews/:interviewId/question-feedbacks';

//Generate a question set on clicking of the checkbox
export const POST_GENERATE_A_QUESTION_SET =
  '/question-bank/generate-question-sets';

// Get interview history
export const GET_INTERVIEW_HISTORY = '/applications/interview-history/:id';

// Get Duplicate Applications
export const GET_DUPLICATE_APPLICATION =
  '/applications/:id/duplicate-applications';

// Email Templates
export const GET_EMAIL_UPLOAD_URL = '/custom-emails/upload-attachment';
export const CUSTOM_EMAIL = '/custom-emails';

//tags API
export const GET_ALL_TAGS = '/applications/tags';
