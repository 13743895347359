import actions from "./actions";

const initialState = {
  allReferrals: [],
  employeeReferrals: [],
  singleReferral: {},
  filterReferral : {}
};

export default function referralsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case actions.SET_REFERRALS_STATE: {
      return { ...state, ...payload };
    }
    case actions.FILTER_REFERRALS_SUCCESS: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
}
