import { all, takeEvery, put } from 'redux-saga/effects';
import { doApiCall, getSearchParamsUrl } from '../../helper';
import actions from './actions';
import atsJobService from '../../services/atsJobs';
import { notification } from 'antd';
import { history } from '../../utilities/configureAxios';
import { queryStringToJSON } from '../../utilities/routies';

const services = new atsJobService();

export function* WATCH_GET_ALL_LOCATIONS(action) {
  const response = yield doApiCall(services.GetAllLocations, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_STATE_LOCATIONS,
      payload: { ...response }
    });
  }
}
export function* WATCH_GET_SINGLE_LOCATION(action) {
  const response = yield doApiCall(services.GetSingleLocation, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_SINGLE_LOCATION,
      payload: { ...response.data }
    });
  }
}
export function* WATCH_POST_LOCATION(action) {
  const response = yield doApiCall(services.PostLocation, action.payload);
  if (response?.success) {
    notification.success({
      message: 'Location added successfully'
    });
    history.push(getSearchParamsUrl({ page: 1 }), {});
    yield put({
      type: actions.GET_ALL_LOCATIONS,
      payload: { params: { page: 1, only: true } }
    });
  }
}

export function* WATCH_EDIT_LOCATION(action) {
  const response = yield doApiCall(services.EditSingleLocation, action.payload);
  if (response?.success) {
    notification.success({
      message: 'Location updated successfully'
    });
    const queryString = window.location.search;
    const searchParams = queryStringToJSON(queryString);
    searchParams.only = true;

    yield put({
      type: actions.GET_ALL_LOCATIONS,
      payload: { params: searchParams }
    });
  }
}

export function* WATCH_GET_ALL_DEPARTMENTS(action) {
  const response = yield doApiCall(services.GetAllDepartments, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_STATE_DEPARTMENTS,
      payload: { ...response }
    });
  }
}

export function* WATCH_GET_ALL_ORGANIZATIONS(action) {
  const response = yield doApiCall(services.GetAllOrgs, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_STATE_ORGANIZATIONS,
      payload: { ...response }
    });
  }
}

export function* WATCH_GET_SINGLE_DEPARTMENT(action) {
  const response = yield doApiCall(
    services.GetSingleDepartment,
    action.payload
  );
  if (response?.success) {
    yield put({
      type: actions.SET_SINGLE_DEPARTMENT,
      payload: { ...response.data }
    });
  }
}
export function* WATCH_POST_DEPARTMENT(action) {
  const response = yield doApiCall(services.PostDepartment, action.payload);
  if (response?.success) {
    notification.success({
      message: 'Department added successfully'
    });
    history.push(getSearchParamsUrl({ page: 1 }), {});
    yield put({
      type: actions.GET_ALL_DEPARTMENTS,
      payload: { params: { page: 1, only: true } }
    });
  }
}

export function* WATCH_EDIT_DEPARTMENT(action) {
  const response = yield doApiCall(
    services.EditSingleDepartment,
    action.payload
  );
  if (response?.success) {
    notification.success({
      message: 'Department updated successfully'
    });
    const queryString = window.location.search;
    const searchParams = queryStringToJSON(queryString);
    searchParams.only = true;
    yield put({
      type: actions.GET_ALL_DEPARTMENTS,
      payload: { params: searchParams }
    });
  }
}
export function* WATCH_GET_SINGLE_JOB(action) {
  const response = yield doApiCall(services.GetSingleJob, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_SINGLE_JOB,
      payload: { ...response.data }
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALL_LOCATIONS, WATCH_GET_ALL_LOCATIONS)]);
  yield all([takeEvery(actions.POST_LOCATION, WATCH_POST_LOCATION)]);
  yield all([
    takeEvery(actions.GET_SINGLE_LOCATION, WATCH_GET_SINGLE_LOCATION)
  ]);
  yield all([takeEvery(actions.EDIT_LOCATION, WATCH_EDIT_LOCATION)]);
  yield all([
    takeEvery(actions.GET_ALL_DEPARTMENTS, WATCH_GET_ALL_DEPARTMENTS)
  ]);
  yield all([takeEvery(actions.POST_DEPARTMENT, WATCH_POST_DEPARTMENT)]);
  yield all([
    takeEvery(actions.GET_SINGLE_DEPARTMENT, WATCH_GET_SINGLE_DEPARTMENT)
  ]);
  yield all([takeEvery(actions.EDIT_DEPARTMENT, WATCH_EDIT_DEPARTMENT)]);
  yield all([takeEvery(actions.GET_SINGLE_JOB, WATCH_GET_SINGLE_JOB)]);
  yield all([
    takeEvery(actions.GET_ALL_ORGANIZATIONS, WATCH_GET_ALL_ORGANIZATIONS)
  ]);
}
