import actions from "./actions";

const initialState = {
  filters: {
    graduation_gte: 1990,
    graduation_lte: 2030,
    ctc_gte: 0,
    ctc_lte: 100 * 100000,
    job_id: null,
    status: null,
    created_at_gte: null,
    created_at_lte: null,
    minRating: 0,
    maxRating: 10,
    tags: [],
    sort: null,
    order: "desc"
  },
  selectApp: "All Jobs",
  appStatus: "Status",
  coolOffStatus:null,
  minYear: 1990,
  maxYear: 2030,
  minCtc: 0,
  maxCtc: 100,
  minRating: 0,
  maxRating: 10,
  active: false,
  isFilterApplied: false,
  isBack: false,
  search: "",
  tags: []
};

export default function filterReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_STATE: {
      return { ...state, ...payload };
    }
    case actions.RESET_STATE1:{
      return {...initialState}
    }
    default: {
      return state;
    }
  }
}
