import { FC } from "react";
import { connect, useDispatch } from "react-redux";
import applicationActions from "../../../redux/applications/actions";
import Task from "../../Task";

const mapStateToProps = (state: any) => ({
  singleApplication: state.applications.singleApplication,
  taskList: state.applications.taskList,
  allTasks: state.tasks.tasks,
});

interface ITaskComp {
  singleApplication?: any;
  taskList: any;
  allTasks: any;
}

const TaskComp: FC<ITaskComp> = ({ singleApplication, taskList, allTasks }) => {
  const dispatch = useDispatch();
  return (
    <div className="bg-white rounded-lg p-5 w-full flex flex-col">
      <div className="flex justify-between">
        <h2 className="text-lg text-[#233A5C] mr-4 mb-0">Task Assigned</h2>
        <div
          className="cursor-pointer"
          onClick={() => {
            dispatch({
              type: applicationActions.TOGGLE_TASK_MODAL,
              payload: true,
            });
          }}
        >
          <img src="/add-interview.svg" alt="" />
        </div>
      </div>
      <div className="max-h-[20rem] overflow-y-scroll">
        {taskList.length > 0 ? (
          taskList.map((item: any) => (
            <Task
              id={singleApplication.id}
              data={item}
              tasks={allTasks}
              key={item.id}
            />
          ))
        ) : (
          <div className=" flex items-center flex-col justify-center h-full py-12 space-y-3">
            <img src="/Icons/NoTask.svg" alt="No Task" />
            <span className="text-lg text-[#0E254B] opacity-40 font-semibold">
              No Task has been assigned yet.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(TaskComp);
