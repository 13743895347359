import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const persistConfig = {
  key: "authType",
  storage,
  blacklist:['hiringTracker']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares))
);

const Persistor = persistStore(Store);
sagaMiddleware.run(rootSaga);

export { Store, Persistor };
