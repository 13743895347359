import { Select } from 'antd';
import { APPLICATION_STATUS_SELECT } from '../../../../constants/HelperConstants';

const CandidateStatusSelect = ({
  data,
  onClick
}: {
  data: any;
  onClick: (value: string) => void;
}) => {
  return (
    <Select
      value={String(data?.status)}
      // defaultValue={String(data?.status)}
      className={`select-box table-select-box candiate-select-box w-full rounded-lg`}
      onChange={(value: string) => {
        onClick(value);
      }}
      placeholder={'Select applicant status'}
    >
      <Select.Option value='1' disabled>
        Pending
      </Select.Option>
      {Object.entries(APPLICATION_STATUS_SELECT).map((item) => (
        <Select.Option value={String(item[0])} key={String(item[0])}>
          {item[1]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CandidateStatusSelect;
