import { Input } from 'antd';
import debounce from '../../../utilities/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../../helper';
import categoryActions from '../../../redux/questionBank/actions';
import { history } from '../../../utilities/configureAxios';

const InterviewQuestionSearch = () => {
  const [value, setValue] = useState<any>('');
  const { q } = getCurrentQueryParams();

  const handleWrapper = (event: any) => {
    setValue(() => event.target.value);
    debounceOnChange(event);
  };

  const handleSearchChange = (event: any) => {
    if (!!event.target.value) {
      history.push(getSearchParamsUrl({ q: event.target.value, page: 1 }), {});
    } else {
      history.push(getSearchParamsUrl({ q: event.target.value, page: 1 }), {});
    }
  };

  const debounceOnChange = useCallback(debounce(handleSearchChange, 400), []);

  useEffect(() => {
    if (!!q) setValue(q);
  }, []);
  return (
    <>
      <Input
        className='input-search'
        allowClear
        placeholder={'Search Question'}
        onChange={(e) => handleWrapper(e)}
        value={value}
        prefix={
          <AiOutlineSearch className='w-10 h-10 g:w-7 lg:h-7 text-light-blue' />
        }
      />
    </>
  );
};

export default InterviewQuestionSearch;
