const actions = {
  // JOB LOCATION
  GET_ALL_HIRING_TRACKER_DATA: 'GET_ALL_HIRING_TRACKER_DATA',
  SET_STATE: 'SET_STATE',
  EXPORT_CSV: 'EXPORT_CSV',
  EXPORT_CSV_LOADING: 'EXPORT_CSV_LOADING',
  // COMMON RESET
  RESET_STATE: 'RESET_STATE'
};

export default actions;
