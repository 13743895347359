import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { connect, useDispatch } from 'react-redux';
import filterActions from '../../../redux/filters/actions';
import { history } from '../../../utilities/configureAxios';
import { getSearchParamsUrl } from '../../../helper';
import { statusArray } from '../../../constants/HelperConstants';

import { queryStringToJSON } from '../../../utilities/routies';

const mapStateToProps = (state: any) => ({
  filters: state.filters.filters,
  appStatus: state.filters.appStatus
});
interface SelectBoxProps {
  handleFilterObj?: any;
  filters?: any;
  appStatus?: any;
  selectApp?: any;
  location?: any;
}

const JobStatus: React.FC<SelectBoxProps> = ({ appStatus, location }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isDefaultValue, setIsDefaultValue] = useState<boolean>(true);
  const { search } = useLocation();
  const searchParams = queryStringToJSON(search);

  const handleChange = (value: any, key: any) => {
    if (value === 'Status') {
      history.push(getSearchParamsUrl({ status: null, page: null }), {});
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          appStatus: 'Status'
        }
      });
    } else {
      history.push(getSearchParamsUrl({ status: key.key, page: null }), {});
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          appStatus: value,
          isFilterApplied: true
        }
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const hasStatusParam = searchParams.has('status');
    if (hasStatusParam) {
      setIsDefaultValue(false);
    } else {
      setIsDefaultValue(true);
    }
  }, [search]);

  const currentValue =
    location?.search === '' ||
    location.search === '?page=1' ||
    !searchParams?.status?.length
      ? 'Status'
      : appStatus;

  return (
    <div className='flex flex-col  mr-4 lg:w-[60%] md:w-[40%] shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
      <Select
        showSearch
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        className={`${
          isDefaultValue ? 'faded-select' : ''
        } filter-select w-full min-w-[100px]`}
        value={currentValue}
        onChange={handleChange}
        placeholder='Status'
        dropdownStyle={{ width: '200px', minWidth: '200px' }}
      >
        <Option className='filter-select-option' value='Status'>
          Status
        </Option>
        {statusArray.map((item: any) => (
          <Option
            className='filter-select-option'
            value={item.name}
            key={item.value}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default connect(mapStateToProps)(JobStatus);
