import {
  Empty,
  Input,
  SelectProps,
  Spin,
  Tooltip,
  AutoComplete as AntdAutoComplete
} from 'antd';
import { useAutoComplete } from './views/useAutoComplete';
import { Fragment, ReactNode } from 'react';
import { DownOutlined } from '@ant-design/icons';

interface AutoCompletePropss extends SelectProps {
  setselectedValue?: any;
  key?: string;
  apiService: (payload: any) => Promise<unknown>;
  edit?: Boolean;
  reset?: Boolean;
  isPayload?: Boolean;
  dependentApiCall?: string;
  extraPayload?: { [key: string]: string | number | null | boolean };
  customJSX?: (item: any) => ReactNode;
  idType?: string;
}

const AutoComplete = (props: AutoCompletePropss) => {
  const {
    handleSelect,
    handleSearchChange,
    clearSearchValue,
    searchResultLoading,
    searchValue,
    searchResult
  } = useAutoComplete({
    setselectedValue: props.setselectedValue,
    key: props.key,
    apiService: props.apiService,
    defaultValue: props.defaultValue as string,
    edit: props.edit,
    extraPayload: props.extraPayload || {},
    dependentApiCall: props.dependentApiCall,
    reset: Boolean(props.reset),
    isPayload: Boolean(props.isPayload),
    idType: props.idType
  });

  return (
    <Fragment>
      <AntdAutoComplete
        allowClear={Boolean(searchValue?.length)}
        onClear={clearSearchValue}
        disabled={props.disabled}
        onSearch={(e) => {
          handleSearchChange(e);
        }}
        status={props.status}
        className={`${
          props.className || ''
        } filter-select autocomplete min-w-[200px] ${
          props?.disabled ? 'autocomplete-disabled' : ''
        }`}
        onSelect={handleSelect}
        notFoundContent={<Empty />}
        filterOption={false}
        value={searchValue || ''}
        dataSource={
          searchResultLoading
            ? [
                <AntdAutoComplete.Option>
                  <div className='option_container option_loader'>
                    <Spin spinning={true} />
                  </div>
                </AntdAutoComplete.Option>
              ]
            : searchResult && searchResult?.length
            ? searchResult?.map((item: any, index: number) => {
                return (
                  <Fragment key={item.job_ref_id || index}>
                    {props.customJSX ? (
                      <Fragment>{props.customJSX(item)}</Fragment>
                    ) : (
                      <AntdAutoComplete.Option
                        label={
                          <Tooltip title={item.name}>
                            <div className='truncate'>{item.name}</div>
                          </Tooltip>
                        }
                        value={`${item?.name}`}
                        data={item}
                      >
                        <div className='truncate w-full'>
                          <Tooltip title={item.name}>{item.name}</Tooltip>
                        </div>
                      </AntdAutoComplete.Option>
                    )}
                  </Fragment>
                );
              })
            : []
        }
        dropdownStyle={{ width: '400px', minWidth: '400px' }}
      >
        <Input
          suffix={<DownOutlined />}
          className='p-0 w-full h-full'
          disabled={props.disabled}
          status={props.status}
          placeholder={props.placeholder as string}
        />
      </AntdAutoComplete>
    </Fragment>
  );
};

export default AutoComplete;
