import { Select } from 'antd';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../redux/applications/actions';

interface SelectReferredProps {
  status?: any;
  ref_id?: any;
  page?: any;
  allHr?: any;
  hr?: any;
}

const mapStateToProps = (state: any) => ({
  applications: state.applications.applications,
  loading: state.auth.loading,
  allHr: state.applications.allHr,
});

const SelectReferred: React.FC<SelectReferredProps> = ({
  status,
  ref_id,
  allHr,
  hr,
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [appStatus, setAppStatus] = useState<any>();

  const handleChange = (value: any) => {
    setAppStatus(value);
    dispatch({
      type: applicationActions.UPDATE_STATUS_OF_APPLICATION,
      payload: { ref_id, body: { hr_id: value } },
    });
  };

  allHr.forEach(function (itm: any) {
    itm.label = itm?.name;
    itm.value = itm?.id;
  });

  allHr = allHr.filter(function (itm: any) {
    return itm.name !== undefined;
  });

  return (
    <div className='test-select'>
      <Select
        className='select-box w-3/3 '
        defaultValue={hr ? hr : 'N.A.'}
        value={appStatus}
        onChange={handleChange}
        style={{ minWidth: '160px' }}
      >
        {allHr.map((item: any) => (
          <Option
            value={item.value}
            key={item.value}
            className='select-box-option'
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default connect(mapStateToProps)(SelectReferred);
