import { connect, useDispatch } from "react-redux";
import applicationActions from "../../redux/applications/actions";
import { APPLICATION_STATUS_COLOR } from "../../constants/HelperConstants";

const mapStateToProps = (state: any) => ({
  singleApplication: state.applications.singleApplication,
  currentStatus: state.applications.currentStatus,
});

type IStatusBox = {
  text: string;
  status: any;
  singleApplication: any;
  currentStatus: any;
};
type Status = {
  [key: number]: string;
};

const StatusBox: React.FC<IStatusBox> = ({ text, status, currentStatus }) => {
  const dispatch = useDispatch();

  const handleChange = (status: any) => {
    dispatch({
      type: applicationActions.UPDATE_CURRENT_STATUS,
      payload: Number(status),
    });
  };
  const colorObj: Status = {
    2: "rgb(45, 148, 243, 0.2)",
    3: "rgb(108, 117, 125, 0.2)",
    4: "rgba(222, 2, 32, 0.2)",
    5: "rgb(45, 148, 243, 0.2)",
    6: "rgb(108, 117, 125, 0.2)",
    7: "rgba(253, 126, 20, 0.2)",
    8: "rgba(111, 66, 193, 0.2)",
    9: "rgba(222, 2, 32, 0.2)",
    10: "rgba(232, 62, 140, 0.2)",
    11: "rgba(253, 126, 20, 0.2)",
    12: "rgba(232, 62, 140, 0.2)",
    13: "rgba(222, 2, 32, 0.2)",
    14: "rgba(40, 167, 69, 0.2)",
    15: "rgba(111, 66, 193, 0.2)",
    16: "rgba(40, 167, 69, 0.2)",
    17: "rgba(222, 2, 32, 0.2)",
    18: "rgba(232, 62, 140, 0.2)",
    19: "rgba(40, 167, 69, 0.2)",
    20: "rgba(222, 2, 32, 0.2)",
    21: "rgba(40, 167, 69, 0.2)",
    22: "rgb(108, 117, 125, 0.2)",
    23: "rgb(45, 148, 243, 0.2)",
    24: "rgba(222, 2, 32, 0.2)",
  };
  return (
    <span
      style={
        Number(currentStatus) === Number(status)
          ? {
              backgroundColor: `${colorObj[status]}`,
              color: `#${APPLICATION_STATUS_COLOR[status]}`,
            }
          : { color: `#${APPLICATION_STATUS_COLOR[status]}` }
      }
      className="cursor-pointer mr-2 mb-2 border-solid hover:scale-105 border p-2 rounded-md bg-opacity-20"
      onClick={() => {
        handleChange(status);
      }}
    >
      {text}
    </span>
  );
};

export default connect(mapStateToProps)(StatusBox);
