import { Pagination, Select, Spin, Tooltip } from 'antd';
import { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import jobActions from '../../../redux/jobs/actions';
import { getSearchParamsUrl } from '../../../helper';
import { history } from '../../../utilities/configureAxios';
import { DASHBOARD_EDIT_JOB } from '../../../constants/RouteConstants';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { queryStringToJSON } from '../../../utilities/routies';

interface InputDetails {
  jobs: any;
  location: any;
  loading: boolean;
  handleModalOpen: (userId: string, status: string) => void;
}

const SettingsTable: FC<InputDetails> = ({
  jobs,
  loading,
  handleModalOpen
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { page, q, status, org_id } = queryStringToJSON(search);
  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, q, status, org_id]);

  const call = () => {
    dispatch({
      type: jobActions.GET_ALL_JOBS,
      payload: {
        page: page ?? 1,
        q,
        active: status?.length ? Boolean(Number(status)) : undefined,
        org_id: org_id?.length ? Number(org_id) : undefined
      }
    });
  };

  const StatusSelect: React.FC<any> = ({ status, userId }) => {
    const { Option } = Select;
    const handleChange = (value: any) => {
      handleModalOpen(userId, value);
    };

    return (
      <>
        <Select
          className={`select-box w-[100%] table-select-box ${
            status === 'Active'
              ? 'table-select-box-success'
              : 'table-select-box-error'
          }`}
          defaultValue={status}
          onChange={handleChange}
        >
          <Option value='Active' className='select-box-option'>
            <span className='text-[#52c41a]'>Active</span>
          </Option>
          <Option value='Inactive' className='select-box-option'>
            <span className='text-[#ff4d4f]'>Inactive</span>
          </Option>
        </Select>
      </>
    );
  };
  const hanldeJobClick = (ref_id: any) => {
    history.push(`${DASHBOARD_EDIT_JOB.replace(':id', ref_id)}`);
  };

  return (
    <>
      <Spin size='large' className='hrTable-loader' spinning={loading}>
        <div className='flex flex-col'>
          <div
            style={{ maxWidth: 'calc(100% - 2)' }}
            className='shadow-lg bg-white mx-8 rounded-2xl overflow-x-auto min-h-[80%] mt-[20px]'
          >
            <table className='w-full table_transition'>
              <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                <tr className=' text-[0.9rem] px-2'>
                  <th className='py-4 w-[10%] min-w-[100px] max-w-[10%] text-center text-[#233A5C] text-opacity-50'>
                    S.No
                  </th>
                  <th className='py-4 px-2 w-[20%] min-w-[130px] max-w-[20%] text-[#233A5C] text-opacity-50'>
                    Job Title
                  </th>
                  <th className='py-4 px-2 w-[20%] min-w-[130px] max-w-[20%] text-[#233A5C] text-opacity-50'>
                    Organization Name
                  </th>
                  <th className='py-4 px-2 w-[20%] min-w-[130px] max-w-[20%] text-[#233A5C] text-opacity-50'>
                    Team
                  </th>
                  <th className='py-4 px-2 w-[20%] min-w-[130px] max-w-[20%] text-[#233A5C] text-opacity-50'>
                    Location
                  </th>
                  <th className='py-4 px-2 w-[20%] min-w-[130px] max-w-[20%] text-[#233A5C] text-opacity-50 text-center'>
                    Status
                  </th>
                  <th className='py-4 px-2 w-[20%] min-w-[130px] max-w-[20%] text-center text-[#233A5C] text-opacity-50'>
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {jobs &&
                  jobs?.data?.length > 0 &&
                  jobs?.data?.map((d: any, i: any) => (
                    <tr
                      key={i}
                      className='text-[13px] hover:shadow-md transition-all duration-100 ease-in border-bottom cursor-pointer text-blue-zodiac table-text  font-normal capitalize mb-0  whitespace-nowrap overflow-auto'
                    >
                      <td
                        className='py-4 px-2 w-[10%] max-w-[5rem] text-overflow-none text-center'
                        onClick={() => {
                          hanldeJobClick(d?.id);
                        }}
                      >
                        {jobs?.meta?.pagination?.perPage *
                          (jobs?.meta?.pagination?.page - 1) +
                          i +
                          1}
                      </td>
                      <td
                        className='py-4 px-2 w-[20%] max-w-[15rem] text-overflow-none'
                        onClick={() => {
                          hanldeJobClick(d?.id);
                        }}
                      >
                        <Tooltip placement='topLeft' title={d?.job_title}>
                          {d?.job_title}
                        </Tooltip>
                      </td>

                      <td
                        className='py-4 px-2 w-[20%] max-w-[15rem] text-overflow-none'
                        onClick={() => {
                          hanldeJobClick(d?.id);
                        }}
                      >
                        <Tooltip
                          placement='topLeft'
                          title={d?.organization_name}
                        >
                          {d?.organization_name}
                        </Tooltip>
                      </td>
                      <td
                        className='py-4 px-2 w-[20%] max-w-[15rem] text-overflow-none'
                        onClick={() => {
                          hanldeJobClick(d?.id);
                        }}
                      >
                        <Tooltip
                          placement='topLeft'
                          title={d?.department_obj?.name}
                        >
                          {d?.department_obj?.name}
                        </Tooltip>
                      </td>

                      <td
                        className='py-4 px-2 w-[20%] max-w-[15rem] text-overflow-none'
                        onClick={() => {
                          hanldeJobClick(d?.id);
                        }}
                      >
                        <Tooltip
                          placement='topLeft'
                          title={d?.location_obj?.name}
                        >
                          {d?.location_obj?.name}
                        </Tooltip>
                      </td>
                      <td className='py-4 px-2 w-[20%] text-overflow-none'>
                        <StatusSelect
                          status={d.status === 1 ? 'Active' : 'Inactive'}
                          userId={d.id}
                        />
                      </td>

                      <td className='py-4 px-2 flex justify-around'>
                        <Tooltip placement='topLeft' title={'Edit Job'}>
                          <div
                            className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
                            onClick={() => {
                              history.push(
                                DASHBOARD_EDIT_JOB.replace(':id', d?.id)
                              );
                            }}
                          >
                            <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue font-semibold' />
                          </div>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {jobs && jobs?.data?.length === 0 && (
              <div className='w-full xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center'>
                No Jobs Found
              </div>
            )}
          </div>
          <div className='px-4 lg:px-12 xl:px-16 flex   justify-center mt-5 mb-10'>
            <div className='flex justify-center py-5'>
              <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                current={jobs?.meta?.pagination?.page}
                defaultPageSize={jobs?.meta?.pagination?.perPage}
                onChange={(page) => {
                  history.push(getSearchParamsUrl({ page: page }), {});
                }}
                total={jobs?.meta?.pagination?.count}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default SettingsTable;
