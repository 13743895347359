import { Input, Modal, Spin, Tooltip } from 'antd';
import React, { FC, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import PrimaryButton from '../../Buttons/Primary';
import taskActions from '../../../redux/tasks/actions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FileUpload from '../../FileUpload/TaskFileUpload';

interface editProps {
  heading?: string;
  id?: any;
  loading?: boolean;
  singleTask: any;
  page: any;
}

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  singleTask: state.tasks.singleTask
});

const EditTaskModal: FC<editProps> = ({
  heading,
  id,
  loading,
  singleTask,
  page
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: '',
    description: ''
  });
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [valid, setValid] = useState(false);
  const [attachmentVisible, setAttachmentVisible] = useState(true);
  const [attachmentURL, setAttachmentURL] = useState<any>('');
  const [fileName, setFileName] = useState<any>('');
  const [fileType, setFileType] = useState<any>('');
  const ref = useRef<any>(null);
  useEffect(() => {
    setTaskTitle(singleTask?.data?.title);
    setAttachmentURL(singleTask?.data?.attachment);
    setTaskDescription(singleTask?.data?.description);
    setInitialValues({
      title: singleTask?.data?.title,
      description: singleTask?.data?.description
    });
  }, [
    singleTask?.data?.description,
    singleTask?.data?.attachment,
    singleTask?.data?.title
  ]);
  useEffect(() => {
    if (
      taskTitle?.trim().length > 0 &&
      ref.current?.unprivilegedEditor.getLength() !== 1
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [taskTitle, taskDescription]);

  const [errors, setErrors] = useState({
    taskTitle: '',
    descriptionError: ''
  });

  const showModal = () => {
    dispatch({
      type: taskActions.GET_SINGLE_TASK,
      payload: {
        id
      }
    });
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
    setTaskTitle(singleTask.data.title);
    setTaskDescription(singleTask.data.description);
    setAttachmentVisible(true);
  };
  const onSave = () => {
    const errorsObject = {
      taskTitle: taskTitle === '' ? 'Job Title is required' : '',
      descriptionError:
        taskDescription === '<p><br></p>' ? 'Description is required' : ''
    };
    if (!errorsObject.taskTitle && !errorsObject.descriptionError) {
      const body =
        singleTask.data.title !== taskTitle
          ? {
              title: taskTitle,
              description: taskDescription,
              attachment: fileName,
              attachment_mime_type: fileType
            }
          : {
              description: taskDescription,
              attachment: fileName,
              attachment_mime_type: fileType
            };
      dispatch({
        type: taskActions.EDIT_TASK,
        payload: {
          id,
          page,
          body: body
        }
      });
      setAttachmentVisible(true);
      handleCancel();
    }
    setErrors(errorsObject);
  };
  const handleChange = () => {
    setTaskDescription(ref.current?.unprivilegedEditor.getHTML());
  };
  const handleAttachmentCancel = () => {
    setAttachmentVisible(false);
    setAttachmentURL(null);
    setFileName(null);
    setFileType(null);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ];

  return (
    <>
      <div
        className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
        onClick={showModal}
      >
        <Tooltip title='Edit Task' placement='top'>
          <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue font-semibold' />
        </Tooltip>
      </div>
      {!loading && (
        <Modal
          className='edit-task-modal'
          visible={isOpen}
          onCancel={handleCancel}
          destroyOnClose={true}
          width='1099px'
          footer={null}
        >
          <div className='bg-[#FAFDFF]'>
            <div className='flex justify-between '>
              <div className='md:px-8'>
                <h3 className='text-xl text-center font-semibold text-blue-zodiac'>
                  {heading}
                </h3>
              </div>

              <div
                className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                onClick={handleCancel}
              >
                <AiOutlineClose className='w-5 h-5 text-gray-light' />
              </div>
            </div>

            <div className='pt-8 md:px-8 -space-y-2 relative'>
              <p className='text-blue-streak text-base opacity-50'>
                {'Task Title'}
                <span className='text-lg text-star-red pl-1'>*</span>
              </p>
              <Input
                onChange={(e) => {
                  let val = e.target.value;
                  setTaskTitle(val);
                }}
                className='edit-input text-base capitalize'
                value={taskTitle}
              />
              {errors.taskTitle !== '' && (
                <div className='error-message absolute bottom-[-20px]'>
                  {errors.taskTitle}
                </div>
              )}
            </div>

            <div className='pt-8 md:px-8 -space-y-2 relative'>
              <p className='text-blue-streak text-base uppercase opacity-50'>
                {'Description'}
                <span className='text-lg text-star-red pl-1'>*</span>
              </p>
              <ReactQuill
                className='editor'
                ref={ref}
                onChange={handleChange}
                value={taskDescription}
                style={{
                  background: '#fff',
                  borderRadius: '8px'
                }}
                modules={modules}
                formats={formats}
              />
              {errors.descriptionError !== '' && (
                <div className='error-message absolute bottom-[-20px]'>
                  {errors.descriptionError}
                </div>
              )}
            </div>

            <div className='pt-8 md:px-8 -space-y-2 relative'>
              <p className='text-blue-streak text-base uppercase opacity-50'>
                {'Attachment'}
              </p>
              <div
                className={`${
                  attachmentURL && attachmentURL !== '' && attachmentVisible
                    ? 'task-input'
                    : ''
                } relative`}
              >
                <FileUpload
                  setFileName={setFileName}
                  fileType={fileType}
                  setFileType={setFileType}
                />
                {attachmentURL && attachmentURL !== '' && attachmentVisible && (
                  <div className='flex absolute left-48 top-1 space-x-1'>
                    <a
                      className=''
                      target='_blank'
                      rel='noopener noreferrer'
                      href={attachmentURL}
                    >
                      Link{' '}
                    </a>
                    <div className='h-full mt-[2px] cursor-pointer'>
                      <MdCancel size={18} onClick={handleAttachmentCancel} />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
              <PrimaryButton disabled={!valid} text='Save' onClick={onSave} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default connect(mapStateToProps)(EditTaskModal);
