import { Switch, Route, Redirect } from "react-router-dom";
import { history } from "../utilities/configureAxios";
import Hr from "./hr";
import Admin from "./admin";
import ErrorPage from "../page/404Error";
import TooManyRequest from "../page/429Error"
import Employee from "./employee";
import { ADMIN, DASHBOARD, EMPLOYEE, INTERVIEWER } from "../constants/RouteConstants";
import Interviewer from "./interviewer";
import Sidebar from "../components/Sidebar";

function AuthenticatedRoutes() {
  return (
    <div className="page-container flex max-h-screen">
      <div className="hidden xl:block lg:w-[290px] fixed side-bar-shadow  bg-white  h-screen min-w-[250px] left-0">
        {/* <HRSidebar location={history.location} /> */}
        <Sidebar/>
      </div>
      <div className="w-full xl:w-4/5 xl:ml-[20%]">
      <Switch location={history.location}>
        <Route path={DASHBOARD}>
          <Hr />
        </Route>
        <Route path={ADMIN}>
          <Admin />
        </Route>
        <Route path={EMPLOYEE}>
          <Employee />
        </Route>
        <Route path={INTERVIEWER}>
          <Interviewer />
        </Route>
        <Route
          path="/429"
          render={() => (
            <div className="page-container flex">
              <TooManyRequest />
            </div>
          )}
        />
        {/* <Redirect to="/429" /> */}
        <Route
          path="/404"
          render={() => (
            <div className="page-container flex">
              <ErrorPage />
            </div>
          )}
        />
        <Redirect to="/404" />
      </Switch>
      </div>
    </div>
  );
}

export default AuthenticatedRoutes;
