import { Select } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import applicationActions from '../../redux/applications/actions';

interface SelectBoxProps {
  status?: any;
  ref_id?: any;
  page?: any;
}

const statusArray = [
  {
    name: 'Pending',
    value: 1
  },
  {
    name: 'Shortlisted',
    value: 2
  },
  {
    name: 'CV Rejected',
    value: 3
  },
  {
    name: 'Rejected',
    value: 4
  },
  {
    name: 'Interview Round - 1',
    value: 5
  },
  {
    name: 'Rescheduled',
    value: 6
  },
  {
    name: 'Absent',
    value: 7
  },
  {
    name: 'Task Sent',
    value: 8
  },
  {
    name: 'Task not submitted',
    value: 9
  },
  {
    name: 'Review',
    value: 10
  },
  {
    name: 'Task Discussion',
    value: 11
  },
  {
    name: 'Interview Round - 2',
    value: 12
  },
  {
    name: 'HR/Founder',
    value: 13
  },
  {
    name: 'Selected',
    value: 14
  },
  {
    name: 'LOI Sent',
    value: 15
  },
  {
    name: 'LOI Accepted',
    value: 16
  },
  {
    name: 'LOI Declined',
    value: 17
  },
  {
    name: 'Offer Letter Sent',
    value: 18
  },
  {
    name: 'Offer Letter Accepted',
    value: 19
  },
  {
    name: 'Offer Letter Declined',
    value: 20
  },
  {
    name: 'Joined',
    value: 21
  },
  {
    name: 'Deferred',
    value: 22
  },
  {
    name: 'Save For Later',
    value: 23
  },
  {
    name: 'Duplicate Application',
    value: 24
  },
  {
    name: 'Interview Round - 3',
    value: 25
  },
  {
    name: 'Interview Round - 4',
    value: 26
  },
  {
    name: 'Interview Round - 5',
    value: 27
  }
];

const SelectBox: React.FC<SelectBoxProps> = ({ status, ref_id, page }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [appStatus, setAppStatus] = useState<number>(1);

  const handleChange = (value: any) => {
    setAppStatus(value);
    dispatch({
      type: applicationActions.UPDATE_STATUS_OF_APPLICATION,
      payload: { ref_id, body: { status: value }, page }
    });
  };

  return (
    <div className='test-select'>
      <Select
        className='select-box w-3/3 '
        defaultValue={appStatus}
        value={status}
        showArrow={false}
        open={false}
        style={{ minWidth: '160px' }}
      >
        {statusArray.map((item: any) => (
          <Option
            value={item.value}
            key={item.value}
            className='select-box-option'
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectBox;
