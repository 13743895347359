import moment from "moment";
import React, { FC } from "react";
import { BsClockHistory } from "react-icons/bs";
import useRescheduleInterview from "../view/useRescheduleInterview";

interface ICreatedInterview {
  item: any;
}
const RescheduledInterview: FC<ICreatedInterview> = ({ item }) => {
  const { ConvertData } = useRescheduleInterview();

  return (
    <>
      {item.meta.length > 0 && (
        <div>
          <div className="flex">
            <div className="bg-light-blue-secondary p-3 rounded-full mr-4">
              <BsClockHistory className="w-6 h-6 font-[900] text-light-blue" />
            </div>
            <div className="text-base pt-2 relative w-full capitalize ">
              <h3>
                Interview <span className="text-light-blue">Rescheduled</span>
              </h3>
              <p className="absolute text-sm p-0 m-0 bottom-[-0.25rem] text-blue-radic opacity-50">
                {moment(item.created_at).format(" MMMM Do | h:mm A")}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="h-auto p-4 w-4 border-l-[1.5px] border border-r-0 border-t-0 border-b-0 translate-x-[1.5rem] border-light-blue-secondary" />
            <ul className="ml-2 mt-2 bg-light-blue-secondary w-full p-4 opacity-50 rounded-md pl-8">
              {ConvertData(item.meta)?.map((element) =>
                element ? <li>{element}</li> : null
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default RescheduledInterview;
