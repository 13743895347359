const actions = {
  SET_EMAIL_TEMPLATES: 'SET_EMAIL_TEMPLATES',
  GET_ALL_EMAIL_TEMPLATES: 'GET_ALL_EMAIL_TEMPLATES',
  SET_TEMPLATE: `SET_TEMPLATE`,
  SET_FORM_FIELDS: 'SET_FORM_FIELDS',
  SET_FORM_VALUES: 'SET_FORM_VALUES',
  RESET_FORM_VALUES: 'RESET_FORM_VALUES',
  SEND_EMAIL:'SEND_EMAIL',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
};

export default actions;