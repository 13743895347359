import { Select, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IInterviewFilter {
  primaryFilter: string;
  containerWidth?: string;
  primaryFitlerValue: string | number;
  values?: any;
  onSelection?: (value: any) => void;
  currentValue?: any;
  filterType: string;
}

const InterviewFilter: FC<IInterviewFilter> = ({
  primaryFilter,
  primaryFitlerValue,
  values,
  onSelection,
  currentValue,
  filterType
}) => {
  const { Option } = Select;
  const [isDefaultValue, setIsDefaultValue] = useState<boolean>(true);

  const locationURL = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(locationURL.search);
    const hasLevelParam = searchParams.has('level');
    const hasCategoryParam = searchParams.has('category');

    if (filterType === 'level') {
      setIsDefaultValue(!hasLevelParam);
    } else if (filterType === 'category') {
      setIsDefaultValue(!hasCategoryParam);
    }
  }, [locationURL, filterType]);

  return (
    <div className='flex flex-col min-w-[10rem] shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
      <Select
        showSearch
        allowClear
        filterOption={(input, option) => {
          // @ts-ignore
          return option?.children?.props?.title
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        className={`${
          isDefaultValue ? 'faded-select' : ''
        } filter-select w-full`}
        value={currentValue}
        onChange={onSelection}
        placeholder={primaryFilter}
      >
        <Option className='filter-select-option' value={primaryFitlerValue}>
          <Tooltip title={primaryFilter}>{primaryFilter}</Tooltip>
        </Option>
        {values?.map((item: any) => (
          <Option
            className='filter-select-option'
            value={item.value}
            key={item.value}
          >
            <Tooltip title={item.name}>{item.name}</Tooltip>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default InterviewFilter;
