import { DatePicker, Modal, Select, TimePicker } from 'antd';
import moment from 'moment';
import React, { DependencyList, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import PrimaryButton from '../../Buttons/Primary';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  tasks: state.tasks.tasks,
  paginatedTask: state.tasks.paginatedTask
});

type EditTask = {
  data: any;
  tasks: any;
  id: any;
  paginatedTask: any;
};

const EditAllotedTask: React.FC<EditTask> = ({
  data,
  tasks,
  id,
  paginatedTask
}) => {
  const [taskTitle, setTaskTitle] = useState();
  const [taskId, setTaskId] = useState();
  const [endDate, setEndDate] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [scheduleDate, setScheduleDate] = useState<any>();
  const [scheduleTime, setScheduleTime] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [valid, setValid] = useState(false);
  const currentTask = paginatedTask?.data?.filter(
    (item: any) => item.title === data?.task_title
  );

  useEffect(() => {
    if (taskId && endDate && endTime) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [taskId, endDate, endTime]);

  useEffect(() => {
    if (!!scheduleDate === true && !!scheduleTime === false) {
      setValid(false);
    } else if (!!scheduleDate === false && !!scheduleTime === true) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [scheduleDate, scheduleTime]);

  useEffect(() => {
    if (data && data?.task_title) {
      setTaskTitle(data?.task_title);
    }
    setEndDate(
      moment(moment(data.expiry).format('YYYY-MM-DD hh:mm a'), 'YYYY-MM-DD')
    );
    setEndTime(moment(moment(data?.expiry).format('LT'), 'h:mm a'));
    // --------schdeule data and time--------------------------------
    setScheduleDate(
      data?.scheduled_at
        ? moment(
            moment(data.scheduled_at).format('YYYY-MM-DD hh:mm a'),
            'YYYY-MM-DD'
          )
        : ''
    );
    setScheduleTime(
      data?.scheduled_at
        ? moment(moment(data?.scheduled_at).format('LT'), 'h:mm a')
        : ''
    );
    // --------------------------------------------------------------
    if (currentTask && currentTask[0]?.id) {
      setTaskId(currentTask[0]?.id);
    }
  }, []);
  const dispatch = useDispatch();
  const handleChange = (value: any, key: any) => {
    if (key && key?.key) {
      setTaskId(key?.key);
    }
    if (value) {
      setTaskTitle(value);
    }
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleDateChange = (date: any) => {
    setEndDate(date);
  };
  const handleTimeChange = (time: any) => {
    setEndTime(time);
  };

  const handleScheduleDateChange = (date: any) => {
    setScheduleDate(date);
  };
  const handleScheduleTimeChange = (time: any) => {
    setScheduleTime(time);
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const onSend = () => {
    setIsOpen(false);

    //  -----editdetail---

    if (!!scheduleDate && !!scheduleTime) {
      const body = {
        expiry: moment(
          `${endDate?.format('YYYY-MM-DD')} ${endTime.format('hh:mm a')}`,
          'YYYY-MM-DD hh:mm a'
        )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
        task_id: taskId,
        scheduled_at: moment(
          `${scheduleDate?.format('YYYY-MM-DD')} ${scheduleTime.format(
            'hh:mm a'
          )}`,
          'YYYY-MM-DD hh:mm a'
        )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')
      };
      if (id) {
        dispatch({
          type: applicationActions.UPDATE_ALLOTED_TASK,
          payload: {
            body: body,
            ref_id: id,
            taskId: data.id
          }
        });
      }
    }
    if (!!!scheduleDate && !!!scheduleTime) {
      const body = {
        expiry: moment(
          `${endDate?.format('YYYY-MM-DD')} ${endTime.format('hh:mm a')}`,
          'YYYY-MM-DD hh:mm a'
        )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
        task_id: taskId
      };
      if (id) {
        dispatch({
          type: applicationActions.UPDATE_ALLOTED_TASK,
          payload: {
            body: body,
            ref_id: id,
            taskId: data.id
          }
        });
      }
    }

    // const body_contains_scheduler = {
    //   expiry: moment(`${endDate?.format("YYYY-MM-DD")} ${endTime.format("hh:mm a")}`, "YYYY-MM-DD hh:mm a").utc().format("YYYY-MM-DDTHH:mm:ss"),
    //   task_id: taskId,
    //   scheduled_at: moment(`${scheduleDate?.format("YYYY-MM-DD")} ${scheduleTime.format("hh:mm a")}`, "YYYY-MM-DD hh:mm a").utc().format("YYYY-MM-DDTHH:mm:ss"),
    // }
    // const body = {
    //   expiry: moment(`${endDate?.format("YYYY-MM-DD")} ${endTime.format("hh:mm a")}`, "YYYY-MM-DD hh:mm a").utc().format("YYYY-MM-DDTHH:mm:ss"),
    //   task_id: taskId,
    // };

    //  ---------

    dispatch({
      type: applicationActions.TOGGLE_TASK_MODAL,
      payload: false
    });
  };

  const { Option } = Select;

  return (
    <>
      <div
        className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
        onClick={showModal}
      >
        <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue font-semibold' />
      </div>
      <Modal
        className='edit-modal'
        visible={isOpen}
        onCancel={handleModalClose}
        destroyOnClose={true}
        width='620px'
      >
        <div className='flex justify-between'>
          <div className='md:px-4'>
            <h3 className='text-lg text-center font-semibold text-[#233A5C]'>
              Edit Task
            </h3>
          </div>
          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleModalClose}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>
        <div className='pt-8 md:px-4 space-y-2 relative'>
          <div>
            <div className='text-blue-streak text-base opacity-50'>
              Task
              <span className='text-lg text-star-red pl-1'>*</span>
            </div>
            <Select
              className='filter-select text-base w-full border border-[#F4F7FC] rounded-lg'
              placeholder='Please select'
              onChange={handleChange}
              value={taskTitle}
            >
              {paginatedTask &&
                paginatedTask?.data?.map((item: any) => (
                  <Option value={item.id} key={item.id}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </div>
          <div className='py-1' />
          <div className='flex justify-between space-x-3'>
            <div className='w-full'>
              <div className='text-blue-streak text-base  opacity-50'>
                End Date
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <DatePicker
                value={endDate}
                className='custom-date-picker edit-input text-base'
                onChange={handleDateChange}
                disabledDate={(current) =>
                  //@ts-ignore
                  current.isBefore(moment().subtract(1, 'day'))
                }
              />
            </div>
            <div className='w-full'>
              <div className='text-blue-streak text-base  opacity-50'>
                End Time
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <TimePicker
                value={endTime}
                use12Hours
                format='h:mm a'
                className='edit-input text-base'
                onChange={handleTimeChange}
              />
            </div>
          </div>
          <div className='py-1' />

          {/* -----added schedule date and time------- */}
          <div className='flex justify-between space-x-3'>
            <div className='w-full'>
              <div className='text-blue-streak text-base  opacity-50'>
                Schedule Date
              </div>
              <DatePicker
                value={scheduleDate}
                className='custom-date-picker edit-input text-base'
                onChange={handleScheduleDateChange}
                disabledDate={(current) =>
                  //@ts-ignore
                  current.isBefore(moment().subtract(1, 'day'))
                }
              />
            </div>
            <div className='w-full'>
              <div className='text-blue-streak text-base  opacity-50'>
                Schedule Time
              </div>
              <TimePicker
                value={scheduleTime}
                use12Hours
                format='h:mm a'
                className='edit-input text-base'
                onChange={handleScheduleTimeChange}
              />
            </div>
          </div>
          {/* ---------------------------------------- */}
        </div>
        <div className='flex justify-center mt-8'>
          <PrimaryButton disabled={!valid} text='Send' onClick={onSend} />
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(EditAllotedTask);
