import { Select, Tag, Tooltip } from 'antd';
import { jobStatusOptions } from '../../helper';

interface Option {
  data?: any;
}

const JobOptionBox = ({ data }: Option) => {
  return (
    <Select.Option
      label={data.job_title}
      value={`${String(data.job_ref_id)}~${data.job_title}~${data.id}`}
      data={data}
    >
      <div className='flex justify-between'>
        <div className='truncate w-1/2'>
          <Tooltip title={data?.job_title}>{data?.job_title}</Tooltip>
        </div>
        <div className='w-1/2 flex gap-y-2.5 justify-end items-start'>
          <div>
            <Tag
              color={data?.status === 1 ? 'success' : 'error'}
              className='statusTag'
            >
              {jobStatusOptions[data?.status]?.label}
            </Tag>
          </div>
          <div>
            <Tag color='blue' className='!rounded-[5px]'>
              {`${data?.organization_name.slice(0, 20)}${
                data?.organization_name?.length > 20 ? '...' : ''
              }`}
            </Tag>
          </div>
        </div>
      </div>
    </Select.Option>
  );
};

export default JobOptionBox;
