import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { AiOutlineSearch } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import filterActions from '../../redux/filters/actions';
import { history } from '../../utilities/configureAxios';
import { queryStringToJSON } from '../../utilities/routies';
import { getSearchParamsUrl } from '../../helper';
import useDebounce from '../../hooks/useDebounce';

interface SearchProps {
  inputClassName?: string;
  onClick?: () => void;
  search?: any;
  filters?: any;
  active?: boolean;
  placeholder?: string;
}

const mapStateToProps = (state: any) => ({
  search: state.filters.search,
  filters: state.filters.filters,
  active: state.filters.active
});

const SearchBox: React.FC<SearchProps> = ({
  inputClassName,
  onClick,
  search,
  placeholder
}) => {
  const dispatch = useDispatch();
  const { search: routeSearchParams } = useLocation();
  const searchParams = queryStringToJSON(routeSearchParams);
  const [searchText, setSearchText] = useState<string>(searchParams?.q || '');

  const handleSearchChange = (e: any) => {
    dispatch({
      type: filterActions.SET_STATE,
      payload: { search: e.target.value, isFilterApplied: true }
    });
    setSearchText(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: filterActions.SET_STATE,
      payload: { search: '' }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchParams.q) {
      setSearchText('');
      dispatch({
        type: filterActions.SET_STATE,
        payload: { search: '' }
      });
    }
  }, [routeSearchParams, searchParams.q, dispatch]);

  const debounceSearch = useDebounce(searchText, 300);
  useEffect(() => {
    if (searchParams?.q?.length > 0 || searchText?.length > 0) {
      history.push(getSearchParamsUrl({ q: searchText, page: 1 }), {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  return (
    <>
      <Input
        className={`${inputClassName}`}
        placeholder={placeholder}
        onChange={handleSearchChange}
        value={searchText}
        prefix={
          <AiOutlineSearch
            className='w-10 h-10 g:w-7 lg:h-7 text-gray-400'
            onClick={onClick}
          />
        }
      />
    </>
  );
};

export default connect(mapStateToProps)(SearchBox);
