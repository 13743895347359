import { Empty, Modal, Spin, Table } from 'antd';
import { FC, Fragment } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { connect } from 'react-redux';
import useDuplicateApplication from './views/useDuplicateApplication';
import useColumns from './views/useColumn';
import { CustomObject } from '../../../Interfaces/common';

const mapStateToProps = (state: any) => ({
  loading: state.applications.loading,
  data: state.applications.duplicateApplication,
  selectedStatus: state.applications.currentStatus,
  currentStatus: state.applications.currentStatus,
  formErrors: state.applications.formErrors
});

interface IDuplicateApplications {
  open: boolean;
  onCancel: any;
  data: Record<string, any>[];
  currentRecord?: CustomObject;
  handleSetRecord: any;
  loading: boolean;
}

const DuplicateApplications: FC<IDuplicateApplications> = ({
  open = true,
  onCancel,
  data,
  handleSetRecord,
  loading
}) => {
  const { rowClick } = useDuplicateApplication();
  const { columns } = useColumns({ handleSetRecord });

  return (
    <Fragment>
      <Modal
        destroyOnClose={true}
        open={open}
        onCancel={() => onCancel()}
        width='80%'
        className='edit-modal relative table-custom-loader'
        bodyStyle={{
          backgroundColor: '#FAFDFF',
          borderRadius: '0.25rem',
          padding: '15px'
        }}
        // centered
      >
        <Spin spinning={loading} className=' h-full'>
          <div className='flex justify-between'>
            <h3 className='text-lg my-4 text-center capitalize font-semibold text-[#233A5C]'>
              Duplicate Applications
            </h3>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
              onClick={() => onCancel()}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>
          <Table
            locale={{
              emptyText: (
                <p className='w-full  xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center flex-col'>
                  <Empty description={false} />
                  <span className=''>No Duplicate Application available</span>
                </p>
              )
            }}
            columns={columns}
            showHeader={true}
            onRow={(record) => {
              return {
                onClick: () => {
                  onCancel();
                  rowClick(record);
                }
              };
            }}
            dataSource={data}
            scroll={{ x: true, y: '60vh' }}
            pagination={false}
            className='table-scrollbar'
            rowClassName={
              '!bg-white text-blue-zodiac max-h-[3.00rem] cursor-pointer'
            }
          />
        </Spin>
      </Modal>
    </Fragment>
  );
};

export default connect(mapStateToProps)(DuplicateApplications);
