const actions = {
  SET_TASKS_STATE: "SET_TASKS_STATE",
  GET_ALL_TASKS: "GET_ALL_TASKS",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  EDIT_TASK: "EDIT_TASK",
  ADD_TASK: "ADD_TASK",
  DELETE_TASK: "DELETE_TASK",
  GET_SINGLE_TASK: "GET_SINGLE_TASK",
  WITHOUT_PAGINATED_TASK_LIST: "WITHOUT_PAGINATED_TASK_LIST",
};

export default actions;
