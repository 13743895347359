import { socialLinks } from "../../constants/HelperConstants";

const SocialLinksFooter = () => {
  return (
    <>
    <footer className="w-full  flex justify-center space-x-4 items-center py-4 bg-[#2D94F32F] rounded-b-[20px]">
      <p className="flex md:text-[2rem] lg:text-[1rem] items-center mb-0 text-[#303C60] font-medium text-base">
        Follow us on:
      </p>{" "}
      <span className="flex space-x-5">
        <a target="_blank" rel="noreferrer" href={socialLinks.linkedin}>
          <img
            className="md:h-[25px] lg:h-[20px]"
            src="/Linkedin.svg"
            alt="linkedin"
          />
        </a>
        <a target="_blank" rel="noreferrer" href={socialLinks.instagram}>
          <img
            className="md:h-[25px] lg:h-[20px]"
            src="/Instagram.svg"
            alt="Instagram"
          />
        </a>
        <a target="_blank" rel="noreferrer" href={socialLinks.facebook}>
          <img
            className="md:h-[25px] lg:h-[20px]"
            src="/Facebook.svg"
            alt="Facebook"
          />
        </a>
        <a target="_blank" rel="noreferrer" href={socialLinks.youtube}>
          <img
            className="md:h-[25px] lg:h-[20px]"
            src="/Youtube.svg"
            alt="Youtube"
          />
        </a>
        <a target="_blank" rel="noreferrer" href={socialLinks.twitter}>
          <img
            className="md:h-[25px] lg:h-[20px]"
            src="/Twitter.svg"
            alt="Twitter"
          />
        </a>
      </span>
    </footer>
    <div className="flex text-[10px] text-[#6F7070] text-center justify-center py-6">Copyright &#169; {new Date().getFullYear()} . All rights reserved | Powered By <span>Squareboat</span></div>
    </>
  );
};

export default SocialLinksFooter;
