import QueryString from 'qs';
import { useDispatch } from 'react-redux';
import applicationActions from '../../../../redux/applications/actions';
import { INDIVIDUAL_CANDIDATES } from '../../../../constants/RouteConstants';
import { getCurrentQueryParams } from '../../../../helper';
import { history } from '../../../../utilities/configureAxios';

const useDuplicateApplication = () => {
  const dispatch = useDispatch();
  const rowClick = (record: any) => {
    dispatch({
      type: applicationActions.TOGGLE_UPDATE_STATUS_MODAL,
      payload: false
    });
    history.push(
      `${INDIVIDUAL_CANDIDATES.replace(
        ':id',
        record?.ref_id
      )}?${QueryString.stringify(getCurrentQueryParams())}`
    );
  };
  return { rowClick };
};

export default useDuplicateApplication;
