import { Button } from "antd";
import React from "react";

interface ICancelButton {
  onClick?: () => void;
  text?: string;
  disabled?: boolean;
}

const CancelButton: React.FC<ICancelButton> = ({ onClick, text, disabled }) => {
  return (
    <>
      <Button className="cancel-button" onClick={onClick} disabled={disabled}>
        {text ? text : "Cancel"}
      </Button>
    </>
  );
};

export default CancelButton;
