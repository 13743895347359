/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import { Pagination, Spin, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import referralActions from '../../../redux/referrals/actions';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../../helper';
import { history } from '../../../utilities/configureAxios';
import EditReferral from '../../Modal/Edit/EditReferral';
import DeleteReferral from '../../Modal/Delete/deleteReferral';
import AddReferral from '../../Modal/AddReferral';
import { AiFillInfoCircle } from 'react-icons/ai';

const mapStateToProps = (state: any) => ({
  employeeReferrals: state.referrals.employeeReferrals,
  loading: state.auth.loading
});

interface ICandidateTable {
  employeeReferrals: any;
  loading: boolean;
}

const ReferralTable: React.FC<ICandidateTable> = ({
  employeeReferrals,
  loading
}) => {
  const { page } = getCurrentQueryParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: referralActions.GET_EMPLOYEE_REFERRALS,
      payload: {
        page: page ?? 1
      }
    });
  }, [page, dispatch]);
  return (
    <>
      <Spin size='large' className='hrTable-loader' spinning={loading}>
        <div className='flex flex-col'>
          <div className='bg-white shadow-lg mx-8 rounded-2xl overflow-x-auto min-h-[80%]'>
            <table className='w-full table_transition'>
              {employeeReferrals && employeeReferrals?.data?.length > 0 && (
                <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                  <tr className=' text-[0.9rem] px-2 text-center'>
                    <th className='py-4 w-[10%] max-w-[10%] text-[#233A5C] text-opacity-50'>
                      S.No
                    </th>
                    <th className='py-4 w-[20%] max-w-[20%] text-[#233A5C] text-opacity-50'>
                      Name
                    </th>
                    <th className='py-4 w-[20%] max-w-[15%] text-[#233A5C] text-opacity-50'>
                      Job Title
                    </th>
                    <th className='py-4 w-[20%] max-w-[15%] text-[#233A5C] text-opacity-50'>
                      Mobile Number
                    </th>
                    <th className='py-4 w-[10%] max-w-[20%] text-[#233A5C] text-opacity-50'>
                      Status
                    </th>
                    <th className='py-4 w-[10%] max-w-[20%] text-[#233A5C] text-opacity-50'>
                      Actions
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {employeeReferrals &&
                  employeeReferrals?.data?.length > 0 &&
                  employeeReferrals?.data?.map((d: any, i: any) => (
                    <tr
                      key={d.uuid}
                      className='text-base border-bottom text-blue-zodiac table-text  font-normal capitalize mb-0  whitespace-nowrap overflow-auto text-center'
                    >
                      <td className='py-2 pr-2 w-[10%] max-w-[5rem] text-overflow-none'>
                        {employeeReferrals?.meta?.pagination?.perPage *
                          (employeeReferrals?.meta?.pagination?.page - 1) +
                          i +
                          1}
                      </td>

                      <td className='py-2 pr-2 w-[20%] max-w-[15rem] text-overflow-none'>
                        <Tooltip placement='bottomLeft' title={d?.name}>
                          {d?.full_name}
                        </Tooltip>
                      </td>
                      <td className='py-2 pr-2 w-[20%] max-w-[8rem] text-overflow-none'>
                        <Tooltip placement='bottomLeft' title={d?.job_title}>
                          {d?.job_title}
                        </Tooltip>
                      </td>
                      <td className='py-2 pr-2 w-[15%] max-w-[8rem] text-overflow-none'>
                        <Tooltip placement='bottomLeft' title={d?.contact}>
                          {d?.contact}
                        </Tooltip>
                      </td>
                      <td className='py-2 w-[10%] max-w-[20%] cursor-not-allowed text-center '>
                        <a
                          className={
                            d.status === 0 || d?.status === 2
                              ? ' cursor-not-allowed pointer-events-none text-gray-light'
                              : 'text-light-blue'
                          }
                          href={`/status/${d.application_slug}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          View Status
                        </a>
                      </td>
                      <td className='py-2 pr-2 pb-2 w-full max-w-[8rem] flex gap-x-2 justify-between ml-4'>
                        <EditReferral
                          page={page}
                          heading='Edit Referral'
                          id={d.uuid}
                          status={d.status}
                        />
                        <DeleteReferral
                          heading='Delete Referral'
                          desc='Are you sure you want to delete this referral from the ATS?'
                          userId={d.uuid}
                          page={page}
                          status={d.status}
                        />
                        <Tooltip placement='topRight' title={d?.description}>
                          <div
                            style={{
                              backgroundColor: '#2D94F31A',
                              width: '32px',
                              height: '32px',
                              borderRadius: '5px',
                              textAlign: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            <AiFillInfoCircle
                              style={{ fontSize: '20px', color: '#1890FF' }}
                            />
                          </div>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {employeeReferrals && employeeReferrals?.data?.length === 0 && (
              <div className='w-full min-h-[70vh] text-blue-zodiac font-medium flex flex-col justify-center items-center'>
                <div>
                  <img src='/TableEmptyState.svg' alt='' />
                </div>
                <p className='max-w-[350px] text-center text-[#0E254B] text-opacity-40'>
                  No Referrals has been found yet.
                </p>
                <AddReferral heading='Add Referral' />
              </div>
            )}
          </div>
          {employeeReferrals && employeeReferrals?.data?.length > 0 && (
            <div className='flex justify-center py-5'>
              <Pagination
                showSizeChanger={false}
                current={employeeReferrals?.meta?.pagination?.page}
                pageSize={10}
                onChange={(page) => {
                  history.push(getSearchParamsUrl({ page: page }), {});
                  // GET this api again here for refetching
                  // https://ats-service.squareboat.info/referrals/employees?page=1
                  dispatch({
                    type: referralActions.GET_EMPLOYEE_REFERRALS,
                    payload: {
                      page: page ?? 1
                    }
                  });
                }}
                total={employeeReferrals?.meta?.pagination?.count}
              />
            </div>
          )}
        </div>
      </Spin>
    </>
  );
};

export default connect(mapStateToProps)(ReferralTable);
