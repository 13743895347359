import actions from "./actions";

const initialState = {
  users: [],
  allInterviewers: [],
  selectedInterview:{}
};

export default function interviewerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case actions.SET_STATE: {
      return { ...state, ...payload };
    }
    case actions.SET_SELECTED_INTERVIEW: {
      return { ...state, selectedInterview:{...payload} };
    }
    default: {
      return state;
    }
  }
}
