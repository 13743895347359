import { Tooltip } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import moment from 'moment';
import { FaRegSnowflake } from 'react-icons/fa';
import CandidateStatusSelect from '../CandiateStatusSelect/CandidateStatusSelect';

const useColumns = ({
  handleSetRecord
}: {
  handleSetRecord: (data: any, value: string) => void;
}) => {
  const columns = [
    {
      title: 'Ref ID',
      key: 'ref_id',
      dataIndex: 'ref_id',
      // ellipsis: true,
      width: '10%',
      align: 'center' as AlignType,
      render: (text: string) => (
        <span className='max-w-[5rem] min-w-[5rem] line-clamp-1'>
          <Tooltip title={text}>{text}</Tooltip>
        </span>
      )
    },
    {
      title: 'Name',
      key: 'applicant_name',
      dataIndex: 'applicant_name',
      // ellipsis: true,
      width: '100',
      render: (text: string) => (
        <span className='max-w-[5rem] min-w-[10rem] line-clamp-1'>
          <Tooltip title={text}>
            {text.length > 20 ? text?.substr(0, 14) + '...' : text}
          </Tooltip>
        </span>
      )
    },
    {
      title: 'Email',
      key: 'applicant_email',
      dataIndex: 'applicant_email',
      // ellipsis: true,
      width: '100',
      render: (text: string) => (
        <span className='max-w-[5rem]'>
          <Tooltip title={text}>
            {text.length > 20 ? text?.substr(0, 14) + '...' : text}
          </Tooltip>
        </span>
      )
    },
    {
      title: 'Contact',
      key: 'applicant_contact',
      dataIndex: 'applicant_contact',
      align: 'center' as AlignType,
      // ellipsis: true,
      width: '100',
      render: (text: string) => (
        <span className='max-w-[5rem]'>
          <Tooltip title={text}>{text}</Tooltip>
        </span>
      )
    },
    {
      title: 'Job Title',
      key: 'job_title',
      dataIndex: 'job_title',
      // ellipsis: true,
      width: '100',
      render: (text: string) => (
        <span className='max-w-[5rem] min-w-[10rem] line-clamp-1'>
          <Tooltip title={text}>{text}</Tooltip>
        </span>
      )
    },
    {
      title: 'Sourcing Date',
      key: 'sourcing_date',
      dataIndex: 'sourcing_date',
      align: 'center' as AlignType,
      width: '100',

      render: (text: string) => (
        <span className='max-w-[5rem] min-w-[10rem] line-clamp-1'>
          <Tooltip title={moment(text).format('DD-MM-YYYY')}>
            {text ? moment(text).format('DD-MM-YYYY') : 'NA'}
          </Tooltip>
        </span>
      )
    },
    {
      title: 'Shortlisted By',
      key: 'ta_name',
      dataIndex: 'ta_name',
      // ellipsis: true,
      width: '100',
      render: (text: string) => (
        <span className='max-w-[5rem] min-w-[10rem] line-clamp-1'>
          <Tooltip title={text}>{text ? text : 'NA'}</Tooltip>
        </span>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center' as AlignType,
      width: 150,
      className: '!p-0',
      render: (text: number, record: any) => (
        <div className='w-full h-full' onClick={(e) => e.stopPropagation()}>
          <div className='w-[150px]'>
            <CandidateStatusSelect
              data={record}
              onClick={(value: string) => {
                handleSetRecord({ ...record, id: record?.ref_id }, value);
              }}
            />
          </div>
        </div>
      )
    },
    {
      title: 'Cool Off Status',
      key: 'in_cool_off',
      dataIndex: 'in_cool_off',
      align: 'center' as AlignType,
      width: '15%',
      render: (text: boolean) => (
        <span className='max-w-[5rem] min-w-[10rem] line-clamp-1'>
          <FaRegSnowflake
            className={`text-[20px] m-auto ${
              text ? 'text-[#1890FF]' : 'text-[#95a6b5]'
            }`}
          />
        </span>
      )
    }
  ];
  return {
    columns
  };
};

export default useColumns;
