import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class CategoryServices {
    constructor() {
        this.client = apiClient;
    }

    GetAllCategories = (payload) => {
        return this.client
            .get(APIs.GET_ALL_CATEGORIES, { params: { ...payload } })
            .then((res) => res)
            .catch((error) => {
                return error;
            });
    }
   
    AddACategory = (payload) => {
        return this.client
            .post(APIs.ADD_A_CATEGORY, { "name": payload.name })
            .then((res) => res)
            .catch((error) => {
                return error;
            });
    }

    DeleteACategory = (payload) => {
        const uuid = payload.uuid;
        return this.client
            .patch(APIs.DELETE_A_CATEGORY.replace(":id", uuid), { "status": "2" })
            .then((res) => res)
            .catch((error) => {
                return error;
            });
    }

    EditACategory = (payload) => {
        const uuid = payload.uuid;
        return this.client
            .patch(APIs.EDIT_A_CATEGORY.replace(":id", uuid), { "name": payload.name })
            .then((res) => res)
            .catch((error) => {
                return error;
            });
    }



    // AddReferral = (payload) => {
    //     const { body } = payload;
    //     return this.client
    //       .post(APIs.ALL_REFERRALS, body)
    //       .then((res) => res)
    //       .catch((error) => {
    //         return error;
    //       });
    //   };

}

export default CategoryServices;
