import { Button, message, Upload, UploadProps } from 'antd';
import { FC } from 'react';
import { DASHBOARD_ALL_CANDIDATES } from '../../constants/RouteConstants';
import ApplicationServices from '../../services/applications';
import { history } from '../../utilities/configureAxios';
import { UploadOutlined } from '@ant-design/icons';

interface IFileUpload {
  setFileName: any;
  setFileType: any;
  fileType: any;
  setAttachmentVisible?: any;
}

const FileUpload: FC<IFileUpload> = ({
  setFileName,
  setFileType,
  fileType,
  setAttachmentVisible
}) => {
  const service = new ApplicationServices();
  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    customRequest: async (options: any) => {
      const { onSuccess, onError, file, onProgress } = options;
      try {
        const fileArry = file?.name?.split('.');
        const extension = fileArry.pop();
        const fileName = fileArry.join('.');
        const res = await service.GetUrlForUpload(fileName, extension);
        const { data } = res.data;
        setFileName(data.file_name);
        const arrayBuffer = await file.arrayBuffer();
        const response = await fetch(data.resume_url, {
          method: 'PUT',
          headers: {
            'Content-Type': fileType
          },
          body: arrayBuffer
        });
        if (response.ok) {
          onSuccess();
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        onError(error);
      }
    },
    accept:
      'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    onChange(info) {
      setFileType(info.file.type);
      if (history.location.pathname !== `${DASHBOARD_ALL_CANDIDATES}`) {
        setAttachmentVisible(false);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        setFileName('');
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === 'removed') {
        message.success(`${info.file.name} file removed successfully`);
        setFileName('');
      }
    }
  };
  return (
    <Upload {...uploadProps} listType='picture' className='upload-list-inline'>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
};

export default FileUpload;
