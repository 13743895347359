import { Input, Modal, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import CancelButton from '../../../components/Buttons/Cancel';
import PrimaryButton from '../../../components/Buttons/Primary';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Inputfield from '../../../components/InputField';
import { connect, useDispatch } from 'react-redux';
import JobActions from '../../../redux/atsJobs/actions';
import { JOB_REGREX } from '../../../constants/HelperConstants';

interface IDepartmentModal {
  type: 'add' | 'edit' | undefined;
  onCancel: () => void;
  onSave: (data: any) => void;
  open: boolean;
  loading: boolean;
  department: any;
}

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  department: state.atsJobsReducers.singleDepartment
});

const DepartmentModal: FC<IDepartmentModal> = ({
  type,
  onCancel,
  onSave,
  open,
  loading,
  department
}) => {
  //   const loginFormSchema = .object().shape({
  //     contactMobileNumber: yup
  //       .string()
  //       .min(10, 'Enter valid mobile number.')
  //       .max(10, 'Enter valid mobile number.')
  //       .matches(/^[6-9]{1}[0-9]{9}$/, 'Enter valid mobile number.')
  //       .required('Mobile number is required.'),
  //   });

  const departmentFormSchema = yup.object().shape({
    departmentName: yup
      .string()
      .min(3, 'Minimum 3 character is required in department name')
      .max(100, 'Enter valid department name')
      .strict(false)
      .trim()
      .matches(JOB_REGREX, 'Enter valid department name')
  });

  const shouldDisable = (values: any, errors: any) => {
    return (
      !values.departmentName.trim() || !(Object.values(errors).length === 0)
    );
  };
  const handleSubmit = (data: any) => {
    let body: any = {};
    body['name'] = data.departmentName.trim();
    onSave(body);
    onCancel();
  };

  if (loading) {
    return null;
  }

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      onCancel={onCancel}
      width='500px'
      className='edit-modal relative'
      bodyStyle={{
        backgroundColor: '#FAFDFF',
        borderRadius: '0.25rem'
      }}
      // centered
    >
      <div className='flex justify-between'>
        <h3 className='text-lg my-4 text-center capitalize font-semibold text-[#233A5C]'>
          {type
            ? type === 'add'
              ? 'Add Department'
              : 'edit Department'
            : null}
        </h3>
        <div
          className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
          onClick={onCancel}
        >
          <AiOutlineClose className='w-5 h-5 text-gray-light' />
        </div>
      </div>
      <Formik
        initialValues={{
          departmentName: department?.name ? department?.name : ''
        }}
        onSubmit={(data) => handleSubmit(data)}
        enableReinitialize
        validationSchema={departmentFormSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          isValid,
          dirty
        }) => (
          <Form>
            {/* <p className="applicant-modal-label">
              {"Location Name"}
              <span className="text-lg text-star-red pl-1">*</span>
            </p>
            <Input
              value={values.departmentName}
              style={{
                borderRadius: "4px",
                height: "45px",
                marginBottom: "1rem",
              }}
              onChange={(event) => {
                handleChange(event?.target?.value);
              }}
              placeholder="Enter a Location Name"
              onBlur={handleBlur}
            /> */}
            <Inputfield
              placeholder='Enter a Department Name'
              // label='Department Name'
              // required
              name='departmentName'
              type='text'
              autocomplete='off'
              value={values.departmentName}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
              }}
              helperText={errors.departmentName ? errors.departmentName : null}
            />
            <div className='flex justify-end mt-4'>
              <div className='mr-4'>
                <PrimaryButton
                  text='Save'
                  type='submit'
                  disabled={
                    shouldDisable(values, errors) || !(isValid && dirty)
                  }
                  // onClick={onSave}
                />
              </div>
              <div>
                <CancelButton onClick={onCancel} text='Cancel' />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(mapStateToProps)(DepartmentModal);
