import moment from "moment";
import {
  ACTION_TAKEN_BY,
  HISTORY_META_TYPE,
} from "../../../../constants/HelperConstants";

const useRescheduleInterview = () => {
  const historyConverter = (item: any) => {
    switch (item.type) {
      case HISTORY_META_TYPE.TITLE_CHANGE:
        return ` Title has been updated from ${item.data.old} to ${
          item.data.new
        } by ${ACTION_TAKEN_BY[item.action_taken_by]}`;
      case HISTORY_META_TYPE.SCHEDULED_AT_CHANGE:
        let startDateTimeMessage = [];
        if (
          moment(item.data.new).format("MMMM Do") !==
          moment(item.data.old).format("MMMM Do")
        ) {
          startDateTimeMessage.push(
            `Date has been updated from ${moment(item.data.old).format(
              "MMMM Do"
            )} to ${moment(item.data.new).format("MMMM Do")} by ${
              ACTION_TAKEN_BY[item.action_taken_by]
            }`
          );
        }
        if (
          moment(item.data.new).format("H:mm") !==
          moment(item.data.old).format("H:mm")
        ) {
          startDateTimeMessage.push(
            `Start time has been updated from ${moment(item.data.old).format(
              "h:mm A"
            )} to ${moment(item.data.new).format("h:mm A")} by ${
              ACTION_TAKEN_BY[item.action_taken_by]
            }`
          );
        }
        return startDateTimeMessage;
      case HISTORY_META_TYPE.END_AT_CHANGE:
        let endDateTimeMessage = [];
        if (
          moment(item.data.new).format("H:mm") !==
          moment(item.data.old).format("H:mm")
        ) {
          endDateTimeMessage.push(
            ` End time has been updated from ${moment(item.data.old).format(
              "h:mm A"
            )} to ${moment(item.data.new).format("h:mm A")} by ${
              ACTION_TAKEN_BY[item.action_taken_by]
            }`
          );
        }
        return endDateTimeMessage;
      case HISTORY_META_TYPE.LOCATION_CHANGE:
        return ` Location has been updated from ${item.data.old} to ${
          item.data.new
        } by ${ACTION_TAKEN_BY[item.action_taken_by]}`;
      case HISTORY_META_TYPE.INTERVIEWERS_CHANGE:
        let interviewers = [];
        let removed = item.data?.old.filter((element: any) => {
          return !item.data?.new.includes(element);
        });
        let added = item.data?.new.filter((element: any) => {
          return !item.data?.old.includes(element);
        });
        if (removed.length > 0) {
          interviewers.push(
            `Interviewers removed ${removed} by ${
              ACTION_TAKEN_BY[item.action_taken_by]
            }`
          );
        }
        if (added.length > 0) {
          interviewers.push(
            `Interviewers added ${added} by ${
              ACTION_TAKEN_BY[item.action_taken_by]
            }`
          );
        }
        return interviewers;
    }
  };

  const ConvertData = (data: any) => {
    let array: any[] = [];
    data.forEach((item: any) => {
      const res: string | string[] | undefined = historyConverter(item);
      if (Array.isArray(res)) {
        array.push(...res);
      } else if (res) {
        array.push(res);
      }
    });
    return array;
  };

  return {
    ConvertData,
  };
};

export default useRescheduleInterview;
