import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class TaskServices {
  constructor() {
    this.client = apiClient;
  }

  GetAllTasks = (payload) => {
    return this.client
      .get(APIs.GET_ALL_TASKS, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetWithoutPaginatedTasks = (payload) => {
    return this.client
      .get(APIs.WITHOUT_PAGINATED_TASK_LIST, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetSingleTask = (payload) => {
    const { id } = payload;
    return this.client
      .get(APIs.GET_SINGLE_TASK.replace(":id", id))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetUrlForUpload = (file_name, mime_type) => {
    return this.client
      .get(`${APIs.GET_TASK_UPLOAD_URL}?mime_type=${mime_type}&file_name=${file_name}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  EditTask = (payload = {}) => {
    const { id, body } = payload;
    return this.client
      .patch(APIs.EDIT_TASK.replace(":id", id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  AddTask = (payload = {}) => {
    const { body } = payload;
    return this.client
      .post(APIs.ADD_TASK, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  DeleteTask = (payload = {}) => {
    const { userId } = payload;
    return this.client
      .delete(APIs.DELETE_TASK.replace(":id", userId))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default TaskServices;
