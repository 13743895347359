import { Button, Input, Modal, notification, Select, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { FaRegUser } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { GoScreenFull } from 'react-icons/go';
import { MdModeEdit } from 'react-icons/md';
import { Store } from '../../../redux/store';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDocumentDuplicate } from 'react-icons/hi';
import DuplicateApplications from '../../Modal/DuplicateApplications';
import { formatsDate } from '../../../helper';
import { EMAIL_MAINREGEX } from '../../../constants/HelperConstants';
import UpdateStatusModal from '../../Modal/UpdateStatusModal';
import { CustomObject } from '../../../Interfaces/common';
import { capitalize } from 'lodash';

const mapStateToProps = (state: any) => ({
  allTags: state.applications.allTags
});
interface IUserDetail {
  singleApplication: any;
  setOpen: any;
  allTags: {
    code: number;
    data: [];
    message: string;
    success: boolean;
  };
}
interface ItemProps {
  job_title: string;
  job_ref_id: Number | string;
}

const UserDetail: FC<IUserDetail> = ({
  singleApplication,
  setOpen,
  allTags
}) => {
  const { Option } = Select;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<CustomObject>({});

  const [lakhs, setLakhs] = useState('');
  const [thousand, setThousand] = useState<string>('');
  const [contact, setContact] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState<String>('');
  const [currentLocation, setCurrentLocation] = useState<any>('');
  const [noticePeriod, setNoticePeriod] = useState<any>('');
  const [errors, setErrors] = useState({
    contact: '',
    email: ''
  });

  const params = useParams<any>();
  const handleSetRecord = (record: any, value: string) => {
    setCurrentRecord(record);
    dispatch({
      type: applicationActions.TOGGLE_UPDATE_STATUS_MODAL,
      payload: true
    });
    dispatch({
      type: applicationActions.UPDATE_CURRENT_STATUS,
      payload: Number(value)
    });
  };
  const dispatch = useDispatch();
  const check232 = useRef<HTMLDivElement | null>(null);
  const fetchJobs = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/jobs?pagination=false`,
      {
        headers: {
          Authorization: `Bearer ${Store.getState().auth?.token}`
        }
      }
    );

    if (res?.data?.data) {
      setJobs(res?.data?.data);
    }
  };
  const [show1, setShow1] = useState(false);

  const handleChange = () => {
    setChecked((prev: boolean) => !prev);
  };
  const getHeight = useCallback((node: HTMLDivElement) => {
    if (node && node?.clientHeight >= 200 && !show1) {
      setShow1(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchJobs();
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
  };

  const formattedJobs = jobs?.map((item: ItemProps) => ({
    ...(item as Object),
    label: item?.job_title,
    value: item?.job_ref_id
  }));

  const handleModalVisible = () => {
    setIsVisible((prev) => !prev);
  };

  function validateEmail(email: any) {
    if (EMAIL_MAINREGEX.test(email)) {
      return true;
    }
    return false;
  }

  const editModalData = async () => {
    setLoading(true);
    const errorsObject = {
      contact: contact?.toString().length !== 10 ? 'Invalid Mobile number' : '',
      email: validateEmail(email) ? '' : 'Invalid email'
    };
    setErrors(errorsObject);

    // if (!!lakhs && !!thousand && !!email && !!job && !!contact) {
    //   setIsVisible(false)
    //   return
    // }
    // --validation-------------------------------------
    if (!errorsObject.contact && !errorsObject.email) {
      let ctc;
      if (lakhs?.toString().length === 1 && thousand?.toString().length === 1) {
        ctc = Number(`0${lakhs}0${thousand}000`);
      } else if (
        lakhs?.toString().length === 1 &&
        thousand?.toString().length === 2
      ) {
        ctc = Number(`0${lakhs}${thousand}000`);
      } else if (
        lakhs?.toString().length === 2 &&
        thousand?.toString().length === 1
      ) {
        ctc = Number(`${lakhs}0${thousand}000`);
      } else {
        ctc = Number(`${lakhs}${thousand}000`);
      }

      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/applications/${params?.id}`,
        {
          job_id: !!!job ? singleApplication?.job_id : job,
          ctc_in_rupees: !!!ctc
            ? singleApplication?.ctc_in_rupees
            : Number(ctc),
          contact: !!!contact ? singleApplication?.contact : Number(contact),
          email: !!!email ? singleApplication?.email : email,
          tags: !!!selectedTags ? singleApplication?.tags : selectedTags,
          current_location:
            currentLocation === null
              ? ''
              : !!!currentLocation && currentLocation.length
              ? singleApplication?.current_location
              : currentLocation,
          notice_period:
            noticePeriod === null
              ? ''
              : !!!noticePeriod && noticePeriod.length
              ? singleApplication?.notice_period
              : noticePeriod
        },
        {
          headers: {
            Authorization: `Bearer ${Store.getState().auth?.token}`
          }
        }
      );

      if (res?.data?.code === 200) {
        setIsVisible(false);
        notification.success({
          message: 'Candidate Detail Successfully Updated!'
        });
        dispatch({
          type: applicationActions.GET_SINGLE_APPLICATION,
          payload: { ref_id: params?.id }
        });
      }

      if (res?.data?.code !== 200) {
        notification.error({
          message: 'An error has occurred, Please try after some time'
        });
      }
    }
    setLoading(false);
  };
  const findUrls = (str: string) => {
    let str1 = str;
    // Regular expression to match URLs
    const regex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+/gi;
    // Array to store the matched URLs
    const urls: string[] = [];
    // Find all matches in the input string
    let match;
    while ((match = regex.exec(str)) !== null) {
      urls.push(match[0]);
    }
    if (urls?.length) {
      let ans = '';
      let splitStrings = str1.split(regex);
      splitStrings.map((val, index) => {
        ans =
          ans +
          val +
          (urls[index]
            ? `<a target="__blank" href="${
                urls[index].toLowerCase().substr(0, 5) !== 'http:' &&
                urls[index].toLowerCase().substr(0, 6) !== 'https:'
                  ? `https://${urls[index].toLowerCase()}`
                  : urls[index].toLowerCase()
              }">${urls[index]}</a>`
            : '');
      });

      return ans;
    } else {
      return str1;
    }
  };
  useEffect(() => {
    setCurrentRecord(singleApplication);
  }, [singleApplication]);
  useEffect(() => {
    setContact(singleApplication?.contact);
    setEmail(singleApplication?.email);
    setCurrentLocation(singleApplication?.current_location);
    setNoticePeriod(singleApplication?.notice_period);
    setSelectedTags(singleApplication?.tags);

    if (
      Intl.NumberFormat('en-IN')
        .format(singleApplication?.ctc_in_rupees)
        .split(',').length === 3
    ) {
      let lakh = Intl.NumberFormat('en-IN')
        .format(singleApplication?.ctc_in_rupees)
        .split(',')[0];
      let thousand =
        Intl.NumberFormat('en-IN')
          .format(singleApplication?.ctc_in_rupees)
          .split(',')[1] + '';
      setLakhs(lakh);
      setThousand(thousand);
    }

    if (
      Intl.NumberFormat('en-IN')
        .format(singleApplication?.ctc_in_rupees)
        .split(',').length === 2
    ) {
      let thousand =
        Intl.NumberFormat('en-IN')
          .format(singleApplication?.ctc_in_rupees)
          .split(',')[0] +
        Intl.NumberFormat('en-IN').format(50000).split(',')[1] +
        '';
      setThousand(thousand);
    }

    if (singleApplication?.ctc_in_rupees === 0) {
      setLakhs('00');
      setThousand('00');
    }
  }, [isVisible]);

  function handleDisabled() {
    if (!email || !contact) {
      return true;
    } else if (
      (!lakhs && !thousand) ||
      lakhs.length > 2 ||
      thousand.length > 2
    ) {
      return true;
    }
    return false;
  }
  const refetchCall = () => {
    dispatch({
      type: applicationActions.GET_DUPLICATE_APPLICATION_MODAL,
      payload: { id: params?.id }
    });
  };
  const showDuplicateApplicationModal = () => {
    dispatch({
      type: applicationActions.GET_DUPLICATE_APPLICATION_MODAL,
      payload: { id: params?.id }
    });
    setIsDuplicateModalVisible(true);
  };

  const handleTagChange = (value: string[]) => {
    setSelectedTags(value);
  };

  const CustomTagRender = (props: any) => {
    const { label, value, onClose, selectedTags } = props;
    const index = selectedTags.findIndex((tag: any) => tag === value);

    if (index < 3) {
      return (
        <Tag
          className='my-tag'
          closable
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      );
    }

    if (index === 3) {
      return (
        <Tag className='my-tag' style={{ marginRight: 3 }}>{`+${
          selectedTags.length - 3
        }`}</Tag>
      );
    }

    return null;
  };

  const children: React.ReactNode[] = [];
  allTags &&
    allTags?.data?.map((item: any) =>
      children.push(
        <Option key={`${item.name}`}>{capitalize(item.name)}</Option>
      )
    );

  return (
    <div className='p-5 bg-white rounded-lg'>
      <div className='flex gap-2 items-center justify-between'>
        <div className=''>
          <div className='flex space-x-3'>
            <div className=''>
              <FaRegUser className='bg-light-blue text-white h-[50px] w-[50px] p-4 rounded-lg' />
            </div>
            <div className=''>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1 className='candidate-name leading-9 font-medium text-[30px] text-primary capitalize m-0 max-w-[25rem] break-words line-clamp-1 mr-4'>
                  <Tooltip title={singleApplication?.full_name}>
                    {singleApplication?.full_name}
                  </Tooltip>
                </h1>
                <div>
                  <MdModeEdit
                    onClick={handleModalVisible}
                    style={{
                      fontSize: '20px',
                      cursor: 'pointer',
                      margin: '0.6rem'
                    }}
                  />
                </div>
                <div>
                  <HiDocumentDuplicate
                    onClick={showDuplicateApplicationModal}
                    style={{
                      fontSize: '20px',
                      cursor: 'pointer',
                      margin: '0.6rem'
                    }}
                  />
                </div>
              </div>
              <p className='font-medium text-[14px] text-blue-zodiac capitalize opacity-50 space-x-3'>
                {singleApplication?.id}
                <span className='w-[2px] h-full border-l border-[#6F7070CC] mx-3'></span>
                {singleApplication?.job_title}
                <span className='w-[2px] h-full border-l border-[#6F7070CC] mx-3'></span>
                {/* <br/> */} Applied on{' '}
                {moment(singleApplication?.created_at).format(formatsDate)}
              </p>
            </div>

            {/* ====MODAL==== */}
            <Modal
              destroyOnClose={true}
              visible={isVisible}
              footer={null}
              onCancel={handleCancel}
              className='edit-application-modal'
            >
              <div className='flex justify-between'>
                <div>
                  <h3 className='text-lg text-center capitalize font-semibold text-[#233A5C]'>
                    Edit Application
                  </h3>
                </div>

                <div
                  className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                  onClick={handleCancel}
                >
                  <AiOutlineClose className='w-5 h-5 text-gray-light' />
                </div>
              </div>
              <div>
                {/* <Select
                defaultValue="Choose from dropdown"
                onChange={(idFromDrowndownJob) => setJob(idFromDrowndownJob)}
              />

              {/* --------dropdown------------------------------------------------- */}
                <div className='mb-6 '>
                  <p className='applicant-modal-label'>
                    {'Job Title'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <div className='test'>
                    <Select
                      // value={job}
                      //@ts-ignore
                      // defaultValue={() => singleApplication?.job_title}
                      className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                      onChange={(idFromDrowndownJob) =>
                        setJob(idFromDrowndownJob)
                      }
                      placeholder={singleApplication?.job_title}
                      options={formattedJobs}
                    ></Select>
                  </div>
                </div>
                {/* ----------------------------------------------------------------------- */}

                {/* ---ctc--- */}
                <div>
                  <p className='applicant-modal-label'>
                    {'Current Annual CTC(INR)'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <div className='flex space-x-3'>
                    <Input
                      className='edit-input text-base capitalize'
                      value={lakhs}
                      onChange={(e: any) => setLakhs(e.target.value)}
                      type='number'
                      placeholder='Enter in lakhs'
                    />
                    <Input
                      className='edit-input text-base capitalize'
                      value={thousand}
                      onChange={(e: any) => setThousand(e.target.value)}
                      type='number'
                      placeholder='Enter in thousands'
                    />
                  </div>
                </div>
                {/* ---ctc--- */}
                <div style={{ margin: '30px' }} />
                {/* ---phone--- */}
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    {'Mobile Number'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base capitalize'
                    value={contact ?? ''}
                    type='number'
                    onChange={(e: any) => setContact(e.target.value)}
                    placeholder={singleApplication?.contact}
                  />
                  {errors.contact !== '' && (
                    <div className='error-message absolute bottom-[-20px]'>
                      {errors.contact}
                    </div>
                  )}
                </div>
                {/* ---phone--- */}
                <div style={{ margin: '30px' }} />
                {/* ---email */}
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    {'Email'}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </p>
                  <Input
                    className='edit-input text-base'
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    placeholder={singleApplication?.email}
                  />
                  {errors.email !== '' && (
                    <div className='error-message absolute bottom-[-20px]'>
                      {errors.email}
                    </div>
                  )}
                </div>
                <div style={{ margin: '30px' }} />
                {/* ---Add Tags */}
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>{'Add Tags'}</p>
                  <Select
                    mode='multiple'
                    allowClear
                    className='edit-input text-base'
                    placeholder={'Please select tags'}
                    onChange={handleTagChange}
                    value={selectedTags}
                    tagRender={(props) => (
                      <CustomTagRender {...props} selectedTags={selectedTags} />
                    )}
                  >
                    {children}
                  </Select>
                </div>
                <div style={{ margin: '30px' }} />
                {/* ---Current Location */}
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>{'Current Location'}</p>
                  <Input
                    type='text'
                    className='edit-input text-base'
                    value={currentLocation}
                    onChange={(e: any) => setCurrentLocation(e.target.value)}
                    placeholder={
                      singleApplication?.current_location ||
                      'Enter Current Location'
                    }
                  />
                </div>
                <div style={{ margin: '30px' }} />
                {/* ---Notice Period */}
                <div className='flex-grow'>
                  <p className='applicant-modal-label'>
                    {'Notice Period (Days)'}
                  </p>
                  <Input
                    type='number'
                    className='edit-input text-base'
                    value={noticePeriod}
                    onChange={(e: any) => setNoticePeriod(e.target.value)}
                    placeholder={
                      singleApplication?.notice_period || 'Enter Notice Period'
                    }
                  />
                </div>
                <div style={{ margin: '30px' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginRight: '15px' }}>
                  <Button
                    style={{ borderRadius: '5px' }}
                    onClick={() => setIsVisible(false)}
                  >
                    Cancel
                  </Button>
                </div>
                <div style={{ marginLeft: '15px' }}>
                  <Button
                    loading={loading}
                    disabled={handleDisabled()}
                    style={{ borderRadius: '5px' }}
                    onClick={editModalData}
                    type='primary'
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Modal>
            <DuplicateApplications
              open={isDuplicateModalVisible}
              onCancel={() => {
                dispatch({
                  type: applicationActions.UPDATE_CURRENT_STATUS,
                  payload: Number(singleApplication?.status)
                });
                setCurrentRecord(singleApplication);
                setIsDuplicateModalVisible(false);
              }}
              currentRecord={currentRecord}
              handleSetRecord={handleSetRecord}
            />

            {/* ====MODAL==== */}
          </div>
          <div className='flex flex-wrap w-full gap-1.5 gap-x-8 mt-[-0.5rem]'>
            <div className='flex flex-col w-1/3'>
              <span className='text-blue-streak opacity-50'>
                Cool Off Status{' '}
              </span>
              <span className=''>
                {singleApplication?.in_cool_off ? 'Yes' : 'No'}
              </span>
            </div>
            <div className='flex flex-col w-1/3'>
              <span className='text-blue-streak opacity-50'>
                Last Applied On
              </span>
              <span className='text-blue-zodiac '>
                {singleApplication?.last_applied
                  ? moment(singleApplication?.last_applied).format(formatsDate)
                  : 'NA'}
              </span>
            </div>
          </div>
          <div className='mt-2 flex flex-wrap w-full gap-y-2 gap-x-8'>
            <div className='flex flex-col w-1/3'>
              <span className='text-blue-streak opacity-50'>
                Graduation Year
              </span>
              <span className='text-blue-zodiac'>
                {singleApplication?.graduation_year}
              </span>
            </div>
            <div className='flex flex-col w-1/3'>
              <span className='text-blue-streak opacity-50'>CTC</span>
              <span className='text-blue-zodiac'>
                {Math.abs(singleApplication?.ctc_in_rupees / 100000)} LPA
              </span>
            </div>
            <div className='flex flex-col w-1/3'>
              <span className='text-blue-streak opacity-50'>Phone Number</span>
              <span className='text-blue-zodiac'>
                {singleApplication?.contact}
              </span>
            </div>
            <div className='flex flex-col w-1/3'>
              <span className='text-blue-streak opacity-50'>Email ID</span>
              <Tooltip placement='top' title={singleApplication?.email}>
                <span
                  className={`text-blue-zodiac max-w-[150px] truncate ${
                    singleApplication?.email?.includes(' ')
                      ? 'break-words'
                      : 'break-all'
                  }`}
                >
                  {singleApplication?.email}
                </span>
              </Tooltip>
            </div>
            {singleApplication?.current_location && (
              <div className='flex flex-col w-1/3'>
                <span className='text-blue-streak opacity-50'>
                  Current Location
                </span>
                <Tooltip
                  placement='top'
                  title={singleApplication?.current_location}
                >
                  <span
                    className={`text-blue-zodiac max-w-[150px] truncate ${
                      singleApplication?.current_location?.includes(' ')
                        ? 'break-words'
                        : 'break-all'
                    }`}
                  >
                    {singleApplication?.current_location}
                  </span>
                </Tooltip>
              </div>
            )}
            {singleApplication?.notice_period && (
              <div className='flex flex-col w-1/3'>
                <span className='text-blue-streak opacity-50'>
                  Notice Period
                </span>
                <div className='flex gap-x-1'>
                  <Tooltip
                    placement='top'
                    title={singleApplication?.notice_period}
                  >
                    <span
                      className={`text-blue-zodiac max-w-[100px] truncate ${
                        singleApplication?.notice_period?.includes(' ')
                          ? 'break-words'
                          : 'break-all'
                      }`}
                    >
                      {singleApplication?.notice_period}
                    </span>
                  </Tooltip>
                  <span>Days</span>
                </div>
              </div>
            )}
          </div>
          {singleApplication?.tags && singleApplication?.tags?.length > 0 && (
            <div className='mt-2 flex flex-wrap w-full gap-1.5 gap-x-8'>
              <div className='flex flex-col w-2/3'>
                <span className='text-blue-streak opacity-50'>
                  Applied Tags
                </span>
                <Tooltip
                  placement='bottomLeft'
                  title={singleApplication.tags.join(', ')}
                >
                  <span className='text-blue-zodiac capitalize'>
                    {singleApplication?.tags?.length > 2
                      ? [
                          ...singleApplication?.tags?.slice(0, 2),
                          `...+${singleApplication?.tags?.length - 2}`
                        ].join(', ')
                      : singleApplication.tags.join(', ')}
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
          {(singleApplication?.portfolio_file ||
            singleApplication?.portfolio_links) && (
            <div className='w-full mt-2'>
              <span className='text-blue-streak opacity-50'>
                Portfolio Links
              </span>
              {singleApplication?.portfolio_file &&
                singleApplication?.portfolio_file_mime_type ===
                  'application/pdf' && (
                  <a
                    href={singleApplication?.portfolio_file}
                    target='_blank'
                    className='w-full flex md:flex-row  flex-col items-center space-x-3 md:justify-start'
                    rel='noreferrer'
                  >
                    Portfolio Link
                  </a>
                )}
              {singleApplication?.portfolio_file &&
                singleApplication?.portfolio_file_mime_type !==
                  'application/pdf' && (
                  <a
                    href={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                      singleApplication?.portfolio_file
                    )}`}
                    target='_blank'
                    className='w-full flex md:flex-row  flex-col items-center space-x-3 md:justify-start'
                    rel='noreferrer'
                  >
                    Portfolio Link
                  </a>
                )}

              {singleApplication?.portfolio_links && (
                <Fragment>
                  <div
                    className='transition-all'
                    ref={getHeight}
                    style={{
                      overflowY: 'hidden',
                      transition: 'all 0.5s ease',
                      minHeight: show1 ? '200px' : 'auto',
                      maxHeight: !checked
                        ? '200px'
                        : check232?.current?.offsetHeight
                    }}
                  >
                    <div
                      ref={check232}
                      dangerouslySetInnerHTML={{
                        __html: `${findUrls(
                          singleApplication?.portfolio_links
                        )}`
                      }}
                    />
                  </div>
                  {show1 && (
                    <Button
                      className='mt-2'
                      onClick={() => {
                        handleChange();
                      }}
                    >
                      {!checked ? 'Show More' : 'Show Less'}
                    </Button>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </div>
        <div className='relative'>
          <div className='h-[250px] w-[400px] opacity-60 overflow-scroll border-2 rounded'>
            <img
              className='w-full h-full'
              src='/BlurResume.jpg'
              alt='Static Resume'
            />
          </div>
          <div className='absolute top-[45%] translate-x-[140px] flex space-x-3 justify-between'>
            <div className='w-2/3 flex '>
              <a
                href={
                  !singleApplication?.resume_mime_type ||
                  singleApplication?.resume_mime_type === 'application/pdf'
                    ? singleApplication?.resume
                    : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                        singleApplication?.resume
                      )}`
                }
                target='_blank'
                className='w-full flex md:flex-row  flex-col items-center space-x-3 md:justify-start px-4'
                rel='noreferrer'
              >
                <div className='bg-gray-light w-9 h-9 rounded-md flex items-center justify-center'>
                  <FiDownload className='text-[#000] !opacity-100' />
                </div>
              </a>
            </div>
            <div className='w-2/3 flex '>
              <div
                className='flex items-center'
                onClick={() => setOpen(true)}
                style={{ cursor: 'pointer' }}
              >
                <div className='bg-gray-light w-9 h-9 rounded-md flex items-center justify-center mr-4'>
                  <GoScreenFull className='text-[#000]' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateStatusModal
        singleApplication={currentRecord}
        {...(currentRecord?.id !== params?.id && { apiCall: refetchCall })}
      />
    </div>
  );
};

export default connect(mapStateToProps)(UserDetail);
