import ProfileDropdown from "./ProfileDropdown";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => ({
  profilePic: state.auth.profilePic,
});

interface Iprofile {
  profilePic: any;
}

const Profile: React.FC<Iprofile> = ({ profilePic }) => {
  return (
    <>
      <div className="flex space-x-1">
        <div className="w-[45px] h-[45px]">
          {profilePic ? (
            <img
              src={profilePic ? profilePic : null}
              referrerPolicy="no-referrer"
              alt=""
              className="rounded-full object-contain"
              width="45px"
              height="45px"
            />
          ) : (
            <div className="profile-pic"></div>
          )}
        </div>
        <div className="flex items-center justify-center">
          <ProfileDropdown />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Profile);
