import { Modal, Select, DatePicker, TimePicker, Checkbox } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import PrimaryButton from '../../Buttons/Primary';
import applicationActions from '../../../redux/applications/actions';
import moment from 'moment';
import { APPLICATION_STATUS_SELECT_FOR_INTERVIEW } from '../../../constants/HelperConstants';
import { useHistory } from 'react-router-dom';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  show: state.applications.taskModalOpen,
  role: state.users.role,
  tasks: state.tasks.tasks,
  paginatedTask: state.tasks.paginatedTask
});
interface IScheduleInterview {
  show: boolean;
  tasks: any;
  id: any;
  paginatedTask: any;
}

const TaskAllotmentModal: FC<IScheduleInterview> = ({
  show,
  tasks,
  paginatedTask,
  id
}) => {
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState<any>('');
  const [endTime, setEndTime] = useState<any>('');
  // -----scheudle date and time -----
  const [scheduleDate, setScheduleDate] = useState<any>('');
  const [scheduleTime, setScheduleTime] = useState<any>('');
  // -----scheudle date and time -----
  const [taskId, setTaskId] = useState('');
  const [check, setCheck] = useState(false);
  const [status, setStatus] = useState();
  const [valid, setValid] = useState(false);
  const history = useHistory();
  const { Option } = Select;

  useEffect(() => {
    if (taskId && endDate && endTime) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [taskId, endDate, endTime]);

  useEffect(() => {
    if (!!scheduleDate === true && !!scheduleTime === false) {
      setValid(false);
    } else if (!!scheduleDate === false && !!scheduleTime === true) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [scheduleDate, scheduleTime]);

  const handleModalClose = () => {
    dispatch({
      type: applicationActions.TOGGLE_TASK_MODAL,
      payload: false
    });
  };
  const handleDateChange = (date: any) => {
    setEndDate(date);
  };
  const handleTimeChange = (time: any) => {
    setEndTime(time);
  };
  // ---added scheduled state-----
  const handleScheduledDateChange = (date: any) => {
    setScheduleDate(date);
  };
  const handleScheduledTimeChange = (time: any) => {
    setScheduleTime(time);
  };
  // ---added scheduled time------
  const handleTaskChange = (value: any) => {
    setTaskId(value);
  };
  const handleCheckbox = (e: any) => {
    setCheck(e.target.checked);
  };

  const onSend = () => {
    let body;
    if (!!scheduleDate && !!scheduleTime) {
      check
        ? (body = {
            expiry: moment(
              `${endDate?.format('YYYY-MM-DD')} ${endTime.format('hh:mm a')}`,
              'YYYY-MM-DD hh:mm a'
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss'),
            task_id: taskId,
            update_status: status,
            scheduled_at: moment(
              `${scheduleDate?.format('YYYY-MM-DD')} ${scheduleTime.format(
                'hh:mm a'
              )}`,
              'YYYY-MM-DD hh:mm a'
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss')
          })
        : (body = {
            expiry: moment(
              `${endDate?.format('YYYY-MM-DD')} ${endTime.format('hh:mm a')}`,
              'YYYY-MM-DD hh:mm a'
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss'),
            task_id: taskId,
            scheduled_at: moment(
              `${scheduleDate?.format('YYYY-MM-DD')} ${scheduleTime.format(
                'hh:mm a'
              )}`,
              'YYYY-MM-DD hh:mm a'
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss')
          });
    } else {
      check
        ? (body = {
            expiry: moment(
              `${endDate?.format('YYYY-MM-DD')} ${endTime.format('hh:mm a')}`,
              'YYYY-MM-DD hh:mm a'
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss'),
            task_id: taskId,
            update_status: status
          })
        : (body = {
            expiry: moment(
              `${endDate?.format('YYYY-MM-DD')} ${endTime.format('hh:mm a')}`,
              'YYYY-MM-DD hh:mm a'
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss'),
            task_id: taskId
          });
    }

    dispatch({
      type: applicationActions.ALLOT_TASK,
      payload: {
        body: body,
        ref_id: id
      }
    });
    dispatch({
      type: applicationActions.TOGGLE_TASK_MODAL,
      payload: false
    });
    dispatch({
      type: applicationActions.GET_ALL_APPLICATIONS_WITHOUT_PAGE
    });
    dispatch({
      type: applicationActions.GET_SINGLE_APPLICATION,
      payload: { ref_id: id, history }
    });
    setTimeout(() => {
      dispatch({
        type: applicationActions.GET_SINGLE_APPLICATION,
        payload: { ref_id: id, history }
      });
    }, 2000);
  };

  const handleChange = (value: any) => {
    setStatus(value);
  };

  return (
    <>
      <Modal
        className='edit-modal'
        open={show}
        onCancel={handleModalClose}
        destroyOnClose={true}
        width='620px'
      >
        <div className='flex justify-between '>
          <div className='md:px-4'>
            <h3 className='text-base text-center capitalize font-semibold text-[#233A5C]'>
              Send Task
            </h3>
          </div>
          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleModalClose}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>
        <div className='pt-8 md:px-4 space-y-2 relative'>
          <div className=''>
            <div className='text-blue-streak text-base capitalize opacity-50'>
              Task
              <span className='text-lg text-star-red pl-1'>*</span>
            </div>
            <div className='test'>
              <Select
                allowClear
                className='filter-select text-base w-full border border-[#F4F7FC] rounded-lg'
                placeholder='Please select'
                onChange={handleTaskChange}
              >
                {paginatedTask &&
                  paginatedTask?.data?.map((item: any) => (
                    <Option key={item.id}>{item.title}</Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className='py-1' />
          <div className='flex justify-between space-x-3'>
            <div className='w-full'>
              <div className='text-blue-streak text-base capitalize opacity-50'>
                End Date
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <DatePicker
                className='custom-date-picker edit-input text-base'
                onChange={handleDateChange}
                disabledDate={(current) =>
                  //@ts-ignore
                  current.isBefore(moment().subtract(1, 'day'))
                }
              />
            </div>
            <div className='w-full'>
              <div className='text-blue-streak text-base capitalize opacity-50'>
                End Time
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <TimePicker
                use12Hours
                format='h:mm a'
                className='custom-date-picker edit-input text-base'
                onChange={handleTimeChange}
              />
            </div>
          </div>
          <div className='py-1' />
          {/* ---added schedule date and time--------------------------------------------------- */}
          <div className='flex justify-between space-x-3'>
            <div className='w-full'>
              <div className='text-blue-streak text-base capitalize opacity-50'>
                Scheduled Date
              </div>
              <DatePicker
                className='custom-date-picker edit-input text-base'
                onChange={handleScheduledDateChange}
                disabledDate={(current) =>
                  //@ts-ignore
                  current.isBefore(moment().subtract(1, 'day'))
                }
              />
            </div>
            <div className='w-full'>
              <div className='text-blue-streak text-base capitalize opacity-50'>
                Scheduled Time
              </div>
              <TimePicker
                use12Hours
                format='h:mm a'
                className='custom-date-picker edit-input text-base'
                onChange={handleScheduledTimeChange}
              />
            </div>
          </div>
          <div className='py-1' />
          {/* ------------------------------------------------------------------------------- */}
          <div className='flex justify-between'>
            <div className='flex items-center'>
              <Checkbox onChange={handleCheckbox}>Update Status?</Checkbox>
            </div>
            {check && (
              <div className='test w-[264px]'>
                <Select
                  className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                  onChange={handleChange}
                  placeholder={'Select applicant status'}
                >
                  {Object.entries(APPLICATION_STATUS_SELECT_FOR_INTERVIEW).map(
                    (item) => (
                      <>
                        <Option className='' value={item[0]} key={item[0]}>
                          {item[1]}
                        </Option>
                      </>
                    )
                  )}
                </Select>
              </div>
            )}
          </div>
          <div className='py-1' />
        </div>
        <div className='flex justify-center mt-8'>
          <PrimaryButton disabled={!valid} text='Send' onClick={onSend} />
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(TaskAllotmentModal);
