import { all, takeEvery, put } from "redux-saga/effects";
import { doApiCall } from "../../helper";
import actions from "./actions";
import InterviewersServices from "../../services/interviewers";
import { notification } from "antd";

const services = new InterviewersServices();

export function* WATCH_GET_ALL_INTERVIEWERS(action) {
  const response = yield doApiCall(services.GetAllInterviewers, action.payload);
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { users: response } });
  }
}
export function* WATCH_GET_ALL_INTERVIEWERS_WITHOUT_PAGE(action) {
  const response = yield doApiCall(
    services.GetAllInterviewersWithoutPage,
    action.payload
  );
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { allInterviewers: response },
    });
  }
}

export function* WATCH_POST_INTERVIEWERS(action) {
  const response = yield doApiCall(services.PostAInterviewer, action.payload);
  if (response?.success) {
    notification.success({ message: "Interviewer added successfully!" });
    yield put({
      type: actions.GET_ALL_INTERVIEWERS,
      payload: { page: 1 },
    });
  }
}

export function* WATCH_UPDATE_INTERVIEWERS_PROFILE(action) {
  const response = yield doApiCall(
    services.UpdateInterviewerProfile,
    action.payload
  );
  if (response?.success) {
    notification.success({ message: "Interviewer updated successfully!" });
    yield put({
      type: actions.GET_ALL_INTERVIEWERS,
      payload: { page: 1 },
    });
  }
}

export function* WATCH_DELETE_INTERVIEWERS(action) {
  const response = yield doApiCall(
    services.DeleteInterviewer,
    action.payload.userId
  );
  if (response?.success) {
    notification.success({ message: "Interviewer deleted successfully!" });
    yield window.location.reload();
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_INTERVIEWERS, WATCH_GET_ALL_INTERVIEWERS),
  ]);
  yield all([
    takeEvery(
      actions.GET_ALL_INTERVIEWERS_FOR_INTERVIEW,
      WATCH_GET_ALL_INTERVIEWERS_WITHOUT_PAGE
    ),
  ]);
  yield all([takeEvery(actions.POST_INTERVIEWERS, WATCH_POST_INTERVIEWERS)]);
  yield all([
    takeEvery(
      actions.UPDATE_INTERVIEWERS_PROFILE,
      WATCH_UPDATE_INTERVIEWERS_PROFILE
    ),
  ]);
  yield all([
    takeEvery(actions.DELETE_INTERVIEWERS, WATCH_DELETE_INTERVIEWERS),
  ]);
}
