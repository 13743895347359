import { Pagination, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AiOutlineEye } from 'react-icons/ai';
import { connect, useDispatch } from 'react-redux';
import Header from '../../components/Header';
import categoryActions from '../../redux/questionBank/actions';
import { history } from '../../utilities/configureAxios';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../helper';
import interviewActions from '../../redux/interviewers/actions';
import SelectBox from '../../components/Select';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  getAllInterviewsForHr: state.getAllInterviewsForHr.getAllInterviewsForHr
});

function AllFeedback({ getAllInterviewsForHr, loading }: any) {
  const { page, q } = getCurrentQueryParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: categoryActions.GET_ALL_INTERVIEWS_FOR_HR,
      payload: {
        page: page,
        q: q ?? ''
      }
    });
  }, [page, q]);

  const handlePageChange = (page: number | string) => {
    history.push(getSearchParamsUrl({ page: page, q: q }), {});
  };

  const handleRouteClick = (data: any) => {
    if (data?.feedback_status === 3) {
      dispatch({
        type: interviewActions.SET_SELECTED_INTERVIEW,
        payload: data
      });
      history.push(`/dashboard/feedback/${data?.uuid}`);
    }
  };

  return (
    <div className='w-full  h-screen  overflow-y-auto bg-ghost-white relative'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>All Feedbacks</title>
      </Helmet>

      <Header />
      <main>
        {!!!getAllInterviewsForHr?.data?.length ? (
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <section className='mt-20'>
              <div className='flex justify-center'>
                <img
                  className='max-h-[300px]'
                  src='/categories.svg'
                  alt='No Category'
                />
              </div>
              <br />
              <div className='text-center font-semibold flex justify-center text-[#0E254B] text-opacity-40 text-[20px]'>
                No feedbacks available at the moment
              </div>
              <br />
              {/* <div className="text-center">
                       <PrimaryButton onClick={handleAddClick} text="Add Question" />
                   </div> */}
            </section>
          </Spin>
        ) : (
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <div className='flex flex-col'>
              <div className='shadow-lg bg-white mx-8 rounded-2xl overflow-x-auto min-h-[80%] mt-[20px]'>
                <table className='w-full table_transition'>
                  <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                    <tr className=' text-[0.9rem] px-2'>
                      <th className='text-[#233A5C] text-center text-opacity-50 py-4'>
                        S.No
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Applicant Name
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Job Position
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Feedback By
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Round Status
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Date
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Time
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAllInterviewsForHr &&
                      getAllInterviewsForHr?.data?.length > 0 &&
                      getAllInterviewsForHr?.data?.map(
                        (itm: any, index: number) => (
                          <tr
                            key={itm.uuid}
                            className='text-base border-bottom text-blue-zodiac table-text font-normal capitalize mb-0 whitespace-nowrap overflow-auto'
                          >
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-2 pr-2 min-w-[6rem] max-w-[10rem] text-overflow-none text-center opacity-50 pointer-events-none'
                                  : 'py-2 pr-2 min-w-[6rem] max-w-[10rem] text-overflow-none text-center'
                              }
                            >
                              {getAllInterviewsForHr?.meta?.pagination
                                ?.perPage *
                                (getAllInterviewsForHr?.meta?.pagination?.page -
                                  1) +
                                index +
                                1}
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-2 pr-2 min-w-[10rem] max-w-[15rem]  text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-2 pr-2 min-w-[10rem] max-w-[15rem]  text-overflow-none overflow-ellipsis'
                              }
                            >
                              <Tooltip
                                title={itm?.applicant_name}
                                placement='topLeft'
                              >
                                <span className='block truncate max-w-[150px]'>
                                  {itm?.applicant_name}
                                </span>
                              </Tooltip>
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-2 pr-2 min-w-[10rem] max-w-[15rem]  text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-2 pr-2 min-w-[10rem] max-w-[15rem]  text-overflow-none overflow-ellipsis'
                              }
                            >
                              <Tooltip
                                title={itm?.job_title}
                                placement='topLeft'
                              >
                                <span className='block truncate max-w-[150px]'>
                                  {itm?.job_title}
                                </span>
                              </Tooltip>
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-2 pr-2 min-w-[10rem] max-w-[15rem] text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-2 pr-2 min-w-[10rem] max-w-[15rem] text-overflow-none overflow-ellipsis'
                              }
                            >
                              <Tooltip
                                title={itm?.feedback_by}
                                placement='topLeft'
                              >
                                <span className='block truncate max-w-[150px]'>
                                  {itm?.feedback_by ? itm?.feedback_by : 'N.A.'}
                                </span>
                              </Tooltip>
                            </td>
                            <td
                              style={{ transform: 'translateX(-5px)' }}
                              className={
                                itm?.status === 2
                                  ? 'py-2 min-w-[10rem] max-w-[15rem] pr-2 opacity-50 pointer-events-none'
                                  : 'py-2 min-w-[10rem] max-w-[15rem] pr-2'
                              }
                            >
                              {itm?.round_status ? (
                                <SelectBox
                                  status={itm?.round_status}
                                  ref_id={itm?.uuid}
                                  page={page}
                                />
                              ) : (
                                'N.A.'
                              )}
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-2 pr-2 min-w-[6rem] max-w-[10rem]  text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-2 pr-2 min-w-[6rem] max-w-[10rem]  text-overflow-none overflow-ellipsis'
                              }
                            >
                              {moment(itm.scheduled_at).format('Do MMM')}
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-2 pr-2 min-w-[6rem] max-w-[10rem]  text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-2 pr-2 min-w-[6rem] max-w-[10rem]  text-overflow-none overflow-ellipsis'
                              }
                            >
                              {moment(itm.scheduled_at).format('h:mm a')}
                            </td>
                            <td className='flex justify-center items-center py-2 min-w-[3rem] max-w-[3rem]'>
                              <Tooltip
                                placement='topLeft'
                                title={
                                  itm.feedback_status === 1
                                    ? 'Feedback not available'
                                    : null
                                }
                              >
                                <div
                                  className={`w-8 h-8  rounded-md flex items-center justify-center ${
                                    itm?.feedback_status === 3
                                      ? `bg-background-blue cursor-pointer`
                                      : `bg-gray-lighter cursor-not-allowed`
                                  }
                                    ${
                                      itm?.status === 2
                                        ? 'pointer-events-none opacity-50'
                                        : null
                                    }
                                    `}
                                  onClick={() => handleRouteClick(itm)}
                                >
                                  <AiOutlineEye
                                    className={`w-5 h-5  
                                           ${
                                             itm.feedback_status === 3
                                               ? 'text-light-blue'
                                               : 'text-gray-light'
                                           }`}
                                  />
                                </div>
                              </Tooltip>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='flex justify-center py-5'>
              <Pagination
                showSizeChanger={false}
                current={getAllInterviewsForHr?.meta?.pagination?.page}
                pageSize={10}
                onChange={(page) => handlePageChange(page)}
                total={getAllInterviewsForHr?.meta?.pagination?.count}
              />
            </div>
          </Spin>
        )}
      </main>
    </div>
  );
}

export default connect(mapStateToProps)(AllFeedback);
