import moment from "moment";
import React from "react";
import SocialLinksFooter from "../../components/SocialLinksFooter";
import { Copyrights, ThankYou } from "../../constants/HelperConstants";

interface IYes {
  sdt?: any;
}

const Yes: React.FC<IYes> = ({ sdt }) => {
  return (
    <>
      <div className="flex justify-center">
        <div className="bg-white mx-4 rounded-[20px] max-w-[1200px]">
          <div className="flex flex-col justify-center">
            <div className="flex justify-center mt-4">
              <img
                className="md:h-[350px] lg:h-[300px]"
                src="/YesResponse.svg"
                alt=""
              />
            </div>
            <h1 className="font-bold text-[2rem] text-center lg:text-[1.5rem] my-5 md:ml-[100px] lg:ml-0">
              Thank you for your confirmation
            </h1>
          </div>
          <div className="text-[1.6rem] md:text-[1.5rem] lg:text-[1rem] mx-2 md:mx-6 lg:mx-[150px]">
            <p className="flex justify-start">
              Your interview is scheduled at{" "}
              {moment.unix(sdt).format("dddd, MMMM Do | h:mm A")}.Kindly connect
              from a Laptop and make sure to have a stable internet connectivity
              and upfront camera.
            </p>
            <br />
            <p>
              If you have any questions, please feel free to contact us at
              <a
                href={`mailto:${process.env.REACT_APP_CAREERS_SB}`}
                target="_blank"
                rel="noreferrer"
                className="text-light-blue font-medium"
              >
                {" "}
                careers@squareboat.com.
              </a>
            </p>
            <br />
            {ThankYou}
          </div>
          <SocialLinksFooter />
        </div>
      </div>
      {Copyrights}
    </>
  );
};

export default Yes;
