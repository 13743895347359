import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ADMIN_HR_MANAGEMENT,
  DASHBOARD_ALL_CANDIDATES,
  DASHBOARD_HIRING_TRACKER,
  DASHBOARD_INTERVIEWER,
  DASHBOARD_JOBS,
  DASHBOARD_TASKS,
  EMPLOYEE_HOME,
  LANDING_PAGE
} from '../../constants/RouteConstants';
import { USER_ROLE } from '../../constants/HelperConstants';
import { notification } from 'antd';
import { Store } from '../../redux/store';
import authActions from '../../redux/auth/actions';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default function requireAuth(Component) {
  class AuthenticatedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { render: true };
    }

    componentWillMount() {
      this.checkAuth();
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        prevState.render !== this.state.render ||
        window.location.pathname !== this.props.location.pathname
      ) {
        this.checkAuth();
      }
    }
    checkAuth() {
      if (this?.props?.token) {
        if (localStorage.getItem('logoutError')) {
          localStorage.removeItem('logoutError');
          setTimeout(() => {
            Store.dispatch({ type: authActions.LOGOUT, payload: { history } });
            return;
          }, 500);
        }
        if (
          this?.props?.role === USER_ROLE.HR ||
          this?.props?.role === USER_ROLE.ORGNIZATION_ADMIN ||
          this?.props?.role === USER_ROLE.HR_LEAD
        ) {
          if (this.props.location.pathname === LANDING_PAGE) {
            this.setState({ render: false });
            this.props.history.push(DASHBOARD_ALL_CANDIDATES);
            window.location.reload();
            return;
          }
        }
        if (
          this?.props?.role === USER_ROLE.HR ||
          this?.props?.role === USER_ROLE.HR_LEAD
        ) {
          if (this.props.location.pathname === ADMIN_HR_MANAGEMENT) {
            this.setState({ render: false });
            this.props.history.push(DASHBOARD_ALL_CANDIDATES);
            window.location.reload();
            return;
          }
        } else if (
          this.props.role === USER_ROLE.EMPLOYEE ||
          this.props.role === USER_ROLE.INTERVIEWER
        ) {
          if (
            this.props.location.pathname === DASHBOARD_ALL_CANDIDATES ||
            this.props.location.pathname === DASHBOARD_TASKS ||
            this.props.location.pathname === DASHBOARD_JOBS ||
            this.props.location.pathname === DASHBOARD_HIRING_TRACKER ||
            this.props.location.pathname === DASHBOARD_INTERVIEWER ||
            this.props.location.pathname === ADMIN_HR_MANAGEMENT
          ) {
            this.setState({ render: false });
            this.props.history.push(EMPLOYEE_HOME);
            window.location.reload();
            return;
          }
          if (this.props.location.pathname === LANDING_PAGE) {
            this.setState({ render: false });
            this.props.history.push(EMPLOYEE_HOME);
            window.location.reload();

            return;
          }
          return;
        }
      } else if (
        this?.props?.token === null &&
        this?.props?.location?.pathname !== LANDING_PAGE
      ) {
        this.props.history.push(LANDING_PAGE);
        window.location.reload();
      } else {
      }
    }

    render() {
      return <>{this.state.render ? <Component {...this.props} /> : <div />}</>;
    }
  }

  const mapStateToProps = (state) => ({
    token: state.auth.token,
    role: state.auth.role
  });

  return withRouter(connect(mapStateToProps)(AuthenticatedComponent));
}
