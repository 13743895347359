import { FC, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import applicationActions from "../../../redux/applications/actions";
import { getCurrentQueryParams } from "../../../helper";
import { history } from "../../../utilities/configureAxios";
import QueryString from "qs";
import { DASHBOARD_ALL_CANDIDATES } from "../../../constants/RouteConstants";

const mapStateToProps = (state: any) => ({
  applications: state.applications.applications,
  filters: state.filters.filters,
  currentPage: state.applications.currentPage,
  firstId: state.applications.firstId,
  nextApplication: state.applications.nextApplication,
  prevApplication: state.applications.prevApplication,
  currentId: state.filters.currentId,
});

interface INextAndPrev {
  applications: any;
  filters: any;
  currentPage: any;
  singleApplication: any;
  nextApplication: any;
  prevApplication: any;
  currentId: any;
}

const NextAndPrev: FC<INextAndPrev> = ({
  singleApplication,
  nextApplication,
  prevApplication,
}) => {
  const dispatch = useDispatch();
  const {
    status,
    page,
    q,
    job_id,
    graduation_gte,
    graduation_lte,
    ctc_gte,
    ctc_lte,
    cool_off_status
  } = getCurrentQueryParams();

  useEffect(() => {
    if (nextApplication?.id) {
      history.push(
        `${DASHBOARD_ALL_CANDIDATES}/${
          nextApplication?.id
        }?${QueryString.stringify(getCurrentQueryParams())}`
      );
    }
    return () => {
      dispatch({
        type: applicationActions.SET_STATE,
        payload: { nextApplication: {} },
      });
    };
  }, [nextApplication?.id]);
  useEffect(() => {
    if (prevApplication?.id) {
      history.push(
        `${DASHBOARD_ALL_CANDIDATES}/${
          prevApplication?.id
        }?${QueryString.stringify(getCurrentQueryParams())}`
      );
    }
    return () => {
      dispatch({
        type: applicationActions.SET_STATE,
        payload: { prevApplication: {} },
      });
    };
  }, [prevApplication?.id]);
  const next = () => {
    dispatch({
      type: applicationActions.GET_PREV_APPLICATION,
      payload: {
        ref_id: singleApplication?.id,
        params: {
          page: page ?? 1,
          status: status,
          job_id: job_id,
          graduation_gte: graduation_gte,
          graduation_lte: graduation_lte,
          ctc_gte: ctc_gte,
          ctc_lte: ctc_lte,
          q: q,
          cool_off_status:cool_off_status
        },
      },
    });
  };

  const previous = () => {
    dispatch({
      type: applicationActions.GET_NEXT_APPLICATION,
      payload: {
        ref_id: singleApplication?.id,
        params: {
          page: page ?? 1,
          status: status,
          job_id: job_id,
          graduation_gte: graduation_gte,
          graduation_lte: graduation_lte,
          ctc_gte: ctc_gte,
          ctc_lte: ctc_lte,
          q: q,
          cool_off_status:cool_off_status
        },
      },
    });
  };

  return (
    <div className="flex justify-end space-x-4">
      <div className=" cursor-pointer" onClick={previous}>
        <img src="/left.svg" alt="" />
      </div>
      <div className="cursor-pointer" onClick={next}>
        <img src="/right.svg" alt="" />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(NextAndPrev);
