import { Modal, Spin } from "antd";
import React, { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import useInterviewHistory from "./view/useInterviewHistory";

const mapStateToProps = (state: any) => ({
  open: state.applications.openInterviewHistoryModal,
  interviewId: state.applications.interviewHistoryId,
  interviewHistoryData: state.applications.interviewHistory,
  loading: state.auth.loading,
});
interface IInterviewHistory {
  open: boolean;
  loading: boolean;
  interviewId: string;
  interviewHistoryData: any;
}

const InterviewHistory: FC<IInterviewHistory> = ({
  open,
  interviewId,
  loading,
  interviewHistoryData,
}) => {
  const { interviewHistorySwitch, closeModal } = useInterviewHistory({
    interviewId,
  });

  return (
    <Modal
      open={open}
      onCancel={() => closeModal()}
      destroyOnClose={true}
      footer={null}
      className="edit-modal transition-height duration-1000 ease-linear"
      width={"650px"}
    >
      <div className="flex justify-between ">
        <div className="md:px-4">
          <h3 className="text-lg text-center capitalize font-semibold text-[#233A5C]">
            Interview History
          </h3>
        </div>
        <div
          className="w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer"
          onClick={() => closeModal()}
        >
          <AiOutlineClose className="w-5 h-5 text-gray-light" />
        </div>
      </div>
      <div
        className={`md:px-4 mt-4 max-h-[30rem] min-h-[5rem]  overflow-y-auto transition-height duration-100 ease-linear  ${
          loading ? "flex justify-center  items-center" : null
        }`}
      >
        <Spin size="large" className="hrTable-loader" spinning={loading}>
          {interviewHistoryData?.data?.map((item: any) => (
            <div id={item.id}>{interviewHistorySwitch(item)}</div>
          ))}
        </Spin>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps)(InterviewHistory);
