import * as Yup from 'yup';
import { JOB_REGREX } from '../../../../constants/HelperConstants';

export const addjobSchema = Yup.object({
  department: Yup.string().required('Team is Required field'),
  location: Yup.string().required('Location is required field'),
  organization: Yup.string().required('Organization is required field'),
  status: Yup.string().required('Status is required field'),
  job_title: Yup.string()
    .trim('This field cannot have only spaces as value')
    .min(3, 'Title must be atleast 3 characters') //change to 3
    .max(100, 'Title must be at most 100 characters')
    .required('Job title is required field')
    .matches(JOB_REGREX, 'Invalid job title'),
  short_description: Yup.string()
    .min(3, 'Description must not be Empty')
    .max(5000, 'Description has exceeded the maximum character limit')
    .trim('This field cannot have only spaces as value')
    .required('Short description is required field'),
  skill_description: Yup.string()
    .min(3, 'Skills Description must not be Empty')
    .max(5000, 'Skills Description has exceeded the maximum character limit')
    .trim('This field cannot have only spaces as value')
    .required('Skill description is required field'),
  required_skill: Yup.string()
    .min(3, 'Required Skills must not be Empty')
    .max(5000, 'Required Skills  has exceeded the maximum character limit')
    .trim('This field cannot have only spaces as value')
    .required('Required skill is required field'),
  skillset: Yup.array()
    .min(1, 'Please enter skills')
    .required('Please enter skill'),
  skillset_string: Yup.string()
    .strict(false)
    .trim('Please enter valid skill')
    .max(100, 'A skill can have maximum of 100 characters'),
  // .trim("This field cannot have only spaces as value")
  // .min(1, "Enter a skill")
  // .max(1500, "Skills has exceeded the maximum character limit")
  // .matches(/^[^\s,]+(?:,\s*[^\s,]+)*$/, "Please enter comma seprated values")
  // .required("Skill set is required field"),
  expectation: Yup.string()
    .trim('This field cannot have only spaces as value')
    .min(3, 'Expectation must not be Empty')
    .max(5000, 'Expectation has exceeded the maximum character limit')
    .required('Expectation is required field')
});
