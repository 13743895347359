import { Button } from 'antd';
import { FC } from 'react';

interface ButtonProps {
  text?: string;
  onClick?: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

const PrimaryButton: FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
  loading,
  type
}) => {
  return (
    <>
      <Button
        type='primary'
        className='primary-button'
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        htmlType={type}
      >
        {text}
      </Button>
    </>
  );
};

export default PrimaryButton;
