import {
  Button,
  Checkbox,
  Input,
  Radio,
  Rate,
  Select,
  Spin,
  Tooltip,
  Upload
} from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import categoryActions from '../../redux/questionBank/actions';
import type { RadioChangeEvent } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import PrimaryButton from '../../components/Buttons/Primary';
import Header from '../../components/Header';
import moment from 'moment';
import './editorScopedStyles.css';
import { history } from '../../utilities/configureAxios';
import {
  applicantFeedbackOptions,
  formatsForRichText,
  formattedBadConduct,
  formattedCommunication,
  formattedGoodConduct,
  formattedInternalOption,
  modulesForRichText
} from '../../helper';
import CancelButton from '../../components/Buttons/Cancel';
import DocumentsComponent from '../../components/FileUpload/DocumentUploader';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  getInterviewQuestionSet:
    state?.getInterviewQuestionSet?.getInterviewQuestionSet,
  questionsList: state.getInterviewQuestionSet.questionsList,
  getInterviewFeedback: state.getInterviewFeedback.getInterviewFeedback,
  getInterviewOverallFeedback:
    state.getInterviewOverallFeedback.getInterviewOverallFeedback,
  getInterviewSetFeedback:
    state.getInterviewSetFeedback.getInterviewSetFeedback,
  getSelectedInterview: state.interviewers.selectedInterview,
  singleInterviewInfo: state.getSingleInterviewInfo.singleInterviewInfo
});

function IndividualInterview({
  getInterviewQuestionSet,
  questionsList,
  getInterviewFeedback,
  getInterviewOverallFeedback,
  getInterviewSetFeedback,
  loading,
  getSelectedInterview,
  singleInterviewInfo
}: any) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [levelValue, setLevelValue] = useState<number>();
  const [feedback, setFeedback] = useState<any>(new Array(5).fill({}));
  const [overallTextFeedback, setOverallTextFeedback] = useState<string>('');
  const [isThankYou, setIsThankYou] = useState<any>(false);
  const [formattedDataForQuestionSet, setFormattedDataForQuestionSet] =
    useState<any>();
  const [generateQuestionSet, setGenerateQuestionSet] = useState<any>([]);
  const [overallRateChange, setOverallRateChange] = useState<number>(0);
  const [applicationStatus, setApplicationStatus] = useState<
    boolean | undefined
  >(undefined);
  const [feedbackFile, setFeedbackFile] = useState<Array<any>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const [feedbackCommunication, setFeedbackCommunication] = useState<any>();
  const [feedbackBadConduct, setFeedbackBadConduct] = useState<any>();
  const [feedbackGoodConduct, setFeedbackGoodConduct] = useState<any>();
  const [feedbackInternalOption, setFeedbackInternalOption] = useState<any>();
  const [dynamicValues, setDynamicValues] = useState<
    {
      question: string;
      answer: string;
      id: number;
      rating?: number;
      content?: string;
    }[]
  >([]);
  const [showExtraSets, setShowExtraSets] = useState(false);

  const handleFeedbackCommunication = (id: any, key: any) => {
    setFeedbackCommunication(id);
  };
  const handleFeedbackBadConduct = (id: any, key: any) => {
    setFeedbackBadConduct(id);
  };

  const handleFeedbackGoodConduct = (id: any, key: any) => {
    setFeedbackGoodConduct(id);
  };

  const handleFeedbackInternalOption = (id: any, key: any) => {
    setFeedbackInternalOption(id);
  };

  useEffect(() => {
    const interviewId = location?.pathname?.split('/')[3];
    let body = {
      interviewId: interviewId
    };
    if (
      interviewId &&
      location?.pathname?.startsWith('employee/view-feedback')
    ) {
      dispatch({
        type: categoryActions.GET_INTERVIEW_FEEDBACK,
        payload: body
      });
      dispatch({
        type: categoryActions.GET_INTERVIEW_OVERALL_FEEDBACK,
        payload: body
      });
      // dispatch({
      //     type: categoryActions.GET_INTERVIEW_SET_FEEDBACK,
      //     payload: body
      // })
    }
  }, [location.pathname]);

  useEffect(() => {
    const uuid = location?.pathname?.split('/')[3];
    let body = {
      uuid: uuid
    };
    dispatch({
      type: categoryActions.GET_SINGLE_INTERVIEW_INFO,
      payload: body
    });

    return () => {
      dispatch({
        type: categoryActions.RESET_INTERVIEW_QUESTION_SET
      });
      dispatch({
        type: categoryActions.GET_SINGLE_INTERVIEW_INFO_RESET
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    const uuid = location?.pathname?.split('/')[3];
    let body = {
      uuid: uuid
    };
    if (uuid && singleInterviewInfo?.data?.question_set_status === 3) {
      dispatch({
        type: categoryActions.GET_INTERVIEW_QUESTION_SET,
        payload: body
      });
    }
  }, [singleInterviewInfo]);

  const modulesQuestion = {
    toolbar: false
  };

  const onLevelChange = (e: RadioChangeEvent) => {
    setLevelValue(e.target.value);
  };

  const onThankYouChange = (e: CheckboxChangeEvent) => {
    setIsThankYou(e.target.checked);
  };

  const isSaveFeedbackDisabled = () => {
    let isFeedbackEmpty = questionsList?.map(
      (i: any) => i?.feedback?.length > 0
    );
    let isRatingEmpty = questionsList?.map((i: any) => i?.rating > 0);
    if (isFeedbackEmpty?.includes(false) && isRatingEmpty?.includes(false)) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange = (e: any, index: number) => {
    const formattedList = questionsList.map((d: any, i: number) =>
      index === i ? { ...d, feedback: e.target.value } : { ...d }
    );
    const formatForPost = formattedList?.map((i: any) => {
      return {
        q_id: i.uuid,
        content: i.feedback,
        rating: i.rating
      };
    });
    setFormattedDataForQuestionSet(formatForPost);

    dispatch({
      type: categoryActions.HANDLE_QUESTION_CHANGE,
      payload: questionsList.map((d: any, i: number) =>
        index === i ? { ...d, feedback: e.target.value } : { ...d }
      )
    });
  };

  const handleRateChange = (e: any, index: number) => {
    const formattedList = questionsList.map((d: any, i: number) =>
      index === i ? { ...d, rating: e } : { ...d }
    );
    const formatForPost = formattedList?.map((i: any) => {
      return {
        q_id: i.uuid,
        content: i.feedback,
        rating: i.rating
      };
    });
    setFormattedDataForQuestionSet(formatForPost);
    dispatch({
      type: categoryActions.HANDLE_QUESTION_CHANGE,
      payload: questionsList.map((d: any, i: number) =>
        index === i ? { ...d, rating: e } : { ...d }
      )
    });
  };

  useEffect(() => {
    const formatForPost = questionsList?.map((i: any) => {
      return {
        q_id: i.uuid,
        content: i.feedback,
        rating: i.rating
      };
    });
    setFormattedDataForQuestionSet(formatForPost);
  }, [questionsList]);

  const handleOverrallTextField = (e: any) => {
    setOverallTextFeedback(e.target.value);
  };

  const handleOverrallClick = () => {
    const formatForPost = questionsList?.map((i: any) => {
      return {
        q_id: i.uuid,
        content: i.feedback ? i.feedback : '',
        rating: i.rating ? i.rating : 0
      };
    });
    const finalFile = files?.filter((val: any) => {
      if (val?.status === 'done' || val?.slug?.length) {
        return {
          name: val?.name,
          type: val?.type,
          slug: val?.slug
        };
      }
    });
    let body: any = {};
    if (!applicationStatus) {
      body = {
        interviewId: location?.pathname?.split('/')[3],
        question_feedbacks: [...formatForPost],
        interview_feedback: {
          send_thankyou_mail: isThankYou,
          overall_feedback: overallTextFeedback ? overallTextFeedback : '',
          overall_rating: overallRateChange ? overallRateChange : 0,
          level: null,
          communication_skill: null,
          bad_conduct: null,
          good_conduct: null,
          internal_option: null,
          attachment: finalFile?.length ? finalFile : null,
          meta: dynamicValues,
          // new field
          in_next_round: applicationStatus
        }
      };
    } else {
      body = {
        interviewId: location?.pathname?.split('/')[3],
        question_feedbacks: [...formatForPost],
        interview_feedback: {
          send_thankyou_mail: isThankYou,
          overall_feedback: overallTextFeedback ? overallTextFeedback : '',
          overall_rating: overallRateChange ? overallRateChange : 0,
          level: Number(levelValue),
          communication_skill: Number(feedbackCommunication),
          bad_conduct: feedbackBadConduct?.includes('6')
            ? [6]
            : feedbackBadConduct.map(Number),
          good_conduct: feedbackGoodConduct?.includes('5')
            ? [5]
            : feedbackGoodConduct.map(Number),
          internal_option: Number(feedbackInternalOption),
          attachment: finalFile?.length ? finalFile : null,
          meta: dynamicValues,
          // new field
          in_next_round: applicationStatus
        }
      };
    }
    // if (formatForPost.length > 0 ){
    //     body['question_feedbacks']= [...formatForPost]
    // }
    dispatch({
      type: categoryActions.SEND_OVERALL_FEEDBACK,
      payload: body
    });
  };

  const handleAddMoreQuestionSet = () => {
    setDynamicValues([
      ...dynamicValues,
      {
        question: '',
        id: Math.random(),
        answer: '',
        rating: undefined,
        content: undefined
      }
    ]);
  };

  const handleExtraQuestionChange = (e: any, index: any) => {
    let newDynamicValues = [...dynamicValues];
    newDynamicValues[index].question = e;
    setDynamicValues(newDynamicValues);
  };

  const handleExtraAnswerChange = (e: any, index: any) => {
    let newDynamicValues = [...dynamicValues];
    newDynamicValues[index].answer = e;
    setDynamicValues(newDynamicValues);
  };

  const handleExtraRateChange = (e: any, index: any) => {
    let newDynamicValues = [...dynamicValues];
    newDynamicValues[index].rating = e;
    setDynamicValues(newDynamicValues);
  };

  const handleExtraFeedbackChange = (e: any, index: any) => {
    let newDynamicValues = [...dynamicValues];
    newDynamicValues[index].content = e.target.value;
    setDynamicValues(newDynamicValues);
  };

  const isQuestionMappingValidated = () => {
    const validation = dynamicValues.every((item) => {
      return (
        item?.answer?.replace(/<(.|\n)*?>/g, '').length > 0 &&
        item?.question?.replace(/<(.|\n)*?>/g, '').length > 0 &&
        (!((item?.content ? item.content : '') === '') ||
          !((item?.rating ? item.rating : 0) === 0))
      );
    });
    if (validation) {
      return false;
    } else return true;
  };

  const isEnableSaveBtn = () => {
    if (
      !!!feedbackInternalOption ||
      !!feedbackGoodConduct?.length ||
      !!feedbackBadConduct?.length ||
      !!feedbackCommunication?.length ||
      !!!overallTextFeedback
    ) {
      return false;
    } else return true;
  };

  const recommendedLevel = [
    {
      value: null,
      label: 'None'
    },
    {
      value: 1,
      label: 'L0'
    },
    {
      value: 2,
      label: 'L1'
    },
    {
      value: 3,
      label: 'L2'
    },
    {
      value: 4,
      label: 'L3'
    },
    {
      value: 5,
      label: 'L4'
    },
    {
      value: 6,
      label: 'L5'
    },
    {
      value: 7,
      label: 'L6'
    },
    {
      value: 8,
      label: 'L7'
    },
    {
      value: 9,
      label: 'L8'
    },
    {
      value: 10,
      label: 'L9'
    },
    {
      value: 11,
      label: 'L10'
    }
  ];

  const handleRemoveAdditionalQuestionSet = (index: any) => {
    const data = [...dynamicValues];
    data.splice(index, 1);
    if (data.length === 0) setShowExtraSets(false);
    setDynamicValues(data);
  };

  return (
    <>
      <div className='w-full  h-screen  overflow-y-auto bg-ghost-white relative'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Add Feedback</title>
        </Helmet>
        <Header />
        <Spin size='large' className='hrTable-loader' spinning={loading}>
          <main>
            <section
              style={{ minHeight: '100vh' }}
              className='bg-white rounded-lg p-5 m-8'
            >
              <div className='text-[20px] text-light-blue font-semibold'>
                Add Interview Feedback
              </div>
              <br />
              {/* {getSelectedInterview ? (
                            <>
                                <div className="flex space-x-12 my-4">
                                    <div className="text-[#707070] flex text-[16px] font-semibold">Applicant Name: <Tooltip title={getSelectedInterview?.applicant_name}><span className="font-light max-w-[15rem] line-clamp-1 break-words  ml-2 capitalize">{getSelectedInterview?.applicant_name}</span></Tooltip></div>
                                    <div className="text-[#707070] flex text-[16px] font-semibold">Job Position: <Tooltip title={getSelectedInterview?.job_title}><span className="font-light max-w-[15rem] line-clamp-1 break-words ml-2 capitalize">{getSelectedInterview?.job_title}</span></Tooltip></div>
                                </div>
                                <div className="my-4">
                                    <div className="text-[#707070] text-[16px] font-semibold">Date & Time: <span className="font-light">
                                        {moment(getSelectedInterview?.scheduled_at).format("MMMM Do, YYYY, h:mm A")}
                                    </span></div>
                                </div>
                            </>
                        ) : <p>Loading...</p>} */}
              {getInterviewQuestionSet ? (
                <>
                  <div className='flex space-x-12 my-4'>
                    <div className='text-[#707070] flex text-[16px] font-semibold'>
                      Applicant Name:{' '}
                      <Tooltip
                        title={singleInterviewInfo?.data?.applicant_name}
                      >
                        <span className='font-light max-w-[15rem] line-clamp-1 break-words  ml-2 capitalize'>
                          {singleInterviewInfo?.data?.applicant_name}
                        </span>
                      </Tooltip>
                    </div>
                    <div className='text-[#707070] flex text-[16px] font-semibold'>
                      Job Position:{' '}
                      <Tooltip title={singleInterviewInfo?.data?.job_title}>
                        <span className='font-light max-w-[15rem] line-clamp-1 break-words ml-2 capitalize'>
                          {singleInterviewInfo?.data?.job_title}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className='my-4'>
                    <div className='text-[#707070] text-[16px] font-semibold'>
                      Date & Time:{' '}
                      <span className='font-light'>
                        {moment(singleInterviewInfo?.data?.scheduled_at).format(
                          'MMMM Do, YYYY, h:mm A'
                        )}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <p>Loading...</p>
              )}
              <br />
              {questionsList &&
                questionsList?.map((itm: any, index: number) => (
                  <div className='my-8' key={index}>
                    <div className='flex items-center my-4'>
                      {itm?.level === 1 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#4E9F09',
                            border: '1px solid #4E9F09',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Easy
                        </span>
                      )}
                      {itm?.level === 2 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#D19E37',
                            border: '1px solid #4E9F09',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Medium
                        </span>
                      )}
                      {itm?.level === 3 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#C87172',
                            border: '1px solid #C87172',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Hard
                        </span>
                      )}
                      {itm?.question_type === 1 ? (
                        <div className='text-[16px] text-[#707070] font-medium'>
                          Subjective Type
                        </div>
                      ) : (
                        <div className='text-[16px] text-[#707070] font-medium'>
                          Objective Type
                        </div>
                      )}
                      {itm?.categories_list
                        ?.slice(0, 3)
                        ?.map((itm: any, index: number) => (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            key={index}
                            className='individual-interview-names'
                          >
                            {itm?.name}
                          </div>
                        ))}
                      {itm?.categories_list?.length > 3 && (
                        <Tooltip
                          title={itm?.categories_list
                            ?.slice(3)
                            ?.map((i: any) => (
                              <span>
                                {i?.name}
                                <br />
                              </span>
                            ))}
                        >
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className='individual-interview-categories'
                          >
                            ...
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className='feedback my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Question
                      </div>
                      <ReactQuill
                        className='editor'
                        value={itm?.content}
                        readOnly
                        style={{
                          color: '#707070',
                          fontSize: '20px',
                          fontWeight: '500'
                        }}
                        modules={modulesQuestion}
                      />
                    </div>
                    <div className='feedback my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Answer
                      </div>
                      <ReactQuill
                        className='editor'
                        value={itm?.answer}
                        readOnly
                        style={{
                          color: '#707070',
                          fontSize: '20px',
                          fontWeight: '500'
                        }}
                        modules={modulesQuestion}
                      />
                    </div>
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Feedback
                      </div>
                      <Input.TextArea
                        value={itm?.feedback || ''}
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder='Type your feedback here'
                        rows={4}
                        minLength={2}
                        style={{ minHeight: '5rem' }}
                      />
                      <div className='relative'>
                        {formattedDataForQuestionSet?.[index]?.content?.length >
                          250 && (
                          <p className='text-right text-star-red right-0 absolute mt-2 mb-0 pb-0'>
                            {' '}
                            Feedback has exceeded the maximum limit
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Rating
                      </div>
                      <Rate
                        value={itm?.rating || ''}
                        onChange={(e) => handleRateChange(e, index)}
                        style={{ color: '#2D94F3' }}
                      />
                    </div>
                    <br />
                    <div className='relative'>
                      {((formattedDataForQuestionSet?.[index]?.content === '' &&
                        formattedDataForQuestionSet?.[index]?.rating === 0) ||
                        (!formattedDataForQuestionSet?.[index]?.content &&
                          formattedDataForQuestionSet?.[index]?.rating === 0) ||
                        (formattedDataForQuestionSet?.[index]?.content === '' &&
                          formattedDataForQuestionSet?.[index]?.rating ===
                            undefined)) && (
                        <p className='text-right text-star-red absolute right-0 mt-2 mb-0 pb-0'>
                          {' '}
                          Feedback or rating one of the field should be filled
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              {!showExtraSets && (
                <PrimaryButton
                  onClick={() => {
                    setShowExtraSets(true);
                    handleAddMoreQuestionSet();
                  }}
                  text='Add Extra Questions'
                />
              )}
              <section>
                {/* //----------------------------// extra manual fields entered by interviewer //---------------------------------------------- */}
                {showExtraSets ? (
                  <>
                    <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                      Add Extra Question Sets
                    </div>
                    {dynamicValues?.map((element, index) => (
                      <div key={element.id}>
                        <div className='my-4'>
                          <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                            Question{' '}
                            <span className='text-lg text-star-red pl-1'>
                              *
                            </span>
                          </div>
                          <div>
                            <ReactQuill
                              className='editor'
                              style={{
                                color: '#707070',
                                fontSize: '20px',
                                fontWeight: '500'
                              }}
                              modules={modulesForRichText}
                              formats={formatsForRichText}
                              onChange={(e) =>
                                handleExtraQuestionChange(e, index)
                              }
                              placeholder='Add a Question'
                              value={element.question}
                            />
                          </div>
                          <div className='relative'>
                            {element.question?.length > 5000 && (
                              <p className='text-star-red pb-0 mb-0 text-right absolute right-0'>
                                Question has exceeded the maximum limit
                              </p>
                            )}
                          </div>
                        </div>
                        <div className='my-4'>
                          <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                            Answer{' '}
                            <span className='text-lg text-star-red pl-1'>
                              *
                            </span>
                          </div>
                          <ReactQuill
                            className='editor'
                            style={{
                              color: '#707070',
                              fontSize: '20px',
                              fontWeight: '500'
                            }}
                            modules={modulesForRichText}
                            formats={formatsForRichText}
                            onChange={(e) => handleExtraAnswerChange(e, index)}
                            placeholder='Add an Answer'
                            value={element.answer}
                          />
                          <div className='relative'>
                            {element.answer?.length > 5000 && (
                              <p className='text-star-red pb-0 mb-0 text-right absolute right-0'>
                                Answer has exceeded the maximum limit
                              </p>
                            )}
                          </div>
                        </div>
                        <div className='my-4'>
                          <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                            Feedback
                          </div>
                          <Input.TextArea
                            onChange={(e) =>
                              handleExtraFeedbackChange(e, index)
                            }
                            placeholder='Type your feedback here'
                            rows={4}
                            minLength={2}
                            style={{ minHeight: '5rem' }}
                          />
                          <div className='relative'>
                            {(element?.content as unknown as string)?.length >
                              250 && (
                              <p className='text-right text-star-red right-0 absolute mt-2 mb-0 pb-0'>
                                {' '}
                                Feedback has exceeded the maximum limit
                              </p>
                            )}
                          </div>
                        </div>
                        <div className='my-4'>
                          <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                            Rating
                          </div>
                          <Rate
                            onChange={(e) => handleExtraRateChange(e, index)}
                            style={{ color: '#2D94F3' }}
                          />
                        </div>
                        <br />
                        <div className='relative'>
                          <div className='flex flex-row-reverse mt-4'>
                            <Button
                              className='dynamic-cancel-button'
                              onClick={() =>
                                handleRemoveAdditionalQuestionSet(index)
                              }
                            >
                              Remove question set
                            </Button>
                          </div>
                          {((element.content === '' && element.rating === 0) ||
                            (!element.content && element.rating === 0) ||
                            (element.content === '' &&
                              element.rating === undefined)) && (
                            <p className='text-right text-star-red absolute top-[-2.5rem] right-0 mt-2 mb-0 pb-0'>
                              {' '}
                              Feedback or rating one of the field should be
                              filled
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                    <div className='my-4'>
                      <PrimaryButton
                        disabled={isQuestionMappingValidated()}
                        onClick={handleAddMoreQuestionSet}
                        text='Add More'
                      />
                    </div>
                  </>
                ) : null}

                {/* --------------------------------// overall feedback //--------------------------------- */}
                <div className='my-8'>
                  <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                    Overall Feedback{' '}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </div>
                  <Input.TextArea
                    onChange={(e) => handleOverrallTextField(e)}
                    placeholder='Type your overall feedback here'
                    rows={6}
                    minLength={2}
                    style={{ minHeight: '5rem' }}
                  />
                  <div className='relative'>
                    {overallTextFeedback?.length > 250 && (
                      <p className='text-right text-star-red mt-2 mb-0 pb-0 absolute right-0'>
                        Overall Feedback has exceeded the maximum limit
                      </p>
                    )}
                  </div>
                </div>

                <div className='my-4'>
                  <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                    Overall Rating{' '}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </div>
                  <Rate
                    value={overallRateChange}
                    onChange={(e) => setOverallRateChange(e)}
                    style={{ color: '#2D94F3' }}
                  />
                </div>
                <div className='my-4'>
                  <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                    Move forward with this applicant{' '}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </div>
                  <div className='test'>
                    <Select
                      value={applicationStatus}
                      className='filter-select w-32  border border-[#F4F7FC] rounded-lg'
                      onChange={(e) => {
                        setApplicationStatus(e);
                      }}
                      placeholder={'Choose'}
                      options={applicantFeedbackOptions}
                    ></Select>
                  </div>
                </div>
                {applicationStatus === undefined ||
                applicationStatus === true ? (
                  <>
                    {/* Recommended level */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Recommended Levels
                      </div>
                      <div className='test'>
                        <Select
                          className='filter-select  w-28  border border-[#F4F7FC] rounded-lg'
                          onChange={(value) => setLevelValue(value)}
                          placeholder={'Choose'}
                          value={levelValue}
                          options={recommendedLevel}
                        />
                      </div>
                    </div>
                    {/* //Communication */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Was the candidate able to communicate clearly?
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </div>
                      <div className='test'>
                        <Select
                          value={feedbackCommunication}
                          className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                          onChange={(id, key) =>
                            handleFeedbackCommunication(id, key)
                          }
                          placeholder={'Choose'}
                          options={formattedCommunication}
                          style={{ maxWidth: '300px' }}
                        ></Select>
                      </div>
                    </div>
                    {/* //Bad conduct */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Did you notice any of the following case?
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </div>
                      <div className='test'>
                        <Select
                          mode='multiple'
                          value={feedbackBadConduct}
                          className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                          // onChange={(id, key) => handleFeedbackBadConduct(id, key)}
                          onChange={(id, key) => {
                            if (
                              id.includes(
                                formattedBadConduct?.[
                                  formattedBadConduct?.length - 1
                                ]?.value
                              )
                            ) {
                              setFeedbackBadConduct((prev: any) => {
                                if (
                                  prev?.length === 1 &&
                                  prev.includes(
                                    formattedBadConduct?.[
                                      formattedBadConduct?.length - 1
                                    ]?.value
                                  )
                                ) {
                                  let indexOfDeleteElement = id.indexOf(
                                    formattedBadConduct?.[
                                      formattedBadConduct?.length - 1
                                    ]?.value
                                  );
                                  id.splice(indexOfDeleteElement, 1);
                                  return id;
                                }
                                return [
                                  formattedBadConduct?.[
                                    formattedBadConduct?.length - 1
                                  ]?.value
                                ];
                              });
                            } else {
                              setFeedbackBadConduct(id);
                            }
                          }}
                          //  filterOption={(input, option) => {
                          //     return option?.label
                          //       ?.toLowerCase()
                          //       ?.includes(input?.toLowerCase())
                          //       ? true
                          //       : false;
                          //   }}
                          placeholder={'Choose'}
                          options={formattedBadConduct}
                        ></Select>
                      </div>
                    </div>
                    {/* //Good Conduct */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Any Good Things you noticed about the candidate?
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </div>
                      <div className='test'>
                        <Select
                          mode='multiple'
                          value={feedbackGoodConduct}
                          className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                          // onChange={(id, key) => handleFeedbackGoodConduct(id, key)}
                          onChange={(id, key) => {
                            if (
                              id.includes(
                                formattedGoodConduct?.[
                                  formattedGoodConduct?.length - 1
                                ]?.value
                              )
                            ) {
                              setFeedbackGoodConduct((prev: any) => {
                                if (
                                  prev?.length === 1 &&
                                  prev.includes(
                                    formattedGoodConduct?.[
                                      formattedGoodConduct?.length - 1
                                    ]?.value
                                  )
                                ) {
                                  let indexOfDeleteElement = id.indexOf(
                                    formattedGoodConduct?.[
                                      formattedGoodConduct?.length - 1
                                    ]?.value
                                  );
                                  id.splice(indexOfDeleteElement, 1);
                                  return id;
                                }
                                return [
                                  formattedGoodConduct?.[
                                    formattedGoodConduct?.length - 1
                                  ]?.value
                                ];
                              });
                            } else {
                              setFeedbackGoodConduct(id);
                            }
                          }}
                          placeholder={'Choose'}
                          options={formattedGoodConduct}
                        ></Select>
                      </div>
                    </div>
                    {/* //Internal Option */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Internal Option for Shortlisted Candidates
                        {/* <span className="text-lg text-star-red pl-1">*</span> */}
                      </div>
                      <div className='test'>
                        <Select
                          value={feedbackInternalOption}
                          className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                          onChange={(id, key) =>
                            handleFeedbackInternalOption(id, key)
                          }
                          placeholder={'Choose'}
                          options={formattedInternalOption}
                        ></Select>
                      </div>
                    </div>
                  </>
                ) : null}
                <div>
                  <p className='applicant-modal-label'>
                    {'Optional File Upload (.pdf, .txt, .docx, .png, .jpg)'}
                  </p>

                  <div className='cursor-pointer'>
                    <DocumentsComponent
                      uploadedFiles={feedbackFile}
                      maxLimit={5}
                      accept='image/jpg, image/jpeg, image/png, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf'
                      maxCount={5}
                      multiple
                      onChange={(responseObj: any) => {
                        setFeedbackFile(responseObj);
                      }}
                      setFiles={setFiles}
                      files={files}
                      size={5}
                    />
                  </div>
                </div>
                <div className='my-4'>
                  <Checkbox onChange={onThankYouChange}>
                    Send thank you email
                  </Checkbox>
                </div>
                <div className='flex justify-end'>
                  <PrimaryButton
                    disabled={
                      // !!!feedbackInternalOption ||
                      applicationStatus === false
                        ? !!!overallRateChange ||
                          overallTextFeedback?.trim()?.length === 0 ||
                          overallTextFeedback?.length > 250 ||
                          Boolean(
                            files.filter((f: any) => f.slug).length !==
                              files.length
                          )
                        : applicationStatus === undefined ||
                          !!!feedbackGoodConduct?.length ||
                          !!!feedbackBadConduct?.length ||
                          !!!feedbackCommunication ||
                          !!!overallRateChange ||
                          overallTextFeedback?.trim()?.length === 0 ||
                          overallTextFeedback?.length > 250 ||
                          formattedDataForQuestionSet?.filter(
                            (item: any) =>
                              ((item?.rating ? item?.rating : 0) === 0 &&
                                (item?.content ? item?.content : '')?.trim()
                                  ?.length === 0) ||
                              (item?.content ? item?.content : '')?.length > 250
                          )?.length > 0 ||
                          (showExtraSets
                            ? dynamicValues?.filter(
                                (item: any) =>
                                  item?.question
                                    ?.replace(/<(.|\n)*?>/g, '')
                                    ?.trim()?.length === 0 ||
                                  item?.question?.length > 5000 ||
                                  item?.answer
                                    ?.replace(/<(.|\n)*?>/g, '')
                                    ?.trim()?.length === 0 ||
                                  item?.question?.length > 5000 ||
                                  ((item?.rating ? item?.rating : 0) === 0 &&
                                    (item?.content ? item?.content : '')?.trim()
                                      ?.length === 0) ||
                                  (item?.content ? item?.content : '')?.length >
                                    250
                              )?.length > 0
                            : files.filter((f: any) => f.slug).length !==
                              files.length
                            ? true
                            : false)
                    }
                    onClick={handleOverrallClick}
                    text='Save Feedbacks'
                  />
                </div>
              </section>
            </section>
          </main>
        </Spin>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(IndividualInterview);
