import actions from "./actions";

const initialState = {
  selectedTemplateStore: '1',
  formFields: [],
  formValues: {},
  currentStep: 0,
  emailTemplates: {}
};

const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_TEMPLATE:
      return {
        ...state,
        selectedTemplateStore: action.payload,
        formValues: {}
      };
    case actions.SET_FORM_FIELDS:
      return {
        ...state,
        formFields: action.payload
      };
    case actions.SET_FORM_VALUES:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload
        }
      };
    case actions.SET_EMAIL_TEMPLATES:
      return {
        ...state,
        ...action.payload
      };
    case actions.RESET_FORM_VALUES:
      return {
        ...state,
        formValues: {}
      };
    case actions.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload
      };
    default:
      return state;
  }
};

export default emailTemplatesReducer;
