import { Select, Tag, Tooltip } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import jobActions from '../../../redux/jobs/actions';
import filterActions from '../../../redux/filters/actions';
import { history } from '../../../utilities/configureAxios';
import { getSearchParamsUrl, jobStatusOptions } from '../../../helper';
import { useLocation } from 'react-router-dom';
import { queryStringToJSON } from '../../../utilities/routies';
import AutoComplete from '../../AutoComplete/AutoComplete';
import JobsServices from '../../../services/jobs';
import { isString } from 'lodash';
const mapStateToProps = (state: any) => ({
  jobs: state.jobs.jobs,
  jobsForFilter: state.jobs.jobsForFilter,
  filters: state.filters.filters,
  selectApp: state.filters.selectApp,
  appStatus: state.filters.appStatus
});
interface IJobSelect {
  jobs: any;
  active?: boolean;
  jobsForFilter: any;
  filters?: any;
  selectApp?: any;
  appStatus?: any;
  location?: any;
}
const JobSelect: React.FC<IJobSelect> = ({
  jobs,
  jobsForFilter,
  selectApp,
  location
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = queryStringToJSON(search);
  const { q, status, job_id, label } = queryStringToJSON(search);
  const jobService = new JobsServices();
  useEffect(() => {
    dispatch({ type: jobActions.GET_ALL_JOBS_FOR_FILTER });
  }, [dispatch]);
  const handleChange = (value: any) => {
    if (value === 'All Jobs') {
      history.push(getSearchParamsUrl({ job_id: null, page: null }), {});
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          selectApp: 'All Jobs'
        }
      });
    } else {
      history.push(getSearchParamsUrl({ job_id: value, page: null }), {});
      let title = getJobTitle(value.toString());
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          selectApp: title,
          isFilterApplied: true
        }
      });
    }
  };
  const getJobTitle = (jobId: any) => {
    let jobTitle = jobsForFilter?.data?.filter((job: any) => jobId === job?.id);
    let title = jobTitle[0]?.job_title;
    return title;
  };
  const handleFilterChange = (key: string, value: any) => {
    let payload: any = {
      page: 1,
      q,
      job_id
    };
    payload[key] = isString(value) ? String(value) : null;
    history.push(
      getSearchParamsUrl(key?.length ? payload : { ...value, page: 1 })
    );
  };
  const customJSX = (item: any): ReactNode => {
    return (
      <Select.Option
        label={
          <Tooltip title={item.job_title}>
            <div className='truncate'>{item.job_title}</div>
          </Tooltip>
        }
        value={`${String(item.id)}~${item.job_title}`}
        data={item}
      >
        <div className='flex justify-between'>
          <div className='truncate w-1/2'>
            <Tooltip title={item.job_title}>{item.job_title}</Tooltip>
          </div>
          <div>
            <Tag
              color={item?.status === 1 ? 'success' : 'error'}
              className='statusTag'
            >
              {jobStatusOptions[item?.status]?.label}
            </Tag>
          </div>
          <div>
            <Tag color='blue' className='!rounded-[5px]'>
              {`${item?.organization_name.slice(0, 20)}${
                item?.organization_name?.length > 20 ? '...' : ''
              }`}
            </Tag>
          </div>
        </div>
      </Select.Option>
    );
  };
  return (
    <div className='flex flex-col mx-4 shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg min-w-[13rem]'>
      <AutoComplete
        apiService={jobService.GetAllJobsForFilter}
        key='job_id'
        placeholder='Filter by Job Position'
        className=' w-full border border-[#F4F7FC] rounded-lg '
        customJSX={customJSX}
        idType='job_id'
        isPayload
        setselectedValue={(data: any) => {
          if (data) {
            handleFilterChange('', {
              job_id: data?.id || null,
              label: data?.job_title || null
            });
          } else {
            handleFilterChange('', {
              job_id: null,
              label: null
            });
          }
        }}
        defaultValue={
          String(job_id)?.length && isString(String(job_id)) && label?.length
            ? `${job_id}~${label}`
            : ''
        }
      />
    </div>
  );
};
export default connect(mapStateToProps)(JobSelect);
