import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EmailSteps from '../../components/EmailSteps';
import { useLocation } from 'react-router-dom';
import { queryStringToJSON } from '../../utilities/routies';
import { connect } from 'react-redux';
import emailActions from '../../redux/emailTemplates/actions';
import { history } from '../../utilities/configureAxios';
import { getSearchParamsUrl } from '../../helper';

const mapStateToProps = (state: any) => ({
  currentStepStore: state.emailTemplates.currentStep
});

const EmailTemplates: React.FC<any> = ({ currentStepStore, dispatch }) => {
  const { search } = useLocation();
  const { step } = queryStringToJSON(search);

  useEffect(() => {
    dispatch({
      type: emailActions.RESET_FORM_VALUES
    });
    history.push(
      getSearchParamsUrl({
        step: currentStepStore
      }),
      {}
    );
  }, []);

  useEffect(() => {
    if (step) {
      dispatch({
        type: emailActions.SET_CURRENT_STEP,
        payload: Number(step) - 1
      });
    }
  }, [step, dispatch]);

  const handleStepChange = (newStep: number) => {
    dispatch({
      type: emailActions.SET_CURRENT_STEP,
      payload: newStep
    });
  };

  return (
    <>
      <div className='w-full h-screen bg-ghost-white relative'>
        <Header />
        <HelmetProvider>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Custom Email templates</title>
          </Helmet>
        </HelmetProvider>
        <div className='px-8'>
          <EmailSteps onStepChange={handleStepChange} />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(EmailTemplates);
