import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { coolOffArray } from '../../../../constants/HelperConstants';
import { getSearchParamsUrl } from '../../../../helper';
import { history } from '../../../../utilities/configureAxios'
import { queryStringToJSON } from '../../../../utilities/routies';
import filterActions from "../../../../redux/filters/actions";


const useCoolOffSelect = () => {
    const dispatch = useDispatch()

    const handleStatusChange =(value:boolean | null)=>{
        if (value === coolOffArray[0].value){
            history.push(getSearchParamsUrl({ cool_off_status: null, page: 1 }), {});
            dispatch({
                type: filterActions.SET_STATE,
                payload: {
                    coolOffStatus: null,
                },
              });
        }else{
            history.push(getSearchParamsUrl({ cool_off_status: value, page: 1 }), {});
            dispatch({
                type: filterActions.SET_STATE,
                payload: {
                  coolOffStatus: value,
                  isFilterApplied: true,
                },
              });
        }
    }
    
  return {
    handleStatusChange,
  }
}

export default useCoolOffSelect
