import { Button } from 'antd';
import React from 'react';

interface IJobSelect {
  handleClear: () => void;
}

const ClearFilters: React.FC<IJobSelect> = ({ handleClear }) => {
  return (
    <div
      className='flex flex-col mr-4 ml-4 shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-[0.5rem]'
      onClick={handleClear}
    >
      <Button className='clear-filter w-full h-full'>
        <span className='opacity-50'>Clear</span>
      </Button>
    </div>
  );
};

export default ClearFilters;
