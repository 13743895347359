import { Breadcrumb } from "antd";
import { FaUserTie } from "react-icons/fa";

const InterviewerBreadcrumb = () => {
  return (
    <>
      <div className="mt-2">
        <Breadcrumb separator=">">
          <Breadcrumb.Item className="flex text-lg lg:text-xl text-primary font-medium">
            <FaUserTie className="w-[24px] h-[24px] mt-[3px] mr-2" />
            Interviewers
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
};

export default InterviewerBreadcrumb;
