import { Checkbox, Input, Radio, Rate, Spin, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import { connect, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/Primary";
import Header from "../../components/Header";
import categoryActions from "../../redux/questionBank/actions";
import "./editorScopedStyle.css"


const mapStateToProps = (state: any) => ({
    loading: state.auth.loading,
    getInterviewFeedback: state.getInterviewFeedback.getInterviewFeedback,
    getInterviewOverallFeedback: state.getInterviewOverallFeedback.getInterviewOverallFeedback,
    getInterviewQuestionSet: state?.getInterviewQuestionSet?.getInterviewQuestionSet,

});


function ViewQuestionSet({ getInterviewFeedback, getInterviewOverallFeedback, getInterviewQuestionSet ,loading}: any) {
    const [interviewId, setInterviewId] = useState<any>();

    const modulesQuestion = {
        "toolbar": false
    }

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const id = location?.pathname?.split("/")[3]
        setInterviewId(id)
        let body = {
            uuid: interviewId
        }
        if (interviewId) {
            // dispatch({
            //     type: categoryActions.GET_INTERVIEW_FEEDBACK,
            //     payload: body
            // })
            // dispatch({
            //     type: categoryActions.GET_INTERVIEW_OVERALL_FEEDBACK,
            //     payload: body
            // })
            dispatch({
                type: categoryActions.GET_INTERVIEW_QUESTION_SET,
                payload: body
            })
        }
    }, [interviewId])


    return (
        <>
            <>
                <div className="w-full  h-screen  overflow-y-auto bg-ghost-white relative">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>View Question Set</title>
                    </Helmet>
                    <Header />
                    <main>
                        <Spin size="large" className="table-loader" spinning={loading}>
                        <section style={{ minHeight: "100vh" }} className="bg-white rounded-lg p-5 m-8">
                            <div className="text-[20px] text-[#2D94F3] font-semibold">Question Sets</div>
                            <br />
                            <>
                                <div className="flex space-x-12 my-4">
                                    <div className="text-[#707070] flex text-[16px] font-semibold">Applicant Name: <Tooltip title={getInterviewQuestionSet?.applicant_details?.applicant_name}><span className="font-light max-w-[15rem] line-clamp-1 break-words ml-2 capitalize">{getInterviewQuestionSet?.applicant_details?.applicant_name}</span></Tooltip></div>
                                    <div className="text-[#707070] flex text-[16px] font-semibold">Job Position: <Tooltip title={getInterviewQuestionSet?.applicant_details?.job_position}><span className="font-light max-w-[15rem] line-clamp-1 break-words ml-2 capitalize">{getInterviewQuestionSet?.applicant_details?.job_position}</span></Tooltip></div>
                                </div>
                                <div className="my-4">
                                    <div className="text-[#707070] text-[16px] font-semibold">Date & Time: 
                                    <span className="font-light">
                                        {moment(getInterviewQuestionSet?.applicant_details?.scheduled_at).format("MMMM Do, YYYY, h:mm A")}
                                    </span>
                                    </div>
                                </div>
                            </>
                            <br />
                            {/* {getInterviewQuestionSet &&
                                getInterviewQuestionSet?.data?.map((itm: any, index: number) => (
                                    <div className="my-8" key={index}>
                                        <div className="flex items-center my-4">
                                            {itm?.categories_list?.slice(0, 3)?.filter((i: any) => i.status === 1).map((itm: any, index: number) => (
                                                <div onClick={(e) => e.stopPropagation()} key={index} style={{ fontSize: "16px", margin: "0px 10px 0px 0px", backgroundColor: "#2D94F31A", width: "fit-content", height: "28px", padding: "0px 10px", color: "#2D94F3", borderRadius: "4px", display: "flex", alignItems: "center" }}>
                                                    {itm?.name}
                                                </div>
                                            ))}
                                            {itm?.categories_list?.length > 3 && (
                                                <Tooltip title={itm?.categories_list?.slice(3)?.map((i: any) => <span>{i?.name}<br /></span>)}>
                                                    <div onClick={(e) => e.stopPropagation()} style={{ margin: "0px 10px", backgroundColor: "#2D94F31A", width: "fit-content", height: "28px", padding: "0px 10px", color: "#1890FF", cursor: "pointer", borderRadius: "4px" }}>
                                                        ...
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {itm?.question_level === 1 && <span style={{ margin: "0px 10px 0px 0px", backgroundColor: "#F3F9FE", width: "fit-content", height: "fit-content", padding: "0px 10px", color: "#4E9F09", border: "1px solid #4E9F09", borderRadius: "4px", fontSize: "16px" }}>Easy</span>}
                                            {itm?.question_level === 2 && <span style={{ margin: "0px 10px 0px 0px", backgroundColor: "#F3F9FE", width: "fit-content", height: "fit-content", padding: "0px 10px", color: "#D19E37", border: "1px solid #4E9F09", borderRadius: "4px", fontSize: "16px" }}>Medium</span>}
                                            {itm?.question_level === 3 && <span style={{ margin: "0px 10px 0px 0px", backgroundColor: "#F3F9FE", width: "fit-content", height: "fit-content", padding: "0px 10px", color: "#C87172", border: "1px solid #C87172", borderRadius: "4px", fontSize: "16px" }}>Hard</span>}
                                            {itm?.question_type === 1 ? <div className="text-[16px] text-[#707070] font-medium">Subjective Type</div> : <div className="text-[16px] text-[#707070] font-medium">Objective Type</div>}

                                        </div>
                                        <div className="my-4">
                                            <div className="text-[16px] text-[#0E254B] opacity-50 my-1">Question</div>
                                            <div className="feedback">
                                                <ReactQuill
                                                    className="editor"
                                                    value={itm?.question}
                                                    readOnly
                                                    style={{ color: "#707070", fontSize: "20px", fontWeight: "500" }}
                                                    modules={modulesQuestion}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-[16px] text-[#0E254B] opacity-50 my-1">Answer</div>
                                            <div className="feedback">
                                                <ReactQuill
                                                    className="editor"
                                                    value={itm?.answer}
                                                    readOnly
                                                    style={{ color: "#707070", fontSize: "20px", fontWeight: "500" }}
                                                    modules={modulesQuestion}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                ))} */}
                            {getInterviewQuestionSet?.questions_list?.map((itm: any, index: any) => (
                                <>
                                    <main>
                                    <div className="flex items-center my-4">
                                    {itm?.level === 1 && <span style={{ margin: "0px 10px 0px 0px", backgroundColor: "#F3F9FE", width: "fit-content", height: "fit-content", padding: "0px 10px", color: "#4E9F09", border: "1px solid #4E9F09", borderRadius: "4px", fontSize: "16px" }}>Easy</span>}
                                            {itm?.level === 2 && <span style={{ margin: "0px 10px 0px 0px", backgroundColor: "#F3F9FE", width: "fit-content", height: "fit-content", padding: "0px 10px", color: "#D19E37", border: "1px solid #D19E37", borderRadius: "4px", fontSize: "16px" }}>Medium</span>}
                                            {itm?.level === 3 && <span style={{ margin: "0px 10px 0px 0px", backgroundColor: "#F3F9FE", width: "fit-content", height: "fit-content", padding: "0px 10px", color: "#C87172", border: "1px solid #C87172", borderRadius: "4px", fontSize: "16px" }}>Hard</span>}
                                            {itm?.categories_list?.filter((i: any) => i.status === 1)?.slice(0, 3).map((itm: any, index: number) => (
                                                <div onClick={(e) => e.stopPropagation()} key={index} style={{ fontSize: "16px", margin: "0px 10px 0px 0px", backgroundColor: "#2D94F31A", width: "fit-content", height: "28px", padding: "0px 10px", color: "#2D94F3", borderRadius: "4px", display: "flex", alignItems: "center" }}>
                                                    {itm?.name}
                                                </div>
                                            ))}
                                            {itm?.categories_list?.length > 3 && (
                                                <Tooltip title={itm?.categories_list?.filter((i: any) => i.status === 1).slice(3)?.map((i: any) => <span>{i?.name}<br /></span>)}>
                                                    <div onClick={(e) => e.stopPropagation()} style={{ margin: "0px 10px", backgroundColor: "#2D94F31A", width: "fit-content", height: "28px", padding: "0px 10px", color: "#1890FF", cursor: "pointer", borderRadius: "4px" }}>
                                                        ...
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {itm?.question_type === 1 ? <div className="text-[16px] text-[#707070] font-medium">Subjective Type</div> : <div className="text-[16px] text-[#707070] font-medium">Objective Type</div>}

                                        </div>
                                        <div className="my-4">
                                            <div className="text-[16px] text-[#0E254B] opacity-50 my-1">Question</div>
                                            <div className="feedback">
                                                <ReactQuill
                                                    className="editor"
                                                    value={itm?.content}
                                                    readOnly
                                                    style={{ color: "#707070", fontSize: "20px", fontWeight: "500" }}
                                                    modules={modulesQuestion}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-[16px] text-[#0E254B] opacity-50 my-1">Answer</div>
                                            <div className="feedback">
                                                <ReactQuill
                                                    className="editor"
                                                    value={itm?.answer}
                                                    readOnly
                                                    style={{ color: "#707070", fontSize: "20px", fontWeight: "500" }}
                                                    modules={modulesQuestion}
                                                />
                                            </div>
                                        </div>
                                    </main>
                                </>
                            ))}
                        </section>
                        </Spin>
                    </main>
                </div>
            </>
        </>
    )
}

export default connect(mapStateToProps)(ViewQuestionSet)