import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Modal, Spin } from 'antd';
import { connect, useDispatch } from 'react-redux';
import Header from '../../components/Header';
import PDF from '../../components/Individual/Pdf';
import { useParams } from 'react-router-dom';
import applicationActions from '../../redux/applications/actions';
import interviewerActions from '../../redux/interviewers/actions';
import taskActions from '../../redux/tasks/actions';
import ScheduleInterview from '../../components/Modal/ScheduleInterview';
import TaskAllotment from '../../components/Modal/TaskAllotment';
import UserDetail from '../../components/Individual/UserDetail';
import StatusComp from '../../components/Individual/Status';
import CommentsComp from '../../components/Individual/Comments';
import TaskComp from '../../components/Individual/Task';
import NextPrev from '../../components/Individual/NextPrev';
import InterviewComp from '../../components/Individual/Interview';
import dayjs from 'dayjs';

const mapStateToProps = (state: any) => ({
  singleApplication: state.applications.singleApplication,
  open: state.applications.modalOpen,
  interviewers: state.interviewers.allInterviewers,
  filters: state.filters.filters,
  userId: state.auth.userId,
  isBack: state.filters.isBack,
  currentId: state.filters.currentId,
  allTasks: state.tasks.tasks,
  applications: state.applications.applications,
  paginatedTask: state.tasks.paginatedTask,
  loading: state.applications.applicationLoading,
  formErrors: state.applications.formErrors
});

interface IindividualCandidate {
  singleApplication: any;
  filters: any;
  interviewers: any;
  allTasks: any;
  paginatedTask: any;
  applications: any;
  currentId: any;
  formErrors: any;
  loading: boolean;
  interviewLoading?: boolean;
}

type Params = {
  id: string;
};

const IndividualCandidate: React.FC<IindividualCandidate> = ({
  singleApplication,
  interviewers,
  allTasks,
  paginatedTask,
  loading,
  formErrors
}) => {
  const [singleInterview, setSingleInterview] = useState(null);
  const [commentId, setCommentId] = useState('');
  const [open, setOpen] = useState(false);
  const [interviewId, setInterviewId] = useState('');
  let { id } = useParams<Params>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: taskActions.WITHOUT_PAGINATED_TASK_LIST
    });
  }, [dispatch]);
  useEffect(() => {
    dispatch({
      type: applicationActions.GET_SINGLE_APPLICATION,
      payload: { ref_id: id }
    });
  }, [dispatch, id]);

  useEffect(() => {
    dispatch({
      type: applicationActions.GET_INTERVIEW_SCHEDULE,
      payload: { ref_id: id }
    });
    dispatch({
      type: applicationActions.GET_ALL_TASKS_LIST,
      payload: { ref_id: id }
    });
    dispatch({
      type: interviewerActions.GET_ALL_INTERVIEWERS_FOR_INTERVIEW
    });
    return () => {
      dispatch({
        type: applicationActions.RESET_STATE
      });
    };
  }, [dispatch, id]);

  return (
    <>
      <div className='lg:w-5/5  w-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Candidate - {id}</title>
        </Helmet>
        <main className='w-full   bg-ghost-white'>
          <>
            <Header />

            {loading && (
              <div
                style={{
                  height: '100vh',
                  zIndex: 1000,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Spin
                  size='large'
                  className='hrTable-loader'
                  spinning={loading}
                />
              </div>
            )}
            <div className='mx-12 space-y-5 pb-5'>
              <NextPrev singleApplication={singleApplication} />
              <UserDetail
                singleApplication={singleApplication}
                setOpen={setOpen}
              />
              <section className='flex justify-between space-x-5'>
                <StatusComp />
                <CommentsComp
                  id={id}
                  commentId={commentId}
                  setCommentId={setCommentId}
                />
              </section>
              <section className='flex justify-between space-x-5 '>
                <InterviewComp
                  id={id}
                  setInterviewId={setInterviewId}
                  setSingleInterview={setSingleInterview}
                  interviewId={interviewId}
                />
                <TaskComp />
              </section>
            </div>
            <ScheduleInterview
              interviewers={interviewers.data}
              singleApplication={singleApplication}
              interviewId={interviewId}
              singleInterview={singleInterview}
              formErrors={formErrors}
            />
            <TaskAllotment
              // @ts-ignore
              paginatedTask={paginatedTask}
              tasks={allTasks}
              id={singleApplication.id}
            />
            {open && (
              <Modal
                className='resume-modal'
                centered
                visible={open}
                onCancel={() => setOpen(false)}
              >
                <div style={{ height: '100%' }}>
                  <PDF
                    file={singleApplication?.resume}
                    title={singleApplication?.full_name}
                    mimeType={singleApplication?.resume_mime_type}
                  />
                </div>
              </Modal>
            )}
          </>
        </main>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(IndividualCandidate);
