import SocialLinksFooter from '../../components/SocialLinksFooter';
const Error = ({ error }: { error: string }) => {
  return (
    <>
      <div className='flex justify-center'>
        <div className='bg-white mx-4 rounded-[20px] max-w-[1500px]'>
          <div className='flex flex-col justify-center'>
            <div className='flex justify-center mt-4'>
              <img
                className='md:h-[550px] lg:h-[400px]'
                src='/ErrorIntRes.svg'
                alt=''
              />
            </div>
            <h1 className='text-[2rem] text-center lg:text-[1.5rem] font-bold my-5'>
              OOPS!
            </h1>
          </div>
          <div className='text-[1.6rem] md:text-[1.5rem] lg:text-[1rem] md:mx-6 lg:mx-[150px]'>
            <p className='flex justify-start '>
              {error?.length
                ? error
                : 'An error has occurred and we are working to fix this problem! We will be up and running shortly'}
            </p>
            <br />
            <p>
              If you have any questions, please feel free to contact us at
              <a
                href={`mailto:${process.env.REACT_APP_CAREERS_SB}`}
                target='_blank'
                rel='noreferrer'
                className='text-light-blue font-medium'
              >
                {' '}
                careers@squareboat.com.
              </a>
            </p>
            <br />
            <div className=''>
              <h2 className=''>Thank You</h2>
              <p className='text-light-blue font-medium'>Squareboat Team</p>
            </div>
          </div>
          <SocialLinksFooter />
        </div>
      </div>
      <div className='flex justify-center mt-5 md:text-[1rem] lg:text-[0.75rem]'>
        Copyright © {new Date().getFullYear()} . All rights reserved | Powered
        By{' '}
        <div className='text-light-blue ml-2'>
          <a href='https://squareboat.com/careers' target='_blank'>
            Squareboat
          </a>
        </div>
      </div>
    </>
  );
};

export default Error;
