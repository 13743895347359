import { all, takeEvery, put } from "redux-saga/effects";
import { doApiCall } from "../../helper";
import actions from "./actions";
import { notification } from "antd";
import ReferralsServices from "../../services/referrals";
import { Store } from "../store";

const service = new ReferralsServices();


export function* WATCH_GET_ALL_HR_REFERRALS(action) {
  const response = yield doApiCall(service.GetAllReferrals, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_REFERRALS_STATE,
      payload: { allReferrals: response },
    });
  }
}
export function* WATCH_GET_EMPLOYEE_REFERRALS(action) {
  const response = yield doApiCall(
    service.GetEmployeeReferrals,
    action.payload
  );
  if (response?.success) {
    yield put({
      type: actions.SET_REFERRALS_STATE,
      payload: { employeeReferrals: response },
    });
  }
}
export function* WATCH_ADD_EMPLOYEE_REFERRAL(action) {
  const response = yield doApiCall(service.AddReferral, action.payload);
  if (response?.success) {
    notification.success({
      message: "Referral Added Successfully",
    });
    yield put({
      type: actions.GET_EMPLOYEE_REFERRALS,
    });
  }
}
export function* WATCH_ADD_HR_REFERRAL(action) {
  const response = yield doApiCall(service.AddReferral, action.payload);
  if (response?.success) {
    notification.success({
      message: "Referral Added Successfully",
    });
    yield put({
      type: actions.GET_ALL_HR_REFERRALS,
    });
  }
}

export function* WATCH_FILTER_REFERRALS(action) {
  const response = yield doApiCall(service.FilterReferrals, action.payload);
  if (response?.success) {
    notification.success({
      message: "Filter Referral",
    });
    yield put({
      type: actions.FILTER_REFERRALS_SUCCESS,
      payload: { filterReferrals: response },
    });
  }
}

export function* WATCH_EDIT_EMPLOYEE_REFERRAL(action) {
  const response = yield doApiCall(
    service.EditEmployeeReferrals,
    action.payload
  );
  if (response?.success) {
    const { page, flag } = action.payload;
    notification.success({
      message: "Referral Updated Successfully",
    });
    if (flag === "admin_edit") {
      yield put({
        type: actions.GET_ALL_HR_REFERRALS,
        payload: { page: page ?? 1 },
      });
    } else {
      yield put({
        type: actions.GET_EMPLOYEE_REFERRALS,
        payload: { page: page ?? 1 },
      });
    }
  }
}
// ====Added for Admin referral=======
export function* WATCH_EDIT_ADMIN_REFERRAL(action) {
  const response = yield doApiCall(
    service.EditAdminReferrals,
    action.payload
  );
  if (response?.success) {
    const { page, flag } = action.payload;
    notification.success({
      message: "Referral Updated Successfully",
    });
    if (flag === "admin_edit") {
      yield put({
        type: actions.GET_ALL_HR_REFERRALS,
        payload: { page: page ?? 1 },
      });
    } else {
      yield put({
        type: actions.GET_EMPLOYEE_REFERRALS,
        payload: { page: page ?? 1 },
      });
    }
  }
}
// ==============================================
export function* WATCH_DELETE_EMPLOYEE_REFERRAL(action) {
  const response = yield doApiCall(
    service.DeleteEmployeeReferrals,
    action.payload
  );
  if (response?.success) {
    const { page, flag } = action.payload;
    notification.error({
      message: "Referral deleted successfully",
    });
    if (flag === "admin_delete") {
      yield put({
        type: actions.GET_ALL_HR_REFERRALS,
        payload: { page: page ?? 1 },
      });
    } else {
      yield put({
        type: actions.GET_EMPLOYEE_REFERRALS,
        payload: { page: page ?? 1 },
      });
    }
    Store.dispatch({
      type: actions.GET_EMPLOYEE_REFERRALS,
      payload: {
        page: 1,
      },
    })
  }
}
// ==============soft delete for HRs===============
export function* WATCH_SOFT_DELETE_EMPLOYEE_REFERRAL(action) {
  const response = yield doApiCall(
    service.SoftDeleteEmployeeReferrals,
    action.payload
  );
  if (response?.success) {
    Store.dispatch({
      type: actions.GET_ALL_HR_REFERRALS,
      payload: {
        page: 1,
      },
    })
    // const { page, flag } = action.payload;
    // notification.error({
    //   message: "Referral deleted successfully",
    // });
    // if (flag === "admin_delete") {
    //   yield put({
    //     type: actions.GET_ALL_HR_REFERRALS,
    //     payload: { page: page ?? 1 },
    //   });
    // } else {
    //   yield put({
    //     type: actions.GET_EMPLOYEE_REFERRALS,
    //     payload: { page: page ?? 1 },
    //   });
    // }
  }
}
// ================================================




export function* WATCH_GET_SINGLE_REFERRAL(action) {
  const response = yield doApiCall(service.GetSingleReferral, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_REFERRALS_STATE,
      payload: { singleReferral: response },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_HR_REFERRALS, WATCH_GET_ALL_HR_REFERRALS),
  ]);
  yield all([
    takeEvery(actions.GET_EMPLOYEE_REFERRALS, WATCH_GET_EMPLOYEE_REFERRALS),
  ]);
  yield all([takeEvery(actions.ADD_HR_REFERRAL, WATCH_ADD_HR_REFERRAL)]);
  yield all([
    takeEvery(actions.ADD_EMPLOYEE_REFERRAL, WATCH_ADD_EMPLOYEE_REFERRAL),
  ]);
  yield all([
    takeEvery(actions.EDIT_EMPLOYEE_REFERRAL, WATCH_EDIT_EMPLOYEE_REFERRAL),
  ]);
  yield all([
    takeEvery(actions.EDIT_ADMIN_REFERRAL, WATCH_EDIT_ADMIN_REFERRAL),
  ]);
  yield all([
    takeEvery(actions.DELETE_EMPLOYEE_REFERRAL, WATCH_DELETE_EMPLOYEE_REFERRAL),
  ]);
  yield all([
    takeEvery(actions.SOFT_DELETE_EMPLOYEE_REFERRAL, WATCH_SOFT_DELETE_EMPLOYEE_REFERRAL),
  ]);
  yield all([
    takeEvery(actions.GET_SINGLE_REFERRAL, WATCH_GET_SINGLE_REFERRAL),
  ]);
  yield all([
    takeEvery(actions.FILTER_REFERRALS, WATCH_FILTER_REFERRALS),
  ]);
}
