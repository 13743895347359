import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class InterviewerServices {
  constructor() {
    this.client = apiClient;
  }

  GetAllInterviewers = (payload = {}) => {
    const { page } = payload;
    return this.client
      .get(APIs.GET_ALL_INTERVIEWERS.replace("num", page))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllInterviewersWithoutPage = () => {
    return this.client
      .get(APIs.GET_ALL_INTERVIEWERS_FOR_INTERVIEW)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostAInterviewer = (payload = {}) => {
    return this.client
      .post(APIs.POST_INTERVIEWERS, payload)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  UpdateInterviewerProfile = (payload = {}) => {
    const { id, body } = payload;
    return this.client
      .patch(APIs.UPDATE_INTERVIEWERS_PROFILE.replace(":id", id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  DeleteInterviewer = (payload) => {
    // const { id } = payload;
    return this.client
      .delete(APIs.DELETE_INTERVIEWERS.replace(":id", payload))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default InterviewerServices;
