import actions from "./actions";

const initialState = {
  users: [],
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_STATE: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
}
