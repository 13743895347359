import Accordion from '../../components/Accordion';
import Header from '../../components/Header';
import CandidateTable from '../../components/Table/CandidateTable';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AddApplicantModal from '../../components/Modal/AddApplicant';
import { history } from '../../utilities/configureAxios';
import { useEffect } from 'react';
import applicationActions from '../../redux/applications/actions';
import { useDispatch } from 'react-redux';

const AllCandidates = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: applicationActions.GET_ALL_TAGS
    });
  });
  return (
    <>
      <div className='page-container flex'>
        <HelmetProvider>
          <Helmet prioritizeSeoTags={true}>
            <meta charSet='utf-8' />
            <title>Application Tracking System</title>
            <meta name='title' content='Application Tracking System' />
            <meta name='description' content='Application Tracking System' />
            <meta property='og:type' content='website' />
            <meta
              property='og:url'
              content='https://ats-portal.squareboat.info/'
            />
            <meta property='og:title' content='Application Tracking System' />
            <meta
              property='twitter:url'
              content='https://ats-portal.squareboat.info/'
            />
            <meta
              property='twitter:image'
              content='https://ats-portal.squareboat.info/sqaureboat-favicon.ico'
            />
            <meta
              property='og:image'
              content='https://ats-portal.squareboat.info/sqaureboat-favicon.ico'
            />
          </Helmet>
        </HelmetProvider>
        <div className='w-full h-screen overflow-y-auto bg-ghost-white'>
          <Header isAllCandidate={true} />
          <div className='flex justify-between'>
            <Accordion
              addApplicantComponent={
                <AddApplicantModal heading='Add Applicant' />
              }
              location={history.location}
            />
            {/* this has been passed to <Accordion /> as a prop to align better  */}
            {/* <div className="mr-[32px]">
              <AddApplicantModal heading="Add Application" />
            </div> */}
          </div>
          <CandidateTable location={history.location} />
        </div>
      </div>
    </>
  );
};

export default AllCandidates;
