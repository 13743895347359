const actions = {
  SET_STATE: `SET_STATE`,
  TOGGLE_LOADING: `TOGGLE_LOADING`,
  GET_ALL_APPLICATIONS: `GET_ALL_APPLICATIONS`,
  GET_ALL_HRS_SUCCESS: `GET_ALL_HRS_SUCCESS`,
  GET_ALL_HRS: `GET_ALL_HRS`,
  GET_ALL_APPLICATIONS_WITHOUT_PAGE: `GET_ALL_APPLICATIONS_WITHOUT_PAGE`,
  GET_SINGLE_APPLICATION: `GET_SINGLE_APPLICATION`,
  UPDATE_STATUS_OF_APPLICATION: `UPDATE_STATUS_OF_APPLICATION`,
  CREATE_APPLICATION: `CREATE_APPLICATION`,
  CREATE_APPLICATION_NOTE: `CREATE_APPLICATION_NOTE`,
  GET_APPLICATION_NOTE: `GET_APPLICATION_NOTE`,
  GET_NEXT_APPLICATION: `GET_NEXT_APPLICATION`,
  GET_PREV_APPLICATION: `GET_PREV_APPLICATION`,
  ADD_COMMENT: 'ADD_COMMENT',
  GET_INTERVIEW_SCHEDULE: 'GET_INTERVIEW_SCHEDULE',
  SCHEDULE_INTERVIEW: 'SCHEDULE_INTERVIEW',
  ADD_SCHEDULE_INTERVIEW: 'ADD_SCHEDULE_INTERVIEW',
  UPDATE_SCHEDULED_INTERVIEW: 'UPDATE_SCHEDULED_INTERVIEW',
  UPDATE_COMMENT: 'UPDATE_COMMENT',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  TOGGLE_INTERVIEW_MODAL: 'TOGGLE_INTERVIEW_MODAL',
  TOGGLE_INTERVIEW_HISTORY_MODAL: 'TOGGLE_INTERVIEW_HISTORY_MODAL',
  GET_DUPLICATE_APPLICATION_MODAL: 'GET_DUPLICATE_APPLICATION_MODAL',
  GET_INTERVIEW_HISTORY: 'GET_INTERVIEW_HISTORY',
  GET_INTERVIEW_HISTORY_SUCCESS: 'GET_INTERVIEW_HISTORY_SUCCESS',
  TOGGLE_COMMENT_MODAL: 'TOGGLE_COMMENT_MODAL',
  TOGGLE_VIEW_COMMENT_MODAL:'TOGGLE_VIEW_COMMENT_MODAL',
  DELETE_INTERVIEW: 'DELETE_INTERVIEW',
  DELETE_COMMENT: 'DELETE_COMMENT',
  ADD_APPLICANT: 'ADD_APPLICANT',
  TOGGLE_TASK_MODAL: 'TOGGLE_TASK_MODAL',
  GET_ALL_TASKS_LIST: 'GET_ALL_TASKS_LIST',
  ALLOT_TASK: 'ALLOT_TASK',
  UPDATE_ALLOTED_TASK: 'UPDATE_ALLOTED_TASK',
  TOGGLE_UPDATE_STATUS_MODAL: 'TOGGLE_UPDATE_STATUS_MODAL',
  UPDATE_CURRENT_STATUS: 'UPDATE_CURRENT_STATUS',
  POST_INTERVIEW_CONCENT: 'POST_INTERVIEW_CONCENT',
  EDIT_CANDIDATE_FIELDS: 'EDIT_CANDIDATE_FIELDS',
  POST_INTERVIEW_RESPONSE: 'POST_INTERVIEW_RESPONSE',
  POST_INTERVIEW_QUESTION_SET: 'POST_INTERVIEW_QUESTION_SET',
  GENERATE_A_QUESTION_SET: 'GENERATE_A_QUESTION_SET',
  GENERATE_A_QUESTION_SET_SUCCESS: 'GENERATE_A_QUESTION_SET_SUCCESS',
  GENERATE_A_QUESTION_SET_GENERIC: 'GENERATE_A_QUESTION_SET_GENERIC',
  RESET_STATE: 'RESET_STATE',
  RESET_INTERVIEW_HISTORY_STATE: 'RESET_INTERVIEW_HISTORY_STATE',
  SET_INTERVIEW_LOADING: 'SET_INTERVIEW_LOADING',
  GET_ALL_TAGS: 'GET_ALL_TAGS',
  SET_ALL_TAGS: 'SET_ALL_TAGS'
};

export default actions;
