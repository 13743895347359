export enum ApiRoutes {
  POST_LOCATION = '/jobs/locations',
  GET_ALL_LOCATIONS = '/jobs/locations',
  GET_SINGLE_LOCATION = '/jobs/locations/:id',
  EDIT_LOCATION = '/jobs/locations/:id',
  POST_DEPARTMENT = '/jobs/departments',
  GET_ALL_DEPARTMENTS = '/jobs/departments',
  GET_SINGLE_DEPARTMENT = '/jobs/departments/:id',
  EDIT_DEPARTMENT = '/jobs/departments/:id',
  POST_JOB = '/jobs',
  GET_ALL_JOBS = '/jobs',
  GET_SINGLE_JOB = '/jobs/:id',
  EDIT_JOB = '/jobs/:id',
  GET_ALL_ORGANIZATIONS = '/users/organizations',
  GET_HIRING_TRACKER = '/applications/hiring-tracker',
  EXPORT_CSV_HIRING_TRACKER = '/applications/hiring-tracker/export-csv',
  GET_SKILLS = '/jobs/settings/skillset'
}
