import { all, takeEvery, put } from 'redux-saga/effects';
import { doApiCall } from '../../helper';
import actions from './actions';
import JobServices from '../../services/jobs';
import { notification } from 'antd';
import { DASHBOARD_JOBS } from '../../constants/RouteConstants';
import { history } from '../../utilities/configureAxios';
import { queryStringToJSON } from '../../utilities/routies';
import QueryString from 'qs';
import { getCurrentQueryParams } from '../../helper';

const services = new JobServices();

// jobs for filter

export function* WATCH_GET_ALL_JOBS_FOR_FILTER(action) {
  const response = yield doApiCall(
    services.GetAllJobsForFilter,
    action.payload
  );
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { jobsForFilter: response }
    });
  }
}
export function* WATCH_GET_ALL_SKILLS() {
  const response = yield doApiCall(services.GetAllSkills, {});
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { skills: response }
    });
  }
}
export function* WATCH_GET_ALL_JOBS_FOR_ADD_APPLICANT(action) {
  const response = yield doApiCall(
    services.GetAllJobsForAddApplicant,
    action.payload
  );
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { jobsForAddApplicant: response }
    });
  }
}

export function* WATCH_GET_ALL_JOBS(action) {
  const response = yield doApiCall(services.GetAllJobs, action.payload);
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { jobs: response } });
  }
}

export function* WATCH_SYNC_ALL_JOBS(action) {
  const response = yield doApiCall(services.SyncJobs);

  if (response?.success) {
    yield put({ type: actions.GET_ALL_JOBS, payload: action.payload });
  }
}

export function* WATCH_POST_JOB(action) {
  const response = yield doApiCall(services.PostAJob, action.payload);

  if (response?.success) {
    yield put({ type: actions.GET_ALL_JOBS, payload: { page: 1 } });
    notification.success({ message: 'Job Added Successfully!' });
  }
}

export function* WATCH_UPDATE_JOB(action) {
  const response = yield doApiCall(services.UpdateStatusOfJob, action.payload);

  if (response?.success) {
    yield put({ type: actions.GET_ALL_JOBS, payload: { page: 1 } });
    notification.success({ message: 'Job Updated Successfully!' });
  }
}

export function* WATCH_POST_NEW_JOB(action) {
  const response = yield doApiCall(
    services.PostNewJob,
    action.payload,
    actions.SET_STATE
  );
  if (response?.success) {
    //change page
    history.push(DASHBOARD_JOBS);
    notification.success({ message: 'Job Added Successfully!' });
    // yield put({type: action.SET_STATE, payload: { formErrors: response }})
  } else {
  }
}

export function* WATCH_PATCH_JOB(action) {
  const response = yield doApiCall(services.PatchJob, action.payload);
  if (response?.success) {
    const queryString = window.location.search;
    const searchParams = queryStringToJSON(queryString);
    history.push(
      `${DASHBOARD_JOBS}?${QueryString.stringify(getCurrentQueryParams())}`
    );
    if (window.location.pathname === DASHBOARD_JOBS) {
      yield put({
        type: actions.GET_ALL_JOBS,
        payload: { ...searchParams, page: searchParams?.page || 1 }
      });
    }
    notification.success({ message: 'Job Updated Successfully!' });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_JOBS_FOR_FILTER, WATCH_GET_ALL_JOBS_FOR_FILTER)
  ]);
  yield all([
    takeEvery(
      actions.GET_ALL_JOBS_FOR_ADD_APPLICANT,
      WATCH_GET_ALL_JOBS_FOR_ADD_APPLICANT
    )
  ]);
  yield all([takeEvery(actions.GET_ALL_JOBS, WATCH_GET_ALL_JOBS)]);
  yield all([takeEvery(actions.SYNC_ALL_JOBS, WATCH_SYNC_ALL_JOBS)]);
  yield all([takeEvery(actions.POST_JOB, WATCH_POST_JOB)]);
  yield all([takeEvery(actions.UPDATE_JOB, WATCH_UPDATE_JOB)]);
  yield all([takeEvery(actions.POST_NEW_JOB, WATCH_POST_NEW_JOB)]);
  yield all([takeEvery(actions.PATCH_JOB, WATCH_PATCH_JOB)]);
  yield all([takeEvery(actions.GET_ALL_SKILLS, WATCH_GET_ALL_SKILLS)]);
}
