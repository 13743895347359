import { Breadcrumb, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IoMdArrowBack } from 'react-icons/io';
import filterActions from '../../../redux/filters/actions';
import { useHistory } from 'react-router';
import { getCurrentQueryParams } from '../../../helper';
import QueryString from 'qs';
import { DASHBOARD_ALL_CANDIDATES } from '../../../constants/RouteConstants';

interface IndividualBreadcrumbProps {
  singleApplication: any;
  currentPage: any;
  isBack: boolean;
  isFilterApplied: boolean;
  applicationActions: any;
  filters: any;
}

const mapStateToProps = (state: any) => ({
  singleApplication: state.applications.singleApplication,
  isBack: state.filters.isBack,
  isFilterApplied: state.filters.isFilterApplied,
  currentPage: state.applications.currentPage,
  applicationActions: state.applications.applicationActions,
  filters: state.filters.filters,
});

const IndividualBreadcrumb: React.FC<IndividualBreadcrumbProps> = ({
  singleApplication,
}) => {
  const { full_name } = singleApplication;
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = getCurrentQueryParams()
  const handleBack = () => {
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        isBack: true,
        isFilterApplied: true,
      },
    });
    if(queryParams?.from?.length){    
      history.back();
    }else{
      history.push(
        `${DASHBOARD_ALL_CANDIDATES}?${QueryString.stringify(
          getCurrentQueryParams()
        )}`
      );
    }
  };

  return (
    <>
      <div className='mt-2 flex items-center'>
        <div className='mr-1 mb-1 cursor-pointer' onClick={handleBack}>
          <IoMdArrowBack fontSize='20px' />
        </div>
        <Breadcrumb separator='>'>
          <Breadcrumb.Item className='text-lg lg:text-xl text-blue-radic font-medium cursor-pointer'>
            All Candidates
          </Breadcrumb.Item>
          <Breadcrumb.Item className='text-base align-baseline block absolute bottom-0 text-breadcrumb font-medium line-clamp-1 break-words capitalize '>
            <Tooltip
              title={full_name}
              className='w-[15rem] line-clamp-1 break-words capitalize'
            >
              {full_name}
            </Tooltip>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(IndividualBreadcrumb);
