import { Modal, Spin, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiFillInfoCircle, AiOutlineClose } from 'react-icons/ai';
import { connect, useDispatch } from 'react-redux';
import referralActions from '../../../redux/referrals/actions';
import { referralType } from '../../../constants/HelperConstants';
import { FaRegUser } from 'react-icons/fa';
import { formatsDate } from '../../../helper';
import moment from 'moment';
import { GoScreenFull } from 'react-icons/go';
import { FiDownload } from 'react-icons/fi';
import PDF from '../../Individual/Pdf';
import Timeline from '../../../page/Timeline';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  singleReferral: state.referrals.singleReferral
});

interface IViewReferral {
  heading: string;
  loading: boolean;
  id: any;
  singleReferral: any;
  status: any;
  application_slug?: string;
}

const ViewReferral: React.FC<IViewReferral> = ({
  heading,
  id,
  loading,
  singleReferral,
  status,
  application_slug
}) => {
  const [isResumeOpen, setIsResumeOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: referralActions.GET_SINGLE_REFERRAL,
        payload: {
          id
        }
      });
    }
  }, [isOpen, id, dispatch]);

  const showModal = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Tooltip placement='topLeft' title='View Referral Details'>
        <div
          className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
          onClick={showModal}
        >
          <AiFillInfoCircle className='w-5 h-5 text-light-blue' />
        </div>
      </Tooltip>
      {!loading && (
        <Modal
          className='view-task-modal'
          open={isOpen}
          onCancel={handleCancel}
          destroyOnClose={true}
          width='1099px'
          footer={null}
        >
          <div className='bg-white'>
            <div className='flex justify-between '>
              <div className=''>
                <h3 className='text-xl text-center font-semibold text-blue-zodiac'>
                  {heading}
                </h3>
              </div>
              <div
                className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                onClick={handleCancel}
              >
                <AiOutlineClose className='w-5 h-5 text-gray-light' />
              </div>
            </div>
            <div className='flex mt-8 justify-between items-center'>
              <div className='w-full'>
                <div className='flex space-x-3'>
                  <div className=''>
                    <FaRegUser className='bg-light-blue text-white h-[50px] w-[50px] p-4 rounded-lg' />
                  </div>
                  <div className=''>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h1 className='candidate-name leading-9 font-medium text-[30px] text-primary capitalize m-0 max-w-[25rem] break-words line-clamp-1 mr-4'>
                        <Tooltip
                          placement='topLeft'
                          title={singleReferral?.data?.full_name}
                        >
                          {singleReferral?.data?.full_name}
                        </Tooltip>
                      </h1>
                    </div>
                    <p className='font-medium text-[14px] text-blue-zodiac capitalize opacity-50 space-x-3'>
                      Referred on{' '}
                      {moment(singleReferral?.data?.created_at).format(
                        formatsDate
                      )}
                    </p>
                  </div>
                </div>
                <div className='flex flex-wrap w-full gap-1.5 gap-x-8 mt-[-0.5rem]'>
                  <div className='flex flex-col w-1/3'>
                    <span className='text-blue-streak opacity-50'>
                      Job Title
                    </span>
                    <span className='text-blue-zodiac '>
                      <Tooltip
                        placement='topLeft'
                        title={singleReferral?.data?.job_title}
                      >
                        <span className='max-w-[150px] block truncate'>
                          {singleReferral?.data?.job_title}
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <div className='flex flex-col w-1/3'>
                    <span className='text-blue-streak opacity-50'>
                      Referred By
                    </span>
                    <span className='text-blue-zodiac '>
                      <Tooltip
                        placement='topLeft'
                        title={
                          singleReferral?.data?.referred_by ??
                          singleReferral?.data?.user_name
                        }
                      >
                        <span className='max-w-[150px] block truncate'>
                          {singleReferral?.data?.referred_by ??
                            singleReferral?.data?.user_name}
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </div>
                <div className='mt-4 flex flex-wrap w-full gap-1.5 gap-x-8'>
                  <div className='flex flex-col w-1/3'>
                    <span className='text-blue-streak opacity-50'>
                      Referral Type
                    </span>
                    <span className='text-blue-zodiac'>
                      <Tooltip
                        placement='topLeft'
                        title={
                          referralType.find(
                            (d) => Number(d.id) === singleReferral?.data?.type
                          )?.title
                        }
                      >
                        <span className='max-w-[150px] line-clamp-2'>
                          {
                            referralType.find(
                              (d) => Number(d.id) === singleReferral?.data?.type
                            )?.title
                          }
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <div className='flex flex-col w-1/3'>
                    <span className='text-blue-streak opacity-50'>
                      Phone Number
                    </span>
                    <span className='text-blue-zodiac '>
                      {singleReferral?.data?.contact}
                    </span>
                  </div>
                  <div className='flex flex-col w-1/3'>
                    <span className='text-blue-streak opacity-50'>
                      Email ID
                    </span>
                    <span className='text-blue-zodiac '>
                      <Tooltip
                        placement='topLeft'
                        title={singleReferral?.data?.email}
                      >
                        <span className='max-w-[150px] block truncate'>
                          {singleReferral?.data?.email}
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  {status === 2 && singleReferral?.data?.description && (
                    <div className='flex flex-col w-1/3'>
                      <span className='text-blue-streak opacity-50'>
                        Reason of Rejection
                      </span>
                      <span className='text-blue-zodiac '>
                        <Tooltip
                          placement='topLeft'
                          title={singleReferral?.data?.description}
                        >
                          <span className='max-w-[150px] block truncate'>
                            {singleReferral?.data?.description}
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className='relative'>
                <div className='h-[250px] w-[450px] opacity-60 overflow-hidden border-2 rounded'>
                  <img
                    className='w-full h-full'
                    src='/BlurResume.jpg'
                    alt='Static Resume'
                  />
                </div>
                <div className='absolute top-[45%] translate-x-[180px] flex space-x-3 justify-between'>
                  <div className='w-2/3 flex '>
                    <a
                      href={
                        !singleReferral?.data?.resume_mime_type ||
                        singleReferral?.data?.resume_mime_type ===
                          'application/pdf'
                          ? singleReferral?.data?.resume_url
                          : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                              singleReferral?.data?.resume_url
                            )}`
                      }
                      target='_blank'
                      className='w-full flex md:flex-row  flex-col items-center space-x-3 md:justify-start px-4'
                      rel='noreferrer'
                    >
                      <div className='bg-gray-light w-9 h-9 rounded-md flex items-center justify-center'>
                        <FiDownload className='text-[#000] !opacity-100' />
                      </div>
                    </a>
                  </div>
                  <div className='w-2/3 flex '>
                    <div
                      className='flex items-center'
                      onClick={() => setIsResumeOpen(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className='bg-gray-light w-9 h-9 rounded-md flex items-center justify-center mr-4'>
                        <GoScreenFull className='text-[#000]' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {status === 1 && (
            <div style={{ minHeight: '300px' }}>
              <Timeline noPadding={true} customSlug={application_slug} />
            </div>
          )}
        </Modal>
      )}
      {!loading && isResumeOpen && (
        <Modal
          className='resume-modal'
          centered
          open={isResumeOpen}
          onCancel={() => setIsResumeOpen(false)}
        >
          <div style={{ height: '100%' }}>
            <PDF
              file={singleReferral?.data?.resume_url}
              title={singleReferral?.data?.full_name}
              mimeType={singleReferral?.data?.resume_mime_type}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ViewReferral);
