import { Input } from 'antd';
import React, { useState } from 'react';
import { IoIosClose, IoIosCloseCircle } from 'react-icons/io';

interface IMultiEmailProps {
  fieldName: string;
  fieldValues: string[];
  onValueChange: (values: string[] | string) => void;
  onErrorChange: (error: boolean) => void;
}

function isValidEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

const MultiEmailInput: React.FC<IMultiEmailProps> = ({
  fieldName,
  fieldValues,
  onValueChange,
  onErrorChange
}) => {
  const [emailInput, setEmailInput] = useState<string>('');

  const getFieldValues = () => {
    return Array.isArray(fieldValues) ? fieldValues : [fieldValues];
  };

  const addEmailField = () => {
    if (isValidEmail(emailInput)) {
      const updated = [...getFieldValues(), emailInput];
      onValueChange(updated);
      setEmailInput('');
      onErrorChange(false);
    } else {
      onErrorChange(true);
    }
  };

  const removeEmailField = (index: number) => {
    const updated = fieldValues.filter((_, i) => i !== index);
    onValueChange(updated);
  };

  const handleSingleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    onValueChange(e.target.value);
  };

  return (
    <div className='flex edit-input bg-white text-base items-center relative w-full'>
      <div className='flex flex-wrap w-full gap-1'>
        {fieldValues &&
          Array.isArray(fieldValues) &&
          fieldValues?.map((item, indx) => (
            <div className='ml-2 bg-[#f5f5f5] flex items-center' key={indx}>
              <span
                style={{
                  fontSize: '14px',
                  padding: '4px'
                }}
              >
                {item}
              </span>
              <IoIosClose
                opacity='0.5'
                fontSize='20px'
                cursor='pointer'
                onClick={() => removeEmailField(indx)}
              />
            </div>
          ))}
        <Input
          className='cc-input text-base flex-1 !min-w-[120px]'
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
          onPressEnter={addEmailField}
          placeholder='Enter email and press Enter'
        />
      </div>
      {fieldValues && fieldValues?.length > 0 && (
        <IoIosCloseCircle
          className='m-2 min-w-fit absolute right-1'
          cursor='pointer'
          opacity='0.5'
          fontSize='16px'
          onClick={() => onValueChange([])}
        />
      )}
    </div>
  );
};

export default MultiEmailInput;
