import { Switch, Route, Redirect } from "react-router-dom";
import {
  INTERVIEWER_HOME,
  INTERVIEWER_INTERVIEW,
  INTERVIEWER_INTERVIEWS,
  INTERVIEWER_INTERVIEW_EDIT,
  INTERVIEWER_INTERVIEW_VIEW,
  INTERVIEWER_REFERRALS,
  VIEW_QUESTION_SET_FOR_ADMIN,
} from "../constants/RouteConstants";
import ErrorPage from "../page/404Error";
import TooManyRequest from "../page/429Error";
import EmployeeHome from "../page/EmployeeHome";
import EmployeeReferrals from "../page/EmployeeReferrals";
import EmployeeInterviews from "../page/EmployeeInterviews";
import ViewFeedback from "../page/ViewFeedback";
import { history } from "../utilities/configureAxios";
import IndividualInterview from "../page/individual-interview";
import ViewQuestionSet from "../page/ViewQuestionSet";
import EditFeedback from "../page/EditFeedback";

function Interviewer() {
  return (
    <Switch location={history.location}>
      <Route path={INTERVIEWER_REFERRALS} component={EmployeeReferrals} exact />
      <Route path={INTERVIEWER_HOME} component={EmployeeHome} exact />
      <Route
        path={INTERVIEWER_INTERVIEWS}
        component={EmployeeInterviews}
        exact
      />
      <Route
        path={INTERVIEWER_INTERVIEW}
        component={IndividualInterview}
        exact
      />
      <Route path={INTERVIEWER_INTERVIEW_VIEW} component={ViewFeedback} exact />
      <Route path={INTERVIEWER_INTERVIEW_EDIT} component={EditFeedback} exact />
      <Route
        path={VIEW_QUESTION_SET_FOR_ADMIN}
        component={ViewQuestionSet}
        exact
      />
      <Route
        path="/429"
        render={() => (
          <div className="page-container flex">
            <TooManyRequest />
          </div>
        )}
      />
      {/* <Redirect to="/429" /> */}
      <Route
        path="/404"
        render={() => (
          <div className="page-container flex">
            <ErrorPage />
          </div>
        )}
      />
      <Redirect to="/404" />
    </Switch>
  );
}

export default Interviewer;
