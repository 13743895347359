import { Breadcrumb } from "antd";
import { FaTasks } from "react-icons/fa";

const TasksBreadcrumb = () => {
  return (
    <>
      <div className="mt-2">
        <Breadcrumb separator=">">
          <Breadcrumb.Item className="flex text-lg lg:text-xl text-primary font-medium">
            <FaTasks className="w-[24px] h-[24px] mt-[3px] mr-2" />
            Tasks
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
};

export default TasksBreadcrumb;
