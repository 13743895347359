import { Input, Modal, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import CancelButton from '../../../components/Buttons/Cancel';
import PrimaryButton from '../../../components/Buttons/Primary';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Inputfield from '../../../components/InputField';
import { connect, useDispatch } from 'react-redux';
import JobActions from '../../../redux/atsJobs/actions';
import { JOB_REGREX } from '../../../constants/HelperConstants';

interface ILocationModal {
  type: 'add' | 'edit' | undefined;
  onCancel: () => void;
  onSave: (data: any) => void;
  open: boolean;
  loading: boolean;
  location: any;
}

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  location: state.atsJobsReducers.singleLocation
});

const LocationModal: FC<ILocationModal> = ({
  type,
  onCancel,
  onSave,
  open,
  loading,
  location
}) => {
  //   const loginFormSchema = .object().shape({
  //     contactMobileNumber: yup
  //       .string()
  //       .min(10, 'Enter valid mobile number.')
  //       .max(10, 'Enter valid mobile number.')
  //       .matches(/^[6-9]{1}[0-9]{9}$/, 'Enter valid mobile number.')
  //       .required('Mobile number is required.'),
  //   });

  const locationFormSchema = yup.object().shape({
    locationName: yup
      .string()
      .min(3, 'Minimum 3 character is required in location name')
      .max(100, 'Enter valid location name')
      .strict(false)
      .trim()
      .matches(JOB_REGREX, 'Enter valid location name')
  });

  const shouldDisable = (values: any, errors: any) => {
    return !values.locationName.trim() || !(Object.values(errors).length === 0);
  };
  const handleSubmit = (data: any) => {
    let body: any = {};
    body['name'] = data.locationName.trim();
    onSave(body);
    onCancel();
  };

  if (loading) {
    return null;
  }

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      onCancel={onCancel}
      width='500px'
      className='edit-modal relative'
      bodyStyle={{
        backgroundColor: '#FAFDFF',
        borderRadius: '0.25rem'
      }}
      // centered
    >
      <div className='flex justify-between'>
        <h3 className='text-lg my-4 text-center capitalize font-semibold text-[#233A5C]'>
          {type ? (type === 'add' ? 'Add Location' : 'edit Location') : null}
        </h3>
        <div
          className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
          onClick={onCancel}
        >
          <AiOutlineClose className='w-5 h-5 text-gray-light' />
        </div>
      </div>
      <Formik
        initialValues={{ locationName: location?.name ? location?.name : '' }}
        onSubmit={(data) => handleSubmit(data)}
        enableReinitialize
        validationSchema={locationFormSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          isValid,
          dirty
        }) => (
          <Form>
            <Inputfield
              placeholder='Enter a Location Name'
              // label='Location Name'
              // required
              name='locationName'
              type='text'
              autocomplete='off'
              value={values.locationName}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
              }}
              helperText={errors.locationName ? errors.locationName : null}
            />
            <div className='flex justify-end mt-4'>
              <div className='mr-4'>
                <PrimaryButton
                  text='Save'
                  type='submit'
                  disabled={
                    shouldDisable(values, errors) || !(isValid && dirty)
                  }
                  // onClick={onSave}
                />
              </div>
              <div>
                <CancelButton onClick={onCancel} text='Cancel' />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(mapStateToProps)(LocationModal);
