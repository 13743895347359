import { DatePicker } from 'antd';
import { DateRangePickerProps } from '../../Interfaces/common';

export const DateRangePicker = (props: DateRangePickerProps) => {
  const disabledDate = (current: any) => {
    return (
      (current && current.valueOf() > Date.now()) ||
      (props?.minDate ? !current || current.isBefore(props.minDate) : false)
    );
  };

  return (
    <div className='hover:scale-105 transition-all duration-100 ease-in cursor-pointer'>
      <DatePicker.RangePicker
        format='YYYY-MM-DD'
        disabled={props.disabled}
        style={
          props.style
            ? props.style
            : {
                borderWidth: 0,
                borderRadius: '0.5rem',
              }
        }
        disabledDate={disabledDate}
        className={
          props?.className
            ? props?.className
            : 'shadow hover:shadow-md w-full h-full'
        }
        {...props}
      />
    </div>
  );
};
