import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './page/home';
import { Provider } from 'react-redux';
import { Store, Persistor } from '../src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import requireAuth from './components/HOC/AuthHoc';
import AuthenticatedRoutes from './routes';
import { useEffect } from 'react';
import { message } from 'antd';
import Timeline from './page/Timeline';
import { history } from './utilities/configureAxios';
import InterviewResponse from './page/InterviewResponse';
import {
  INTERVIEW_RESPONSE,
  LANDING_PAGE,
  STATUS_TIMELINE
} from './constants/RouteConstants';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

function App() {
  useEffect(() => {
    if (!navigator.onLine) {
      message.error(`No internet connection`);
    }
  }, [navigator]);
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const perf = getPerformance(app);

  return (
    <>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <Provider store={Store}>
          <PersistGate loading={null} persistor={Persistor}>
            <Router history={history}>
              <Switch location={history.location}>
                <Route
                  path={LANDING_PAGE}
                  component={requireAuth(Home)}
                  exact
                />
                {/* <Route path={"/test"} component={Home} exact /> */}
                <Route path={STATUS_TIMELINE} component={Timeline} exact />
                <Route
                  path={INTERVIEW_RESPONSE}
                  component={InterviewResponse}
                  exact
                />
                <Route
                  path={LANDING_PAGE}
                  component={requireAuth(AuthenticatedRoutes)}
                />
                <Redirect to='/404' />
              </Switch>
            </Router>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
