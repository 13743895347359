import { Spin, Tooltip } from 'antd';
import React from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { connect, useDispatch } from 'react-redux';
import emailActions from '../../../redux/emailTemplates/actions';

const mapStateToProps = (state: any) => ({
  formValues: state.emailTemplates.formValues,
  selectedTemplateStore: state.emailTemplates.selectedTemplateStore,
  loading: state.auth.loading
});

interface ISend {
  prev: () => void;
  formValues: any;
  selectedTemplateStore: string;
  loading: boolean;
}

const Send: React.FC<ISend> = ({
  prev,
  formValues,
  selectedTemplateStore,
  loading
}) => {
  const filteredFormValues = Object.keys(formValues)
    .filter((key) => formValues[key] !== undefined)
    .reduce((obj, key) => {
      obj[key] = formValues[key];
      return obj;
    }, {} as { [key: string]: any });
  const dispatch = useDispatch();

  const handleSendEmail = () => {
    dispatch({
      type: emailActions.SEND_EMAIL,
      payload: {
        body: { ...formValues, template_id: selectedTemplateStore }
      }
    });
  };

  return (
    <Spin size='large' className='hrTable-loader' spinning={loading}>
      <div className='flex flex-col'>
        <div>
          <h1 className='text-xl text-primary mb-5'>
            Send Email with Below Data
          </h1>
          {Object.keys(filteredFormValues).map((key) => {
            const value = filteredFormValues[key];
            if (Array.isArray(value)) {
              if (value.length > 0) {
                return (
                  <div key={key} className='mb-2'>
                    <p className='mb-0 font-semibold text-base capitalize'>
                      {key} :
                    </p>
                    <ul className='px-4'>
                      {value.map((item, index) => (
                        <li key={index} className='mb-1'>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              } else {
                return (
                  <div key={key} className='mb-2'>
                    <p className='mb-0 font-semibold text-base capitalize'>
                      {key} :
                    </p>
                    <p className='mb-0'>NA</p>
                  </div>
                );
              }
            }

            return key !== 'attachment_mime_type' ? (
              <div key={key} className='mb-2'>
                <p className='mb-0 capitalize text-base font-semibold'>
                  {key.includes('_')
                    ? key.split('_').map((key) => key + ' ')
                    : key + ' '}
                  :
                </p>
                <p className='mb-0'>{value}</p>
              </div>
            ) : (
              ''
            );
          })}
        </div>
        {Object.keys(formValues).length > 0 && (
          <div className='flex justify-start gap-5 my-5 items-center'>
            <div>
              <button
                onClick={prev}
                className='text-[#1890FF] hover:bg-[#1890FF] hover:text-white flex gap-x-1 items-center transition-all duration-150 ease-in  border border-[#1890FF] px-5 py-2 rounded-md'
              >
                <IoMdArrowRoundBack /> Back
              </button>
            </div>
            <div>
              <button
                className='text-white bg-[#1890FF] border border-[#1890FF] px-5 py-2 rounded-md'
                onClick={handleSendEmail}
              >
                {loading ? 'Sending...' : 'Send Email'}
              </button>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default connect(mapStateToProps)(Send);
