const actions = {
  SET_STATE: `SET_STATE`,
  GET_ALL_JOBS: `GET_ALL_JOBS`,
  GET_ALL_SKILLS: `GET_ALL_SKILLS`,
  GET_ALL_JOBS_FOR_FILTER: `GET_ALL_JOBS_FOR_FILTER`,
  POST_JOB: `POST_JOB`,
  UPDATE_JOB: `UPDATE_JOB`,
  SYNC_ALL_JOBS: 'SYNC_ALL_JOBS',
  GET_ALL_JOBS_FOR_ADD_APPLICANT: 'GET_ALL_JOBS_FOR_ADD_APPLICANT',
  POST_NEW_JOB: `POST_NEW_JOB`,
  GET_ALL_ORGANIZATIONS: `GET_ALL_ORGANIZATIONS`,
  RESET_STATE: `RESET_STATE`,
  SET_ORGANIZATIONS: `SET_ORGANIZATIONS`,
  PATCH_JOB: 'PATCH_JOB',
  // GET_ALL_DEPARTMENTS: `GET_ALL_DEPARTMENTS`,
  // GET_ALL_LOCATIONS: `GET_ALL_LOCATIONS`
};

export default actions;
