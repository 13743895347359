import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import authActions from '../../redux/auth/actions';
import { notification, Spin, theme } from 'antd';
import { Helmet } from 'react-helmet';
import { GoogleLogin } from '@react-oauth/google';
const mapStateToProps = (state: any) => ({
  auth: state.auth,
  loading: state.auth.loading
});
interface homepageProps {
  auth: any;
  loading: boolean;
}
const Home: React.FC<homepageProps> = ({ auth, loading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const responseGoogle = (response: any) => {
    const dataObj = response;
    dispatch({
      type: authActions.LOGIN,
      payload: { idToken: dataObj?.credential, history }
    });
    dispatch({
      type: authActions.SET_STATE,
      payload: { loading: true }
    });
  };
  return (
    <>
      <Spin size='large' className='hrTable-loader' spinning={loading}>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Login</title>
        </Helmet>
        <div className='bg-homepage bg-cover w-full h-full bg-center'>
          <div className='page-container w-screen h-screen z-10 relative overflow-hidden'>
            <div className='absolute top-0 left-0 lg:flex justify-center lg:justify-between w-full h-full'>
              <div className='w-full h-1/2 lg:h-full lg:w-1/2 xl:w-3/5 flex items-center  justify-center lg:px-10 xl:px-0'>
                <img
                  src='/image/homepage/homepage-2.svg'
                  alt=''
                  className='w-[600px] h-[350px] object-contain zoom-1'
                />
              </div>
              <div className='w-full lg:w-1/2 xl:w-2/5 flex justify-center  items-center lg:px-10 xl:px-0'>
                <div className=''>
                  <div className='my-3 -translate-x-[20px]'>
                    <img
                      src='/SquareboatLogo.png'
                      alt=''
                      className='h-[45px] object-contain zoom-1'
                    />
                  </div>
                  <div className='lg:space-y-2'>
                    <div className='text-blue-radic landing-text leading-[10px] lg:leading-normal'>
                      Login to <span className='text-light-blue'>ATS</span>
                    </div>
                    <p className='text-blue-radic opacity-60 landing-subtext text-left'>
                      Login to ATS dashboard.
                    </p>
                  </div>
                  <div className='my-5'>
                    <GoogleLogin
                      onSuccess={responseGoogle}
                      onError={() => {
                        notification.error({
                          message: 'Please contact your organization admin'
                        });
                      }}
                      theme='filled_blue'
                      width='300'
                      size='large'
                      ux_mode='popup'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default connect(mapStateToProps)(Home);
