import { all, takeEvery, put } from 'redux-saga/effects';
import { doApiCall, getSearchParamsUrl } from '../../helper';
import actions from './actions';
import EmailTemplateServices from '../../services/emailTemplates';
import { notification } from 'antd';
import { history } from '../../utilities/configureAxios';
import { queryStringToJSON } from '../../utilities/routies';

const services = new EmailTemplateServices();

export function* WATCH_POST_EMAIL(action) {
  const response = yield doApiCall(services.SendEmail, action.payload);
  if (response?.success) {
    notification.success({
      message: 'Email Sent successfully'
    });
    yield put({
      type: actions.RESET_FORM_VALUES,
      // payload: { params: { page: 1, only: true } }
    });
    yield put({
      type: actions.SET_CURRENT_STEP,
      payload: 0
    });
    history.push(getSearchParamsUrl({ step: 1 }), {});
  }
}

export function* WATCH_GET_ALL_EMAIL_TEMPLATES(action) {
  const response = yield doApiCall(
    services.GetEmailTemplates,
    action.payload
  );
  if (response?.success) {
    const { data } = response;
    yield put({
      type: actions.SET_EMAIL_TEMPLATES,
      payload: { emailTemplates: data }
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALL_EMAIL_TEMPLATES, WATCH_GET_ALL_EMAIL_TEMPLATES)]);
  yield all([takeEvery(actions.SEND_EMAIL, WATCH_POST_EMAIL)]);
}