import { Pagination, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import {
  AiFillInfoCircle,
  AiOutlineEye,
  AiOutlineFileAdd
} from 'react-icons/ai';
import { GrChapterAdd } from 'react-icons/gr';
import { connect, useDispatch } from 'react-redux';
import Header from '../../components/Header';
import categoryActions from '../../redux/questionBank/actions';
import interviewActions from '../../redux/interviewers/actions';
import { history } from '../../utilities/configureAxios';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../helper';
import { useHistory } from 'react-router-dom';
import { USER_ROLE } from '../../constants/HelperConstants';

const mapStateToProps = (state: any) => ({
  allQuestions: state.allQuestions.allQuestions,
  loading: state.auth.loading,
  allInterviewsForInterviewers:
    state.allInterviewsForInterviewers.allInterviewsForInterviewers,
  role: state.users.role
});

const EmployeeInterviews = ({
  allInterviewsForInterviewers,
  loading,
  role
}: any) => {
  const dispatch = useDispatch();
  const { page, q } = getCurrentQueryParams();
  // const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: categoryActions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER,
      payload: {
        page: page,
        q: q ?? ''
      }
    });
  }, [page, q]);

  const handleAddRouteClick = (itm: any) => {
    // if (itm.question_set_status === 3) {
    if (itm?.feedback_status === 1 || itm?.feedback_status === 2) {
      dispatch({
        type: interviewActions.SET_SELECTED_INTERVIEW,
        payload: itm
      });
      if (role !== USER_ROLE.INTERVIEWER) {
        history.push(`/dashboard/interviews/${itm?.uuid}`);
      } else {
        history.push(`/interviewer/interviews/${itm?.uuid}`);
      }
    }
    // }
  };

  const handleViewFeedbackClick = (itm: any) => {
    if (itm?.feedback_status === 3) {
      dispatch({
        type: interviewActions.SET_SELECTED_INTERVIEW,
        payload: itm
      });
      if (role !== USER_ROLE.INTERVIEWER) {
        history.push(`/dashboard/view-feedback/${itm?.uuid}`);
      } else {
        history.push(`/interviewer/view-feedback/${itm?.uuid}`);
      }
    }
  };

  const handleEditFeedbackClick = (itm: any) => {
    if (itm?.feedback_status === 3) {
      dispatch({
        type: interviewActions.SET_SELECTED_INTERVIEW,
        payload: itm
      });
      if (role !== USER_ROLE.INTERVIEWER) {
        history.push(`/dashboard/edit-feedback/${itm?.uuid}`);
      } else {
        history.push(`/interviewer/edit-feedback/${itm?.uuid}`);
      }
    }
  };

  const handlePageChange = (page: number) => {
    history.push(getSearchParamsUrl({ page: page, q: q }), {});
  };

  // useEffect(() => {
  //   dispatch({
  //     type: categoryActions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER,
  //     payload: {
  //       page: page,
  //     },
  //   });
  // }, [page, location]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch({
  //       type: categoryActions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER,
  //       payload: {
  //         page: page,
  //       },
  //     });
  //   }, 1500);
  // }, []);

  return (
    <div className='w-full  h-screen  overflow-y-auto bg-ghost-white relative'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Interviews</title>
      </Helmet>
      <Header />
      <main>
        {!!!allInterviewsForInterviewers?.data?.length ? (
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <section className='mt-20'>
              <div className='flex justify-center'>
                <img
                  className='max-h-[300px]'
                  src='/categories.svg'
                  alt='No Category'
                />
              </div>
              <br />
              <div className='text-center font-semibold flex justify-center text-[#0E254B] text-opacity-40 text-[20px]'>
                No interviews available at the moment
              </div>
              <br />
              {/* <div className="text-center">
                 <PrimaryButton onClick={handleAddClick} text="Add Question" />
             </div> */}
            </section>
          </Spin>
        ) : (
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <div className='flex flex-col'>
              <div className='shadow-lg bg-white mx-8 rounded-2xl overflow-x-auto min-h-[80%] mt-[20px]'>
                <table className='w-full table_transition'>
                  <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                    <tr className=' text-[0.9rem] px-2'>
                      <th className='text-[#233A5C] text-center text-opacity-50 py-4'>
                        S.No
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Applicant Name
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Job Position
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Title
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Interviewers
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Date
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Time
                      </th>
                      <th className='text-[#233A5C] text-left text-opacity-50 py-4'>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allInterviewsForInterviewers &&
                      allInterviewsForInterviewers?.data?.length > 0 &&
                      allInterviewsForInterviewers?.data?.map(
                        (itm: any, index: number) => (
                          <tr
                            key={`${itm.uuid} ${itm.feedback_status}`}
                            className='text-base border-bottom text-blue-zodiac table-text font-normal capitalize mb-0 whitespace-nowrap overflow-auto'
                          >
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-4 pr-2 w-[10%] max-w-[5rem] text-overflow-none text-center opacity-50 pointer-events-none'
                                  : 'py-4 pr-2 w-[10%] max-w-[5rem] text-overflow-none text-center'
                              }
                            >
                              {allInterviewsForInterviewers?.meta?.pagination
                                ?.perPage *
                                (allInterviewsForInterviewers?.meta?.pagination
                                  ?.page -
                                  1) +
                                index +
                                1}
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis'
                              }
                            >
                              {/* {itm?.applicant_name} */}
                              <Tooltip
                                placement='topLeft'
                                title={itm?.applicant_name}
                              >
                                <span className='max-w-[120px] block truncate'>
                                  {itm?.applicant_name}
                                </span>
                              </Tooltip>
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis'
                              }
                            >
                              <Tooltip
                                placement='topLeft'
                                title={itm?.job_title}
                              >
                                <span className='max-w-[120px] block truncate'>
                                  {itm?.job_title}
                                </span>
                              </Tooltip>
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis'
                              }
                            >
                              <Tooltip placement='topLeft' title={itm?.title}>
                                <span className='max-w-[120px] block truncate'>
                                  {itm?.title}
                                </span>
                              </Tooltip>
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis'
                              }
                            >
                              <Tooltip
                                placement='topLeft'
                                title={itm?.interviewers_list
                                  ?.map((interviewer: any) => interviewer.name)
                                  .join(', ')}
                              >
                                <span className='text-blue-zodiac capitalize'>
                                  {itm?.interviewers_list?.length > 1
                                    ? `${
                                        itm?.interviewers_list[0]?.name
                                      }, ...+${
                                        itm?.interviewers_list?.length - 1
                                      }`
                                    : itm?.interviewers_list[0]?.name}
                                </span>
                              </Tooltip>
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis'
                              }
                            >
                              {moment(itm.scheduled_at).format('Do MMM')}
                            </td>
                            <td
                              className={
                                itm?.status === 2
                                  ? 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis opacity-50 pointer-events-none'
                                  : 'py-4 pr-2 w-[15%] max-w-[15rem] text-overflow-none overflow-ellipsis'
                              }
                            >
                              {moment(itm.scheduled_at).format('h:mm A')}
                            </td>
                            <td
                              className={
                                // itm.question_set_status === 5
                                `flex justify-start py-2 pr-2 text-overflow-none overflow-ellipsis ${
                                  itm?.status === 2
                                    ? 'pointer-events-none opacity-50'
                                    : null
                                }`
                                //   : "flex justify-start cursor-not-allowed py-2 pr-2 text-overflow-none overflow-ellipsis"
                              }
                            >
                              <Tooltip title='View Feedback'>
                                <div
                                  onClick={() => handleViewFeedbackClick(itm)}
                                  className={`w-8 h-8 bg-background-blue rounded-md flex items-center justify-center
                                    ${
                                      itm.feedback_status === 3
                                        ? 'text-light-blue cursor-pointer'
                                        : 'cursor-not-allowed text-gray-light'
                                    }`}
                                >
                                  <AiOutlineEye className={`w-5 h-5`} />
                                </div>
                              </Tooltip>
                              &nbsp;&nbsp;
                              <Tooltip title='Add Feedback'>
                                <div
                                  onClick={() => handleAddRouteClick(itm)}
                                  className={`w-8 h-8 bg-background-red rounded-md flex items-center justify-center
                                    ${
                                      itm.feedback_status === 1 ||
                                      itm.feedback_status === 2
                                        ? 'cursor-pointer text-light-blue'
                                        : 'cursor-not-allowed text-gray-light'
                                    }`}
                                >
                                  <AiOutlineFileAdd className={`w-5 h-5`} />
                                </div>
                              </Tooltip>
                              &nbsp;&nbsp;
                              <Tooltip title='Edit Feedback'>
                                <div
                                  className={`w-8 h-8 bg-background-blue rounded-md flex items-center justify-center
                                    ${
                                      itm.feedback_status === 3
                                        ? 'text-light-blue cursor-pointer'
                                        : 'text-gray-light cursor-not-allowed'
                                    }`}
                                  onClick={() => handleEditFeedbackClick(itm)}
                                >
                                  <MdOutlineModeEditOutline
                                    className={`w-5 h-5`}
                                  />
                                </div>
                              </Tooltip>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='flex justify-center py-5'>
              <Pagination
                showSizeChanger={false}
                current={allInterviewsForInterviewers?.meta?.pagination?.page}
                pageSize={10}
                onChange={(page) => handlePageChange(page)}
                total={allInterviewsForInterviewers?.meta?.pagination?.count}
              />
            </div>
          </Spin>
        )}
      </main>
    </div>
  );
};

export default connect(mapStateToProps)(EmployeeInterviews);
