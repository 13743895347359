// comment.tsx
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../redux/applications/actions';
import { AiOutlineEye } from 'react-icons/ai';
import { MdVisibility } from 'react-icons/md';
import { Tooltip } from 'antd';

const mapStateToProps = (state: any) => ({
  applicationNotes: state.applications.applicationNotes
});

interface IComment {
  data: any;
  setEdit: any;
  isEdit: boolean;
  userId: string;
  getCommentId: (value: string) => void;
  onView: (comment: any) => void;
  applicationNotes?: any;
}

const Comment: React.FC<IComment> = ({
  data,
  getCommentId,
  userId,
  setEdit,
  isEdit,
  onView, // Destructure this prop
  applicationNotes
}) => {
  const dispatch = useDispatch();
  return (
    <div
      className={
        isEdit
          ? 'flex justify-between items-center w-full opacity-40 pointer-events-none'
          : 'flex justify-between items-center w-full'
      }
    >
      <div className='flex w-full'>
        <div className='mr-4'>
          <div
            style={{
              width: '40px',
              height: '40px'
            }}
          >
            <img
              src={data?.user?.profilePic || '/user-pic.svg'}
              alt=''
              className='object-cover rounded-full'
              referrerPolicy='no-referrer'
            />
          </div>
        </div>
        <div className='w-full'>
          <div className='w-full flex justify-between'>
            <div className='flex space-x-4'>
              <div className='text-[#0D264C] font-bold text-base flex items-center'>
                {data?.user?.name}
              </div>
              <div className='flex items-center'>
                <span className='text-[#1D1B1B] opacity-30'>
                  {moment(data.created_at).fromNow()}
                </span>
              </div>
            </div>
            {data?.user?.id === userId && (
              <div className='flex'>
                <div
                  className='cursor-pointer min-w-fit'
                  onClick={(e: any) => {
                    dispatch({
                      type: applicationActions.TOGGLE_COMMENT_MODAL,
                      payload: true
                    });
                    getCommentId(data.id);
                  }}
                >
                  <Tooltip placement='top' title='Delete Comment'>
                    <img src='/image/icons/Delete-Icon.svg' alt='' />
                  </Tooltip>
                </div>
                <div
                  className='w-[35px] h-[35px] bg-background-blue rounded-lg flex items-center justify-center cursor-pointer min-w-fit ml-4'
                  onClick={() => onView(data)}
                >
                  <Tooltip placement='top' title='View Comment'>
                    <MdVisibility className='w-5 h-5 text-light-blue' />
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          <div
            className={`text-[#1D1B1B] text-opacity-75 line-clamp-2 whitespace-pre-wrap text-sm mb-3 mt-1 pr-4 ${
              data.note.includes(' ') ? 'break-words' : 'break-all'
            }`}
          >
            {data.note}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Comment);
