import {
  Button,
  Input,
  Modal,
  Pagination,
  Radio,
  Select,
  Spin,
  Tag,
  Tooltip
} from 'antd';
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import CancelButton from '../../components/Buttons/Cancel';
import PrimaryButton from '../../components/Buttons/Primary';
import Header from '../../components/Header';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuestionComponent from '../../components/QuestionComponent';
import { connect, useDispatch } from 'react-redux';
import categoryActions from '../../redux/questionBank/actions';
import './editorscopedstyle.css';
import {
  MdDeleteOutline,
  MdEdit,
  MdOutlineModeEditOutline
} from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import DangerButton from '../../components/Buttons/Danger';
import { history } from '../../utilities/configureAxios';
import { getCurrentQueryParams, getSearchParamsUrl } from '../../helper';
import './editorscopedstyle.css';
import InterviewFilter from '../../components/Interview/Filter';
import { useLocation } from 'react-router-dom';
import ClearFilters from '../../components/Select/ClearButton';

const mapStateToProps = (state: any) => ({
  allCategories: state.allCategories.allCategories,
  allQuestions: state.allQuestions.allQuestions,
  loading: state.auth.loading
});

const Questions = ({ allCategories, allQuestions, loading }: any) => {
  //remove this after adding logic
  const [isVisible, setIsVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const router = useLocation();
  function handleAddClick() {
    setIsVisible(true);
  }
  function onCancel() {
    setIsVisible(false);
    setQuestionDescription('');
    setAnswerDescription('');
    setUuidCategoryCollection([]);
    setValueRadioButtonDifficulty('');
    setValueRadioButtonQuestionType('');
  }
  function onEditModalCancel() {
    setIsEditModalVisible(false);
  }

  function onDeleteModalCancel() {
    setIsDeleteModalVisible(false);
  }

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent'
  ];

  const modulesQuestion = {
    toolbar: false
  };

  const refQuestion = useRef<any>(null);
  const refAnswer = useRef<any>(null);
  const refEditQuestion = useRef<any>(null);
  const refEditAnswer = useRef<any>(null);
  const [valueRadioButtonDifficulty, setValueRadioButtonDifficulty] =
    useState<any>('');
  const [valueRadioButtonQuestionType, setValueRadioButtonQuestionType] =
    useState<string>('');
  const [uuidCategoryCollection, setUuidCategoryCollection] = useState([]);
  const [questionDescription, setQuestionDescription] = useState<string>('');
  const [answerDescription, setAnswerDescription] = useState<string>('');
  //for edit modal states
  const [editQuestion, setEditQuestion] = useState<string>('');
  const [editAnswer, setEditAnswer] = useState<string>('');
  const [editCategoryCollection, setEditCategoryCollection] = useState([]);
  const [editValueRadioButtonDifficulty, setEditValueRadioButtonDifficulty] =
    useState<any>('');
  const [
    editValueRadioButtonQuestionType,
    setEditValueRadioButtonQuestionType
  ] = useState<any>();
  const [currentItem, setCurrentItem] = useState<any>();
  const [currentPage, setCurrentPage] = useState<any>();
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [levelFilter, setLevelFilter] = useState<number>(0);

  const dispatch = useDispatch();

  const { page, q, category, level } = getCurrentQueryParams();

  const handleRadioButtonQuestionChange = (e: any) => {
    setValueRadioButtonQuestionType(e.target.value + '');
  };
  const handleRadioButtonDifficultyChange = (e: any) => {
    setValueRadioButtonDifficulty(e.target.value);
  };
  const handleQuestionChange = () => {
    setQuestionDescription(refQuestion.current?.unprivilegedEditor.getHTML());
  };

  const handleAnswerChange = () => {
    setAnswerDescription(refAnswer.current?.unprivilegedEditor.getHTML());
  };

  const editQuestionChange = () => {
    setEditQuestion(refEditQuestion?.current?.unprivilegedEditor?.getHTML());
  };

  const editAnswerChange = () => {
    setEditAnswer(refEditAnswer?.current?.unprivilegedEditor?.getHTML());
  };

  const handleEditRadioButtonDifficultyChange = (e: any) => {
    setEditValueRadioButtonDifficulty(e.target.value);
  };

  const handleEditRadioButtonQuestionChange = (e: any) => {
    setEditValueRadioButtonQuestionType(e.target.value);
  };

  const formattedCategories = allCategories?.data?.map((item: any) => ({
    ...(item as Object),
    label: item?.name,
    value: item?.uuid
  }));

  const handleSave = () => {
    let body = {
      categories: uuidCategoryCollection,
      content: questionDescription,
      answer: answerDescription,
      level: Number(valueRadioButtonDifficulty),
      question_type:
        valueRadioButtonQuestionType === 'subjective' ? Number(1) : Number(2)
    };
    dispatch({
      type: categoryActions.POST_A_QUESTION,
      payload: body
    });
    setIsVisible(false);
    setValueRadioButtonDifficulty('');
    setValueRadioButtonQuestionType('');
    setUuidCategoryCollection([]);
    setQuestionDescription('');
    setAnswerDescription('');
  };

  useEffect(() => {
    //to get all the categories in the modal
    dispatch({
      type: categoryActions.GET_ALL_CATEGORIES,
      payload: {
        pagination: false
      }
    });
    //to get question listings on the page
    // dispatch({ type: categoryActions.GET_ALL_QUESTIONS });
  }, []);

  const handleEditClick = (itm: any) => {
    //repopulating the edit modal logic moved to the `useEffect`, apparently it can be done here as well
    setIsEditModalVisible(true);
    setCurrentItem(itm);
  };
  const handleDeleteClick = (element: any, itm: any) => {
    element.stopPropagation();
    setIsDeleteModalVisible(true);
    setCurrentItem(itm);
  };

  const handleDeleteQuestion = () => {
    let body = {
      uuid: currentItem.uuid,
      status: 2
    };
    dispatch({
      type: categoryActions.DELETE_A_QUESTION,
      payload: body
    });
    setIsDeleteModalVisible(false);
  };

  const handlePageChange = (page: any) => {
    history.push(
      getSearchParamsUrl({
        page: page,
        category: categoryFilter,
        level: levelFilter,
        q: q
      }),
      {}
    );
  };

  const handleCategoryFilterChange = (category: any) => {
    history.push(
      getSearchParamsUrl({ page: 1, category: category, level: levelFilter }),
      {}
    );
  };

  const handleLevelFilterChange = (level: any) => {
    history.push(
      getSearchParamsUrl({ page: 1, category: categoryFilter, level: level }),
      {}
    );
  };

  useEffect(() => {
    //repopulates the edit modal with the data
    if (currentItem) {
      setEditQuestion(currentItem?.content);
      setEditAnswer(currentItem?.answer);
      setEditCategoryCollection(
        currentItem?.categories_list
          ?.filter((i: any) => i.status === 1)
          ?.map((i: any) => i?.uuid)
      );
      setEditValueRadioButtonQuestionType(() =>
        currentItem?.question_type === 1 ? 'subjective' : 'objective'
      );
      setEditValueRadioButtonDifficulty(currentItem?.level);
    }
  }, [currentItem, isEditModalVisible]);

  useEffect(() => {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const page = urlParams.get('page')
    if (currentPage) {
      let body: any = {
        page: currentPage
      };
      if (levelFilter !== 0) body['level'] = levelFilter;
      if (categoryFilter.trim().length > 0)
        body['category_id'] = categoryFilter;
      if (searchText.trim().length > 0) body['q'] = searchText;
      dispatch({
        type: categoryActions.GET_ALL_QUESTIONS_PAGINATED,
        payload: body
      });
    }
  }, [currentPage, categoryFilter, levelFilter, searchText]);

  useEffect(() => {
    const queryString = router.search;
    const urlParams = new URLSearchParams(queryString);

    const page = urlParams.get('page');
    page ? setCurrentPage(Number(page)) : setCurrentPage(1);

    const category = urlParams.get('category');
    category && category.trim().length > 0
      ? setCategoryFilter(category)
      : setCategoryFilter('');

    const level = urlParams.get('level');
    level ? setLevelFilter(Number(level)) : setLevelFilter(0);

    const q = urlParams.get('q');
    q && q.trim().length > 0 ? setSearchText(q) : setSearchText('');
  }, [router]);

  const handleEdit = () => {
    setIsEditModalVisible(false);
    let body = {
      uuid: currentItem?.uuid,
      categories: editCategoryCollection,
      content: editQuestion,
      answer: editAnswer,
      level: Number(editValueRadioButtonDifficulty),
      question_type:
        editValueRadioButtonQuestionType === 'subjective'
          ? Number(1)
          : Number(2)
    };
    dispatch({
      type: categoryActions.EDIT_A_QUESTION,
      payload: body
    });
  };

  const key = 'uuid';
  const uniqueAllQuestions = [
    ...new Map(
      allQuestions.data?.map((item: any) => [item[key], item])
    ).values()
  ];

  const checkForQuestionAnswerEmpty = () => {
    if (
      questionDescription.replace(/<(.|\n)*?>/g, '').trim().length === 0 ||
      questionDescription.length > 5000 ||
      answerDescription.replace(/<(.|\n)*?>/g, '').trim().length === 0 ||
      answerDescription.length > 5000
    ) {
      return true;
    } else return false;
  };
  const checkForEditQuestionAnswerEmpty = () => {
    if (
      editQuestion.replace(/<(.|\n)*?>/g, '').trim().length === 0 ||
      editQuestion.length > 5000 ||
      editAnswer.replace(/<(.|\n)*?>/g, '').trim().length === 0 ||
      editAnswer.length > 5000
    ) {
      return true;
    } else return false;
  };
  const levelConfig = [
    {
      name: 'Easy',
      value: 1
    },
    {
      name: 'Medium',
      value: 2
    },
    {
      name: 'Hard',
      value: 3
    }
  ];

  return (
    <div className='w-full h-screen overflow-y-auto bg-ghost-white relative'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Questions</title>
      </Helmet>
      <Header />
      <div className='text-right m-8 flex gap-2 justify-between'>
        <div className='flex gap-4'>
          <InterviewFilter
            primaryFilter='All Level'
            primaryFitlerValue={0}
            values={levelConfig}
            currentValue={levelFilter}
            filterType='level'
            onSelection={(value: number) => {
              handleLevelFilterChange(value);
            }}
          />
          <InterviewFilter
            primaryFilter='All Categories'
            primaryFitlerValue={''}
            currentValue={categoryFilter}
            filterType='category'
            values={allCategories?.data?.map((item: any) => ({
              name: item.name,
              value: item.uuid
            }))}
            onSelection={(value: string) => {
              handleCategoryFilterChange(value);
            }}
          />

          {/* <ClearFilters
            handleClear={() => {
              history.push(
                getSearchParamsUrl({
                  page: 1,
                  category: null,
                  level: null,
                  q: null
                }),
                {}
              );
            }}
          /> */}
        </div>
        <PrimaryButton onClick={handleAddClick} text='Add new question' />
      </div>
      <main>
        {!!!uniqueAllQuestions.length &&
        !!!(q as string)?.trim()?.length &&
        !!!Number(level) &&
        !!!(category as string)?.trim()?.length ? (
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <section>
              <div className='flex justify-center'>
                <img
                  className='max-h-[300px]'
                  src='/questionEmptyState.png'
                  alt='No Category'
                />
              </div>
              <br />
              <div className='text-center font-semibold flex justify-center text-[#0E254B] text-opacity-40 text-[20px]'>
                No questions have been added yet. <br />
                Click on the button to add questions.
              </div>
              <br />
            </section>
          </Spin>
        ) : (
          <>
            <Spin size='large' className='hrTable-loader' spinning={loading}>
              <section className='bg-white rounded-lg p-5 m-8'>
                {allQuestions?.data.length !== 0 &&
                  allQuestions?.data?.map((itm: any, index: number) => (
                    <div
                      key={itm?.uuid}
                      onClick={() => handleEditClick(itm)}
                      style={{
                        cursor: 'pointer',
                        border: '0.2px solid #343A40',
                        padding: '22px 15px',
                        borderRadius: '8px'
                      }}
                      className='my-2'
                    >
                      <div className='flex justify-between'>
                        <div style={{ display: 'flex' }}>
                          {itm.level === 1 && (
                            <span
                              style={{
                                margin: '0px 10px 0px 0px',
                                backgroundColor: '#F3F9FE',
                                width: 'fit-content',
                                height: 'fit-content',
                                padding: '0px 10px',
                                color: '#4E9F09',
                                border: '1px solid #4E9F09',
                                borderRadius: '4px',
                                fontSize: '16px'
                              }}
                            >
                              Easy
                            </span>
                          )}
                          {itm.level === 2 && (
                            <span
                              style={{
                                margin: '0px 10px 0px 0px',
                                backgroundColor: '#F3F9FE',
                                width: 'fit-content',
                                height: 'fit-content',
                                padding: '0px 10px',
                                color: '#D19E37',
                                border: '1px solid #D19E37',
                                borderRadius: '4px',
                                fontSize: '16px'
                              }}
                            >
                              Medium
                            </span>
                          )}
                          {itm.level === 3 && (
                            <span
                              style={{
                                margin: '0px 10px 0px 0px',
                                backgroundColor: '#F3F9FE',
                                width: 'fit-content',
                                height: 'fit-content',
                                padding: '0px 10px',
                                color: '#C87172',
                                border: '1px solid #C87172',
                                borderRadius: '4px',
                                fontSize: '16px'
                              }}
                            >
                              Hard
                            </span>
                          )}
                          {itm?.categories_list
                            ?.filter((i: any) => i.status === 1)
                            ?.slice(0, 3)
                            ?.map((itm: any, index: number) => (
                              <div
                                onClick={(e) => e.stopPropagation()}
                                key={itm.uuid}
                                style={{
                                  fontSize: '16px',
                                  margin: '0px 10px',
                                  backgroundColor: '#2D94F31A',
                                  width: 'fit-content',
                                  height: '28px',
                                  padding: '0px 10px',
                                  color: '#2D94F3',
                                  borderRadius: '4px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {itm?.name.length > 15 ? (
                                  <Tooltip title={itm?.name}>
                                    {itm?.name.slice(0, 15) + '...'}
                                  </Tooltip>
                                ) : (
                                  <div>{itm?.name}</div>
                                )}
                              </div>
                            ))}
                          {itm?.categories_list?.filter(
                            (i: any) => i.status === 1
                          )?.length > 3 && (
                            <Tooltip
                              title={itm?.categories_list
                                ?.filter((i: any) => i.status === 1)
                                ?.slice(3)
                                ?.map((i: any) => (
                                  <span>
                                    {i?.name}
                                    <br />
                                  </span>
                                ))}
                            >
                              <div
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                  margin: '0px 10px',
                                  backgroundColor: '#2D94F31A',
                                  width: 'fit-content',
                                  height: '28px',
                                  padding: '0px 10px',
                                  color: '#1890FF',
                                  cursor: 'pointer',
                                  borderRadius: '4px'
                                }}
                              >
                                ...
                              </div>
                            </Tooltip>
                          )}
                        </div>
                        <div className='flex'>
                          <div
                            className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
                            onClick={() => handleEditClick(itm)}
                          >
                            <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue' />
                          </div>
                          &nbsp; &nbsp;
                          <div
                            className='w-8 h-8 bg-background-red rounded-md flex items-center justify-center cursor-pointer'
                            onClick={(element: any) =>
                              handleDeleteClick(element, itm)
                            }
                          >
                            <MdDeleteOutline className=' w-5 h-5 text-tamato' />
                          </div>
                        </div>
                      </div>
                      <br />
                      {/* <span className="text-[#0E254B]">Q{allQuestions?.meta?.pagination?.perPage *
                                               (allQuestions?.meta?.pagination?.page - 1) +
                                                index +
                                                1}.</span> */}

                      <div className='scoped'>
                        <ReactQuill
                          className='editor'
                          value={
                            itm?.content.length > 300
                              ? `${itm?.content.slice(0, 300)} ...`
                              : itm?.content
                          }
                          readOnly
                          style={{ color: '#6C757D' }}
                          modules={modulesQuestion}
                        />
                      </div>
                    </div>
                  ))}
                {allQuestions?.data?.length === 0 && (
                  <div className='w-full xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center'>
                    Oops! No Question to show
                  </div>
                )}
              </section>
              <div className='text-center pb-4'>
                {
                  <Pagination
                    showSizeChanger={false}
                    defaultCurrent={1}
                    current={allQuestions?.meta?.pagination?.page}
                    defaultPageSize={allCategories?.meta?.pagination?.perPage}
                    // pageSize={allQuestions?.meta?.pagination?.perPage}
                    onChange={(page) => handlePageChange(page)}
                    total={allQuestions?.meta?.pagination?.count}
                  />
                }
              </div>
            </Spin>
          </>
        )}
        {/* ======This is Add Modal Component================================================= */}
        <Modal
          destroyOnClose={true}
          open={isVisible}
          onCancel={onCancel}
          width='1100px'
          className='edit-modal relative'
          bodyStyle={{
            backgroundColor: '#FAFDFF',
            borderRadius: '0.25rem'
          }}
        >
          <div className='rounded-[8px] p-2 my-[15px] mx-[10px] border-1 border-[#707070] bg-[#FAFDFF]'>
            <div className='flex items-center justify-between mb-4'>
              <div className='text-xl text-center font-semibold text-blue-zodiac'>
                Add new Question
              </div>
              <div
                className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-[-2.25rem] right-[-1.75rem]'
                onClick={onCancel}
              >
                <AiOutlineClose className='w-5 h-5 text-gray-light' />
              </div>
            </div>
            <div className='flex items-center'>
              <div className='text-[#707070] font-semibold text-[16px]'>
                Categories:
                <span className='text-lg text-star-red pl-1 font-semibold'>
                  *
                </span>{' '}
              </div>
              <Select
                //@ts-ignore
                filterOption={(input, option) => {
                  return (option!.name as unknown as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                mode='multiple'
                allowClear
                style={{ minWidth: '350px', margin: '0px 20px', width: '100%' }}
                onChange={(idFromCategoryDropdown) =>
                  setUuidCategoryCollection(idFromCategoryDropdown)
                }
                placeholder='Choose a Category'
                options={formattedCategories}
              ></Select>
            </div>
            <br />
            <div className='flex items-center'>
              <div className='text-[#707070] text-[16px] font-semibold'>
                Difficulty Level:
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <div className='mx-8'>
                <Radio.Group
                  style={{ display: 'flex' }}
                  onChange={handleRadioButtonDifficultyChange}
                  value={valueRadioButtonDifficulty}
                >
                  <Radio
                    style={
                      valueRadioButtonDifficulty === 1
                        ? {
                            border: '2px solid #4D92EC',
                            color: '#4E9F09',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                        : {
                            color: '#4E9F09',
                            border: '2px solid #14254940',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                    }
                    value={1}
                  >
                    Easy
                  </Radio>
                  <Radio
                    style={
                      valueRadioButtonDifficulty === 2
                        ? {
                            color: '#D19E37',
                            border: '2px solid #4D92EC',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                        : {
                            color: '#D19E37',
                            border: '2px solid #14254940',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                    }
                    value={2}
                  >
                    Medium
                  </Radio>
                  <Radio
                    style={
                      valueRadioButtonDifficulty === 3
                        ? {
                            color: '#C87172',
                            border: '2px solid #4D92EC',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                        : {
                            color: '#C87172',
                            border: '2px solid #14254940',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                    }
                    value={3}
                  >
                    Hard
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <br />
            <div className='text-[#707070] text-[16px] font-semibold'>
              Question Type:
              <span className='text-lg text-star-red pl-1'>*</span>
            </div>
            <div className='my-2'>
              <Radio.Group
                style={{ display: 'flex' }}
                onChange={handleRadioButtonQuestionChange}
                value={valueRadioButtonQuestionType}
              >
                <Radio
                  style={
                    valueRadioButtonQuestionType === 'subjective'
                      ? {
                          border: '2px solid #4D92EC',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px',
                          color: '#707070'
                        }
                      : {
                          border: '2px solid #14254940',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px',
                          color: '#707070'
                        }
                  }
                  value={'subjective'}
                >
                  Subjective Type
                </Radio>
                <Radio
                  style={
                    valueRadioButtonQuestionType === 'objective'
                      ? {
                          border: '2px solid #4D92EC',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px',
                          color: '#707070'
                        }
                      : {
                          border: '2px solid #14254940',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px',
                          color: '#707070'
                        }
                  }
                  value={'objective'}
                >
                  Objective Type
                </Radio>
              </Radio.Group>
            </div>
            <br />
            <div className='text-[#0E254B] font-medium'>
              Question<span className='text-lg text-star-red pl-1'>*</span>
            </div>
            <ReactQuill
              modules={modules}
              formats={formats}
              ref={refQuestion}
              onChange={handleQuestionChange}
              placeholder='Enter Question'
            />
            {questionDescription.length > 5000 && (
              <p className='text-star-red pb-0 mb-0 text-right'>
                Question has exceeded the maximum limit
              </p>
            )}
            <br />
            <div className='text-[#0E254B] font-medium'>
              Answer
              <span className='text-lg text-star-red pl-1'>*</span>
              <span className='font-light'> (Please enter correct answer)</span>
            </div>
            <ReactQuill
              modules={modules}
              formats={formats}
              ref={refAnswer}
              onChange={handleAnswerChange}
              placeholder='Enter Answer'
            />
            {answerDescription.length > 5000 && (
              <p className='text-star-red pb-0 mb-0 text-right'>
                Answer has exceeded the maximum limit
              </p>
            )}
            <br />
            <div className='flex justify-end'>
              <PrimaryButton
                onClick={handleSave}
                disabled={
                  !!!uuidCategoryCollection.length ||
                  !!!questionDescription ||
                  !!!valueRadioButtonQuestionType ||
                  !!!valueRadioButtonDifficulty ||
                  checkForQuestionAnswerEmpty()
                }
                text='Save'
              />
              <div className='mx-4'>
                <CancelButton onClick={onCancel} text='Cancel' />
              </div>
            </div>
          </div>
        </Modal>

        {/* ======This is Edit Modal Component================================================= */}
        <Modal
          forceRender
          destroyOnClose
          open={isEditModalVisible}
          onCancel={onEditModalCancel}
          width='1100px'
          className='edit-modal relative'
        >
          <div className='rounded-[8px] p-2 my-[15px] mx-[10px] border-1 border-[#707070]'>
            <div className='flex items-center justify-between mb-4'>
              <div className='text-xl text-center font-semibold text-blue-zodiac'>
                Edit This Question
              </div>
              <div
                className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-[-2.25rem] right-[-1.75rem]'
                onClick={onEditModalCancel}
              >
                <AiOutlineClose className='w-5 h-5 text-gray-light' />
              </div>
            </div>
            <div className='flex items-center'>
              <div className='text-[#707070] font-semibold text-[16px]'>
                Categories:
                <span className='text-lg text-star-red pl-1 font-semibold'>
                  *
                </span>{' '}
              </div>
              <Select
                mode='multiple'
                allowClear
                style={{
                  width: '100%',
                  margin: '0px 20px',
                  color: '#2D94F3'
                }}
                onChange={(idFromCategoryDropdown) =>
                  setEditCategoryCollection(idFromCategoryDropdown)
                }
                placeholder='Choose a Category'
                options={formattedCategories}
                value={editCategoryCollection}
                filterOption={(input, option) => {
                  return (option!.name as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input.toLowerCase());
                }}
              ></Select>
            </div>
            <br />
            <div className='flex items-center'>
              <div className='text-[#707070] font-semibold text-[16px]'>
                Difficulty Level:
                <span className='text-lg text-star-red pl-1 font-semibold'>
                  *
                </span>
              </div>
              <div className='mx-8'>
                <Radio.Group
                  style={{ display: 'flex' }}
                  onChange={handleEditRadioButtonDifficultyChange}
                  value={editValueRadioButtonDifficulty}
                >
                  <Radio
                    style={
                      editValueRadioButtonDifficulty === 1
                        ? {
                            color: '#4E9F09',
                            border: '2px solid #4D92EC',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                        : {
                            color: '#4E9F09',
                            border: '2px solid #14254940',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                    }
                    value={1}
                  >
                    Easy
                  </Radio>
                  <Radio
                    style={
                      editValueRadioButtonDifficulty === 2
                        ? {
                            color: '#D19E37',
                            border: '2px solid #4D92EC',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                        : {
                            color: '#D19E37',
                            border: '2px solid #14254940',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                    }
                    value={2}
                  >
                    Medium
                  </Radio>
                  <Radio
                    style={
                      editValueRadioButtonDifficulty === 3
                        ? {
                            color: '#C87172',
                            border: '2px solid #4D92EC',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                        : {
                            color: '#C87172',
                            border: '2px solid #14254940',
                            padding: '15px',
                            borderRadius: '5px',
                            margin: '0px 30px 0px 0px'
                          }
                    }
                    value={3}
                  >
                    Hard
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <br />
            <div className='text-[#707070] font-semibold text-[16px]'>
              Question Type:
              <span className='text-lg text-star-red pl-1 font-semibold'>
                *
              </span>
            </div>
            <div className='my-2'>
              <Radio.Group
                style={{ display: 'flex' }}
                onChange={handleEditRadioButtonQuestionChange}
                value={editValueRadioButtonQuestionType}
              >
                <Radio
                  style={
                    editValueRadioButtonQuestionType === 'subjective'
                      ? {
                          color: '#707070',
                          border: '2px solid #4D92EC',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px'
                        }
                      : {
                          color: '#707070',
                          border: '2px solid #14254940',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px'
                        }
                  }
                  value={'subjective'}
                >
                  Subjective Type
                </Radio>
                <Radio
                  style={
                    editValueRadioButtonQuestionType === 'objective'
                      ? {
                          color: '#707070',
                          border: '2px solid #4D92EC',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px'
                        }
                      : {
                          color: '#707070',
                          border: '2px solid #14254940',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 30px 0px 0px'
                        }
                  }
                  value={'objective'}
                >
                  Objective Type
                </Radio>
              </Radio.Group>
            </div>
            <br />
            <div className='text-[#0E254B] font-medium'>
              Question<span className='text-lg text-star-red pl-1'>*</span>
            </div>
            <ReactQuill
              modules={modules}
              formats={formats}
              ref={refEditQuestion}
              onChange={editQuestionChange}
              value={editQuestion}
            />
            {editQuestion.length > 5000 && (
              <p className='text-star-red pb-0 mb-0 text-right'>
                Question has exceeded the maximum limit
              </p>
            )}
            <br />
            <div className='text-[#0E254B] font-medium'>
              Answer
              <span className='text-lg text-star-red pl-1'>*</span>
              <span className='font-light'> (Please enter correct answer)</span>
            </div>
            <ReactQuill
              modules={modules}
              formats={formats}
              ref={refEditAnswer}
              onChange={editAnswerChange}
              value={editAnswer}
            />
            {editAnswer.length > 5000 && (
              <p className='text-star-red pb-0 mb-0 text-right'>
                Answer has exceeded the maximum limit
              </p>
            )}
            <br />
            <div className='flex justify-end'>
              <PrimaryButton
                onClick={handleEdit}
                disabled={
                  !!!editCategoryCollection.length ||
                  checkForEditQuestionAnswerEmpty()
                }
                text='Save'
              />
              <div className='mx-4'>
                <CancelButton onClick={onEditModalCancel} text='Cancel' />
              </div>
            </div>
          </div>
        </Modal>
        {/* =================================================================================== */}
        {/* ======This is Delete Modal Component================================================= */}
        <Modal
          destroyOnClose={true}
          open={isDeleteModalVisible}
          onCancel={onDeleteModalCancel}
          width='500px'
          centered
          className='edit-modal relative'
          bodyStyle={{
            backgroundColor: '#FAFDFF',
            borderRadius: '0.25rem'
          }}
        >
          <div className='flex items-center justify-between mb-2'>
            <div className='text-xl text-center font-semibold text-blue-zodiac'>
              Delete Question
            </div>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-[-0.75rem] right-[-0.75rem]'
              onClick={onDeleteModalCancel}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>
          <div className='text-base text-blue-radic text-center lg:text-left mb-4'>
            Are you sure you want to delete this question?
          </div>
          <div>
            <div className='flex justify-end'>
              <div onClick={handleDeleteQuestion} className='mr-4'>
                <DangerButton />
              </div>
              <div>
                <CancelButton onClick={onDeleteModalCancel} text='Cancel' />
              </div>
            </div>
          </div>
        </Modal>
        {/* =================================================================================== */}
      </main>
    </div>
  );
};

export default connect(mapStateToProps)(Questions);
