import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CreatedInterview from "../CreatedInterview";
import DeletedInterview from "../DeletedInterview";
import RescheduledInterview from "../ReschduleInterview";

import applicationActions from "../../../../redux/applications/actions";
import { INTERVIEW_HISTORY_STATUS } from "../../../../constants/HelperConstants";

interface IuseInterviewHistory {
  interviewId: string;
}

const useInterviewHistory = ({ interviewId }: IuseInterviewHistory) => {
  const dispatch = useDispatch();

  const interviewHistorySwitch = (data: any) => {
    if (data.status === INTERVIEW_HISTORY_STATUS.SCHEDULED) {
      return <CreatedInterview item={data} />;
    } else if (data.status === INTERVIEW_HISTORY_STATUS.CANCELLED) {
      return <DeletedInterview item={data} />;
    } else {
      return <RescheduledInterview item={data} />;
    }
  };
  const closeModal = () => {
    dispatch({
      type: applicationActions.TOGGLE_INTERVIEW_HISTORY_MODAL,
      payload: { status: false, id: undefined },
    });
  };

  useEffect(() => {
    if (interviewId) {
      dispatch({
        type: applicationActions.GET_INTERVIEW_HISTORY,
        payload: { id: interviewId },
      });
    }

    return () => {
      dispatch({
        type: applicationActions.RESET_INTERVIEW_HISTORY_STATE,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId]);

  return {
    interviewHistorySwitch,
    closeModal,
  };
};

export default useInterviewHistory;
