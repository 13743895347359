import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import AddTaskModal from "../../components/Modal/AddTask/AddTaskModal";
import TaskTable from "../../components/Table/TaskTable";
import taskActions from "../../redux/tasks/actions";

const Tasks = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: taskActions.GET_ALL_TASKS,
      payload: { page: 1 },
    });
  }, [dispatch]);

  return (
    <div className="w-full  h-screen  overflow-y-auto bg-ghost-white relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tasks</title>
      </Helmet>
      <Header />
      <div className="px-[31.5px]">
        <div className="flex justify-end">
          <AddTaskModal heading="Add Task" />
        </div>
        <TaskTable />
      </div>
    </div>
  );
};

export default Tasks;
