import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import users from './users/sagas';
import jobs from './jobs/sagas';
import interviewers from './interviewers/sagas';
import applications from './applications/sagas';
import tasks from './tasks/sagas';
import referrals from './referrals/sagas';
import questionBank from './questionBank/sagas';
import atsJobs from './atsJobs/sagas';
import hiringTracker from './hiringTracker/sagas';
import emailTemplatesReducer from './emailTemplates/sagas';

export default function* rootSaga() {
  yield all([
    auth(),
    users(),
    jobs(),
    applications(),
    emailTemplatesReducer(),
    interviewers(),
    tasks(),
    referrals(),
    questionBank(),
    atsJobs(),
    hiringTracker()
  ]);
}
