import { all, takeEvery, put } from "redux-saga/effects";
import { doApiCall } from "../../helper";
import actions from "./actions";
import { notification } from "antd";
import TaskServices from "../../services/tasks";

const service = new TaskServices();

export function* WATCH_GET_ALL_TASKS(action) {
  const response = yield doApiCall(service.GetAllTasks, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_TASKS_STATE,
      payload: { tasks: response },
    });
  }
}
export function* GET_ALL_WITHOUT_TASKS(action) {
  const response = yield doApiCall(
    service.GetWithoutPaginatedTasks,
    action.payload
  );
  if (response?.success) {
    yield put({
      type: actions.SET_TASKS_STATE,
      payload: { paginatedTask: response },
    });
  }
}
export function* WATCH_GET_SINGLE_TASK(action) {
  const response = yield doApiCall(service.GetSingleTask, action.payload);
  if (response?.success) {
    yield put({
      type: actions.SET_TASKS_STATE,
      payload: { singleTask: response },
    });
  }
}

export function* WATCH_EDIT_TASKS(action) {
  // const { id, body } = payload;
  const response = yield doApiCall(service.EditTask, action.payload);
  if (response?.success === true) {
    notification.success({
      message: "Task Updated Successfully",
    });
    yield put({
      type: actions.GET_ALL_TASKS,
      payload: { page: action.payload.page },
    });
  }
}
export function* WATCH_ADD_TASKS(action) {
  const response = yield doApiCall(service.AddTask, action.payload);
  if (response?.success) {
    notification.success({
      message: "Task Added Successfully",
    });
    yield put({
      type: actions.GET_ALL_TASKS,
      payload: { page: 1 },
    });
  }
}
export function* WATCH_DELETE_TASKS(action) {
  const response = yield doApiCall(service.DeleteTask, action.payload);
  if (response?.success) {
    notification.error({
      message: "Task deleted successfully",
    });
    yield put({
      type: actions.GET_ALL_TASKS,
      payload: { page: action.payload.page },
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALL_TASKS, WATCH_GET_ALL_TASKS)]);
  yield all([
    takeEvery(actions.WITHOUT_PAGINATED_TASK_LIST, GET_ALL_WITHOUT_TASKS),
  ]);
  yield all([takeEvery(actions.EDIT_TASK, WATCH_EDIT_TASKS)]);
  yield all([takeEvery(actions.ADD_TASK, WATCH_ADD_TASKS)]);
  yield all([takeEvery(actions.DELETE_TASK, WATCH_DELETE_TASKS)]);
  yield all([takeEvery(actions.GET_SINGLE_TASK, WATCH_GET_SINGLE_TASK)]);
}

// else {
//   notification.error({ message: response.message });
// }
