import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import InterviewHistory from '../../Interview/InterviewHistory';
import Interviews from '../../Interviews';
import DeleteInterviewModal from '../../Modal/Delete/cancelInterview';
import { Spin } from 'antd';

const mapStateToProps = (state: any) => ({
  singleApplication: state.applications.singleApplication,
  applications: state.applications.applications,
  filters: state.filters.filters,
  interviewDetails: state.applications.interviewDetails,
  interviewLoading: state.applications.interviewLoading
});

interface IInterviewComp {
  applications: any;
  singleApplication: any;
  filters: any;
  interviewDetails: any;
  setInterviewId: any;
  setSingleInterview: any;
  id: any;
  interviewId: any;
  interviewLoading?: boolean;
}

const InterviewComp: FC<IInterviewComp> = ({
  interviewDetails,
  setInterviewId,
  setSingleInterview,
  id,
  interviewId,
  interviewLoading
}) => {
  const dispatch = useDispatch();
  const getInterviewId = (value: string) => {
    setInterviewId(value);
    const singleInterview = interviewDetails.filter(
      (item: any) => item.id === value
    )?.[0];
    setSingleInterview(singleInterview);
  };

  return (
    <div className='bg-white rounded-lg p-5 w-full'>
      <div className='flex justify-between'>
        <h2 className='text-lg text-[#233A5C] mr-4 mb-0'>
          Interviews Scheduled
        </h2>
        <div
          className='cursor-pointer'
          onClick={() => {
            setInterviewId('');
            setSingleInterview({});
            dispatch({
              type: applicationActions.TOGGLE_MODAL,
              payload: true
            });
          }}
        >
          <img src='/add-interview.svg' alt='' />
        </div>
      </div>
      <div className='max-h-[20rem] overflow-y-auto'>
        {interviewLoading ? (
          <div className='h-[20rem] w-full flex justify-center items-center'>
            <Spin size='large' />
          </div>
        ) : interviewDetails.length > 0 ? (
          interviewDetails.map((item: any) => (
            <Interviews
              key={item.id}
              data={item}
              getInterviewId={getInterviewId}
            />
          ))
        ) : (
          <div className='w-full flex items-center flex-col justify-center h-full py-12 space-y-3'>
            <img src='/Icons/NoInterview.png' alt='No Interviews' />
            <span className='text-lg text-[#0E254B] opacity-40 font-semibold'>
              No Interview Scheduled.
            </span>
          </div>
        )}
      </div>
      {/* <InterviewHistory/> */}
      <DeleteInterviewModal
        desc='Are You Sure You Want To Cancel This Interview?'
        heading='CANCEL INTERVIEW'
        userId={id}
        interviewId={interviewId}
      />
      <InterviewHistory />
    </div>
  );
};

export default connect(mapStateToProps)(InterviewComp);
