import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import InterviewerTable from "../../components/Table/InterviewerTable";
import { history } from "../../utilities/configureAxios";

const inputArray = [
  {
    inputHeading: "Name",
    placeholder: "Name",
  },
  {
    inputHeading: "Email",
    placeholder: "Email",
  },
  {
    inputHeading: "Job Title",
    placeholder: "Job Title",
  },
];
interface InputProps {
  inputHeading: string;
  placeholder: string;
}

interface InputDetails {
  inputDetails?: InputProps[];
  role?: number;
  token?: string;
}

const Interviewer: FC<InputDetails> = () => {
  return (
    <div className="w-full  h-screen  overflow-y-auto bg-ghost-white relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Interviewers</title>
      </Helmet>
      <Header />
      <InterviewerTable location={history.location} inputDetails={inputArray} />
    </div>
  );
};

export default Interviewer;
