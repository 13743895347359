import { Button, message, notification, Upload, UploadProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import ApplicationServices from '../../services/applications';
import { UploadOutlined } from '@ant-design/icons';
interface IPortfolioFileUpload {
  setPortfolioFileName: any;
  setPortfolioFileType: any;
  portfolioFileType: any;
  files: any;
  setFiles: any;
}

const PortfolioFileUpload: FC<IPortfolioFileUpload> = ({
  setPortfolioFileName,
  setPortfolioFileType,
  portfolioFileType,
  files,
  setFiles
}) => {
  const service = new ApplicationServices();

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    customRequest: async (options: any) => {
      const { onSuccess, onError, file, onProgress } = options;
      try {
        const fileArry = file?.name?.split('.');
        const extension = fileArry.pop();
        const fileName = fileArry.join('.');
        const res = await service.GetPortfolioUrlForUpload(fileName, extension);
        const { data } = res.data;
        setPortfolioFileName(data.file_name);
        const arrayBuffer = await file.arrayBuffer();
        const response = await fetch(data.portfolio_url, {
          method: 'PUT',
          headers: {
            'Content-Type': portfolioFileType
          },
          body: arrayBuffer
        });
        if (response.ok) {
          onSuccess();
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        onError(error);
      }
    },
    accept:
      'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    onChange(info) {
      if (
        !'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'.includes(
          info?.file?.type || ''
        )
      ) {
        return;
      }
      if ((info?.file?.size || 0) > 5 * 1024 * 1024) {
        return;
      }
      setPortfolioFileType(info.file.type);
      setFiles(info.file);
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setPortfolioFileType(info.file.type);
        setFiles(info.file);
      } else if (info.file.status === 'error') {
        setPortfolioFileName('');
        setFiles({});
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === 'removed') {
        message.success(`${info.file.name} file removed successfully`);
        setPortfolioFileName('');
        setPortfolioFileType('');
        setFiles({});
      }
    },
    beforeUpload(file) {
      if (
        file?.type?.length === 0 ||
        !'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'.includes(
          file?.type || ''
        )
      ) {
        notification.warn({ message: 'File type is invalid' });
        return false;
      }
      if (file?.size > 5 * 1024 * 1024) {
        notification.warn({ message: 'File size is more than 5MB.' });
        return false;
      }
    }
  };
  return (
    <Upload {...uploadProps} listType='picture' className='upload-list-inline'>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
      <span style={{ cursor: 'auto', pointerEvents: 'none' }}>
        {' '}
        {`(Max file size 5MB)`}
      </span>
    </Upload>
  );
};

export default PortfolioFileUpload;
