import apiClient from '../utilities/configureAxios';
import * as APIs from '../constants/ApiConstants';
import { ApiRoutes } from '../constants/ApiEnums';

class JobsServices {
  constructor() {
    this.client = apiClient;
  }

  GetAllJobsForFilter = (payload = {}) => {
    return this.client
      .get(APIs.GET_ALL_JOBS_FOR_FILTER, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetAllJobsForAddApplicant = (payload = {}) => {
    return this.client
      .get(APIs.GET_ALL_JOBS_FOR_ADD_APPLICANT, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetAllJobs = (payload = {}) => {
    return this.client
      .get(APIs.GET_ALL_JOBS, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  SyncJobs = (payload = {}) => {
    return this.client
      .post(APIs.SYNC_JOBS)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostAJob = (payload = {}) => {
    return this.client
      .post(APIs.POST_JOB, payload)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostNewJob = (payload = {}) => {
    return this.client
      .post(APIs.POST_NEW_JOB, payload)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PatchJob = (payload = {}) => {
    return this.client
      .patch(APIs.PATCH_JOB.replace(':id', payload.id), payload.body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetAllSkills = () => {
    return this.client
      .get(ApiRoutes.GET_SKILLS)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  UpdateStatusOfJob = (payload = {}) => {
    const { id, body } = payload;
    return this.client
      .patch(APIs.UPDATE_JOB.replace(':id', id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default JobsServices;
