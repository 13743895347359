/* eslint-disable no-loop-func */
import { useEffect, useState } from "react";
import { Upload, Modal, Button, Alert, notification } from "antd";
import axios from "axios";
import QuestionServices from "../../services/Questions";
import { UploadOutlined } from "@ant-design/icons";


const service = new QuestionServices();

const DocumentsComponent = ({
  maxLimit,
  uploadedFiles,
  multiple = false,
  maxCount,
  accept,
  onChange,
  title = "Media Files",
  files,
  setFiles,
  size = 5
}) => {

  const [preview, setPreview] = useState({
    visible: false,
    image: null,
    title: "",
  });
  const [error, setError] = useState('')
  const [loader, setLoader] = useState(false)

  const errors = (err) => {
    // heloo
    const messages = {};
    Object.keys(err)?.forEach((key) => {
      const errorMessage = err[key];
      if (!errorMessage) return '';

      messages[key] =
        typeof errorMessage === 'string' ? errorMessage : errorMessage.join(',');
      return null;
    });

    return Object.values(messages)[0];
  };

  // text/plain
  const cancelPreview = () => setPreview({ ...preview, visible: false });

  const properties = {
    accept,
    fileList: files,
    maxLimit,
    // listType: "picture-card",
    multiple,
    defaultFileList: files,
    onChange: ({ fileList }) => {
      let errorCheck = false
      if (fileList?.length > maxCount && !error?.length) {
        if (!errorCheck) {
          errorCheck = true
          setError('both')
        }
      }
      const tempFile = [...(fileList.slice(0, maxCount))].filter((f) => {
        if (((f.size < size * 1024 * 1024 && accept.includes(f?.type)) || f?.slug?.length)) {
          return true
        } else {
          if (!error?.length) {
            if (!errorCheck) {
              // if (!(((f.size < size * 1024 * 1024 && accept.includes(f?.type)) || f?.slug?.length))) {
              setError('both')
              // }
              // else if (f.size > size * 1024 * 1024) {
              //   setError('size')
              // } else if (!((accept.includes(f?.type)) || f?.slug?.length)) {
              //   setError('type')
              // }
            }
            errorCheck = true
          }
          return false;
        }
      })
      setFiles([...tempFile]);
    },
    beforeUpload: () => {
      return false;
    },
  };
  useEffect(() => {
    if (error?.length) {
      // if (error === 'length') {
      //   text = 'More than 5 files can not be uploaded'
      //   description = 'Files after 5 will be get ignored'
      // }
      // else if (error === 'size') {
      //   text = 'Files having size more than 5MB will be get ignored'
      // }
      // else if (error === 'type') {
      //   text = 'Files are not having the specified types'
      // }
      // else if (error === 'both') {
      //   text = 
      // }
      notification.warning({ message: 'The attachment size is too large or the file type is invalid.' })
    }
  }, [error])



  const uploadImages = async () => {
    setLoader(true)
    const uploadingFiles = files.filter((f) => f.status !== "done");
    const response = await service.GetFeedbackUrlForUpload(
      uploadingFiles.length
    )
    if (response?.data?.success) {

      setFiles([
        ...files.map((f) => ({
          ...f,
          isNew: true,
          status: f.status === "done" ? "done" : "uploading",
        })),
      ]);
      const urls = response.data.data;
      let arr = []
      for (let i = 0; i < uploadingFiles?.length; i++) {
        const file = uploadingFiles[i];
        const storageObj = urls[i];

        await axios
          .put(storageObj.attachment_url, file.originFileObj, {
            onUploadProgress: (progressEvent) => {

              const progress = (progressEvent.loaded * 100) / file.size;

              const newFiles = files;
              const index = files.findIndex((f) => f.uid === file.uid);

              if (index < 0) return;
              newFiles[index] = {
                ...file,
                status: "uploading",
                percent: progress,
              };

              setFiles([...newFiles]);
            },
            headers: { "Content-Type": file.type },
          })
          .then(() => {
            const newFiles = files;
            const index = newFiles.findIndex((f) => f.uid === file.uid);
            newFiles[index] = {
              ...file,
              status: "done",
              slug: storageObj.filename,
            };
            arr = newFiles
            setFiles([...newFiles]);
          })
          .catch((error) => {
            const newFiles = files;
            const index = newFiles.findIndex((f) => f.uid === file.uid);
            newFiles[index] = { ...file, status: "error" };
            arr = newFiles
            setFiles([...newFiles]);
          });
        onChange(arr)
      }
      setLoader(false)
      return
    }
    else {
      const { data } = response.response;
      notification.warn({
        message: data?.code === 422 ? errors(data.errors) : data?.message,
      })
      // const { data } = response.response;

      // notification['error']({
      //   message: errors(data.errors || ),
      // });
      setLoader(false)
      return
    }
  }



  const completedFiles = files.filter((f) => f.status === "done");
  const uploadingFiles = files.filter((f) => f.status === "uploading");


  return (
    <div className="card bg-light">
      {/* {multiple ? <CardHeader title={title} /> : null}  */}
      <div className="card-body">
        {(uploadingFiles.length === 0 && completedFiles.length < files.length && !loader) ? (
          <div className="row justify-content-end mb-2">
            <div className="col-md-12">
              <Alert
                message={
                  multiple
                    ? "Attention! Please upload all media files first."
                    : ""
                }
                type="warning"
                showIcon
                action={
                  <Button type="link" onClick={uploadImages}>
                    <i className="fe fe-upload-cloud" /> &nbsp; Upload
                  </Button>
                }
              />
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-12">
            <Upload {...properties} listType="picture" className={`upload-list-inline ${files.length === maxCount ? 'antd-cutom-upload' : ''}`}>
              {multiple || files.length < 1 ? (
                <div onClick={() => {
                  if (error?.length) {
                    setError('')
                  }
                }}>
                  <Button icon={<UploadOutlined />} disabled={files.length === maxCount}>Click to Upload</Button>
                  <span style={{ cursor: 'auto', pointerEvents: 'none' }}> {`(Max file size ${size}MB)`}</span>
                </div>
              ) : null}
              <div style={{ marginTop: 8, cursor: 'auto' }} onClick={() => {
                if (error?.length) {
                  setError('')
                }
              }}>{`Select Files (Max ${(maxCount)} files)`}</div>
            </Upload>
          </div>
        </div>
      </div>

      <Modal
        visible={preview.visible}
        title={preview.title}
        footer={null}
        centered
        onCancel={cancelPreview}
        onOk={cancelPreview}
      >
        <img alt="example" style={{ width: "100%" }} src={preview.image} />
      </Modal>
    </div>
  );
};

export default DocumentsComponent;
