import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { coolOffArray } from '../../../constants/HelperConstants';
import useCoolOffSelect from './view/useCoolOffSelect';
import { useLocation } from 'react-router-dom';
import { queryStringToJSON } from '../../../utilities/routies';

const mapStateToProps = (state: any) => ({
  coolOffStatus: state.filters.coolOffStatus
});
interface ICoolOffSelect {
  coolOffStatus: boolean | null;
}
const CoolOffSelect: FC<ICoolOffSelect> = ({ coolOffStatus }) => {
  const { handleStatusChange } = useCoolOffSelect();
  const { search } = useLocation();
  const searchParams = queryStringToJSON(search);
  const [isDefaultCoolOffStatus, setIsDefaultCoolOffStatus] =
    useState<boolean>(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const hasCoolOffParam = searchParams.has('cool_off_status');
    if (hasCoolOffParam) {
      setIsDefaultCoolOffStatus(false);
    } else {
      setIsDefaultCoolOffStatus(true);
    }
  }, [search]);

  return (
    <div className='flex flex-col shadow hover:shadow-md hover:scale-105 w-[150px] transition-all duration-100 ease-in rounded-lg'>
      <Select
        options={coolOffArray}
        placeholder='Cool Off Status'
        className={`${
          isDefaultCoolOffStatus && 'faded-select'
        } filter-select w-full font-normal`}
        value={!searchParams?.cool_off_status?.length ? null : coolOffStatus}
        onChange={handleStatusChange}
      />
    </div>
  );
};

export default connect(mapStateToProps)(CoolOffSelect);
