import actions from './actions';

const initialState = {
  loading: false,
  exportCSVLoading: false,
  hiringData: {}
};

export default function hiringTracker(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_STATE: {
      return { ...state, ...payload };
    }
    case actions.EXPORT_CSV_LOADING: {
      return { ...state, exportCSVLoading: !state.exportCSVLoading };
    }
    case actions.RESET_STATE: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}
