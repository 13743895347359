import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { USER_ROLE } from '../../constants/HelperConstants';
const mapStateToProps = (state: any) => ({
  role: state.auth.role
});
const ErrorPage = ({ role }: { role: any }) => {
  const history = useHistory();
  const handleClick = () => {
    if (role === USER_ROLE.EMPLOYEE) {
      history.push('/employee/home');
    } else {
      history.push('/dashboard/all-candidates');
    }
  };
  return (
    <div className='w-full h-screen flex flex-col bg-ghost-white justify-center items-center'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>404 Page not found</title>
      </Helmet>
      <h1 className='text-blue-radic text-xl font-medium'>
        Oops! 404 Error Page
      </h1>
      <Button type='primary' className='primary-button' onClick={handleClick}>
        Back To Page
      </Button>
    </div>
  );
};

export default connect(mapStateToProps)(ErrorPage);
