import { Menu } from 'antd';
import { FC, useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { connect } from 'react-redux';
import {
  DASHBOARD_ALL_CANDIDATES,
  DASHBOARD_EMAILS,
  DASHBOARD_FEEDBACK,
  DASHBOARD_HIRING_TRACKER,
  DASHBOARD_INTERVIEWS,
  DASHBOARD_JOBS,
  EMPLOYEE_HOME,
  INTERVIEWER_HOME
} from '../../constants/RouteConstants';
import {
  adminTabs,
  employeeTabs,
  hrTabs,
  interviewerTabs
} from '../../constants/SidebarConstants';
import { history } from '../../utilities/configureAxios';
import useRouteChangeEffect from '../../hooks/useRouterChagne';
import { USER_ROLE } from '../../constants/HelperConstants';

const mapStateToProps = (state: any) => ({
  role: state.users.role
});

interface IHRsidebar {
  role: number;
}

const Sidebar: FC<IHRsidebar> = ({ role }) => {
  const [current, setCurrent] = useState('Analytics');
  const [items, setItems] = useState(adminTabs);
  const [currentSubMenu, setCurrentSubMenu] = useState<string[]>([]);
  const [subMenu, setSubMenu] = useState([]);

  const handleRouterChange = () => {
    if (role !== USER_ROLE.EMPLOYEE && role !== USER_ROLE.INTERVIEWER) {
      const tabs =
        role === USER_ROLE.HR || role === USER_ROLE.HR_LEAD
          ? hrTabs
          : adminTabs;
      switch (true) {
        case history.location.pathname === '/dashboard/analytics':
          setCurrent('Analytics');
          break;
        case history.location.pathname.includes(DASHBOARD_ALL_CANDIDATES):
          setCurrent('Candidates');
          break;
        case history.location.pathname.includes(DASHBOARD_FEEDBACK):
          setCurrent('Feedbacks');
          return;
        case history.location.pathname.includes(DASHBOARD_HIRING_TRACKER):
          setCurrent('Hiring Tracker');
          return;
        case history.location.pathname.includes(DASHBOARD_INTERVIEWS):
          setCurrent('Interviews');
          return;
        case history.location.pathname.includes(DASHBOARD_JOBS):
          setCurrent('Jobs');
          return;
        default:
          let filteredTab: any = [];
          let subMenuArray: any = [];
          tabs.forEach((item: any) => {
            if (!item?.children) {
              filteredTab.push(item);
            } else {
              subMenuArray.push({
                key: item.key,
                children: item?.children.map((element: any) => element.key)
              });
              filteredTab = [...filteredTab, ...item?.children];
            }
          });
          const currentTab = filteredTab.find(
            (t: any) => t.path === history.location.pathname
          );
          subMenuArray.forEach((item: any) => {
            if (item.children?.includes(currentTab?.key)) {
              setCurrentSubMenu([item.key]);
            }
          });
          setCurrent(currentTab?.key ?? 'Candidates');
          setSubMenu(subMenuArray);
      }
    } else {
      switch (true) {
        case history.location.pathname.includes('interviews'):
        case history.location.pathname.includes('feedback'):
          setCurrent('Interviews');
          return;
        case role === USER_ROLE.EMPLOYEE:
          const employeeTab = employeeTabs.find(
            (t) => t.path === history.location.pathname
          );
          setCurrent(employeeTab?.key ?? 'Home');
          return;
        case role === USER_ROLE.INTERVIEWER:
          const interviewerTab = interviewerTabs.find(
            (t) => t.path === history.location.pathname
          );
          setCurrent(interviewerTab?.key ?? 'Home');
          return;
      }
    }
  };

  useRouteChangeEffect(() => {
    handleRouterChange();
  });

  const onClick = (e: any) => {
    history.push(e.path);
    let checkSubMenu = '';
    subMenu?.forEach((item: any) => {
      if (item.children?.includes(e?.key)) {
        setCurrentSubMenu([item.key]);
        checkSubMenu = item.key;
      }
    });
    setCurrent(e.key);
  };
  useEffect(() => {
    handleRouterChange();
  }, []);

  useEffect(() => {
    switch (role) {
      case 1:
      case 2:
        setItems(adminTabs);
        break;
      case 3:
      case 6:
        setItems(hrTabs);
        break;
      case 4:
        setItems(interviewerTabs);
        break;
      case 5:
        setItems(employeeTabs);
        break;
    }
  }, [role]);

  const handleLogoClick = () => {
    switch (role) {
      case USER_ROLE.SUPER_ADMIN:
      case USER_ROLE.ORGNIZATION_ADMIN:
      case USER_ROLE.HR:
      case USER_ROLE.HR_LEAD:
        history.push(`${DASHBOARD_ALL_CANDIDATES}`);
        break;
      case USER_ROLE.INTERVIEWER:
        history.push(`${INTERVIEWER_HOME}`);
        break;
      case USER_ROLE.EMPLOYEE:
        history.push(`${EMPLOYEE_HOME}`);
        break;
    }
  };

  return (
    <div
      style={{ minWidth: '250px' }}
      className='w-full scroll-auto overflow-y-auto max-h-screen bg-white relative right-0'
    >
      <div className='p-10'>
        <img
          src='/SquareboatLogo.png'
          alt='Logo'
          className=' cursor-pointer object-contain'
          onClick={handleLogoClick}
        />
      </div>
      <Menu
        selectedKeys={[current]}
        style={{ color: '#6c757d' }}
        mode='inline'
        openKeys={currentSubMenu.length > 0 ? currentSubMenu : undefined}
        onOpenChange={(openKeys) => {
          if (openKeys.length > 0) setCurrentSubMenu(openKeys);
          else {
            setCurrentSubMenu([]);
          }
        }}
        expandIcon={(props) => (
          <>
            <MdOutlineKeyboardArrowRight
              className={`w-6 h-6 ${
                props.isOpen ? 'rotate-90' : null
              } transition-all duration-300`}
            />
          </>
        )}
      >
        {items.map((item) =>
          !item?.children ? (
            <Menu.Item
              onClick={() => onClick(item)}
              style={{ height: '80px' }}
              key={item.key}
              className={`transition-all duration-1000 relative`}
            >
              <div className={`flex ml-6`}>
                <span className='flex items-center'>{item.icon}</span>
                <span className='ml-3'>{item.label}</span>
              </div>
            </Menu.Item>
          ) : (
            <Menu.SubMenu
              title={item.label}
              icon={item.icon}
              style={{
                height: 'auto',
                background: '#ffffff',
                minHeight: '80px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
              key={item.key}
              className={`transitiloon-all duration-1000 sub-menu-container`}
            >
              {item?.children.map((element) => (
                <Menu.Item
                  onClick={() => onClick(element)}
                  style={{ height: '80px' }}
                  className={`text-[#6c757d]`}
                  key={element.key}
                >
                  <div className={`flex ml-6`}>
                    <span className='flex items-center'>{element.icon}</span>
                    <span className='ml-3'>{element.label}</span>
                  </div>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )
        )}
      </Menu>
    </div>
  );
};

export default connect(mapStateToProps)(Sidebar);
