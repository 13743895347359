import { call, put } from 'redux-saga/effects';
import actions from '../redux/auth/actions';
import applicationActions from '../redux/applications/actions';
import { notification } from 'antd';
import QueryString from 'qs';

export const getGenericErrorMessage = (e) => {
  if (e.response?.data?.errors || e.response?.data?.message) {
    const text = e.response?.data?.errors
      ? Object.values(e.response?.data?.errors)[0]
      : e.response?.data?.message || 'Something went wrong!';
    notification.error({ message: text });
  }
};

const errors = (err) => {
  // heloo
  const messages = {};
  Object.keys(err)?.forEach((key) => {
    const errorMessage = err[key];
    if (!errorMessage) return '';

    messages[key] =
      typeof errorMessage === 'string' ? errorMessage : errorMessage.join(',');
    return null;
  });

  return Object.values(messages)[0];
};

const errors1 = (err) => {
  const messages = {};
  Object.keys(err).forEach((key) => {
    const errorMessage = err[key];
    if (!errorMessage) return '';
    messages[key] =
      typeof errorMessage === 'string' ? errorMessage : errorMessage.join(', ');
  });

  return messages;
};
export function* doApiCall(service, payload, ACTION_SET_STATE = false) {
  if (ACTION_SET_STATE) {
    yield showLoading(ACTION_SET_STATE, true);
  }
  yield showLoading(actions.TOGGLE_LOADING, true);
  yield showLoading(applicationActions.TOGGLE_LOADING, true);

  try {
    const response = yield call(service, payload);
    if (ACTION_SET_STATE) {
      yield showLoading(ACTION_SET_STATE, true);
    }
    if (response.status === 200 || response.status === 201) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
      return response.data;
    } else if (response.response.status === 422) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
      const { data } = response.response;

      notification['error']({
        message: errors(data.errors)
      });
      if (ACTION_SET_STATE) {
        yield put({
          type: ACTION_SET_STATE,
          payload: { formErrors: errors1(data.errors) }
        });
      }
    } else if (response.response.status === 403) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
      const { data } = response.response;

      notification['error']({
        message: errors(data.errors)
      });
    } else if (response.response.status === 500) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);

      notification['error']({
        message: 'Internal Server Error'
      });
    } else if (response.response.status === 400) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
      const { data } = response.response;
      notification['error']({
        message: data.errors
      });
      return data;
    } else if (response.response.status === 404) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
      const { data } = response.response;

      notification['error']({
        message: data.errors
      });

      return data;
    } else if (response.response.status === 429) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
      notification['error']({
        message: 'Please try after sometime! 429 Too Many Requests'
      });
    } else if (response.response.status === 408) {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
      notification['error']({
        message: 'The token has expired please relogin'
      });
    } else {
      yield showLoading(actions.TOGGLE_LOADING, false);
      yield showLoading(applicationActions.TOGGLE_LOADING, false);
    }
  } catch (e) {
    if (ACTION_SET_STATE) {
      yield showLoading(ACTION_SET_STATE, true);
    }
    yield showLoading(actions.TOGGLE_LOADING, false);
    yield showLoading(applicationActions.TOGGLE_LOADING, false);
    const { response } = e;

    if (response) {
      const { code, errors } = response;
      if (code === 422) {
        if (ACTION_SET_STATE) {
          yield put({
            type: ACTION_SET_STATE,
            payload: { formErrors: errors1(errors) }
          });
        }

        notification['error']({
          message: errors(errors)
        });
      }
    } else if (response !== undefined) {
      notification['error']({
        // message: "No Internet",
      });
    }
    return {
      success: false,
      response: response || {}
    };
  }
  return { success: false };
}

export function* showLoading(action, loading) {
  yield put({ type: action, payload: loading });
}

export function getSearchParamsUrl(obj, presist = false) {
  const params = {};
  const queryParams = window.location?.search.slice(1);
  let oldParams = QueryString.parse(queryParams);
  for (const item in obj) {
    if (obj[item]) {
      params[item] = obj[item];
    } else {
      if (!presist) {
        delete oldParams[item];
      }
    }
  }
  const newParams = { ...oldParams, ...params };
  if (newParams.tags && Array.isArray(newParams.tags) && newParams.tags.length === 0) {
    delete newParams.tags;
  } else if (newParams.tags && Array.isArray(newParams.tags)) {
    newParams.tags = newParams.tags.join(',');
  }
  return {
    pathname: window.location.pathname,
    search: `?${QueryString.stringify(newParams)}`
  };
}

export function getCurrentQueryParams() {
  const queryParams = window.location?.search.slice(1);
  const params = QueryString.parse(queryParams);
  return params;
}

export function doesContainSpecialCharacter(inputField) {
  // const spCharsRegExp = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
  const format = /[!@$%^&*()_\-=\[\]{};':"\\|,<>\/?]+/;
  // const format = /[`^[a-zA-Z0-9 -.\’\_]+$`]/;
  if (inputField.match(format)) {
    return true;
  } else {
    return false;
  }
}

export const formattedCommunication = [
  {
    label: 'Struggling to Communicate',
    value: '1'
  },
  {
    label: 'Intermediate Communication',
    value: '2'
  },
  {
    label: 'Fluent in Communication',
    value: '3'
  }
];

export const formattedBadConduct = [
  {
    label: 'Sounded Disinterested',
    value: '1'
  },
  {
    label: 'Rude/Arrogant Behaviour',
    value: '2'
  },
  {
    label: 'Joined the Interview late',
    value: '3'
  },
  {
    label: 'Inappropriately Dressed',
    value: '4'
  },
  {
    label: 'Suspected Cheating',
    value: '5'
  },
  {
    label: 'None of the Above',
    value: '6'
  }
];
export const formattedGoodConduct = [
  {
    label: 'Joined the call on time',
    value: '1'
  },
  {
    label:
      'Was inquisitive, tried understanding more about Squareboat and the role',
    value: '2'
  },
  {
    label: 'Dressed Appropriately',
    value: '3'
  },
  {
    label: 'Positive/Restpectful Behaviour',
    value: '4'
  },
  {
    label: 'None of the Above',
    value: '5'
  }
];
export const formattedInternalOption = [
  {
    label: 'None',
    value: null
  },
  {
    label:
      'Just another candidate, shortlisted & cleared our selection criteria, worth hiring at our usual standards/package',
    value: '1'
  },
  {
    label:
      'Worth negotiating. Squareboat should make some extra efforts to get the person onboarded.',
    value: '2'
  },
  {
    label:
      'Outstanding candidate, Squareboat MUST get this candidate at all costs, even it means stretching extensively on our usual budgets.',
    value: '3'
  }
];

export const applicantFeedbackOptions = [
  {
    label: 'Proceed',
    value: true
  },
  {
    label: 'Decline',
    value: false
  }
];
export const jobStatusOptions = [
  {
    label: 'Inactive',
    value: '0'
  },
  {
    label: 'Active',
    value: '1'
  }
];
export const modulesForRichText = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false
  }
};
export const formatsForRichText = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link'
];

export const formatsDate = 'MMMM Do, YYYY';
export const formatsDate2 = 'YYYY-MM-DD';
export const formatsTime = 'hh:mm a';
export const formatsDateTime = 'YYYY-MM-DD hh:mm a';
export const formatsDateTimeBackend = 'YYYY-MM-DDTHH:mm:ss';
