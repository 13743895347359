import { Input, Modal } from 'antd';
import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import CancelButton from '../../Buttons/Cancel';
import PrimaryButton from '../../Buttons/Primary';
import interviewerActions from '../../../redux/interviewers/actions';
import userEvent from '@testing-library/user-event';

interface editProps {
  heading?: string;
  id?: any;
  name?: string;
  email?: string;
  loading?: boolean;
  title?: string;
}

interface InterviewerData {
  name: string | undefined;
  email: string | undefined;
  jobTitle: string | undefined;
}

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading
});

const EditModal: FC<editProps> = ({
  heading,
  id,
  name,
  email,
  loading,
  title
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [initialInterviewerData, setInitialInterviewerdata] =
    useState<InterviewerData>({
      name: '',
      email: '',
      jobTitle: ''
    });
  const [jobTitle, setJobTitle] = useState<string | undefined>('');
  const [userName, setName] = useState<string | undefined>('');
  const [userEmail, setEmail] = useState<string | undefined>('');
  const [isInterviewerUpdated, setIsInterviewerUpdated] =
    useState<boolean>(false);

  const [errors, setErrors] = useState({
    jobTitle: '',
    name: '',
    email: ''
  });

  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
    resetFields();
  };

  const resetFields = () => {
    setName(initialInterviewerData.name);
    setEmail(initialInterviewerData.email);
    setJobTitle(initialInterviewerData.jobTitle);
    setErrors({
      jobTitle: '',
      name: '',
      email: ''
    });
  };

  useEffect(() => {
    setName(name);
    setEmail(email);
    setJobTitle(title);
    setInitialInterviewerdata({
      name: name,
      email: email,
      jobTitle: title
    });
  }, [email, name, title]);

  useEffect(() => {
    if (isInterviewerUpdated) {
      setInitialInterviewerdata({
        name: userName,
        email: userEmail,
        jobTitle: jobTitle
      });
      setIsInterviewerUpdated(false);
    }
  }, [userEmail, userName, jobTitle, isInterviewerUpdated]);

  const onSave = () => {
    const errorsObject = {
      jobTitle: jobTitle === '' ? 'Job Title is required' : '',
      name: userName === '' ? 'Name is required' : '',
      email:
        userEmail === ''
          ? 'Email is required'
          : !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              userEmail || ''
            )
          ? 'Please enter valid email'
          : ''
    };
    setIsInterviewerUpdated(false);
    if (
      errorsObject.jobTitle === '' &&
      errorsObject.name === '' &&
      errorsObject.email === ''
    ) {
      dispatch({
        type: interviewerActions.UPDATE_INTERVIEWERS_PROFILE,
        payload: {
          id,
          body: {
            name: userName,
            job_title: jobTitle,
            email: userEmail
          }
        }
      });
      handleCancel();
      setIsInterviewerUpdated(true);
    }
    setErrors(errorsObject);
  };

  return (
    <>
      <div
        className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
        onClick={showModal}
      >
        <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue font-semibold' />
      </div>
      <Modal
        className='edit-modal'
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <div className='flex justify-between '>
          <div className='md:px-8'>
            <h3 className='text-xl text-center uppercase font-semibold text-blue-radic'>
              {heading}
            </h3>
          </div>

          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleCancel}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>

        <div className='pt-8 md:px-8 -space-y-2 relative'>
          <p className='text-blue-streak text-base uppercase opacity-50'>
            {'Name'}
            <span className='text-lg text-star-red pl-1'>*</span>
          </p>
          <Input
            onChange={(e) => {
              let val = e.target.value;
              setName(val);
            }}
            className='edit-input text-base'
            value={userName}
          />
          {errors.name !== '' && (
            <div className='error-message absolute bottom-[-20px]'>
              {errors.name}
            </div>
          )}
        </div>

        <div className='pt-8 md:px-8 -space-y-2 relative'>
          <p className='text-blue-streak text-base uppercase opacity-50'>
            {'Email'}
            <span className='text-lg text-star-red pl-1'>*</span>
          </p>
          <Input
            onChange={(e) => {
              let val = e.target.value;
              setEmail(val);
            }}
            className='edit-input text-base'
            value={userEmail}
          />
          {errors.email !== '' && (
            <div className='error-message absolute bottom-[-20px]'>
              {errors.email}
            </div>
          )}
        </div>

        <div className='pt-8 md:px-8 -space-y-2 relative'>
          <p className='text-blue-streak text-base uppercase opacity-50'>
            {'Job Title'}
            <span className='text-lg text-star-red pl-1'>*</span>
          </p>
          <Input
            onChange={(e) => {
              let val = e.target.value;
              setJobTitle(val);
            }}
            className='edit-input text-base'
            value={jobTitle}
          />
          {errors.jobTitle !== '' && (
            <div className='error-message absolute bottom-[-20px]'>
              {errors.jobTitle}
            </div>
          )}
        </div>
        <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
          <PrimaryButton text='Save' onClick={onSave} />
          <CancelButton onClick={handleCancel} />
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(EditModal);
