import { Select, Slider, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import filterActions from '../../redux/filters/actions';
import applicationActions from '../../redux/applications/actions';
import { history } from '../../utilities/configureAxios';
import { getSearchParamsUrl } from '../../helper';
import CoolOffSelect from '../Select/CoolOffSelect';
import { capitalize } from 'lodash';
import { sortByOptions, sortOrder } from '../../constants/HelperConstants';
import { useLocation } from 'react-router-dom';

const mapStateToProps = (state: any) => ({
  filters: state.filters.filters,
  active: state.filters.active,
  search: state.applications.search,
  minYear: state.filters.minYear,
  maxYear: state.filters.maxYear,
  minCtc: state.filters.minCtc,
  maxCtc: state.filters.maxCtc,
  minRating: state.filters.minRating,
  maxRating: state.filters.maxRating,
  tags: state.filters.tags,
  sort: state.filters.sort,
  order: state.filters.order,
  allTags: state.applications.allTags
});
interface Filterprops {
  filters: any;
  active: any;
  search: any;
  minYear: number;
  maxYear: number;
  minCtc: number;
  maxCtc: number;
  minRating: number;
  maxRating: number;
  tags: any[];
  location: any;
  sort: any;
  order: any;
  allTags: {
    code: number;
    data: [];
    message: string;
    success: boolean;
  };
}

const Filter: React.FC<Filterprops> = ({
  minCtc,
  minYear,
  maxCtc,
  maxYear,
  minRating,
  maxRating,
  tags,
  location,
  sort,
  order,
  allTags
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [selectedSortOrder, setSelectedSortOrder] = useState<string | null>(
    'desc'
  );
  const [isDefaultSortValue, setIsDefaultSortValue] = useState<boolean>(true);
  const locationURL = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(locationURL.search);
    const hasSortParam = searchParams.has('sort');
    const hasOrderParam = searchParams.has('order');

    if (hasSortParam && hasOrderParam) {
      setIsDefaultSortValue(false);
    } else {
      setIsDefaultSortValue(true);
    }
  }, [locationURL]);

  useEffect(() => {
    setSelectedTags(tags);
  }, [tags]);

  useEffect(() => {
    setSelectedSortBy(sort);
  }, [sort]);

  useEffect(() => {
    if (order) {
      setSelectedSortOrder(order);
    } else {
      setSelectedSortOrder('desc');
    }
  }, [order]);

  useEffect(() => {
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        minYear,
        maxYear,
        minCtc,
        maxCtc,
        minRating,
        maxRating,
        tags
      }
    });
  }, []);

  const debouncedFn = (value: any) => {
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        minYear: value[0],
        maxYear: value[1],
        isFilterApplied: true
      }
    });
    history.push(
      getSearchParamsUrl({
        graduation_gte: value[0],
        graduation_lte: value[1],
        page: 1
      }),
      {}
    );
  };

  const debouncedCtc = (value: any) => {
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        minCtc: value[0],
        maxCtc: value[1],
        isFilterApplied: true
      }
    });
    history.push(
      getSearchParamsUrl({
        ctc_gte: value[0] === 0 ? '0' : value[0] * 100000,
        ctc_lte: value[1] === 0 ? '0' : value[1] * 100000,
        page: 1
      }),
      {}
    );
  };

  const debouncedCvRating = (value: any) => {
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        minRating: value[0],
        maxRating: value[1],
        isFilterApplied: true
      }
    });
    history.push(
      getSearchParamsUrl({
        cv_gte: value[0] === 0 ? '0' : value[0],
        cv_lte: value[1] === 0 ? '0' : value[1],
        page: 1
      }),
      {}
    );
  };

  function onAfterChange() {}

  const getCtc = (value: any) => {
    if (value > 1) {
      return 'lakhs';
    } else {
      return 'lakh';
    }
  };

  const handleTagChange = (value: string[]) => {
    setSelectedTags(value);
    dispatch({
      type: filterActions.SET_STATE,
      payload: {
        tags: value,
        isFilterApplied: true
      }
    });
    history.push(
      getSearchParamsUrl({
        tags: value,
        page: 1
      }),
      {}
    );
  };

  const CustomTagRender = (props: any) => {
    const { label, value, onClose, selectedTags } = props;
    const index = selectedTags.findIndex((tag: any) => tag === value);

    if (index < 2) {
      return (
        <Tag
          className='my-tag'
          closable
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      );
    }

    if (index === 2) {
      return (
        <Tag className='my-tag' style={{ marginRight: 3 }}>{`+${
          selectedTags.length - 2
        }`}</Tag>
      );
    }

    return null;
  };

  const children: React.ReactNode[] = [];
  allTags &&
    allTags?.data?.map((item: any) => {
      children.push(
        <Option key={`${item.name}`}>{capitalize(item.name)}</Option>
      );
    });

  const handleSortByChange = (value: any) => {
    if (value === null) {
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          sort: null,
          order: null,
          isFilterApplied: true
        }
      });
      history.push(
        getSearchParamsUrl({
          sort: null,
          order: null,
          page: 1
        }),
        {}
      );
    } else {
      const values = value.split(',');
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          sort: value,
          order: values[1],
          isFilterApplied: true
        }
      });
      history.push(
        getSearchParamsUrl({
          sort: values[0],
          order: values[1],
          page: 1
        }),
        {}
      );
    }
  };

  return (
    <>
      <div className='flex flex-col bg-ghost-white dropdown-container'>
        <div className='grid grid-cols-3 gap-x-16'>
          <div className=''>
            <p className='text-blue-streak opacity-50 text-base font-semibold mb-2'>
              Graduation Year
            </p>
            <Slider
              range
              min={1990}
              max={2030}
              step={1}
              value={[minYear, maxYear]}
              tooltipPlacement='bottom'
              tipFormatter={(value) => `${value}`}
              getTooltipPopupContainer={(triggerNode) => triggerNode}
              onChange={debouncedFn}
              onAfterChange={onAfterChange}
              tooltipVisible
            />
          </div>

          <div className=''>
            <p className='uppercase text-blue-streak opacity-50 text-base font-semibold mb-2'>
              CTC
            </p>
            <Slider
              range
              min={0}
              max={100}
              step={1}
              tooltipPlacement='bottom'
              tipFormatter={(value) => `${value} ${getCtc(value)}`}
              getTooltipPopupContainer={(triggerNode) => triggerNode}
              value={[minCtc, maxCtc]}
              onChange={debouncedCtc}
              onAfterChange={onAfterChange}
              tooltipVisible
            />
          </div>

          <div className=''>
            <p className='text-blue-streak opacity-50 text-base font-semibold mb-2'>
              CV Rating
            </p>
            <Slider
              range
              min={0}
              max={10}
              step={1}
              tooltipPlacement='bottom'
              tipFormatter={(value) => `${value}`}
              getTooltipPopupContainer={(triggerNode) => triggerNode}
              value={[minRating, maxRating]}
              onChange={debouncedCvRating}
              onAfterChange={onAfterChange}
              tooltipVisible
            />
          </div>
        </div>

        <div className='grid grid-cols-3 gap-x-16 mt-16'>
          <div className='col-span-1'>
            <p className='text-blue-streak opacity-50 text-base font-semibold mb-2'>
              Cool Off Status
            </p>
            <CoolOffSelect />
          </div>
          <div className='col-span-2'>
            <p className='text-blue-streak text-base font-semibold opacity-50 mb-2'>
              Tags Applied
            </p>
            <div className='flex flex-col shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
              <Select
                mode='multiple'
                allowClear
                className='filter-multi-select w-full text-base'
                placeholder='Select Tags to apply filter'
                value={selectedTags}
                onChange={handleTagChange}
                style={{ width: '100%', overflow: 'auto' }}
                tagRender={(props) => (
                  <CustomTagRender {...props} selectedTags={selectedTags} />
                )}
              >
                {children}
              </Select>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-x-16 mt-4'>
          <div className='col-span-2'>
            <p className='text-blue-streak opacity-50 text-base font-semibold mb-2'>
              Sort By
            </p>
            <div className='shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
              <Select
                className={`${
                  isDefaultSortValue && 'faded-select'
                } filter-select w-full text-base`}
                options={sortByOptions}
                placeholder='Select Sort Entity'
                value={selectedSortBy}
                onChange={handleSortByChange}
              ></Select>
            </div>
          </div>
          {/* <div className='col-span-1'>
            <p className='text-blue-streak text-base font-semibold opacity-50 mb-2'>
              Sort Order
            </p>
            <div className='shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
              <Select
                className={`${
                  isDefaultSortValue && 'faded-select'
                } filter-select text-base w-full`}
                options={sortOrder}
                disabled={!selectedSortBy}
                placeholder='High To Low'
                value={selectedSortOrder}
                defaultValue={selectedSortOrder}
                onChange={handleSortOrderChange}
              ></Select>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Filter);
