import { Switch, Route, Redirect } from 'react-router-dom';
import AllCandidates from '../page/all-candidates';
import individualCandidate from '../page/individual-candidate';
import IndividualInterview from '../page/individual-interview';
import Interviewer from '../page/interviewer';
import Tasks from '../page/tasks';
import { history } from '../utilities/configureAxios';
import ErrorPage from '../page/404Error';
import TooManyRequest from '../page/429Error';
import AllReferrals from '../page/all-referrals';
import Categories from '../page/categories';
import {
  DASHBOARD_ALL_CANDIDATES,
  DASHBOARD_INTERVIEWER,
  DASHBOARD_JOBS,
  DASHBOARD_REFERRALS,
  DASHBOARD_TASKS,
  INDIVIDUAL_CANDIDATES,
  DASHBOARD_ANALYTICS,
  DASHBOARD_CATEGORIES,
  DASHBOARD_QUESTIONS,
  DASHBOARD_INTERVIEW_GENERATION,
  // INDIVIDUAL_INTERVIEW,
  VIEW_FEEDBACK,
  DASHBOARD_INTERVIEWS,
  DASHBOARD_INTERVIEW_ADD,
  DASHBOARD_INTERVIEW_VIEW,
  DASHBOARD_INTERVIEW_EDIT,
  DASHBOARD_JOB_DEPARTMENT,
  DASHBOARD_JOB_LOCATON,
  DASHBOARD_ADD_JOB,
  DASHBOARD_EDIT_JOB,
  DASHBOARD_HIRING_TRACKER,
  DASHBOARD_SINGLE_FEEDBACK,
  DASHBOARD_ALL_FEEDBACK,
  DASHBOARD_VIEW_QUESTION_SET,
  DASHBOARD_EMAILS
} from '../constants/RouteConstants';
import ANALYTICS from '../page/analytics';
import Questions from '../page/Questions';
import ViewFeedback from '../page/ViewFeedback';
import InterviewGeneration from '../page/InterviewGeneration';
import AllFeedback from '../page/all-feedback';
import ViewQuestionSet from '../page/ViewQuestionSet';
import EditFeedback from '../page/EditFeedback';
import EmployeeInterviews from '../page/EmployeeInterviews';
import JobLocation from '../page/JobLocation';
import JobDepartment from '../page/JobDepartment';
import Jobs from '../page/Jobs';
import AddJob from '../page/Jobs/AddJob/AddJob';
import HiringTracker from '../page/HiringTracker';
import EmailTemplates from '../page/EmailTemplates';

function Hr() {
  return (
    <Switch location={history.location}>
      <Route path={DASHBOARD_ALL_CANDIDATES} component={AllCandidates} exact />
      <Route path={DASHBOARD_ANALYTICS} component={ANALYTICS} exact />
      <Route path={DASHBOARD_TASKS} component={Tasks} exact />
      {/* <Route path={DASHBOARD_JOBS} component={Setting} exact /> */}
      {/* <Route path={DASHBOARD_JOBS} component={Setting} exact /> */}
      <Route
        path={INDIVIDUAL_CANDIDATES}
        component={individualCandidate}
        exact
      />
      <Route path={DASHBOARD_INTERVIEWER} component={Interviewer} exact />
      <Route path={DASHBOARD_REFERRALS} component={AllReferrals} exact />
      <Route path={DASHBOARD_CATEGORIES} component={Categories} exact />
      <Route path={DASHBOARD_QUESTIONS} component={Questions} exact />
      <Route
        path={DASHBOARD_INTERVIEW_GENERATION}
        component={InterviewGeneration}
        exact
      />
      <Route path={DASHBOARD_SINGLE_FEEDBACK} component={ViewFeedback} exact />
      <Route path={DASHBOARD_ALL_FEEDBACK} component={AllFeedback} exact />
      <Route
        path={DASHBOARD_VIEW_QUESTION_SET}
        component={ViewQuestionSet}
        exact
      />
      <Route path={DASHBOARD_INTERVIEWS} component={EmployeeInterviews} exact />
      <Route
        path={DASHBOARD_INTERVIEW_ADD}
        component={IndividualInterview}
        exact
      />
      <Route path={DASHBOARD_INTERVIEW_VIEW} component={ViewFeedback} exact />
      <Route path={DASHBOARD_INTERVIEW_EDIT} component={EditFeedback} exact />
      <Route path={DASHBOARD_JOB_LOCATON} component={JobLocation} exact />
      <Route path={DASHBOARD_JOB_DEPARTMENT} component={JobDepartment} exact />
      <Route path={DASHBOARD_JOBS} component={Jobs} exact />
      <Route path={DASHBOARD_ADD_JOB} component={AddJob} exact />
      <Route path={DASHBOARD_EDIT_JOB} component={AddJob} exact />
      <Route path={DASHBOARD_HIRING_TRACKER} component={HiringTracker} exact />
      <Route path={DASHBOARD_EMAILS} component={EmailTemplates} exact />
      <Route
        path='/429'
        render={() => (
          <div className='page-container flex'>
            <TooManyRequest />
          </div>
        )}
      />
      {/* <Redirect to="/429" /> */}
      <Route
        path='/404'
        render={() => (
          <div className='page-container flex'>
            <ErrorPage />
          </div>
        )}
      />
      <Redirect to='/404' />
    </Switch>
  );
}
export default Hr;
