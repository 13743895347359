import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import axios from "axios";
import apiClient from "../../../utilities/configureAxios";
interface Ipdf {
  file?: any;
  title?: any;
  unmountIframe?: boolean;
  mimeType?: any;
  frameload?: any;
}

const PDF: React.FC<Ipdf> = ({
  file,
  title,
  // unmountIframe,
  mimeType,
  // frameload,
}) => {
  const [resume, setResume] = useState(encodeURIComponent(file));
  // const [cvload, setCvLoad] = useState(true);

  useEffect(() => {
    if (file) {
      setResume(encodeURIComponent(file));
    }
    return () => {
      setResume("");
    };
  }, [file]);
  // useEffect(() => {
  //   style = open ? { opacity: "0.3" } : { opacity: "1" };
  // }, [open]);

  // useEffect(() => {
  //   if (unmountIframe) {
  //     setCvLoad(true);
  //   }
  // }, [unmountIframe]);

  // function frameload() {
  //   setCvLoad(false);
  // }

  return (
    <div className="relative w-full h-full flex flex-col items-center">
      <div
        className="h-full w-full absolute left-0 top-0 flex justify-center items-center"
        id="loading"
      >
        {/* Loading... */}
        {/* {<Spin size="large" className="table-loader" spinning={cvload}></Spin>} */}
      </div>
      {file && (
        <>
          {!mimeType || mimeType === "application/pdf" ? (
            <iframe
              // onLoad={frameload}
              // src={resume}
              src={`${file}#view=Fith`}
              width="100%"
              height="100%"
              title={`${title}'s Resume`}
              style={{ opacity: "1" }}
              id={`${title}-iframe`}
            />
          ) : (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${resume}`}
              // onLoad={frameload}
              width="100%"
              height="100%"
              title={`${title}'s Resume`}
              style={{
                opacity: "1",
              }}
              id={`${title}-iframe`}
            />
          )}
        </>
      )}
    </div>
  );
};
export default PDF;

// position: "absolute",
// right: 0,
// top: 0,
// bottom: 0,
// width: "100%",
