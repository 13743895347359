import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
interface QuilInterfce extends ReactQuillProps {
    errorJsx?: ReactNode;
}
const RecatQuilEditor = (props: QuilInterfce) => {
    const [first, setFirst] = useState(true);
    useEffect(() => {
        setFirst(false);
    }, []);
    if (first) {
        return null;
    }

    return (
        <Fragment>
            <ReactQuill {...props} />
            {props.errorJsx}
        </Fragment>
    );
};

export default RecatQuilEditor;
