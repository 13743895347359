import { connect } from "react-redux";
import { Input } from "antd";
import { useEffect, useState } from "react";
import { FiSend } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";

const mapStateToProps = (state: any) => ({
  profilePic: state.auth.profilePic,
  applicationNotes: state.applications.applicationNotes,
});

const { TextArea } = Input;

interface IEmptyComment {
  profilePic: any;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onPost: any;
  applicationNotes: any;
  isEdit: boolean;
  commentId: string;
}
const EmptyComment: React.FC<IEmptyComment> = ({
  profilePic,
  onPost,
  setEdit,
  applicationNotes,
  isEdit,
  commentId,
}) => {
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    if (isEdit && commentId) {
      const comment = applicationNotes.filter(
        (item: any) => item.id === commentId
      )[0];
      setCommentText(comment.note);
    } else {
      setCommentText("");
    }
  }, [applicationNotes, commentId, isEdit]);

  return (
    <div className="mb-2">
      <div className="flex">
        <div className="mr-4">
          <div
            style={{
              width: "40px",
              height: "40px",
            }}
          >
            <img
              src={profilePic || "/user-pic.svg"}
              alt=""
              className="object-cover cursor-pointer rounded-full"
            />
          </div>
        </div>

        <div className="w-full border-solid border border-[#BDBBC766] rounded px-2 flex bg-[#F4F7FD66]">
          <TextArea
            placeholder="Comment"
            rows={1}
            name="comment"
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (event.shiftKey) return;
                else {
                  event.preventDefault();
                  onPost(commentText);
                }
              }
            }}
            className="emptyCommentInput"
          />
          <div className="flex items-center justify-end">
            <button
              className="border-none cursor-pointer"
              disabled={!commentText}
              onClick={() => {
                onPost(commentText);
              }}
            >
              <FiSend color="#2D94F3" fontSize="20px" />
            </button>
            {isEdit && (
              <AiOutlineCloseCircle
                color="#2D94F3"
                className="ml-2"
                fontSize="20px"
                cursor="pointer"
                onClick={() => {
                  setCommentText("");
                  setEdit(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(EmptyComment);
