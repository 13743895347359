import { Pagination, Spin, Tooltip } from 'antd';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import DeleteTaskModal from '../../Modal/Delete/deleteTask';
import EditTask from '../../Modal/Edit/EditTask';
import taskActions from '../../../redux/tasks/actions';
import AddTaskModal from '../../Modal/AddTask/AddTaskModal';

const mapStateToProps = (state: any) => ({
  tasks: state.tasks.tasks,
  loading: state.auth.loading
});
interface ITasks {
  tasks?: any;
  loading: any;
}
const TaskTable: React.FC<ITasks> = ({ tasks, loading }) => {
  const dispatch = useDispatch();
  const handleChange = (value: any) => {
    dispatch({
      type: taskActions.GET_ALL_TASKS,
      payload: { page: value }
    });
  };
  return (
    <Spin size='large' className='hrTable-loader' spinning={loading}>
      <div className='flex flex-col'>
        <div className='shadow-lg overflow-auto min-h-[80%] bg-white rounded-2xl mt-[20px]'>
          <table className='w-full table_transition'>
            {tasks && tasks?.data?.length > 0 && (
              <thead className='setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                <tr className='text-[0.9rem] px-2'>
                  <th className='py-4 w-[10%] text-center text-[#233A5C] text-opacity-50'>
                    S.No
                  </th>
                  <th className='py-4 w-[80%] text-[#233A5C] text-left text-opacity-50'>
                    Task Title
                  </th>
                  <th className='py-4 w-[20%] text-[#233A5C] text-center text-opacity-50'>
                    Action
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {tasks &&
                tasks?.data?.length > 0 &&
                tasks?.data?.map((task: any, i: any) => (
                  <tr
                    key={task.uuid}
                    className='text-base border-bottom cursor-pointer text-blue-zodiac table-text  font-normal capitalize mb-0  whitespace-nowrap overflow-auto'
                  >
                    <td className='py-2 pr-2 w-[10%] text-center text-overflow-none'>
                      {tasks?.meta?.pagination?.perPage *
                        (tasks?.meta?.pagination?.page - 1) +
                        i +
                        1}
                    </td>
                    <td className='py-2 pr-2 w-[10%]  text-overflow-none'>
                      <Tooltip placement='bottomLeft' title={task.title}>
                        {task.title}
                      </Tooltip>
                    </td>
                    <td className='py-2 pb-2 flex justify-around'>
                      <EditTask
                        page={tasks?.meta?.pagination?.page}
                        heading='Edit Task'
                        id={task.uuid}
                      />
                      <DeleteTaskModal
                        heading='Delete Task'
                        desc='Are you sure you want to delete this task from the ATS?'
                        userId={task.uuid}
                        page={tasks?.meta?.pagination?.page}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {tasks && tasks?.data?.length === 0 && (
            <div className='w-full min-h-[70vh] text-blue-zodiac font-medium flex flex-col justify-center items-center'>
              <div>
                <img src='/TableEmptyState.svg' alt='' />
              </div>
              <p className='max-w-[350px] text-center text-[#0E254B] text-opacity-40'>
                No tasks have been added yet. Click on the button to add a task.
              </p>
              <AddTaskModal heading='Add Task' />
            </div>
          )}
        </div>
        {tasks && tasks?.data?.length > 0 && (
          <div className='px-4 lg:px-12 xl:px-16 flex flex-col  justify-center mt-5 mb-10'>
            <div className='flex justify-center py-5'>
              <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                current={tasks?.meta?.pagination?.page}
                defaultPageSize={tasks?.meta?.pagination?.perPage}
                onChange={handleChange}
                total={tasks?.meta?.pagination?.count}
              />
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default connect(mapStateToProps)(TaskTable);
