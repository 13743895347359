import { Button, Input, Modal, Pagination, Spin, Tag } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import PrimaryButton from '../../components/Buttons/Primary';
import Header from '../../components/Header';
import categoryActions from '../../redux/questionBank/actions';
import { AiOutlineClose } from 'react-icons/ai';
import CancelButton from '../../components/Buttons/Cancel';
import DangerButton from '../../components/Buttons/Danger';
import {
  doesContainSpecialCharacter,
  getCurrentQueryParams,
  getSearchParamsUrl
} from '../../helper';
import { BiSync } from 'react-icons/bi';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Tooltip from 'antd/es/tooltip';

const mapStateToProps = (state: any) => ({
  allCategories: state.allCategories.allCategories,
  loading: state.auth.loading
});

const Categories = ({ allCategories, loading }: any) => {
  const [inputField, setInputField] = useState<string>('');
  const [categoryToggle, setCategoryToggle] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isAddVisible, setIsAddVisible] = useState<boolean>(false);
  const [editTag, setEditTag] = useState('');
  const [currentTag, setCurrentTag] = useState<any>({});
  const [currentTagId, setCurrentTagId] = useState<any>('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, q } = getCurrentQueryParams();

  function handleInputChange(event: any) {
    setInputField(event.target.value);
  }

  function handleAdd() {
    let body = {
      name: inputField
    };
    dispatch({
      type: categoryActions.ADD_A_CATEGORY,
      payload: body
    });
    setInputField('');
    setIsAddVisible(false);
  }

  function handleDeleteTag(element: any, itm: any) {
    element.stopPropagation();
    setCurrentTagId(itm.uuid);
    setIsVisibleDeleteModal(true);
  }

  useEffect(() => {
    dispatch({
      type: categoryActions.GET_ALL_CATEGORIES,
      payload: {
        page: page,
        q: q
      }
    });
  }, [page, q]);

  const handleClick = (itm: any) => {
    setIsVisible(true);
    setCurrentTag(itm);
    setEditTag(itm.name);
  };

  const onCancel = () => {
    setIsVisible(false);
    setCurrentTag(null);
  };

  const onAddCancel = () => {
    setIsAddVisible(false);
    setInputField('');
  };

  const handleAddCategories = () => {
    setIsAddVisible(true);
  };

  const onCancelDeleteModal = () => {
    setIsVisibleDeleteModal(false);
  };

  const handleEditChange = (event: any) => {
    setEditTag(event?.target.value);
  };

  const handleEditTag = () => {
    let body = {
      uuid: currentTag?.uuid,
      name: editTag
    };
    dispatch({
      type: categoryActions.EDIT_A_CATEGORY,
      payload: body
    });
    setIsVisible(false);
  };

  function handleDeleteButton() {
    let body = {
      //pass current tag here
      uuid: currentTagId
    };
    dispatch({
      type: categoryActions.DELETE_A_CATEGORY,
      payload: body
    });
    setIsVisibleDeleteModal(false);
  }

  useEffect(() => {
    if (currentTag) {
      setEditTag(currentTag?.name);
    }
  }, [currentTag]);

  const handlePageChange = (page: number) => {
    history.push(getSearchParamsUrl({ page: page, q: q }), {});
  };

  return (
    <>
      <>
        <div className='w-full h-screen overflow-y-auto bg-ghost-white relative'>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Categories</title>
          </Helmet>
          <Header />
          <div className='flex justify-end m-8'>
            <PrimaryButton
              onClick={handleAddCategories}
              text='Add Categories'
            />
          </div>
          {!!!allCategories?.data?.length &&
          (q === undefined ? true : !!!(q as string)?.trim()?.length) ? (
            <Spin size='large' className='hrTable-loader' spinning={loading}>
              <section>
                <div className='flex justify-center'>
                  <img
                    className='max-h-[300px]'
                    src='/categories.svg'
                    alt='No Category'
                  />
                </div>
                <br />
                <div className='text-center font-semibold flex justify-center text-[#0E254B] text-opacity-40 text-[20px]'>
                  No categories has been added yet. <br />
                  Click on the button to add categories.
                </div>
                <br />
                {/* <div className="text-center">
                        <PrimaryButton onClick={handleAddClick} text="Add Question" />
                    </div> */}
              </section>
            </Spin>
          ) : (
            <Spin size='large' className='hrTable-loader' spinning={loading}>
              <div className='flex flex-col'>
                <div className='bg-white shadow-lg mx-8 overflow-auto rounded-2xl min-h-[75%]'>
                  <table className='w-full table_transition'>
                    <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                      <tr className='px-2'>
                        <th className='text-[#233A5C] text-center text-opacity-50 py-4 w-[10%] max-w-[10%]'>
                          S.No
                        </th>
                        <th className='py-4 w-[80%] text-[#233A5C] text-left text-opacity-50'>
                          Category Name
                        </th>
                        <th className='py-4 w-[10%] text-[#233A5C] text-left text-opacity-50'>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allCategories &&
                        allCategories?.data?.length > 0 &&
                        allCategories?.data?.map((itm: any, i: any) => (
                          <tr
                            key={itm.uuid}
                            className='text-base border-bottom text-blue-zodiac table-text font-normal capitalize mb-0 text-overflow-none whitespace-nowrap overflow-auto'
                          >
                            <td className='py-2 text-center'>
                              {/* {allCategories?.meta?.pagination?.perPage *
                                (allCategories?.meta?.pagination?.page - 1) +
                                i +
                                1} */}
                              {(Number(page ? page : 1) - 1 === 0
                                ? 0
                                : (Number(page ? page : 1) - 1) * 10) +
                                i +
                                1}
                            </td>
                            <td className='py-2'>
                              <Tooltip title={itm.name}>{itm.name}</Tooltip>
                            </td>
                            <td className='flex py-2 pr-2 text-overflow-none overflow-ellipsis mr-2'>
                              <div
                                className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
                                onClick={() => handleClick(itm)}
                              >
                                <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue' />
                              </div>
                              &nbsp;&nbsp;
                              <div
                                className='w-8 h-8 bg-background-red rounded-md flex items-center justify-center cursor-pointer'
                                onClick={(element: any) =>
                                  handleDeleteTag(element, itm)
                                }
                              >
                                <MdDeleteOutline className='w-5 h-5 text-tamato' />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {allCategories && allCategories?.data?.length === 0 && (
                    <div className='w-full xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center'>
                      Oops! No Categories to show
                    </div>
                  )}
                </div>
                <div className='px-4 lg:px-12 xl:px-16 flex   justify-center mt-5 mb-10'>
                  <div className='flex justify-center py-5'>
                    <Pagination
                      showSizeChanger={false}
                      defaultCurrent={1}
                      current={allCategories?.meta?.pagination?.page}
                      defaultPageSize={allCategories?.meta?.pagination?.perPage}
                      onChange={(page) => handlePageChange(page)}
                      total={allCategories?.meta?.pagination?.count}
                    />
                  </div>
                </div>
              </div>
            </Spin>
          )}
        </div>
        {/* //----delete category confirmation modal---------------------------- */}
        <Modal
          destroyOnClose={true}
          open={isVisibleDeleteModal}
          onCancel={onCancelDeleteModal}
          width='500px'
          className='edit-modal relative'
          centered
          bodyStyle={{
            backgroundColor: '#FAFDFF',
            borderRadius: '0.25rem'
          }}
        >
          <div className='flex items-center justify-between mb-2'>
            <div className='text-xl text-center font-semibold text-blue-zodiac'>
              Delete Category
            </div>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-[-0.75rem] right-[-0.75rem]'
              onClick={onCancelDeleteModal}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>
          <div className='text-base text-blue-radic text-center lg:text-left mb-4'>
            Are you sure you want to delete this category?
          </div>

          <div className='flex justify-end'>
            <div className='mr-4'>
              <DangerButton onClick={handleDeleteButton} />
            </div>
            <div>
              <CancelButton onClick={onCancelDeleteModal} text='Cancel' />
            </div>
          </div>
        </Modal>
        {/* //edit category modal */}
        <Modal
          destroyOnClose={true}
          open={isVisible}
          centered
          onCancel={onCancel}
          width='500px'
          className='edit-modal relative'
          bodyStyle={{
            backgroundColor: '#FAFDFF',
            borderRadius: '0.25rem'
          }}
        >
          <div className='flex items-center justify-between '>
            <div className='text-xl text-center font-semibold text-blue-zodiac mb-2'>
              Edit Category
            </div>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-[-0.75rem] right-[-0.75rem]'
              onClick={onCancel}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>
          <p className='applicant-modal-label'>
            {'Category Name'}
            <span className='text-lg text-star-red pl-1'>*</span>
          </p>
          <Input
            value={editTag}
            style={{
              borderRadius: '4px',
              height: '45px',
              marginBottom: '1rem'
            }}
            onChange={(event) => handleEditChange(event)}
            placeholder='Enter a new category name'
          />
          <div className='flex justify-end'>
            <div className='mr-4'>
              <PrimaryButton text='Save' onClick={handleEditTag} />
            </div>
            <div>
              <CancelButton onClick={onCancel} text='Cancel' />
            </div>
          </div>
        </Modal>
        {/* //Add category modal */}
        <Modal
          destroyOnClose={true}
          open={isAddVisible}
          onCancel={onAddCancel}
          width='500px'
          className='edit-modal relative '
          centered
          bodyStyle={{
            backgroundColor: '#FAFDFF',
            borderRadius: '0.25rem'
          }}
        >
          <div className='flex items-center justify-between '>
            <div className='text-xl text-center font-semibold text-blue-zodiac mb-2'>
              Add Category
            </div>
            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-[-0.75rem] right-[-0.75rem]'
              onClick={onAddCancel}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>
          <p className='applicant-modal-label'>
            {'Category Name'}
            <span className='text-lg text-star-red pl-1'>*</span>
          </p>
          <Input
            value={inputField}
            style={{
              borderRadius: '4px',
              height: '45px',
              marginBottom: '1rem'
            }}
            onChange={(event) => handleInputChange(event)}
            placeholder='Enter Category'
          />
          <div className='flex justify-end'>
            <div className='mr-4'>
              <PrimaryButton
                disabled={
                  !!!inputField ||
                  inputField.length > 30 ||
                  inputField.trim().length < 2 ||
                  doesContainSpecialCharacter(inputField)
                }
                onClick={handleAdd}
                text='Add'
              />
            </div>
            <div>
              <CancelButton onClick={onAddCancel} text='Cancel' />
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default connect(mapStateToProps)(Categories);
