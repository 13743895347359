import {
  AiOutlineWechat,
  AiTwotoneContainer,
  AiTwotoneSnippets
} from 'react-icons/ai';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { FaListAlt, FaTasks, FaUsers, FaUserTie } from 'react-icons/fa';
import { MdLocationOn, MdSpaceDashboard } from 'react-icons/md';
import { RiBuilding4Fill, RiUserSearchLine } from 'react-icons/ri';
import { TbLayoutGridAdd } from 'react-icons/tb';
import { VscFeedback, VscReferences } from 'react-icons/vsc';
import {
  ADMIN_HR_MANAGEMENT,
  DASHBOARD_ALL_CANDIDATES,
  DASHBOARD_ANALYTICS,
  DASHBOARD_CATEGORIES,
  DASHBOARD_INTERVIEWER,
  DASHBOARD_INTERVIEWS,
  DASHBOARD_INTERVIEW_GENERATION,
  DASHBOARD_JOBS,
  DASHBOARD_JOB_DEPARTMENT,
  DASHBOARD_JOB_LOCATON,
  DASHBOARD_QUESTIONS,
  DASHBOARD_REFERRALS,
  DASHBOARD_TASKS,
  EMPLOYEE_HOME,
  EMPLOYEE_REFERRALS,
  DASHBOARD_HIRING_TRACKER,
  INTERVIEWER_HOME,
  INTERVIEWER_INTERVIEWS,
  INTERVIEWER_REFERRALS,
  DASHBOARD_EMAILS
} from './RouteConstants';
import { CgTemplate } from 'react-icons/cg';

const adminTabs = [
  {
    path: `${DASHBOARD_ANALYTICS}`,
    label: 'Analytics',
    key: 'Analytics',
    icon: <MdSpaceDashboard className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_ALL_CANDIDATES}`,
    label: 'Candidates',
    key: 'Candidates',
    icon: <FaListAlt className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_TASKS}`,
    label: 'Tasks',
    key: 'Tasks',
    icon: <FaTasks className='w-[24px] h-[24px]' />
  },
  {
    path: ``,
    label: 'Job Portal',
    key: 'Job Portal',
    icon: <BsFillBriefcaseFill className='w-[24px] h-[24px]' />,
    children: [
      {
        path: `${DASHBOARD_JOB_LOCATON}`,
        label: 'Locations',
        key: 'Locations',
        icon: <MdLocationOn className='w-[24px] h-[24px]' />
      },
      {
        path: `${DASHBOARD_JOB_DEPARTMENT}`,
        label: 'Departments',
        key: 'Departments',
        icon: <RiBuilding4Fill className='w-[24px] h-[24px]' />
      },
      {
        path: `${DASHBOARD_JOBS}`,
        label: 'Jobs',
        key: 'Jobs',
        icon: <RiUserSearchLine className='w-[24px] h-[24px]' />
      }
    ]
  },
  {
    path: `${DASHBOARD_INTERVIEWER}`,
    label: 'Interviewer',
    key: 'Interviewer',
    icon: <FaUserTie className='w-[24px] h-[24px]' />
  },
  {
    path: `${ADMIN_HR_MANAGEMENT}`,
    label: 'HR Management',
    key: 'HR Management',
    icon: <FaUsers className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_HIRING_TRACKER}`,
    label: 'Hiring Tracker',
    key: 'Hiring Tracker',
    icon: <FaUsers className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_REFERRALS}`,
    label: 'All Referrals',
    key: 'All Referrals',
    icon: <VscReferences className='w-[24px] h-[24px]' />
  },
  {
    path: `${'/dashboard/all-feedbacks'}`,
    label: 'Feedbacks',
    key: 'Feedbacks',
    icon: <VscReferences className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_INTERVIEWS}`,
    label: 'Interviews',
    key: 'Interviews',
    icon: <VscFeedback className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_EMAILS}`,
    label: 'Custom Email Templates',
    key: 'Custom Email Templates',
    icon: <CgTemplate className='w-[24px] h-[24px]' />
  },
  {
    path: ``,
    label: 'Interview Mapping',
    key: 'Interview Mapping',
    icon: <AiTwotoneSnippets className='w-[24px] h-[24px]' />,
    children: [
      {
        path: `${DASHBOARD_CATEGORIES}`,
        label: 'Categories',
        key: 'Categories',
        icon: <TbLayoutGridAdd className='w-[24px] h-[24px]' />
      },
      {
        path: `${DASHBOARD_QUESTIONS}`,
        label: 'Questions',
        key: 'Questions',
        icon: <AiOutlineWechat className='w-[24px] h-[24px]' />
      },
      {
        path: `${DASHBOARD_INTERVIEW_GENERATION}`,
        label: 'Interview Generation',
        key: 'Interview Generation',
        icon: <AiTwotoneContainer className='w-[24px] h-[24px]' />
      }
    ]
  }
];
const hrTabs = [
  {
    path: `${DASHBOARD_ANALYTICS}`,
    label: 'Analytics',
    key: 'Analytics',
    icon: <MdSpaceDashboard className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_ALL_CANDIDATES}`,
    label: 'Candidates',
    key: 'Candidates',
    icon: <FaListAlt className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_TASKS}`,
    label: 'Tasks',
    key: 'Tasks',
    icon: <FaTasks className='w-[24px] h-[24px]' />
  },
  {
    path: ``,
    label: 'Job Portal',
    key: 'Job Portal',
    icon: <BsFillBriefcaseFill className='w-[24px] h-[24px]' />,
    children: [
      {
        path: `${DASHBOARD_JOB_LOCATON}`,
        label: 'Locations',
        key: 'Locations',
        icon: <MdLocationOn className='w-[24px] h-[24px]' />
      },
      {
        path: `${DASHBOARD_JOB_DEPARTMENT}`,
        label: 'Departments',
        key: 'Departments',
        icon: <RiBuilding4Fill className='w-[24px] h-[24px]' />
      },
      {
        path: `${DASHBOARD_JOBS}`,
        label: 'Jobs',
        key: 'Jobs',
        icon: <RiUserSearchLine className='w-[24px] h-[24px]' />
      }
    ]
  },
  {
    path: `${DASHBOARD_HIRING_TRACKER}`,
    label: 'Hiring Tracker',
    key: 'Hiring Tracker',
    icon: <FaUsers className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_INTERVIEWER}`,
    label: 'Interviewer',
    key: 'Interviewer',
    icon: <FaUserTie className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_REFERRALS}`,
    label: 'All Referrals',
    key: 'All Referrals',
    icon: <VscReferences className='w-[24px] h-[24px]' />
  },
  {
    path: `${'/dashboard/all-feedbacks'}`,
    label: 'Feedbacks',
    key: 'Feedbacks',
    icon: <VscReferences className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_INTERVIEWS}`,
    label: 'Interviews',
    key: 'Interviews',
    icon: <VscFeedback className='w-[24px] h-[24px]' />
  },
  {
    path: `${DASHBOARD_EMAILS}`,
    label: 'Custom Email Templates',
    key: 'Custom Email Templates',
    icon: <CgTemplate className='w-[24px] h-[24px]' />
  }
];
const interviewerTabs = [
  {
    path: `${INTERVIEWER_HOME}`,
    label: 'Home',
    key: 'Home',
    icon: <FaUsers className='w-[24px] h-[24px]' />
  },
  {
    path: `${INTERVIEWER_REFERRALS}`,
    label: 'Referrals',
    key: 'Referrals',
    icon: <VscReferences className='w-[24px] h-[24px]' />
  },
  {
    path: `${INTERVIEWER_INTERVIEWS}`,
    label: 'Interviews',
    key: 'Interviews',
    icon: <VscFeedback className='w-[24px] h-[24px]' />
  }
];
const employeeTabs = [
  {
    path: `${EMPLOYEE_HOME}`,
    label: 'Home',
    key: 'Home',
    icon: <FaUsers className='w-[24px] h-[24px]' />
  },
  {
    path: `${EMPLOYEE_REFERRALS}`,
    label: 'Referrals',
    key: 'Referrals',
    icon: <VscReferences className='w-[24px] h-[24px]' />
  }
];

export { adminTabs, hrTabs, interviewerTabs, employeeTabs };
