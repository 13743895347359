import React, { ReactNode, useEffect, useState } from 'react';
import { DatePicker, Input, Select, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailActions from '../../../redux/emailTemplates/actions';
import { capitalize } from 'lodash';
import { TEMPLATE_OPTIONS } from '../../../constants/HelperConstants';
import moment, { Moment } from 'moment';
import FileUpload from '../../FileUpload/EmailTemplateFileUpload';
import { connect } from 'react-redux';
import MultiEmailInput from './MultiEmailInput';
import PrimaryButton from '../../Buttons/Primary';
import { queryStringToJSON } from '../../../utilities/routies';
import { Prompt, useLocation } from 'react-router-dom';

const { TextArea } = Input;

const mapStateToProps = (state: any) => ({
  selectedTemplateStore: state.emailTemplates.selectedTemplateStore,
  formValuesStore: state.emailTemplates.formValues,
  formFieldsStore: state.emailTemplates.formFields,
  emailTemplatesStore: state.emailTemplates.emailTemplates,
  loading: state.auth.loading
});

interface IAddContentForm {
  next: () => void;
  selectedTemplateStore: string;
  formFieldsStore: [];
  emailTemplatesStore: {
    [key: string]: EmailTemplate;
  };
  loading: boolean;
  formValuesStore: { [key: string]: any };
}

interface IFormFields {
  field_name: string;
  type: string;
  name: string;
  input_type?: string;
  required: boolean;
}

interface EmailField {
  field_name: string;
  type: string;
  name: string;
  input_type: string;
  required: boolean;
}

interface EmailTemplate {
  template_name: string;
  template_file: string;
  fields: EmailField[];
}

const createValidationSchema = (fields: EmailField[]) => {
  const shape: any = {};
  fields.forEach((field) => {
    if (field.type === 'text') {
      shape[field.name] = Yup.string().required('This field cannot be empty!');
    }
    // else if (field.type === 'email' && field?.input_type === 'multi') {
    //   shape[field.name] = Yup.array()
    //     .of(Yup.string().email('Invalid email address'))
    //     .min(1, 'At least one email is required')
    //     .required('This field cannot be empty!');
    // }
    else if (field.type === 'email' && field?.input_type === 'single') {
      shape[field.name] = Yup.string()
        .email('Invalid email address')
        .required('This field cannot be empty!');
    } else if (field.type === 'date') {
      shape[field.name] = Yup.date().required('This field cannot be empty!');
    } else if (field.type === 'file' && field.required) {
      shape[field.name] = Yup.string().required('File is required!');
    }
  });
  return Yup.object().shape(shape);
};

const AddContentForm = (props: IAddContentForm) => {
  const {
    next,
    selectedTemplateStore,
    formFieldsStore,
    emailTemplatesStore,
    loading,
    formValuesStore
  } = props;

  const dispatch = useDispatch();
  const { Option } = Select;
  const { search, pathname } = useLocation();
  const { step } = queryStringToJSON(search);

  const [selectedTemplate, setSelectedTemplate] = useState<string>('1');
  const [formFields, setFormFields] = useState<IFormFields[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment | null>();
  const [additionalDate, setAdditionalDate] = useState<Moment | null>();
  const [validationSchema, setValidationSchema] = useState(Yup.object());
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [emailFields, setEmailFields] = useState<{ [key: string]: string[] }>({
    to: [],
    cc: [],
    bcc: []
  });
  const [emailErrors, setEmailErrors] = useState<{ [key: string]: boolean }>({
    to: false,
    cc: false,
    bcc: false
  });
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    dispatch({
      type: emailActions.GET_ALL_EMAIL_TEMPLATES
    });
    if (!step) {
      dispatch({
        type: emailActions.SET_TEMPLATE,
        payload: '1'
      });
    }
  }, [dispatch]);

  useEffect(() => {
    setSelectedTemplate(selectedTemplateStore);
    setFormFields(formFieldsStore || []);
    setValidationSchema(createValidationSchema(formFieldsStore || []));
    setFileName(formValuesStore['attachment'] || '');
    setFileType(formValuesStore['attachment_mime_type'] || '');
    setSelectedDate(
      formValuesStore['deadline']
        ? moment(formValuesStore['deadline'], 'DD MMMM, YYYY')
        : null
    );
    setAdditionalDate(
      formValuesStore['date_of_joining']
        ? moment(formValuesStore['date_of_joining'], 'DD MMMM, YYYY')
        : null
    );
  }, [selectedTemplateStore, formFieldsStore]);

  const formik = useFormik({
    initialValues: formFields.reduce((acc, field) => {
      acc[field.name] =
        formValuesStore[field.name] ||
        (field.type === 'email' && field.input_type === 'multi' ? [] : '');
      return acc;
    }, {} as { [key: string]: any }),
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        ...values,
        attachment: fileName || undefined,
        attachment_mime_type: fileType || undefined,
        deadline:
          (selectedTemplateStore === '3' || selectedTemplateStore === '4') &&
          selectedDate
            ? selectedDate?.format('DD MMMM, YYYY')
            : undefined,
        date_of_joining:
          selectedTemplateStore === '4' && additionalDate
            ? additionalDate.format('DD MMMM, YYYY')
            : undefined
      };
      dispatch({
        type: emailActions.SET_FORM_VALUES,
        payload: payload
      });
      next();
      // setIsFormDirty(false);
    }
  });

  useEffect(() => {
    if (!loading && Object.keys(emailTemplatesStore).length > 0) {
      dispatch({
        type: emailActions.SET_FORM_FIELDS,
        payload: emailTemplatesStore[selectedTemplateStore]?.fields
      });
    }
  }, [emailTemplatesStore]);

  const handleTemplateChange = (value: string) => {
    let fields;
    if (Number(value) > 0) {
      fields = emailTemplatesStore[value.toString()]?.fields;
      setFormFields(fields || []);
      setValidationSchema(createValidationSchema(fields || []));
    }
    setSelectedDate(undefined);
    setAdditionalDate(undefined);
    dispatch({
      type: emailActions.SET_FORM_FIELDS,
      payload: fields
    });
    dispatch({
      type: emailActions.SET_TEMPLATE,
      payload: value
    });
    dispatch({
      type: emailActions.RESET_FORM_VALUES
    });
    setFileName('');
    setFileType('');
    setSelectedTemplate(value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    // setIsFormDirty(true);
  };

  const onDateChange = (value: any, field: string) => {
    if (field === 'deadline') {
      setSelectedDate(value);
      formik.setFieldValue(field, value ? value.format('DD MMMM, YYYY') : '');
    } else if (field === 'date_of_joining') {
      setAdditionalDate(value);
      formik.setFieldValue(field, value ? value.format('DD MMMM, YYYY') : '');
    }
    // setIsFormDirty(true);
  };

  const handleEmailChange = (field: string, value: string[] | string) => {
    formik.setFieldValue(field, value);
    if (Array.isArray(value)) {
      setEmailFields((prevFields) => ({ ...prevFields, [field]: value }));
    } else {
      setEmailFields((prevFields) => ({ ...prevFields, [field]: [value] }));
    }
  };

  const handleEmailErrorChange = (field: string, error: boolean) => {
    setEmailErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const areAllFieldsFilled = () => {
    return Object.entries(formik.values).every(([key, value]) => {
      const field = formFields.find((f) => f.name === key);
      if (field && field.type === 'file' && !field.required) {
        return true;
      }
      return value !== '';
    });
  };

  const isAnyFieldFilled = () => {
    return Object.entries(formik.values).some(([_, value]) => {
      return value !== '';
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isAnyFieldFilled()) {
        e.preventDefault();
        return (e.returnValue = '');
      }
      return null;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isAnyFieldFilled]);

  return (
    <Spin size='large' className='hrTable-loader' spinning={loading}>
      <div className='flex flex-col gap-y-5'>
        <div className='w-[25%]'>
          <p className='text-blue-streak opacity-50 text-base font-semibold mb-2'>
            Select Template
          </p>
          <div className='shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
            <Select
              className={`filter-select w-full text-base`}
              options={TEMPLATE_OPTIONS}
              placeholder='Select Email Template'
              value={selectedTemplate}
              onChange={handleTemplateChange}
            >
              {TEMPLATE_OPTIONS.map((item) => (
                <Option key={item.value} value={item.value}>
                  {capitalize(item.label)}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        {formFields &&
          Number(selectedTemplate) > 0 &&
          Number(selectedTemplateStore) > 0 && (
            <div className='py-4 rounded-lg'>
              <div className=''>
                <h3 className='text-xl capitalize font-semibold text-blue-radic'>
                  {
                    emailTemplatesStore[selectedTemplate.toString()]
                      ?.template_name
                  }
                </h3>
              </div>
              {/* <Prompt
                when={isAnyFieldFilled()}
                message='You have unsaved changes. Are you sure you want to leave?'
              /> */}
              <form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
                {formFields?.map((field, index) => (
                  <div key={index}>
                    <p className='text-blue-streak text-base mb-0 capitalize opacity-50'>
                      {field.field_name}
                      {(field.type === 'email' &&
                        field.input_type === 'multi') ||
                      (field.type === 'file' && !field.required) ? (
                        ''
                      ) : (
                        <span className='text-lg text-star-red pl-1'>*</span>
                      )}
                    </p>
                    {field.type === 'date' ? (
                      <DatePicker
                        className='custom-date-picker edit-input text-base'
                        value={
                          field.name === 'deadline'
                            ? selectedDate
                            : additionalDate
                        }
                        onChange={(date) => onDateChange(date, field.name)}
                        disabledDate={(current) => {
                          return current && current < moment().startOf('day');
                        }}
                      />
                    ) : field.type === 'file' ? (
                      <FileUpload
                        fileName={fileName}
                        attachmentName={formValuesStore['attachment']}
                        setFileName={(name: string) => {
                          setFileName(name);
                          formik.setFieldValue(field.name, name);
                          // setIsFormDirty(true);
                        }}
                        fileType={fileType}
                        setFileType={setFileType}
                      />
                    ) : field.type === 'text' ? (
                      <Input
                        className='edit-input text-base'
                        type={field.type}
                        name={field.name}
                        value={formik.values[field.name] || ''}
                        onChange={handleInputChange}
                        placeholder={`Enter ${field.field_name}`}
                        onBlur={formik.handleBlur}
                      />
                    ) : field.type === 'email' ? (
                      field.input_type === 'multi' ? (
                        <>
                          <MultiEmailInput
                            fieldValues={formik.values[field.name]}
                            fieldName={field.name}
                            onValueChange={(val: any) => {
                              handleEmailChange(field.name, val);
                              // setIsFormDirty(true);
                            }}
                            onErrorChange={(error: boolean) =>
                              handleEmailErrorChange(field.name, error)
                            }
                          />
                        </>
                      ) : (
                        <Input
                          className='edit-input text-base'
                          type={field.type}
                          name={field.name}
                          value={formik.values[field.name] || ''}
                          onChange={handleInputChange}
                          placeholder={`Enter Email Address`}
                          onBlur={formik.handleBlur}
                        />
                      )
                    ) : (
                      ''
                    )}
                    <div className='text-red-500 h-3 text-sm mb-2'>
                      {formik.touched[field.name] && formik.errors[field.name]
                        ? formik.errors[field.name] as ReactNode
                        : emailErrors[field.name]
                        ? 'Invalid email address'
                        : ''}
                    </div>
                  </div>
                ))}
                <div className='mx-auto mt-10 text-center'>
                  <PrimaryButton
                    disabled={
                      Object.values(emailErrors).some((error) => error) ||
                      !areAllFieldsFilled()
                    }
                    text='Save & Next'
                    type='submit'
                  />
                </div>
              </form>
            </div>
          )}
      </div>
    </Spin>
  );
};

export default connect(mapStateToProps)(AddContentForm);
