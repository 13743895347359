import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import AddReferral from "../../components/Modal/AddReferral";

const EmployeeHome = () => {
  return (
    <div className="w-full  h-screen  overflow-y-auto bg-ghost-white relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
      </Helmet>
      <Header />

      <div className="flex justify-end mr-[32px] mb-4">
        <AddReferral heading="Add Referral" />
      </div>
      <div className="bg-white mx-5 min-h-[60vh] relative rounded-[20px]">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <h1 className="flex justify-center text-[#303C60] text-[30px] font-bold">
            Welcome to ATS
          </h1>
          <img src="/EmployeeHome.svg" alt="Home Employee" />
        </div>
      </div>
    </div>
  );
};

export default EmployeeHome;
