import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import HrTable from '../../components/Table/HRTable';
import { history } from '../../utilities/configureAxios';

const inputArray = [
  {
    inputHeading: 'Name',
    placeholder: 'Name'
  },
  {
    inputHeading: 'Email',
    placeholder: 'Email'
  },
  {
    inputHeading: 'Select Role',
    placeholder: 'Select Role'
  },
  {
    inputHeading: 'Job Title',
    placeholder: 'Job Title'
  },
  {
    inputHeading: 'Contact',
    placeholder: 'Contact'
  }
];
interface InputProps {
  inputHeading: string;
  placeholder: string;
}

interface InputDetails {
  inputDetails?: InputProps[];
  role: number;
  token: string;
}

const HR: FC<InputDetails> = () => {
  return (
    <div className='w-full  h-screen  overflow-y-auto bg-ghost-white relative'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>HR Management</title>
      </Helmet>
      <Header />
      <HrTable location={history.location} inputDetails={inputArray} />
    </div>
  );
};

export default HR;
