import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class AuthServices {
  constructor() {
    this.client = apiClient;
  }
  Login = (payload = {}) => {
    return this.client
      .post(APIs.POST_LOGIN_REQUEST, payload)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default AuthServices;
