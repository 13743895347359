import { Checkbox, Input, Radio, Rate, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { Fragment, LegacyRef, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PrimaryButton from '../../components/Buttons/Primary';
import { FileTextOutlined } from '@ant-design/icons';

import Header from '../../components/Header';
import {
  applicantFeedbackOptions,
  formattedBadConduct,
  formattedCommunication,
  formattedGoodConduct,
  formattedInternalOption
} from '../../helper';
import categoryActions from '../../redux/questionBank/actions';
import './editorScopedStyle.css';
import { imageData } from '../../Interfaces/common';
import { imageTypeURl } from '../../constants/HelperConstants';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  getInterviewFeedback: state.getInterviewFeedback.getInterviewFeedback,
  getInterviewOverallFeedback:
    state.getInterviewOverallFeedback.getInterviewOverallFeedback,
  getSelectedInterview: state.interviewers.selectedInterview,
  singleInterviewInfo: state.getSingleInterviewInfo.singleInterviewInfo
});

function ViewFeedback({
  getInterviewFeedback,
  getInterviewOverallFeedback,
  loading,
  getSelectedInterview,
  singleInterviewInfo
}: any) {
  // const [interviewId, setInterviewId] = useState<any>();

  const modulesQuestion = {
    toolbar: false
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const interviewId = location?.pathname?.split('/')[3];
    let body = {
      interviewId: interviewId
    };
    dispatch({
      type: categoryActions.GET_INTERVIEW_OVERALL_FEEDBACK,
      payload: body
    });

    return () => {
      dispatch({
        type: categoryActions.RESET_INTERVIEW_FEEDBACK_QUESTION_SET
      });
      dispatch({
        type: categoryActions.RESET_INTERVIEW_OVERALL_FEEDBACK
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    const uuid = location?.pathname?.split('/')[3];
    let body = {
      uuid: uuid
    };
    dispatch({
      type: categoryActions.GET_SINGLE_INTERVIEW_INFO,
      payload: body
    });

    return () => {
      dispatch({
        type: categoryActions.RESET_INTERVIEW_QUESTION_SET
      });
      dispatch({
        type: categoryActions.GET_SINGLE_INTERVIEW_INFO_RESET
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    const interviewId = location?.pathname?.split('/')[3];
    let body = {
      interviewId: interviewId
    };
    if (interviewId && singleInterviewInfo?.data?.question_set_status === 3) {
      dispatch({
        type: categoryActions.GET_INTERVIEW_FEEDBACK,
        payload: body
      });
    }
  }, [singleInterviewInfo]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const recommendedLevel = [
    {
      value: null,
      label: 'None'
    },
    {
      value: 1,
      label: 'L0'
    },
    {
      value: 2,
      label: 'L1'
    },
    {
      value: 3,
      label: 'L2'
    },
    {
      value: 4,
      label: 'L3'
    },
    {
      value: 5,
      label: 'L4'
    },
    {
      value: 6,
      label: 'L5'
    },
    {
      value: 7,
      label: 'L6'
    },
    {
      value: 8,
      label: 'L7'
    },
    {
      value: 9,
      label: 'L8'
    },
    {
      value: 10,
      label: 'L9'
    },
    {
      value: 11,
      label: 'L10'
    }
  ];

  return (
    <>
      <div className='w-full  overflow-y-auto bg-ghost-white'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>View Feedback</title>
        </Helmet>
        <Header />
        <main>
          <Spin size='large' className='hrTable-loader' spinning={loading}>
            <section
              style={{ minHeight: '100vh' }}
              className='bg-white rounded-lg p-5 m-8'
            >
              <div className='text-[20px] text-light-blue font-semibold'>
                View Interview Feedback
              </div>
              <br />
              <>
                <div className='flex space-x-12 my-4'>
                  <div className='text-slate-grey text-[16px] font-semibold flex'>
                    Applicant Name:{' '}
                    <Tooltip
                      title={getInterviewOverallFeedback?.data?.applicant_name}
                    >
                      <span className='font-light max-w-[15rem] line-clamp-1 break-words ml-2 capitalize'>
                        {getInterviewOverallFeedback?.data?.applicant_name}
                      </span>
                    </Tooltip>
                  </div>
                  <div className='text-slate-grey text-[16px] font-semibold flex'>
                    Job Position:{' '}
                    <Tooltip
                      title={getInterviewOverallFeedback?.data?.job_title}
                    >
                      <span className='font-light  max-w-[15rem] line-clamp-1 break-words ml-2 capitalize'>
                        {getInterviewOverallFeedback?.data?.job_title}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className='flex space-x-12 my-4'>
                  {getInterviewOverallFeedback?.data?.feedback_by && (
                    <div className='text-slate-grey text-[16px] font-semibold'>
                      Feedback By:{' '}
                      <span className='font-light'>
                        {getInterviewOverallFeedback?.data?.feedback_by}
                      </span>
                    </div>
                  )}
                  <div className='text-slate-grey text-[16px] font-semibold'>
                    Date & Time:{' '}
                    <span className='font-light'>
                      {moment(
                        getInterviewOverallFeedback?.data?.scheduled_at
                      ).format('MMMM Do, YYYY, h:mm A')}
                    </span>
                  </div>
                </div>
              </>
              <br />
              {getInterviewFeedback &&
                getInterviewFeedback?.data?.map((itm: any, index: number) => (
                  <div className='my-8' key={index}>
                    <div className='flex items-center my-4'>
                      {itm?.question_level === 1 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#4E9F09',
                            border: '1px solid #4E9F09',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Easy
                        </span>
                      )}
                      {itm?.question_level === 2 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#D19E37',
                            border: '1px solid #D19E37',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Medium
                        </span>
                      )}
                      {itm?.question_level === 3 && (
                        <span
                          style={{
                            margin: '0px 10px 0px 0px',
                            backgroundColor: '#F3F9FE',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '0px 10px',
                            color: '#C87172',
                            border: '1px solid #C87172',
                            borderRadius: '4px',
                            fontSize: '16px'
                          }}
                        >
                          Hard
                        </span>
                      )}
                      {itm?.question_type === 1 ? (
                        <div className='text-[16px] text-slate-grey font-medium'>
                          Subjective Type
                        </div>
                      ) : (
                        <div className='text-[16px] text-slate-grey font-medium'>
                          Objective Type
                        </div>
                      )}
                      {itm?.categories_list
                        ?.slice(0, 3)
                        .map((itm: any, index: number) => (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            key={index}
                            style={{
                              fontSize: '16px',
                              margin: '0px 0px 0px 10px',
                              backgroundColor: '#2D94F31A',
                              width: 'fit-content',
                              height: '28px',
                              padding: '0px 10px',
                              color: '#2D94F3',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {itm?.name}
                          </div>
                        ))}
                      {itm?.categories_list?.length > 3 && (
                        <Tooltip
                          title={itm?.categories_list
                            ?.slice(3)
                            ?.map((i: any) => (
                              <span>
                                {i?.name}
                                <br />
                              </span>
                            ))}
                        >
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              margin: '0px 10px',
                              backgroundColor: '#2D94F31A',
                              width: 'fit-content',
                              height: '28px',
                              padding: '0px 10px',
                              color: '#1890FF',
                              cursor: 'pointer',
                              borderRadius: '4px'
                            }}
                          >
                            ...
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Question
                      </div>
                      <div className='feedback'>
                        <ReactQuill
                          className='editor'
                          value={itm?.question}
                          readOnly
                          style={{
                            color: '#707070',
                            fontSize: '20px',
                            fontWeight: '500'
                          }}
                          modules={modulesQuestion}
                        />
                      </div>
                    </div>
                    <div>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Answer
                      </div>
                      <div className='feedback'>
                        <ReactQuill
                          className='editor'
                          value={itm?.answer}
                          readOnly
                          style={{
                            color: '#707070',
                            fontSize: '20px',
                            fontWeight: '500'
                          }}
                          modules={modulesQuestion}
                        />
                      </div>
                    </div>
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Feedback
                      </div>
                      <Input.TextArea
                        disabled
                        placeholder={itm?.content}
                        style={{ minHeight: '5rem' }}
                      />
                    </div>
                    <div className='my-4'>
                      <Rate
                        style={{ color: '#2D94F3' }}
                        disabled
                        value={itm?.rating}
                      />
                    </div>
                    <br />
                  </div>
                ))}
              {/* // -----------------------------// extra question sets //------------------------------*/}
              <section>
                {getInterviewOverallFeedback?.data?.meta?.[0]?.question
                  ?.replace(/<(.|\n)*?>/g, '')
                  .trim().length > 0 &&
                  getInterviewOverallFeedback?.data?.meta?.[0]?.answer
                    ?.replace(/<(.|\n)*?>/g, '')
                    .trim().length > 0 && (
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Extra Question Sets
                      </div>
                    </div>
                  )}
                {getInterviewOverallFeedback &&
                  getInterviewOverallFeedback?.data?.meta?.length > 0 &&
                  getInterviewOverallFeedback?.data?.meta?.map(
                    (itm: any, index: number) => (
                      <>
                        {itm?.question?.replace(/<(.|\n)*?>/g, '').trim()
                          .length > 0 &&
                          itm?.answer?.replace(/<(.|\n)*?>/g, '').trim()
                            .length > 0 && (
                            <>
                              <div className='my-4'>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Question
                                </div>
                                <div className='feedback'>
                                  <ReactQuill
                                    className='editor'
                                    value={itm?.question}
                                    readOnly
                                    style={{
                                      color: '#707070',
                                      fontSize: '20px',
                                      fontWeight: '500'
                                    }}
                                    modules={modulesQuestion}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Answer
                                </div>
                                <div className='feedback'>
                                  <ReactQuill
                                    className='editor'
                                    value={itm?.answer}
                                    readOnly
                                    style={{
                                      color: '#707070',
                                      fontSize: '20px',
                                      fontWeight: '500'
                                    }}
                                    modules={modulesQuestion}
                                  />
                                </div>
                              </div>
                              <div className='my-4'>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Feedback
                                </div>
                                <Input.TextArea
                                  disabled
                                  placeholder={itm?.content}
                                  style={{ minHeight: '5rem' }}
                                />
                              </div>
                              <div className='my-8'>
                                <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                                  Rating
                                </div>
                                <Rate
                                  style={{ color: '#2D94F3' }}
                                  disabled
                                  value={itm?.rating}
                                />
                              </div>
                            </>
                          )}
                      </>
                    )
                  )}
              </section>
              {/* ------------------------------------------------------------------------------------------------ */}
              <hr className='opacity-20' />
              <section>
                {/* --------------------------------// overall feedback //--------------------------------- */}
                <div className='my-4'>
                  <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                    Overall Feedback
                  </div>
                  <Input.TextArea
                    disabled
                    style={{ minHeight: '5rem' }}
                    placeholder={
                      getInterviewOverallFeedback?.data?.overall_feedback
                    }
                  />
                </div>
                <div className='my-4'>
                  <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                    Overall Rating
                  </div>
                  <Rate
                    style={{ color: '#2D94F3' }}
                    disabled
                    value={getInterviewOverallFeedback?.data?.overall_rating}
                  />
                </div>
                <div className='my-4'>
                  <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                    Move forward with this applicant{' '}
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </div>
                  <Select
                    value={getInterviewOverallFeedback?.data?.in_next_round}
                    className='filter-select w-32 border border-[#F4F7FC] rounded-lg'
                    placeholder={'Choose'}
                    options={applicantFeedbackOptions}
                    disabled
                  ></Select>
                </div>
                {getInterviewOverallFeedback?.data?.in_next_round ? (
                  <Fragment>
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Recommended Levels
                      </div>
                      <Select
                        className='filter-select  border border-[#F4F7FC] rounded-lg'
                        // onChange={(id, key) => handleFeedbackInternalOption(id, key)}
                        // onChange={(value) => setOverallLevel(value)}
                        disabled
                        placeholder={'Choose'}
                        value={getInterviewOverallFeedback?.data?.level}
                        options={recommendedLevel}
                      />
                    </div>
                    {/* //Communication */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Was the candidate able to communicate clearly?
                      </div>
                      {formattedCommunication?.map((i) =>
                        i.value ==
                        getInterviewOverallFeedback?.data
                          ?.communication_skill ? (
                          <p>{i.label}</p>
                        ) : null
                      )}
                    </div>
                    {/* //Bad conduct */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Did you notice any of the following case?
                      </div>
                      {formattedBadConduct
                        .filter((i) =>
                          getInterviewOverallFeedback?.data?.bad_conduct?.includes(
                            Number(i.value)
                          )
                        )
                        .map((j) => (
                          <div>{j.label}</div>
                        ))}
                    </div>
                    {/* //Good Conduct */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Any Good Things you noticed about the candidate?
                      </div>
                      {formattedGoodConduct
                        .filter((i) =>
                          getInterviewOverallFeedback?.data?.good_conduct?.includes(
                            Number(i.value)
                          )
                        )
                        .map((j) => (
                          <div>{j.label}</div>
                        ))}
                    </div>
                    {/* //Internal Option */}
                    <div className='my-4'>
                      <div className='text-[16px] text-blue-streak opacity-50 my-1'>
                        Internal Option for Shortlisted Candidates
                      </div>
                      {formattedInternalOption?.map((i) =>
                        i.value ==
                        getInterviewOverallFeedback?.data?.internal_option ? (
                          <p>{i.label}</p>
                        ) : null
                      )}
                    </div>
                  </Fragment>
                ) : null}
                {getInterviewOverallFeedback?.data?.attachment?.length && (
                  <div className='my-4'>
                    <span className='text-[16px] text-blue-streak opacity-50 my-1'>
                      Optional File
                    </span>
                    <div className='flex flex-wrap w-full flex-direction-row gap-x-4 gap-y-4 mt-2'>
                      {getInterviewOverallFeedback?.data?.attachment?.map(
                        (val: imageData) => (
                          <div>
                            <a
                              href={imageTypeURl(val.type, val.attachment_url)}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  columnGap: '20px',
                                  border: '1px solid #d9d9d9',
                                  borderRadius: '2px',
                                  alignItems: 'center',
                                  padding: '8px',
                                  width: '200px',
                                  maxHeight: '66px',
                                  height: '66px'
                                }}
                              >
                                <span>
                                  {val?.type?.includes('image/') ? (
                                    <img
                                      src={val.attachment_url}
                                      style={{
                                        display: 'block',
                                        width: '48px',
                                        height: '48px',
                                        maxWidth: '100%',
                                        objectFit: 'contain'
                                      }}
                                      alt={val?.name}
                                    />
                                  ) : (
                                    <FileTextOutlined
                                      style={{ fontSize: '20px' }}
                                    />
                                  )}
                                </span>

                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    color: 'black',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  {val.name}
                                </p>
                              </div>
                            </a>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                <div>
                  <Checkbox
                    // defaultChecked={getInterviewOverallFeedback?.data?.send_thankyou_mail}
                    checked={
                      getInterviewOverallFeedback?.data?.send_thankyou_mail
                    }
                    disabled
                  >
                    Send thank you email
                  </Checkbox>
                </div>
              </section>
            </section>
          </Spin>
        </main>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(ViewFeedback);
