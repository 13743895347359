import Header from "../../components/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Analytics = () => {
  return (
    <>
      <div className="page-container flex">
        <HelmetProvider>
          <Helmet prioritizeSeoTags={true}>
            <meta charSet="utf-8" />
            <title>Application Tracking System</title>
            <meta name="title" content="Application Tracking System" />
            <meta name="description" content="Application Tracking System" />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content="https://ats-portal.squareboat.info/"
            />
            <meta property="og:title" content="Application Tracking System" />
            <meta
              property="twitter:url"
              content="https://ats-portal.squareboat.info/"
            />
            <meta
              property="twitter:image"
              content="https://ats-portal.squareboat.info/sqaureboat-favicon.ico"
            />
            <meta
              property="og:image"
              content="https://ats-portal.squareboat.info/sqaureboat-favicon.ico"
            />
          </Helmet>
        </HelmetProvider>
        <div className="w-full h-screen overflow-y-auto bg-ghost-white">
          <Header isAllCandidate={true} />
          <main style={{height:"100%", display : "flex"}}>
            <iframe src={process.env.REACT_APP_IFRAME_URL} width="100%" />
          </main>
        </div>
      </div>
    </>
  );
};

export default Analytics;
