import apiClient from '../utilities/configureAxios';
import { ApiRoutes } from '../constants/ApiEnums';

class hiringTrackerService {
  constructor() {
    this.client = apiClient;
  }

  GetHiringData = async (payload) => {
    try {
      const res = await this.client.get(ApiRoutes.GET_HIRING_TRACKER, {
        params: payload
      });
      return res;
    } catch (error) {
      return error;
    }
  };
  ExportCSV = async (payload) => {
    try {
      const res = await this.client.post(
        ApiRoutes.EXPORT_CSV_HIRING_TRACKER,
        payload
      );
      return res;
    } catch (error) {
      return error;
    }
  };
}
export default hiringTrackerService;
