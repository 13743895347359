import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class UserServices {
  constructor() {
    this.client = apiClient;
  }

  GetAllUsers = (payload = {}) => {
    const { page } = payload;
    return this.client
      .get(APIs.GET_ALL_USERS.replace("num", page))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostInviteMail = (payload = {}) => {
    return this.client
      .post(APIs.POST_INVITE_MAIL, payload)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  UpdateRoleAndStatus = (payload = {}) => {
    const { id, body } = payload;
    return this.client
      .patch(APIs.UPDATE_ROLE_AND_STATUS.replace(":id", id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  UpdateUserProfile = (payload = {}) => {
    const { id, body } = payload;
    return this.client
      .patch(APIs.UPDATE_USER_PROFILE.replace(":id", id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default UserServices;
