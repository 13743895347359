import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class ApplicationServices {
  constructor() {
    this.client = apiClient;
  }

  AddApplicant = (payload) => {
    const { body } = payload;
    return this.client
      .post(APIs.GET_ALL_APPLICATIONS, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetUrlForUpload = (file_name, mime_type) => {
    return this.client
      .get(`${APIs.GET_UPLOAD_URL}?mime_type=${mime_type}&file_name=${file_name}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetPortfolioUrlForUpload = (file_name, mime_type) => {
    return this.client
      .get(`${APIs.GET_PORTFOLIO_UPLOAD_URL}?mime_type=${mime_type}&file_name=${file_name}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllApplications = (payload) => {
    return this.client
      .get(APIs.GET_ALL_APPLICATIONS, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetDuplicateApplications = (payload) => {
    return this.client
      .get(APIs.GET_DUPLICATE_APPLICATION.replace(":id", payload?.id))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllHRs = () => {
    return this.client
      .get(APIs.GET_ALL_HRS)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetAllTaskList = (payload) => {
    const { ref_id } = payload;
    return this.client
      .get(APIs.GET_ALL_TASKS_LIST.replace(":id", ref_id))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  AllotTask = (payload) => {
    const { ref_id, body } = payload;
    return this.client
      .post(APIs.GET_ALL_TASKS_LIST.replace(":id", ref_id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  UpdateAllotedTask = (payload) => {
    const { ref_id, body, taskId } = payload;
    return this.client
      .patch(
        APIs.UPDATE_ALLOTED_TASK.replace(":id", ref_id).replace(
          ":taskId",
          taskId
        ),
        body
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetAllApplicationsWithoutPage = (payload) => {
    // .get(`${APIs.GET_ALL_APPLICATIONS}?${queryString.stringify(payload)}`)

    return this.client
      .get(APIs.GET_ALL_APPLICATIONS_WITHOUT_PAGE, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetSingleApplication = (payload = {}) => {
    const { ref_id } = payload;

    return this.client
      .get(APIs.GET_SINGLE_APPLICATION.replace(":id", ref_id))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  UpdateSingleApplication = (payload) => {
    const { ref_id, body } = payload;
    return this.client
      .patch(APIs.UPDATE_STATUS_OF_APPLICATION.replace(":id", ref_id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  CreateApplication = (payload = {}) => {
    const { body, org_id } = payload;
    return this.client
      .post(APIs.POST_APPLICATION.replace(":id", org_id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  CreateApplicationNote = (payload = {}) => {
    const { ref_id, body } = payload;
    return this.client
      .post(APIs.CREATE_APPLICATION_NOTE.replace(":id", ref_id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetApplicationNote = (payload = {}) => {
    const { ref_id } = payload;
    return this.client
      .get(APIs.GET_APPLICATION_NOTE.replace(":id", ref_id))
      .then((res) => res);
    // .catch((error) => {
    //   return error;
    // });
  };

  GetNextSingleApplication = (payload = {}) => {
    const { ref_id, params } = payload;
    return this.client
      .get(APIs.GET_NEXT_APPLICATION.replace(":id", ref_id), {
        params: { ...params },
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetPrevSingleApplication = (payload = {}) => {
    const { ref_id, params } = payload;
    return this.client
      .get(APIs.GET_PREV_APPLICATION.replace(":id", ref_id), {
        params: { ...params },
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetInterview = (payload = {}) => {
    const { ref_id } = payload;
    return this.client
      .get(APIs.GET_INTERVIEW_SCHEDULE.replace(":id", ref_id))
      .then((res) => res);
    // .catch((error) => {
    //   return error;
    // });
  };

  ScheduleInterview = (payload = {}) => {
    const { ref_id, body } = payload;
    return this.client
      .post(APIs.POST_INTERVIEW_SCHEDULE.replace(":id", ref_id), body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  DeleteInterview = (payload = {}) => {
    const { ref_id, interviewId } = payload;
    return this.client
      .delete(`applications/${ref_id}/interviews/${interviewId}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  DeleteComment = (payload = {}) => {
    const { ref_id, commentId } = payload;
    return this.client
      .delete(`applications/${ref_id}/notes/${commentId}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  UpdateComment = (payload = {}) => {
    const { ref_id, commentId, body } = payload;
    return this.client
      .patch(`applications/${ref_id}/notes/${commentId}`, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  updateInterview = (payload = {}) => {
    const { ref_id, interviewId, body } = payload;
    return this.client
      .patch(`applications/${ref_id}/interviews/${interviewId}`, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostInterviewConcent = (payload) => {
    const { id, uuid, body } = payload;
    return this.client
      .post(
        APIs.POST_INTERVIEW_CONCENT.replace(":id", id).replace(":uuid", uuid),
        body
      )
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostInterviewQuestionSet = (payload) => {
    return this.client
      .post(APIs.POST_INTERVIEW_QUESTION_SET.replace(":id", payload.id))
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  PostGenerateAQuestionSet = (payload) => {
    return this.client
      .post(APIs.POST_GENERATE_A_QUESTION_SET, {
        ref_id: payload.ref_id,
      })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };

  GetInterviewHistory = async (payload) => {
    try {
      const res = await this.client.get(
        APIs.GET_INTERVIEW_HISTORY.replace(":id", payload.id)
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  GetAllTags = (payload) => {
    return this.client
      .get(APIs.GET_ALL_TAGS)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default ApplicationServices;
