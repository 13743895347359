import { Breadcrumb } from "antd";
import { FC, ReactElement } from "react";
import { IconType } from "react-icons";
import { TbLayoutGridAdd } from "react-icons/tb";

interface IDummybreadcrumb{
    title: string;
    icon:ReactElement<any, any>
}
const DummyBreadCrumb:FC<IDummybreadcrumb> = ({title,icon}) => {
  return (
    <>
      <div className="mt-2">
        <Breadcrumb separator=">">
          <Breadcrumb.Item className="flex text-lg lg:text-xl text-primary font-medium">
            {icon}
            {title}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
};

export default DummyBreadCrumb;
