import React, { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';
import { connect, useDispatch } from 'react-redux';
import applicationActions from '../../../redux/applications/actions';
import moment from 'moment';
import { formatsDateTime } from '../../../helper';

const mapStateToProps = (state: any) => ({
  open: state.applications.openViewCommentModal
});

interface IComment {
  note: string;
  user: any;
  created_at: string;
  id: string;
}

interface IViewCommentModal {
  open: boolean;
  onClose: () => void;
  comment: IComment;
  onEdit: (newNote: string) => void;
}

const ViewComment: React.FC<IViewCommentModal> = ({
  open,
  onClose,
  comment,
  onEdit
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editedNote, setEditedNote] = useState(comment?.note || '');

  useEffect(() => {
    if (comment.note !== editedNote) {
      setEditedNote(comment.note);
    }
  }, [comment.note]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    onEdit(editedNote);
    setIsEditing(false);
  };

  return (
    <Modal
      className='view-comment-modal'
      centered
      visible={open}
      onCancel={onClose}
      width='1099px'
      footer={null}
    >
      <div className='flex justify-between'>
        <div className='px-4 md:px-5'>
          <h3 className='text-xl mb-0 uppercase font-semibold text-blue-radic'>
            Comment Details
          </h3>
        </div>
      </div>
      <div className='flex justify-between items-center mt-8 px-4 md:px-5'>
        <p className='text-base mb-0 text-blue-radic'>
          <strong>Author:</strong> {comment?.user?.name}
        </p>
        <p className='text-base mb-0 text-blue-radic'>
          <strong>Commented On:</strong>{' '}
          {moment(comment?.created_at).format(formatsDateTime)}
        </p>
      </div>
      <p className='px-4 md:px-5 mb-0 text-base text-blue-radic'>
        <strong>Comment: </strong>
      </p>
      <div className='px-4 md:px-5 mb-0 max-h-[305px] overflow-y-auto'>
        {isEditing ? (
          <Input.TextArea
            className='!text-base !rounded-lg'
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
            rows={8}
          />
        ) : (
          editedNote.split('\n').map((note, index) => (
            <p key={index} className='text-base pb-0 text-blue-radic'>
              {note}
            </p>
          ))
        )}
      </div>
      <div className='flex justify-end px-4 mt-3 md:px-5 space-x-4'>
        {isEditing ? (
          <>
            <button
              className='h-10 w-28 rounded-md bg-[#2D94F3] text-white hover:bg-[#40a9ff]'
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className='h-10 w-28 rounded-md border text-red-500 border-red-500'
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button
              className='h-10 w-28 rounded-md bg-[#2D94F3] text-white hover:bg-[#40a9ff]'
              onClick={handleEdit}
            >
              Edit
            </button>
            <button
              className='h-10 w-28 rounded-md border text-red-500 border-red-500'
              onClick={onClose}
            >
              Close
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps)(ViewComment);
