import { Button, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, connect } from "react-redux";
import applicationActions from "../../../redux/applications/actions";

const mapStateToProps = (state: any) => ({
  open: state.applications.openInterviewModal,
});

interface DeleteProps {
  heading: string;
  desc: string;
  userId?: any;
  open: boolean;
  interviewId: string;
}

const DeleteInterviewModal: FC<DeleteProps> = ({
  heading,
  desc,
  userId,
  open,
  interviewId,
}) => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    if (open){setLoading(false)}
  
  }, [open])
  
  const onDelete = () => {
    setLoading(true)
    dispatch({
      type: applicationActions.DELETE_INTERVIEW,
      payload: {
        ref_id: userId,
        interviewId,
      },
    });
  };

  return (
    <>
      <Modal
        className="delete-modal"
        centered
        open={open}
        onCancel={() =>
          dispatch({
            type: applicationActions.TOGGLE_INTERVIEW_MODAL,
            payload: false,
          })
        }
      >
        <div className="flex justify-between">
          <div className="px-4 md:px-10">
            <h3 className="text-xl uppercase font-semibold text-blue-radic">
              {heading}
            </h3>
          </div>

          <div className="w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer">
            <AiOutlineClose
              className="w-5 h-5 text-gray-light"
              onClick={() =>
                dispatch({
                  type: applicationActions.TOGGLE_INTERVIEW_MODAL,
                  payload: false,
                })
              }
            />
          </div>
        </div>
        <div className="mt-10 md:mt-10 px-4 md:px-10">
          <p className="text-base text-blue-radic text-center lg:text-left">
            {desc}
          </p>
        </div>
        <div className="flex justify-center md:px-12 pt-4 pb-6 space-x-4 lg:space-x-7 mt-7">
          <Button className="yesBtn" onClick={onDelete} loading={loading}>
            Yes
          </Button>
          <button
            className="noBtn"
            onClick={() =>
              dispatch({
                type: applicationActions.TOGGLE_INTERVIEW_MODAL,
                payload: false,
              })
            }
          >
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(DeleteInterviewModal);
