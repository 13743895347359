import actions from "./actions";

const initialState = {
  tasks: [],
  singleTask: [],
  paginatedTask: [],
};

export default function taskReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_TASKS_STATE: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
}
