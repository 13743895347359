import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Pagination, Spin, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { FaRegSnowflake } from 'react-icons/fa';
import QueryString from 'qs';
import SelectBox from '../../Select';
import SelectReferred from '../../SelectReferred';
import applicationActions from '../../../redux/applications/actions';
import {
  formatsDate,
  getCurrentQueryParams,
  getSearchParamsUrl
} from '../../../helper';
import { history } from '../../../utilities/configureAxios';
import { DASHBOARD_ALL_CANDIDATES } from '../../../constants/RouteConstants';
import { queryStringToJSON } from '../../../utilities/routies';
import moment from 'moment';

const mapStateToProps = (state: any) => ({
  applications: state.applications.applications,
  loading: state.auth.loading,
  allHr: state.applications.allHr
});

type Application = {
  ref_id: string;
  name: string;
  email: string;
  job_title: string;
  graduation_year: number;
  ctc_in_rupees: number;
  referral_name: string;
  cv_rating: number;
  created_at: number;
};

interface ICandidateTable {
  applications: {
    data: Application[];
    meta: {
      pagination: {
        page: number;
        count: number;
      };
    };
  };
  loading: boolean;
  location?: any;
  allHr?: any;
}

const CandidateTable: React.FC<ICandidateTable> = ({
  applications,
  loading,
  allHr
}) => {
  const { search } = useLocation();
  const {
    status,
    page,
    q,
    job_id,
    graduation_gte,
    graduation_lte,
    ctc_gte,
    ctc_lte,
    cool_off_status,
    created_at_gte,
    created_at_lte,
    cv_gte,
    cv_lte,
    tags,
    sort,
    order
  } = queryStringToJSON(search);
  const dispatch = useDispatch();

  useEffect(() => {
    handleApiRequest(
      page,
      status,
      job_id,
      graduation_gte,
      graduation_lte,
      ctc_gte,
      ctc_lte,
      q,
      cool_off_status,
      created_at_gte,
      created_at_lte,
      cv_gte,
      cv_lte,
      tags,
      sort,
      order
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, status, job_id, dispatch, cool_off_status]);

  useEffect(() => {
    debounceOnChange(
      page,
      status,
      job_id,
      graduation_gte,
      graduation_lte,
      ctc_gte,
      ctc_lte,
      q,
      cool_off_status,
      created_at_gte,
      created_at_lte,
      cv_gte,
      cv_lte,
      tags,
      sort,
      order
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ctc_gte,
    ctc_lte,
    graduation_gte,
    graduation_lte,
    q,
    created_at_gte,
    created_at_lte,
    cv_gte,
    cv_lte,
    tags,
    sort,
    order
  ]);

  const handleApiRequest = (
    page: string,
    status: string,
    job_id: string,
    graduation_gte: string,
    graduation_lte: string,
    ctc_gte: string,
    ctc_lte: string,
    q: string,
    cool_off_status: string,
    created_at_gte: string,
    created_at_lte: string,
    cv_gte: string,
    cv_lte: string,
    tags: any[],
    sort: string,
    order: string
  ) => {
    dispatch({
      type: applicationActions.GET_ALL_APPLICATIONS,
      payload: {
        page: page ?? 1,
        status: status,
        job_id: job_id,
        graduation_gte: graduation_gte,
        graduation_lte: graduation_lte,
        ctc_gte: ctc_gte,
        ctc_lte: ctc_lte,
        q: q,
        cool_off_status: cool_off_status,
        created_at_gte,
        created_at_lte,
        cv_gte,
        cv_lte,
        tags,
        sort,
        order
      }
    });
  };

  const debounceOnChange = useCallback(debounce(handleApiRequest, 400), []);

  const hanldeCandidateClick = (ref_id: any) => {
    history.push(
      `${DASHBOARD_ALL_CANDIDATES}/${ref_id}?${QueryString.stringify(
        getCurrentQueryParams()
      )}`
    );
  };

  useEffect(() => {
    dispatch({
      type: applicationActions.GET_ALL_HRS
    });
    // dispatch({
    //   type: applicationActions.GET_ALL_TAGS
    // });
  }, []);

  const [hrId, setHrId] = useState<string | number>('');
  function handleChangeReferral(value: any, d: any) {
    const ref = d.id;
    setHrId(value);
    dispatch({
      type: applicationActions.UPDATE_STATUS_OF_APPLICATION,
      payload: {
        ref,
        body: {
          ref_id: value
        }
      }
    });
  }

  allHr.forEach(function (itm: any) {
    itm.label = itm?.name;
    itm.value = itm?.id;
  });

  allHr.push({
    label: 'N.A.',
    value: 'N.A.'
  });

  return (
    <>
      <Spin size='large' className='hrTable-loader' spinning={loading}>
        <div className='flex flex-col'>
          <div className='mx-8 mt-[20px]'>
            <h3 className='text-[#979797] text-sm'>
              Total Candidates: {applications?.meta?.pagination?.count}
            </h3>
          </div>
          <div className='shadow-lg bg-white mx-8 rounded-2xl overflow-x-auto min-h-[80%] '>
            <table className='w-full table_transition'>
              <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5'>
                <tr className=' text-[0.9rem] px-2'>
                  <th className='py-4 w-[10%] min-w-[100px] max-w-[10%] text-center text-[#233A5C] text-opacity-50'>
                    Ref Id
                  </th>
                  <th className='py-4 w-[20%] min-w-[130px] max-w-[20%] text-[#233A5C] text-opacity-50'>
                    Name
                  </th>
                  <th className='py-4 w-[20%] min-w-[150px] max-w-[15%] text-[#233A5C] text-opacity-50'>
                    Email
                  </th>
                  <th className='py-4 w-[20%] min-w-[150px] max-w-[15%] text-[#233A5C] text-opacity-50'>
                    Job Title
                  </th>
                  <th className='py-4 w-[20%] min-w-[150px] max-w-[15%] text-[#233A5C] text-opacity-50'>
                    Referred By
                  </th>
                  <th className='py-4 w-[20%] min-w-[150px] max-w-[15%] text-[#233A5C] text-opacity-50'>
                    Shortlisted By
                  </th>
                  <th className='py-4 w-[20%] min-w-[150px] text-center max-w-[15%] text-[#233A5C] text-opacity-50'>
                    Cool Off Status
                  </th>
                  <th className='py-4 w-[10%] max-w-[10%] text-center text-[#233A5C] text-opacity-50'>
                    Graduation
                  </th>
                  <th className='py-4 w-[10%] min-w-[150px] max-w-[10%] text-center text-[#233A5C] text-opacity-50'>
                    <div className='cursor-pointer w-max mx-auto'>CTC</div>
                  </th>
                  <th className='py-4 w-[10%] min-w-[150px] max-w-[10%] text-center text-[#233A5C] text-opacity-50'>
                    <div className='cursor-pointer w-max mx-auto'>
                      CV Rating
                    </div>
                  </th>
                  <th className='py-4 w-[20%] min-w-[150px] max-w-[15%] text-[#233A5C] text-opacity-50'>
                    <div className='cursor-pointer w-max'>Applied On</div>
                  </th>
                  <th className='py-4 w-[10%] min-w-[160px] max-w-[20%] text-[#233A5C] text-opacity-50'>
                    Status of Application
                  </th>
                </tr>
              </thead>
              <tbody>
                {applications?.data &&
                  applications?.data?.length > 0 &&
                  applications?.data?.map((d: any, index: number) => (
                    <tr
                      key={`${d.ref_id}${index}`}
                      className='text-[13px] hover:shadow-md transition-all duration-100 ease-in border-bottom cursor-pointer text-blue-zodiac table-text font-normal capitalize mb-0 whitespace-nowrap overflow-auto'
                    >
                      <td
                        className='py-2 pr-2 w-[10%] max-w-[5rem] text-overflow-none text-center'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        <Tooltip placement='bottomLeft' title={d?.ref_id}>
                          {d?.ref_id}
                        </Tooltip>
                      </td>
                      <td
                        className='py-2 pr-2 w-[20%] max-w-[15rem] text-overflow-none'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        <Tooltip placement='bottomLeft' title={d?.name}>
                          <span className='max-w-[100px] block truncate'>
                            {d?.name}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        className='py-2 pr-2 w-[20%] max-w-[8rem] lowercase text-overflow-none'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        <Tooltip placement='bottomLeft' title={d?.email}>
                          <span className='max-w-[100px] block truncate'>
                            {d?.email}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        className='py-2 pr-2 w-[20%] max-w-[8rem] text-overflow-none'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        <Tooltip placement='bottomLeft' title={d?.job_title}>
                          <span className='max-w-[100px] block truncate'>
                            {d?.job_title}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        className='py-2 pr-2 w-[20%] max-w-[8rem] text-overflow-none'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        <Tooltip
                          placement='bottomLeft'
                          title={d?.referral_name}
                        >
                          <span className='max-w-[100px] block truncate'>
                            {d?.referral_name ? d.referral_name : 'N.A'}
                          </span>
                        </Tooltip>
                      </td>
                      <>
                        <td
                          style={{ transform: 'translateX(-5px)' }}
                          className='py-2 w-[10%] max-w-[20%] text-left pr-2'
                        >
                          <SelectReferred
                            status={d?.status}
                            ref_id={d?.ref_id}
                            page={page}
                            hr={d?.hr}
                          />
                        </td>
                      </>
                      <td
                        className='py-2 pr-2 w-[20%] text-center max-w-[8rem] text-overflow-none'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        <FaRegSnowflake
                          className={`text-[20px] m-auto ${
                            d?.in_cool_off ? 'text-[#1890FF]' : 'text-[#95a6b5]'
                          }`}
                        />
                      </td>
                      <td
                        className='py-2 pr-2 w-[10%] max-w-[10%] text-center'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        {d.graduation_year !== null ? d.graduation_year : 'N.A'}
                      </td>
                      <td
                        className='py-2 w-[10%] max-w-[10%] text-center'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        {d.ctc_in_rupees > 200000
                          ? `${(d.ctc_in_rupees / 100000).toFixed(1)} Lakhs`
                          : `${(d.ctc_in_rupees / 100000).toFixed(1)} Lakh`}
                      </td>
                      <td
                        className='py-2 pr-2 w-[10%] max-w-[10%] text-center'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        {d.cv_rating !== null ? d.cv_rating : 'N.A'}
                      </td>
                      <td
                        className='py-2 pr-2 w-[10%] max-w-[10%]'
                        onClick={() => hanldeCandidateClick(d?.ref_id)}
                      >
                        <Tooltip
                          placement='bottomLeft'
                          title={
                            d?.created_at !== null &&
                            moment(d?.created_at).format(formatsDate)
                          }
                        >
                          <span className='max-w-[100px] block truncate'>
                            {d?.created_at !== null
                              ? moment(d?.created_at).format(formatsDate)
                              : 'N.A'}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        style={{ transform: 'translateX(-5px)' }}
                        className='py-2 w-[10%] max-w-[20%] text-right pr-2'
                      >
                        <SelectBox
                          status={d?.status}
                          ref_id={d?.ref_id}
                          page={page}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {applications && applications?.data?.length === 0 && (
              <div className='w-full xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center'>
                Oops! No applications to show
              </div>
            )}
          </div>
          <div className='flex justify-center py-5'>
            <Pagination
              showSizeChanger={false}
              current={applications?.meta?.pagination?.page}
              pageSize={10}
              onChange={(page) =>
                history.push(getSearchParamsUrl({ page: page }), {})
              }
              total={applications?.meta?.pagination?.count}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default connect(mapStateToProps)(CandidateTable);
