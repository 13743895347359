import { combineReducers } from 'redux';
import auth from './auth/reducers';
import users from './users/reducers';
import applications from './applications/reducers';
import jobs from './jobs/reducers';
import interviewers from './interviewers/reducers';
import filters from './filters/reducers';
import tasks from './tasks/reducers';
import referrals from './referrals/reducers';
import hiringTracker from './hiringTracker/reducers';
import emailTemplates from './emailTemplates/reducers'
import allCategories, {
  allQuestions,
  allQuestionMappings,
  allInterviewsForInterviewers,
  getInterviewQuestionSet,
  getInterviewFeedback,
  getInterviewOverallFeedback,
  getInterviewSetFeedback,
  getAllInterviewsForHr,
  getSingleQuestionMapping,
  getSingleInterviewInfo
} from './questionBank/reducers';
import {
  generateAQuestionSet,
  generateAQuestionSetGeneric
} from './applications/reducers';
import atsJobsReducers from './atsJobs/reducers';
import { history } from '../utilities/configureAxios';

const appReducer = combineReducers({
  auth,
  users,
  applications,
  jobs,
  interviewers,
  filters,
  tasks,
  referrals,
  allCategories,
  allQuestions,
  allQuestionMappings,
  allInterviewsForInterviewers,
  getInterviewQuestionSet,
  getInterviewFeedback,
  getInterviewOverallFeedback,
  getInterviewSetFeedback,
  getAllInterviewsForHr,
  getSingleQuestionMapping,
  generateAQuestionSet,
  generateAQuestionSetGeneric,
  getSingleInterviewInfo,
  atsJobsReducers,
  hiringTracker,
  emailTemplates
});

export default function rootReducer(state, action) {
  if (action.type === 'LOGOUT') {
    localStorage.clear();
    sessionStorage.clear();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
