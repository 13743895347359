import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class EmailTemplateServices {
    constructor() {
        this.client = apiClient;
    }
    SendEmail = (payload) => {
        const { body } = payload;
        return this.client
        .post(APIs.CUSTOM_EMAIL, body)
        .then((res) => res)
        .catch((error) => {
            return error;
        });
    };

    GetEmailTemplates = () => {
        return this.client
        .get(APIs.CUSTOM_EMAIL)
        .then((res) => res)
        .catch((error) => {
            return error;
        });
    };

    GetUrlForEmailUpload = (file_name, mime_type) => {
        return this.client
        .get(`${APIs.GET_EMAIL_UPLOAD_URL}?mime_type=${mime_type}&file_name=${file_name}`)
        .then((res) => res)
        .catch((error) => {
            return error;
        });
    };
}

export default EmailTemplateServices;