import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import AddReferral from "../../components/Modal/AddReferral";
import AllReferralsTable from "../../components/Table/AllReferralsTable";
import { history } from "../../utilities/configureAxios";

const AllReferrals = () => {
  return (
    <div className="w-full h-screen overflow-y-auto bg-ghost-white relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Referrals</title>
      </Helmet>
      <Header />
      <div className="flex justify-end mr-[32px] mb-4">
        <AddReferral heading="Add Referral" />
      </div>

      <AllReferralsTable location={history.location} />
    </div>
  );
};

export default AllReferrals;
