import { all, takeEvery, put } from "redux-saga/effects";
import { doApiCall } from "../../helper";
import actions from "./actions";
import applicationActions from '../applications/actions';
import AuthServices from "../../services/auth";
import axios from "axios";
import { notification } from "antd";
import { history } from "../../utilities/configureAxios";
import { USER_ROLE } from "../../constants/HelperConstants";
const service = new AuthServices();

export function* WATCH_LOGIN(action) {
  const response = yield doApiCall(service.Login, {
    idToken: action.payload.idToken,
  });

  if (response?.success) {
    const { id, name, email, profilePic, role, status, token } = response?.data;
    yield put({
      type: actions.SET_STATE,
      payload: { userId: id, name, email, profilePic, role, status, token },
    });
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
    yield put({
      type: applicationActions.GET_ALL_TAGS,
    })
    if (role === USER_ROLE.EMPLOYEE) {
      history.push("/employee/home");
    } else if (role === USER_ROLE.INTERVIEWER) {
      history.push("/interviewer/home");

    } else {
      history.push("/dashboard/all-candidates");
    }
    window.location.reload();
  } else {
    notification.error({
      message: "Unauthorized: Please contact your organization admin",
    });
  }
}

export function* WATCH_TOGGLE_LOADING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: action.payload },
  });
}

export function WATCH_LOGOUT(action) {
  delete axios.defaults.headers.common["Authorization"];
  // history.push(`/`);
  // window.location.reload();
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOGIN, WATCH_LOGIN)]);
  yield all([takeEvery(actions.LOGOUT, WATCH_LOGOUT)]);
  yield all([takeEvery(actions.TOGGLE_LOADING, WATCH_TOGGLE_LOADING)]);
}
