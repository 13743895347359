import apiClient from '../utilities/configureAxios';
import * as APIs from '../constants/ApiConstants';
import { ApiRoutes } from '../constants/ApiEnums';

class atsJobService {
  constructor() {
    this.client = apiClient;
  }

  PostLocation = async (payload) => {
    const { body } = payload;
    try {
      const res = await this.client.post(ApiRoutes.POST_LOCATION, body);
      return res;
    } catch (error) {
      return error;
    }
  };
  GetAllLocations = async (payload) => {
    try {
      const res = await this.client.get(ApiRoutes.GET_ALL_LOCATIONS, {
        params: { ...payload.params }
      });
      return res;
    } catch (error) {
      return error;
    }
  };

  GetSingleLocation = async (payload) => {
    const { id } = payload;
    try {
      const res = await this.client.get(
        ApiRoutes.GET_SINGLE_LOCATION.replace(':id', id)
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  EditSingleLocation = async (payload) => {
    const { id, body } = payload;
    try {
      const res = await this.client.patch(
        ApiRoutes.GET_SINGLE_LOCATION.replace(':id', id),
        body
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  PostDepartment = async (payload) => {
    const { body } = payload;
    try {
      const res = await this.client.post(ApiRoutes.POST_DEPARTMENT, body);
      return res;
    } catch (error) {
      return error;
    }
  };
  GetAllDepartments = async (payload) => {
    try {
      const res = await this.client.get(ApiRoutes.GET_ALL_DEPARTMENTS, {
        params: { ...payload.params }
      });
      return res;
    } catch (error) {
      return error;
    }
  };

  GetAllOrgs = async (payload) => {
    try {
      const res = await this.client.get(ApiRoutes.GET_ALL_ORGANIZATIONS, {
        params: { ...payload.params }
      });
      return res;
    } catch (error) {
      return error;
    }
  };

  GetSingleDepartment = async (payload) => {
    const { id } = payload;
    try {
      const res = await this.client.get(
        ApiRoutes.GET_SINGLE_DEPARTMENT.replace(':id', id)
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  EditSingleDepartment = async (payload) => {
    const { id, body } = payload;
    try {
      const res = await this.client.patch(
        ApiRoutes.GET_SINGLE_DEPARTMENT.replace(':id', id),
        body
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  GetSingleJob = async (payload) => {
    try {
      const res = await this.client.get(
        ApiRoutes.GET_SINGLE_JOB.replace(':id', payload)
      );
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default atsJobService;
