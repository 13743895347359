const actions = {
  // JOB LOCATION
  GET_ALL_LOCATIONS: 'GET_ALL_LOCATIONS',
  SET_STATE_LOCATIONS: 'SET_STATE_LOCATIONS',
  POST_LOCATION: 'POST_LOCATION',
  GET_SINGLE_LOCATION: 'GET_SINGLE_LOCATION',
  SET_SINGLE_LOCATION: 'SET_SINGLE_LOCATION',
  RESET_SINGLE_LOCATION: 'RESET_SINGLE_LOCATION',
  EDIT_LOCATION: 'EDIT_LOCATION',

  // JOB DEPARTMENT
  GET_ALL_DEPARTMENTS: 'GET_ALL_DEPARTMENTS',
  SET_STATE_DEPARTMENTS: 'SET_STATE_DEPARTMENTS',
  POST_DEPARTMENT: 'POST_DEPARTMENT',
  GET_SINGLE_DEPARTMENT: 'GET_SINGLE_DEPARTMENT',
  SET_SINGLE_DEPARTMENT: 'SET_SINGLE_DEPARTMENT',
  RESET_SINGLE_DEPARTMENT: 'RESET_SINGLE_DEPARTMENT',
  EDIT_DEPARTMENT: 'EDIT_DEPARTMENT',

  // JOBS
  POST_JOB: 'POST_JOB',
  GET_ALL_JOBS: 'GET_ALL_JOBS',
  GET_SINGLE_JOB: 'GET_SINGLE_JOB',
  EDIT_JOB: 'EDIT_JOB',
  SET_SINGLE_JOB: 'SET_SINGLE_JOB',
  GET_ALL_ORGANIZATIONS: 'GET_ALL_ORGANIZATIONS',
  SET_STATE_ORGANIZATIONS: 'SET_STATE_ORGANIZATIONS',
  //form Errors
  SET_STATE: 'SET_STATE',

  // COMMON RESET
  RESET_STATE: 'RESET_STATE'
};

export default actions;
