import moment from "moment";
import { Dispatch, FC, SetStateAction } from "react";
import {
  APPLICATION_STATUS,
  STATUS_ICON,
  TIMLINE_SPLITTER,
} from "../../constants/HelperConstants";

interface IAct {
  full_name: string;
  id: string;
  job_title: string;
}
interface IActivity {
  title: any;
  status: number;
  timestamp: string;
  activity: IAct;
  inx: number;
  length: any;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  setRole: Dispatch<SetStateAction<string>>;
}

const Activity: FC<IActivity> = ({
  title,
  status,
  timestamp,
  activity,
  inx,
  length,
  name,
  setName,
  setRole,
}) => {
  setName(activity.full_name);
  setRole(activity.job_title);

  const text = title ? title.split(TIMLINE_SPLITTER) : ["", ""];
  const convertTime = (timestamp: string) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const res = new Date(timestamp);
    const date = res.getDate();
    const month = monthNames[res.getMonth()];
    const year = res.getFullYear();
    let hour = res.getHours();
    let meridian;
    if (hour > 12) {
      hour = hour - 12;
      meridian = "PM";
    } else {
      meridian = "AM";
    }
    const min = res.getMinutes();
    return {
      date: date,
      month: month,
      year: year,
      hour: hour,
      min: min,
      meridian: meridian,
    };
  };
  const time = convertTime(timestamp);
  return (
    <div className={`${inx === 0 ? "" : "translate-x-3"}`}>
      <div className="flex space-x-5">
        <div
          className={`bg-[#E9F4FE] flex ${
            inx === 0 ? "h-[70px] w-[70px]" : "h-[45px] w-[45px]"
          }  rounded-full`}
        >
          <div className="m-auto">
            <img
              className={`${inx === 0 ? "h-14 translate-x-1" : ""}`}
              src={STATUS_ICON[status]}
              alt={APPLICATION_STATUS[status]}
            />
          </div>
        </div>
        <div className={`${inx === 0 ? "my-auto" : ""}`}>
          <div className="text-[#6F7070CC] text-[16px] my-auto">
            <span
              className={`capitalize ${
                inx === 0 ? "font-bold text-[#303C60]" : ""
              }`}
            >
              {name}
              {", "}
            </span>
            <span>{text[0]}</span>
            {text.length > 1 && (
              <span className="font-bold text-light-blue ">
                {APPLICATION_STATUS[status]}
              </span>
            )}
            <span>{text[1]}</span>
          </div>
          <div className="flex h-[17px] space-x-2 text-[#6F7070CC] text-[14px]">
            {moment(timestamp).format("dddd, MMMM Do | h:mm A")}
          </div>
        </div>
      </div>
      <div
        className={`${
          inx === length - 1 || length === 1
            ? "hidden"
            : `h-9 w-4 border-l-[1.5px] border border-r-0 border-t-0 border-b-0 ${
                inx === 0 ? "translate-x-[33px]" : "translate-x-[22px]"
              }  border-[#d3eaff]`
        }`}
      ></div>
    </div>
  );
};

export default Activity;
