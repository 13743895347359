import { Fragment } from 'react';
import { getSearchParamsUrl } from '../../helper';
import { Empty, Spin, Table as AntdTable } from 'antd';
import { history } from '../../utilities/configureAxios';
import { TableInterface } from '../../Interfaces/common';
import { PER_PAGE } from '../../constants/HelperConstants';

const Table = (props: TableInterface) => {
  return (
    <Spin size='large' className='hrTable-loader' spinning={props.loading}>
      <div className='shadow-lg overflow-y-scroll min-h-[80%] mt-[20px] rounded-lg'>
        <AntdTable
          locale={{
            emptyText: (
              <p className='w-full  xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center flex-col'>
                {!props.loading && (
                  <Fragment>
                    <Empty description={false} />
                    <span>{props.emptyText}</span>
                  </Fragment>
                )}
              </p>
            )
          }}
          columns={props.columns}
          showHeader={true}
          dataSource={props.data?.length > 0 ? props.data : []}
          pagination={{
            showSizeChanger: false,
            current: props.tablePagination?.page || 1,
            pageSize: PER_PAGE,
            onChange: (page) =>
              history.push(
                getSearchParamsUrl({ page: Number(page) ? Number(page) : 1 }),
                {}
              ),
            total: props.tablePagination?.count,
            position: ['bottomCenter']
          }}
          rowClassName={`bg-white ${props.rowClassName || ''}`}
          scroll={{ x: 'max-content' }}
          onRow={props.onRow}
        />
      </div>
    </Spin>
  );
};

export default Table;
