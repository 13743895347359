const actions = {
  SET_STATE: `SET_STATE`,
  SET_SELECTED_INTERVIEW: `SET_SELECTED_INTERVIEW`,
  GET_ALL_INTERVIEWERS: `GET_ALL_INTERVIEWERS`,
  POST_INTERVIEWERS: `POST_INTERVIEWERS`,
  UPDATE_INTERVIEWERS_PROFILE: `UPDATE_INTERVIEWERS_PROFILE`,
  DELETE_INTERVIEWERS: `DELETE_INTERVIEWERS`,
  GET_ALL_INTERVIEWERS_FOR_INTERVIEW: "GET_ALL_INTERVIEWERS_FOR_INTERVIEW",
};

export default actions;
