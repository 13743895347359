import {
  Button,
  Input,
  Modal,
  Radio,
  Select,
  Tag,
  InputNumber,
  Spin,
  Tooltip,
  Pagination
} from 'antd';
import React, { useState, useRef, useEffect, Fragment, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import CancelButton from '../../components/Buttons/Cancel';
import PrimaryButton from '../../components/Buttons/Primary';
import Header from '../../components/Header';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { history } from '../../utilities/configureAxios';
import QuestionComponent from '../../components/QuestionComponent';
import { connect, useDispatch } from 'react-redux';
import questionActions from '../../redux/questionBank/actions';
import {
  getCurrentQueryParams,
  getSearchParamsUrl,
  jobStatusOptions
} from '../../helper';
import { DeleteQuestionSet } from '../../components/Modal/DeleteQuestionSet/DeleteQuestionSet';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import DangerButton from '../../components/Buttons/Danger';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import jobActions from '../../redux/jobs/actions';
import { consoleSandbox } from '@sentry/utils';
import { BiPlus } from 'react-icons/bi';
import { number } from 'yup/lib/locale';
import { isNull, isNumber, isString } from 'lodash';
import JobsServices from '../../services/jobs';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import { queryStringToJSON } from '../../utilities/routies';
import { useLocation } from 'react-router-dom';
import JobOptionBox from '../../components/JobOptionBox/JobOptionBox';

const mapStateToProps = (state: any) => ({
  jobsForFilter: state.jobs.jobsForFilter,
  allCategories: state.allCategories.allCategories,
  allQuestionMappings: state.allQuestionMappings.allQuestionMappings,
  loading: state.auth.loading,
  getSingleQuestionMapping:
    state.getSingleQuestionMapping.getSingleQuestionMapping,
  mappingList: state.getSingleQuestionMapping.questionsList
});

const InterviewGeneration = ({
  jobsForFilter,
  allCategories,
  allQuestionMappings,
  loading,
  getSingleQuestionMapping,
  mappingList
}: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [valueRadioButtonDifficulty, setValueRadioButtonDifficulty] =
    useState<number>(1);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [currentUuid, setCurrentUuid] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number | string>();
  const [currentItem, setCurrentItem] = useState<any>();
  const [editJobTitle, setEditJobTitle] = useState<string>();
  const [editJobRefId, setEditJobRefId] = useState<string>();
  const [generateJobRefId, setGenerateJobRefId] = useState<any>();
  const [jobForFilterIdChange, setJobFilterIdChange] = useState<any>(-1);
  const [dynamicValues, setDynamicValues] = useState([
    { category: '', level: '', question_count: '' }
  ]);
  const [dynamicEditSets, setDynamicEditSets] = useState([
    { category: '', level: '', question_count: '' }
  ]);
  const [list, setList] = useState<any>();
  const [countForEditSets, setCountForEditSets] = useState<number>(0);
  const { search } = useLocation();
  const { q, status, label } = queryStringToJSON(search);

  const [jobId, setJobId] = useState('');
  const [jobRefId, setJobRefId] = useState('');
  const [jobTitle, setJobTitle] = useState<any>();
  const jobService = new JobsServices();

  function handleAddClick() {
    setIsVisible(true);
  }
  function onCancel() {
    setIsVisible(false);
    setJobRefId('');
    setJobTitle('');
    setGenerateJobRefId(undefined);
    setValueRadioButtonDifficulty(1);
    setDynamicValues([{ category: '', level: '', question_count: '' }]);
  }

  const dispatch = useDispatch();

  const formattedCategories = allCategories?.data?.map((item: any) => ({
    ...(item as Object),
    label: item?.name,
    value: item?.uuid
  }));
  const formattedJobs = jobsForFilter?.data?.map((item: any) => ({
    ...(item as Object),
    label: item?.job_title,
    value: item?.job_ref_id
  }));
  const formattedJobsForFilter = jobsForFilter?.data?.map((item: any) => ({
    ...(item as Object),
    label: item?.job_title,
    value: item?.job_ref_id
  }));

  const test = [
    {
      id: '000',
      job_ref_id: -1,
      job_title: 'All Jobs',
      label: 'All Jobs',
      value: -1
    },
    ...formattedJobsForFilter
  ];

  const { job, page } = getCurrentQueryParams();
  const noOfQues: any = [];
  const formattedNoOfQuestions = () => {
    for (let i = 1; i < 11; i++) {
      noOfQues.push({
        value: i + '',
        label: i + ''
      });
    }
  };

  formattedNoOfQuestions();
  const handleGenerateQuestion = () => {
    let body = {
      job: jobRefId,
      mappings: dynamicValues
    };
    dispatch({
      type: questionActions.GENERATE_A_QUESTION,
      payload: body
    });
    setIsVisible(false);
    setGenerateJobRefId(undefined);
    history.push(getSearchParamsUrl({ job: null, page: null }), {});
    setDynamicValues([{ category: '', level: '', question_count: '' }]);
  };

  const handleEditGenerateQuestion = () => {
    const formatList = list?.map((i: any) => {
      return {
        uuid: i?.uuid,
        category: i?.category_list?.uuid,
        question_count: i?.question_count,
        level: i?.level
      };
    });

    const formatDynamicEditSets = dynamicEditSets?.map((i: any) => {
      return {
        category: i.category,
        level: Number(i.level),
        question_count: Number(i.question_count)
      };
    });

    const res = formatDynamicEditSets.filter((o) => o.category);
    let body = {
      uuid: currentItem.uuid,
      job: Number(editJobRefId),
      mappings: res.length > 0 ? [...formatList, ...res] : [...formatList]
    };

    dispatch({
      type: questionActions.EDIT_A_QUESTION_MAPPING,
      payload: body
    });
    setIsEditModalVisible(false);
    setDynamicEditSets([{ category: '', level: '', question_count: '' }]);
    setCountForEditSets(0);
  };

  useEffect(() => {
    if (job && page) {
      setJobFilterIdChange(Number(job));
      setCurrentPage(Number(page));
      return;
    }
    if (job) {
      setJobFilterIdChange(Number(job));
      dispatch({
        type: questionActions.GET_ALL_QUESTION_MAPPINGS,
        payload: {
          page: null,
          job: job
        }
      });
      return;
    }
    if ((job === undefined || job === null || job === '') && page) {
      setJobFilterIdChange(-1);
      setCurrentPage(Number(page));
      dispatch({
        type: questionActions.GET_ALL_QUESTION_MAPPINGS,
        payload: {
          page: page
        }
      });
      return;
    }

    dispatch({
      type: questionActions.GET_ALL_QUESTION_MAPPINGS,
      payload: {
        page: 1
      }
    });
  }, [page, job]);

  const handleDeleteQuestionSet = () => {
    dispatch({
      type: questionActions.DELETE_A_QUESTION_MAPPING,
      payload: {
        status: 2,
        uuid: currentUuid,
        currentPage: currentPage
      }
    });

    setIsDeleteModalVisible(false);
  };

  const handleDeleteButtonClick = (itm: any) => {
    setCurrentUuid(itm?.uuid);
    setIsDeleteModalVisible(true);
  };

  const handleEditButtonClick = (itm: any) => {
    const UUID = itm?.uuid;
    setIsEditModalVisible(true);
    setCurrentItem(itm);
  };

  const handlePageChange = (page: number | string) => {
    history.push(getSearchParamsUrl({ page: page }), {});
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentItem) {
      setEditJobTitle(currentItem?.job_title);
      setEditJobRefId(currentItem?.job_id);
      setList(currentItem?.mappings);
    }
  }, [currentItem, isEditModalVisible]);

  const handleJobFilterChange = (id: any, key: any) => {
    if (id === -1) {
      setJobFilterIdChange(key?.job_ref_id);
      history.push(getSearchParamsUrl({ job: null, page: 1 }), {});
      dispatch({
        type: questionActions.FILTER_A_QUESTION_MAPPING_BY_JOB,
        payload: {
          page: 1
        }
      });
    } else {
      setJobFilterIdChange(key?.job_ref_id);

      history.push(getSearchParamsUrl({ job: id, page: null }), {});
      dispatch({
        type: questionActions.FILTER_A_QUESTION_MAPPING_BY_JOB,
        payload: {
          jobId: id
        }
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: questionActions.GET_ALL_CATEGORIES,
      payload: {
        pagination: false
      }
    });
    dispatch({ type: jobActions.GET_ALL_JOBS_FOR_FILTER });
  }, []);

  // for interview generation
  const handleGenerateJob = (id: any, key: any) => {
    const temp = jobsForFilter?.data?.filter((i: any) => i.job_ref_id === id);
    setGenerateJobRefId(key.job_ref_id);
  };

  const handleGenerateCategory = (id: any, key: any) => {};

  const handleEditJob = (id: any, key: any) => {
    const temp = jobsForFilter?.data?.filter((i: any) => i.job_ref_id === id);
    setEditJobTitle(key.job_title);
    setEditJobRefId(key.job_ref_id);
  };

  const handleEditCategory = (id: any, key: any) => {};

  const isNoOfQues = () => {
    if (noOfQues === undefined || noOfQues === null) {
      return true;
    } else return false;
  };

  const handleMoreBtnClick = () => {
    if (!isQuestionMappingValidated()) {
      setDynamicValues([
        ...dynamicValues,
        { category: '', level: '', question_count: '' }
      ]);
    }
  };

  // For Generate Questions Modal
  const handleChange = (value: any, key: any) => {
    let newDynamicValues = [...dynamicValues];
    if (key.category === 'category') {
      // @ts-ignore
      newDynamicValues[key.index][key.category] = key.key;
      // @ts-ignore
      newDynamicValues[key.index].level = null;
      // @ts-ignore
      newDynamicValues[key.index].question_count = null;
    }
    if (key.question_count === 'question_count') {
      // @ts-ignore
      newDynamicValues[key.index][key.question_count] = key.value;
    }
    setDynamicValues(newDynamicValues);
  };

  const handleDifficultyChange = (value: any, index: number) => {
    let newDynamicValues = [...dynamicValues];
    newDynamicValues[index].level = value.target.value;
    setDynamicValues(newDynamicValues);
  };

  // ----- For Edit Questions Modal
  const handleEditCategoryChange = (value: any, key: any) => {
    let newDynamicEditSets = [...dynamicEditSets];
    if (key.category === 'category') {
      // @ts-ignore
      newDynamicEditSets[key.index][key.category] = key.key;
      // @ts-ignore
      newDynamicEditSets[key.index].question_count = null;
      // @ts-ignore
      newDynamicEditSets[key.index].level = null;
    }
    if (key.question_count === 'question_count') {
      // @ts-ignore
      newDynamicEditSets[key.index][key.question_count] = key.value;
    }
    setDynamicEditSets(newDynamicEditSets);
  };

  const handleEditDifficultyChange = (value: any, index: number) => {
    let newDynamicEditSets = [...dynamicEditSets];
    newDynamicEditSets[index].level = value.target.value;
    setDynamicEditSets(newDynamicEditSets);
  };
  // -------------------------------

  const handleDeleteSet = (element: any, index: number) => {
    let newDynamicValues = [...dynamicValues];
    const result = newDynamicValues.splice(index, 1);
    setDynamicValues(newDynamicValues);
  };
  const handleEditDeleteSet = (element: any, index: number) => {
    let newDynamicEditValues = [...dynamicEditSets];
    const result = newDynamicEditValues.splice(index, 1);
    setCountForEditSets((prev) => prev - 1);
    setDynamicEditSets(newDynamicEditValues);
  };
  const isQuestionMappingValidated = () => {
    const validation = dynamicValues?.every(
      (item) => item.category && item.level && item.question_count
    );
    if (validation) {
      return false;
    } else return true;
  };

  const isQuestionEditModalMappingValidated = () => {
    if (countForEditSets === 0) {
      const validation2 = list?.every(
        (item: any) =>
          item.category_list.uuid && item.level && item.question_count
      );
      if (validation2) {
        return false;
      } else return true;
    } else {
      const validation1 = dynamicEditSets?.every((item) => {
        return item.category && item.level && item.question_count;
      });
      const validation2 = list?.every(
        (item: any) =>
          item.category_list.uuid && item.level && item.question_count
      );
      if (validation1 && validation2) {
        return false;
      } else return true;
    }
  };

  const handleDeleteInterviewSet = () => {
    setIsDeleteModalVisible(false);
    history.push(getSearchParamsUrl({ page: 1 }), {});
  };

  const handleSingleMappingCategory = (
    e: any,
    index: number,
    k: any,
    itm: any
  ) => {
    const spreadList = [...list];
    const response = spreadList.map((d: any, i: number) => {
      return index === i
        ? {
            ...d,
            category_list: { ...d.category_list, uuid: k.key, name: k.value },
            level: null,
            question_count: null
          }
        : { ...d };
    });
    setList(response);
  };

  const handleSingleMappingDifficultyLevel = (
    e: any,
    index: number,
    k: any,
    itm: any
  ) => {
    const spreadList = [...list];
    const response = spreadList.map((d: any, i: number) =>
      index === i ? { ...d, level: e.target.value, uuid: itm?.uuid } : { ...d }
    );
    setList(response);
  };

  const handleSingleMappingNoOfQues = (
    e: any,
    index: number,
    k: any,
    itm: any
  ) => {
    const spreadList = [...list];
    const response = spreadList.map((d: any, i: number) =>
      index === i
        ? { ...d, question_count: Number(e), uuid: itm?.uuid }
        : { ...d }
    );
    setList(response);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setDynamicEditSets([{ category: '', level: '', question_count: '' }]);
    setCountForEditSets(0);
  };

  const handleMoreEditModalBtnClick = () => {
    if (!isQuestionEditModalMappingValidated() && countForEditSets === 0) {
      setDynamicEditSets([...dynamicEditSets]);
      setCountForEditSets((prev) => prev + 1);
    }
    if (!isQuestionEditModalMappingValidated() && countForEditSets > 0) {
      setDynamicEditSets([
        ...dynamicEditSets,
        { category: '', level: '', question_count: '' }
      ]);
      setCountForEditSets((prev) => prev + 1);
    }
  };

  const handleDeleteMapping = (itm: any, index: any) => {
    let newMappingList = [...list];
    const arr = newMappingList.filter((item, i) => i !== index);
    setList(arr);
  };

  const handleOnEditModalCancel = () => {
    setIsEditModalVisible(false);
    setDynamicEditSets([{ category: '', level: '', question_count: '' }]);
    setList([]);
    setCountForEditSets(0);
    setCurrentItem(null);
  };

  const handleFilterChange = (key: string, value: any) => {
    let payload: any = {
      page: 1,
      q,
      job
    };

    payload[key] = isString(value) ? String(value) : null;

    history.push(
      getSearchParamsUrl(key?.length ? payload : { ...value, page: 1 })
    );
  };
  const customJSX = (item: any): ReactNode => {
    return JobOptionBox({ data: item });
  };

  return (
    <div className='w-full h-screen overflow-y-auto bg-ghost-white relative'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Interview Generation</title>
      </Helmet>
      <Header />
      <main>
        <div className=' m-8 flex justify-between'>
          <div className='flex flex-col min-w-[20rem] shadow hover:shadow-md hover:scale-105 transition-all duration-100 ease-in rounded-lg'>
            {/* <Select
              placeholder="Filter by Job Position"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase())
                  ? true
                  : false;
              }}
              showSearch
              value={jobForFilterIdChange}
              className="filter-select w-full border border-[#F4F7FC] rounded-lg"
              // className="filter-select w-full"
              onChange={(id, key) => handleJobFilterChange(id, key)}
              options={test}
            /> */}

            <AutoComplete
              apiService={jobService.GetAllJobsForFilter}
              key='job'
              placeholder='Filter by Job Position'
              className=' w-full border border-[#F4F7FC] rounded-lg '
              customJSX={customJSX}
              isPayload
              setselectedValue={(data: any) => {
                if (data) {
                  handleFilterChange('', {
                    job: data?.job_ref_id || null,
                    label: data?.job_title || null
                  });
                } else {
                  handleFilterChange('', {
                    job: null,
                    label: null
                  });
                }
              }}
              defaultValue={
                String(job)?.length && isString(String(job)) && label?.length
                  ? `${job}~${label}`
                  : ''
              }
            />
          </div>
          <PrimaryButton onClick={handleAddClick} text='Generate Interviews' />
        </div>
        <>
          <section>
            {!!!allQuestionMappings?.data?.length && !!!Number(job) ? (
              <Spin size='large' className='hrTable-loader' spinning={loading}>
                <div className='flex justify-center'>
                  <img
                    className='max-h-[300px]'
                    src='/generate_interview.svg'
                    alt='No Category'
                  />
                </div>
                <br />
                <div className='text-center font-semibold flex justify-center text-[#0E254B] text-opacity-40 text-[20px]'>
                  No interviews have been added yet. <br />
                  Click on the button to create interviews.
                </div>
                <br />
              </Spin>
            ) : (
              <Spin size='large' className='hrTable-loader' spinning={loading}>
                <div className='flex flex-col'>
                  <div className='shadow-lg bg-white mx-8 rounded-2xl overflow-x-auto min-h-[80%] mt-[20px]'>
                    <table className='w-full table_transition'>
                      <thead className='text-left setting-header rounded-full bg-[#233A5C] bg-opacity-5 py-4'>
                        <tr className=' text-[0.9rem] px-2'>
                          <th className='text-[#233A5C] text-center text-opacity-50 py-4 w-[10%] max-w-[10%]'>
                            S.No
                          </th>
                          <th className='text-[#233A5C] text-left text-opacity-50 py-4 w-[55%] max-w-[55%]'>
                            Job Position
                          </th>
                          <th className='text-[#233A5C] text-center text-opacity-50 py-4 w-[15%] max-w-[15%]'>
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allQuestionMappings &&
                          allQuestionMappings?.data?.length > 0 &&
                          allQuestionMappings?.data?.map(
                            (itm: any, index: number) => (
                              <tr
                                key={itm.uuid}
                                className='text-base border-bottom text-blue-zodiac table-text font-normal capitalize mb-0 whitespace-nowrap overflow-auto'
                              >
                                <td
                                  className={
                                    itm?.status === 2
                                      ? 'py-2 pr-2 w-[10%] max-w-[5rem] text-overflow-none text-center opacity-50 cursor-not-allowed'
                                      : 'py-2 pr-2 w-[10%] max-w-[5rem] text-overflow-none text-center'
                                  }
                                >
                                  {allQuestionMappings?.meta?.pagination
                                    ?.perPage *
                                    (allQuestionMappings?.meta?.pagination
                                      ?.page -
                                      1) +
                                    index +
                                    1}
                                </td>
                                <td
                                  className={
                                    itm?.status === 2
                                      ? 'py-2 pr-2 w-[15%] max-w-[15%] text-overflow-none overflow-ellipsis opacity-50 cursor-not-allowed'
                                      : 'py-2 pr-2 w-[15%] max-w-[15%] text-overflow-none overflow-ellipsis'
                                  }
                                >
                                  <Tooltip
                                    placement='bottomLeft'
                                    title={itm?.job_title}
                                  >
                                    {itm?.job_title}
                                  </Tooltip>
                                </td>
                                <td className='flex py-2 pr-2 text-overflow-none justify-center gap-2 overflow-ellipsis'>
                                  <div
                                    className='w-8 h-8 bg-background-blue rounded-md flex items-center justify-center cursor-pointer'
                                    onClick={() => handleEditButtonClick(itm)}
                                  >
                                    <MdOutlineModeEditOutline className='w-5 h-5 text-light-blue' />
                                  </div>
                                  <div
                                    className='w-8 h-8 bg-background-red rounded-md flex items-center justify-center cursor-pointer'
                                    onClick={() => handleDeleteButtonClick(itm)}
                                  >
                                    <MdDeleteOutline className='w-5 h-5 text-tamato' />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                    {allQuestionMappings &&
                      allQuestionMappings?.data?.length === 0 && (
                        <div className='w-full xl:h-[20rem] 2xl: h-[30rem] text-blue-zodiac font-medium text-[1.7rem] flex justify-center items-center'>
                          Oops! No Question Set to show
                        </div>
                      )}
                  </div>
                </div>
                <div className='flex justify-center py-5'>
                  <Pagination
                    showSizeChanger={false}
                    current={allQuestionMappings?.meta?.pagination?.page}
                    pageSize={10}
                    onChange={(page) => handlePageChange(page)}
                    total={allQuestionMappings?.meta?.pagination?.count}
                  />
                </div>
              </Spin>
            )}
          </section>
        </>
        {/* =================================== Add Modal ================================== */}
        <Modal
          destroyOnClose={true}
          open={isVisible}
          onCancel={onCancel}
          width='900px'
          className='edit-modal relative'
          bodyStyle={{
            backgroundColor: '#FAFDFF',
            borderRadius: '0.25rem'
          }}
        >
          <div className='text-xl font-semibold text-blue-zodiac mb-4'>
            Generate Interview Questions
          </div>

          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-3 right-3'
            onClick={onCancel}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
          <div className='text-[#707070]'>
            Job Position <span className='text-lg text-star-red pl-1'>*</span>
          </div>
          <div>
            {/* <Select
              value={generateJobRefId}
              showSearch
              filterOption={(input, option) => {
                return (option?.label as string)
                  ?.toLowerCase()
                  ?.includes(input.toLowerCase())
                  ? true
                  : false;
              }}
              className="filter-select w-full border border-[#F4F7FC] rounded-lg"
              onChange={(id, key) => handleGenerateJob(id, key)}
              placeholder="Choose a Job"
              options={formattedJobs}
            /> */}

            <AutoComplete
              apiService={jobService.GetAllJobsForFilter}
              key='job_id'
              placeholder='Choose a Job'
              isPayload
              setselectedValue={(data: any) => {
                if (!data) {
                  setJobRefId('');
                  setJobTitle('');
                } else {
                  setJobRefId(data?.job_ref_id);
                  setJobTitle(data?.job_title);
                }
              }}
              customJSX={customJSX}
              defaultValue={
                String(jobRefId)?.length &&
                isString(String(jobRefId)) &&
                jobTitle?.length
                  ? `${jobRefId}~${jobTitle}`
                  : ''
              }
              // reset={reset}
              className='edit-input text-base capitalize'
            />
          </div>
          {dynamicValues?.map((element, index) => (
            <section
              className='border border-[#707070] rounded-md p-4 mt-4 relative'
              key={index}
            >
              {dynamicValues?.length > 1 ? (
                <div className='flex justify-end absolute right-[1rem] top-[1rem] z-10'>
                  <div
                    className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                    onClick={() => handleDeleteSet(element, index)}
                  >
                    <AiOutlineClose className='w-5 h-5 text-gray-light' />
                  </div>
                </div>
              ) : null}
              <div className='text-[#707070] mt-4 mb-2'>
                Categories:
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <Select
                style={{ width: '100%' }}
                className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                onChange={handleChange}
                // eslint-disable-next-line no-restricted-globals
                value={
                  formattedCategories?.find(
                    (item: any) => item.value === element.category
                  )?.name
                }
                placeholder='Choose a Category'
              >
                {formattedCategories &&
                  formattedCategories?.map((item: any) => (
                    <Select.Option
                      value={item.label}
                      category={'category'}
                      index={index}
                      key={item.uuid}
                    >
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
              <div className='flex items-center mt-4'>
                <div className='text-[#707070]'>
                  Difficulty Level:
                  <span className='text-lg text-star-red pl-1'>*</span>
                </div>
                <div className='mx-8'>
                  <Radio.Group
                    style={{ display: 'flex' }}
                    onChange={(e) => handleDifficultyChange(e, index)}
                    value={element.level === '' ? null : element.level}
                  >
                    <Radio
                      style={{
                        ...(dynamicValues.filter(
                          (item: any) =>
                            item.category === element.category &&
                            item.level === 1 &&
                            item.category?.trim().length > 0 &&
                            !!Number(item.level)
                        ).length > 0
                          ? {
                              cursor: 'not-allowed',
                              opacity: '50%',
                              pointerEvents: 'none'
                            }
                          : {
                              cursor: 'pointer',
                              opacity: '100%',
                              pointerEvents: 'auto'
                            }),
                        border: '2px solid #707070',
                        padding: '15px',
                        borderRadius: '5px',
                        margin: '0px 10px 0px 0px',
                        color: '#4E9F09'
                      }}
                      value={1}
                      // disabled={dynamicValues.filter((item:any)=> item.category === element.category && item.level === 1 && item.category?.trim().length > 0 && !!Number(item.level)).length >0}
                    >
                      Easy
                    </Radio>
                    <Radio
                      style={{
                        ...(dynamicValues.filter(
                          (item: any) =>
                            item.category === element.category &&
                            item.level === 2 &&
                            item.category?.trim().length > 0 &&
                            !!Number(item.level)
                        ).length > 0
                          ? {
                              cursor: 'not-allowed',
                              opacity: '50%',
                              pointerEvents: 'none'
                            }
                          : {
                              cursor: 'pointer',
                              opacity: '100%',
                              pointerEvents: 'auto'
                            }),
                        border: '2px solid #707070',
                        padding: '15px',
                        borderRadius: '5px',
                        margin: '0px 10px 0px 0px',
                        color: '#D19E37'
                      }}
                      value={2}
                      // disabled={dynamicValues.filter((item:any)=> item.category === element.category && item.level === 2 && item.category?.trim().length > 0 && !!Number(item.level)).length >0}
                    >
                      Medium
                    </Radio>
                    <Radio
                      style={{
                        ...(dynamicValues.filter(
                          (item: any) =>
                            item.category === element.category &&
                            item.level === 3 &&
                            item.category?.trim().length > 0 &&
                            !!Number(item.level)
                        ).length > 0
                          ? {
                              cursor: 'not-allowed',
                              opacity: '50%',
                              pointerEvents: 'none'
                            }
                          : {
                              cursor: 'pointer',
                              opacity: '100%',
                              pointerEvents: 'auto'
                            }),
                        border: '2px solid #707070',
                        padding: '15px',
                        borderRadius: '5px',
                        margin: '0px 10px 0px 0px',
                        color: '#C87172'
                      }}
                      value={3}
                      // disabled={dynamicValues.filter((item:any)=> item.category === element.category && item.level === 3 && item.category??.trim().length > 0 && !!Number(item.level)).length >0}
                    >
                      Hard
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className='flex items-center mt-4'>
                <div className='text-[#707070]'>
                  No. of Questions
                  <span className='text-lg text-star-red pl-1'>*</span>
                </div>
                <div className='ml-4'>
                  <Select
                    onChange={handleChange}
                    placeholder='Choose a number'
                    id='count'
                    value={
                      element.question_count === ''
                        ? null
                        : element.question_count
                    }
                  >
                    {noOfQues &&
                      noOfQues?.map((item: any) => (
                        <Select.Option
                          value={item.label}
                          question_count={'question_count'}
                          index={index}
                          key={item.uuid}
                        >
                          {item.label}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
            </section>
          ))}
          <section className='my-4 '>
            <div
              className={
                isQuestionMappingValidated()
                  ? 'h-12 flex items-center justify-center bg-gray-lighter rounded-lg cursor-not-allowed text-gray-light font-[600] text-[0.9rem] py-4 px-2 uppercase'
                  : 'h-12 flex items-center justify-center rounded-lg cursor-pointer bg-[rgba(44,127,255,.12)] text-[#2c7fff] font-[600] text-[0.9rem] py-4 px-2 uppercase'
              }
              onClick={handleMoreBtnClick}
            >
              <AiOutlinePlus
                className={`w-5 h-5 font-bold ${
                  isQuestionMappingValidated()
                    ? 'text-gray-light'
                    : 'text-[#2c7fff]'
                }`}
              />
              <span className='ml-2'>Add More</span>
            </div>
          </section>
          <div className='flex justify-end'>
            <PrimaryButton
              disabled={!!!jobRefId || isQuestionMappingValidated()}
              onClick={handleGenerateQuestion}
              text='Save'
            />
            <div className='mx-4'>
              <CancelButton onClick={onCancel} text='Cancel' />
            </div>
          </div>
        </Modal>
        {/* ====================================Edit Modal ======================================= */}
        <Modal
          forceRender
          destroyOnClose={true}
          open={isEditModalVisible}
          onCancel={handleOnEditModalCancel}
          width='900px'
          className='edit-modal relative'
          bodyStyle={{
            backgroundColor: '#FAFDFF',
            borderRadius: '0.25rem'
          }}
        >
          <div className='text-xl font-semibold text-blue-zodiac mb-4'>
            Edit Interview Question
          </div>
          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-3 right-3'
            onClick={handleOnEditModalCancel}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
          <div className='text-[#707070]'>
            Job Position<span className='text-lg text-star-red pl-1'>*</span>
          </div>
          <div>
            <Select
              value={editJobTitle}
              className='filter-select w-full border border-[#F4F7FC] rounded-lg'
              onChange={(id, key) => handleEditJob(id, key)}
              placeholder={'Choose a Job'}
              options={formattedJobs}
              disabled
            ></Select>
          </div>
          {/*------------// mappings here for a particular set------------------------------------ */}
          {list?.map((itm: any, index: number) => (
            <section
              className='border border-[#707070] rounded-md p-4 mt-4 relative'
              key={`${itm.uuid} ${itm?.category?.uuid} ${itm?.category_list?.uuid}`}
            >
              {list?.length > 1 || countForEditSets ? (
                <div className='flex justify-end absolute right-[1rem] top-[1rem] z-10'>
                  <div
                    className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                    onClick={(itm) => handleDeleteMapping(itm, index)}
                  >
                    <AiOutlineClose className='w-5 h-5 text-gray-light' />
                  </div>
                </div>
              ) : null}

              <div className='text-[#707070] mt-4 mb-2'>
                Categories:
                <span className='text-lg text-star-red pl-1'>*</span>
              </div>
              <Select
                style={{ width: '100%' }}
                className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                onChange={(e, k) =>
                  handleSingleMappingCategory(e, index, k, itm)
                }
                placeholder='Choose a Category'
                defaultValue={itm?.category_list?.name}
              >
                {formattedCategories &&
                  formattedCategories?.map((item: any) => (
                    <Select.Option
                      value={item.label}
                      category={'category'}
                      index={index}
                      key={item.uuid}
                    >
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
              <div className='flex items-center mt-4'>
                <div className='text-[#707070]'>
                  Difficulty Level:
                  <span className='text-lg text-star-red pl-1'>*</span>
                </div>
                <div className='mx-8'>
                  <Radio.Group
                    style={{ display: 'flex' }}
                    // @ts-ignore
                    onChange={(e: any, k: any) =>
                      handleSingleMappingDifficultyLevel(e, index, k, itm)
                    }
                    defaultValue={itm?.level}
                  >
                    <Radio
                      style={{
                        ...([
                          ...list.map((formatItm: any) => ({
                            category: formatItm.category_list.uuid,
                            level: formatItm.level,
                            question_count: formatItm.question_count
                          })),
                          ...dynamicEditSets
                        ].filter(
                          (item: any) =>
                            item?.category === itm?.category_list?.uuid &&
                            item.level === 1 &&
                            item.category?.trim().length > 0 &&
                            !!Number(item.level)
                        ).length > 0
                          ? {
                              cursor: 'not-allowed',
                              opacity: '50%',
                              pointerEvents: 'none'
                            }
                          : {
                              cursor: 'pointer',
                              opacity: '100%',
                              pointerEvents: 'auto'
                            }),
                        border: '2px solid #707070',
                        padding: '15px',
                        borderRadius: '5px',
                        margin: '0px 10px 0px 0px',
                        color: '#4E9F09'
                      }}
                      value={1}
                    >
                      Easy
                    </Radio>

                    <Radio
                      style={{
                        ...([
                          ...list.map((formatItm: any) => ({
                            category: formatItm.category_list.uuid,
                            level: formatItm.level,
                            question_count: formatItm.question_count
                          })),
                          ...dynamicEditSets
                        ].filter(
                          (item: any) =>
                            item?.category === itm?.category_list?.uuid &&
                            item.level === 2 &&
                            item.category?.trim().length > 0 &&
                            !!Number(item.level)
                        ).length > 0
                          ? {
                              cursor: 'not-allowed',
                              opacity: '50%',
                              pointerEvents: 'none'
                            }
                          : {
                              cursor: 'pointer',
                              opacity: '100%',
                              pointerEvents: 'auto'
                            }),
                        border: '2px solid #707070',
                        padding: '15px',
                        borderRadius: '5px',
                        margin: '0px 10px 0px 0px',
                        color: '#D19E37'
                      }}
                      value={2}
                    >
                      Medium
                    </Radio>

                    <Radio
                      style={{
                        ...([
                          ...list.map((formatItm: any) => ({
                            category: formatItm.category_list.uuid,
                            level: formatItm.level,
                            question_count: formatItm.question_count
                          })),
                          ...dynamicEditSets
                        ].filter(
                          (item: any) =>
                            item?.category === itm?.category_list?.uuid &&
                            item.level === 3 &&
                            item.category?.trim().length > 0 &&
                            !!Number(item.level)
                        ).length > 0
                          ? {
                              cursor: 'not-allowed',
                              opacity: '50%',
                              pointerEvents: 'none'
                            }
                          : {
                              cursor: 'pointer',
                              opacity: '100%',
                              pointerEvents: 'auto'
                            }),
                        border: '2px solid #707070',
                        padding: '15px',
                        borderRadius: '5px',
                        margin: '0px 10px 0px 0px',
                        color: '#C87172'
                      }}
                      value={3}
                    >
                      Hard
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className='flex items-center mt-4'>
                <div className='text-[#707070]'>
                  No. of Questions
                  <span className='text-lg text-star-red pl-1'>*</span>
                </div>
                <div className='ml-4'>
                  <Select
                    onChange={(e, k) =>
                      handleSingleMappingNoOfQues(e, index, k, itm)
                    }
                    placeholder='Choose a number'
                    id='count'
                    defaultValue={itm?.question_count}
                  >
                    {noOfQues &&
                      noOfQues?.map((item: any) => (
                        <Select.Option
                          value={item.label}
                          question_count={'question_count'}
                          index={index}
                          key={item.uuid}
                        >
                          {item.label}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
            </section>
          ))}
          {/* ==================== Add Extra Sets to the Edit Modal ================= */}
          {countForEditSets > 0 &&
            dynamicEditSets?.map((element, index) => (
              <section
                className='border border-[#707070] rounded-md p-4 mt-4 relative'
                key={`${index} ${element?.category}`}
              >
                {list?.length || countForEditSets > 1 ? (
                  <div className='flex justify-end absolute right-[1rem] top-[1rem] z-10'>
                    <div
                      className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
                      onClick={() => handleEditDeleteSet(element, index)}
                    >
                      <AiOutlineClose className='w-5 h-5 text-gray-light' />
                    </div>
                  </div>
                ) : null}
                <div className='text-[#707070]  mt-4 mb-2'>
                  Categories:
                  <span className='text-lg text-star-red pl-1'>*</span>
                </div>
                <Select
                  style={{ width: '100%' }}
                  className='filter-select w-full border border-[#F4F7FC] rounded-lg'
                  onChange={handleEditCategoryChange}
                  value={
                    formattedCategories?.find(
                      (item: any) => item.value === element.category
                    )?.name
                  }
                  placeholder='Choose a Category'
                >
                  {formattedCategories &&
                    formattedCategories?.map((item: any) => (
                      <Select.Option
                        value={item.label}
                        category={'category'}
                        index={index}
                        key={item.uuid}
                      >
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
                <div className='flex items-center mt-4'>
                  <div className='text-[#707070]'>
                    Difficulty Level:
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </div>
                  <div className='mx-8'>
                    <Radio.Group
                      style={{ display: 'flex' }}
                      onChange={(e) => handleEditDifficultyChange(e, index)}
                    >
                      <Radio
                        style={{
                          ...([
                            ...list.map((formatItm: any) => ({
                              category: formatItm.category_list.uuid,
                              level: formatItm.level,
                              question_count: formatItm.question_count
                            })),
                            ...dynamicEditSets
                          ].filter(
                            (item: any) =>
                              item.category === element.category &&
                              item.level === 1 &&
                              item.category?.trim().length > 0 &&
                              !!Number(item.level)
                          ).length > 0
                            ? {
                                cursor: 'not-allowed',
                                opacity: '50%',
                                pointerEvents: 'none'
                              }
                            : {
                                cursor: 'pointer',
                                opacity: '100%',
                                pointerEvents: 'auto'
                              }),
                          border: '2px solid #707070',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 10px 0px 0px',
                          color: '#4E9F09'
                        }}
                        value={1}
                      >
                        Easy
                      </Radio>
                      <Radio
                        style={{
                          ...([
                            ...list.map((formatItm: any) => ({
                              category: formatItm.category_list.uuid,
                              level: formatItm.level,
                              question_count: formatItm.question_count
                            })),
                            ...dynamicEditSets
                          ].filter(
                            (item: any) =>
                              item.category === element.category &&
                              item.level === 2 &&
                              item.category?.trim().length > 0 &&
                              !!Number(item.level)
                          ).length > 0
                            ? {
                                cursor: 'not-allowed',
                                opacity: '50%',
                                pointerEvents: 'none'
                              }
                            : {
                                cursor: 'pointer',
                                opacity: '100%',
                                pointerEvents: 'auto'
                              }),
                          border: '2px solid #707070',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 10px 0px 0px',
                          color: '#D19E37'
                        }}
                        value={2}
                      >
                        Medium
                      </Radio>
                      <Radio
                        style={{
                          ...([
                            ...list.map((formatItm: any) => ({
                              category: formatItm.category_list.uuid,
                              level: formatItm.level,
                              question_count: formatItm.question_count
                            })),
                            ...dynamicEditSets
                          ].filter(
                            (item: any) =>
                              item.category === element.category &&
                              item.level === 3 &&
                              item.category?.trim().length > 0 &&
                              !!Number(item.level)
                          ).length > 0
                            ? {
                                cursor: 'not-allowed',
                                opacity: '50%',
                                pointerEvents: 'none'
                              }
                            : {
                                cursor: 'pointer',
                                opacity: '100%',
                                pointerEvents: 'auto'
                              }),
                          border: '2px solid #707070',
                          padding: '15px',
                          borderRadius: '5px',
                          margin: '0px 10px 0px 0px',
                          color: '#C87172'
                        }}
                        value={3}
                      >
                        Hard
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className='flex items-center mt-4'>
                  <div className='text-[#707070] '>
                    No. of Questions
                    <span className='text-lg text-star-red pl-1'>*</span>
                  </div>
                  <div className='ml-4'>
                    <Select
                      onChange={handleEditCategoryChange}
                      placeholder='Choose a number'
                      id='count'
                    >
                      {noOfQues &&
                        noOfQues?.map((item: any) => (
                          <Select.Option
                            value={item.label}
                            question_count={'question_count'}
                            index={index}
                            key={item.uuid}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </section>
            ))}
          <section className='my-4 '>
            <div
              className={
                isQuestionEditModalMappingValidated()
                  ? 'h-12 flex items-center justify-center bg-gray-lighter rounded-lg cursor-not-allowed text-gray-light font-[600] text-[0.9rem] py-4 px-2 uppercase'
                  : 'h-12 flex items-center justify-center rounded-lg cursor-pointer bg-[rgba(44,127,255,.12)] text-[#2c7fff] font-[600] text-[0.9rem] py-4 px-2 uppercase'
              }
              onClick={handleMoreEditModalBtnClick}
            >
              <AiOutlinePlus
                className={`w-5 h-5 font-bold ${
                  isQuestionEditModalMappingValidated()
                    ? 'text-gray-light'
                    : 'text-[#2c7fff]'
                }`}
              />
              <span className='ml-2'>Add More</span>
            </div>
          </section>
          <br />
          <div className='flex justify-end'>
            <PrimaryButton
              onClick={handleEditGenerateQuestion}
              text='Save Changes'
              disabled={isQuestionEditModalMappingValidated()}
            />
            <div className='mx-4'>
              <CancelButton onClick={handleOnEditModalCancel} text='Cancel' />
            </div>
          </div>
        </Modal>
      </main>
      {/* ==================================Delete Modal =================================== */}
      <Modal
        destroyOnClose={true}
        open={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        width='500px'
        className='edit-modal relative'
        centered
        bodyStyle={{
          backgroundColor: '#FAFDFF',
          borderRadius: '0.25rem'
        }}
      >
        <div className='flex items-center justify-between mb-2'>
          <div className='text-xl text-center font-semibold text-blue-zodiac'>
            Delete This Question?
          </div>
          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer absolute top-[-0.75rem] right-[-0.75rem]'
            onClick={handleDeleteInterviewSet}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>
        <div className='text-base text-blue-radic text-center lg:text-left mb-4'>
          Are you sure you want to delete this interview question set?
        </div>
        <div>
          <div className='flex justify-end'>
            <div className='mr-4'>
              <DangerButton onClick={handleDeleteQuestionSet} />
            </div>
            <div>
              <CancelButton
                onClick={() => setIsDeleteModalVisible(false)}
                text='Cancel'
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps)(InterviewGeneration);
