import moment from "moment";
import React from "react";
import SocialLinksFooter from "../../components/SocialLinksFooter";
import { Copyrights, ThankYou } from "../../constants/HelperConstants";
interface INo {
  sdt?: any;
}
const No: React.FC<INo> = ({ sdt }) => {
  return (
    <>
      <div className="flex justify-center">
        <div className="bg-white mx-4 rounded-[20px] max-w-[1200px]">
          <div className="flex flex-col justify-center">
            <div className="flex justify-center mt-4">
              <img
                className="md:h-[350px] lg:h-[300px]"
                src="/InterviewDeclined.svg"
                alt=""
              />
            </div>
            <h1 className="text-[2rem] text-center lg:text-[1.5rem] font-bold my-5">
              Interview Declined
            </h1>
          </div>
          <div className="text-[1.6rem] md:text-[1.5rem] lg:text-[1rem] mx-2 md:mx-6 lg:mx-[150px]">
            <p className="">
              We saw you declined the interview scheduled for{" "}
              {moment.unix(sdt).format("dddd, MMMM Do | h:mm A")}. If you have
              any questions, please feel free to contact us at
              <a
                href={`mailto:${process.env.REACT_APP_CAREERS_SB}`}
                target="_blank"
                rel="noreferrer"
                className="text-light-blue font-medium inline"
              >
                {" "}
                careers@squareboat.com.
              </a>
            </p>
            {ThankYou}
          </div>
          <SocialLinksFooter />
        </div>
      </div>
      {Copyrights}
    </>
  );
};

export default No;
