import { Switch, Route, Redirect } from "react-router-dom";
import {
  EMPLOYEE_HOME,
  // EMPLOYEE_INTERVIEWS,
  EMPLOYEE_REFERRALS,
  // INDIVIDUAL_INTERVIEW,
  // INDIVIDUAL_INTERVIEW_VIEW,
  // VIEW_FEEDBACK,
  // VIEW_QUESTION_SET_FOR_ADMIN,
} from "../constants/RouteConstants";
import ErrorPage from "../page/404Error";
import TooManyRequest from '../page/429Error'
import EmployeeHome from "../page/EmployeeHome";
import EmployeeReferrals from "../page/EmployeeReferrals";
import { history } from "../utilities/configureAxios";

function Employee() {
  return (
    <Switch location={history.location}>
      <Route path={EMPLOYEE_REFERRALS} component={EmployeeReferrals} exact />
      <Route path={EMPLOYEE_HOME} component={EmployeeHome} exact />
      {/* <Route path={EMPLOYEE_INTERVIEWS} component={EmployeeInterviews} exact />  
      <Route path={INDIVIDUAL_INTERVIEW} component={IndividualInterview} exact />  
      <Route path={INDIVIDUAL_INTERVIEW_VIEW} component={ViewFeedback} exact /> 
      <Route path={"/employee/edit-feedback/:id"} component={EditFeedback} exact /> 
      <Route path={VIEW_QUESTION_SET_FOR_ADMIN} component={ViewQuestionSet} exact />   */}
      <Route
        path="/429"
        render={() => (
          <div className="page-container flex">
            <TooManyRequest />
          </div>
        )}
      />
      {/* <Redirect to="/429" /> */}
       <Route
        path="/404"
        render={() => (
          <div className="page-container flex">
            <ErrorPage />
          </div>
        )}
      />
      <Redirect to="/404" />
    </Switch>
  );
}

export default Employee;
