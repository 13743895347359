import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.css";
import "./styles/font.css";
import App from "./App";
import "font-awesome/css/font-awesome.min.css";
import "antd/dist/antd.min.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import { createRoot } from 'react-dom/client';




// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.warn = () => { }
  console.info = () => { }
  console.trace = () => { }
  console.clear()
}


Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [new BrowserTracing()],
  // environment: process.env.REACT_APP_ENVIORNMENT,
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// After
// const container = document.getElementById('root');
// const root = createRoot(container!); // createRoot(container!) if you use TypeScript
// root.render(<App />);