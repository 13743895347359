import { all, takeEvery, put,select } from "redux-saga/effects";
import { doApiCall, getCurrentQueryParams, getSearchParamsUrl } from "../../helper";
import actions from "./actions";
import CategoryServices from "../../services/categories";
import { notification } from "antd";
import QuestionServices from "../../services/Questions";
import { history } from "../../utilities/configureAxios";
import {DASHBOARD_INTERVIEWS ,INTERVIEWER_INTERVIEWS} from "../../constants/RouteConstants";
import { USER_ROLE } from "../../constants/HelperConstants";

const categoryServices = new CategoryServices()
const questionServices = new QuestionServices()

export const allCategories = (state) => state.allCategories.allCategories
export const allQuestionMappings=(state)=> state.allQuestionMappings.allQuestionMappings
export const allQuestions=(state)=> state.allQuestions.allQuestions

export const user=(state)=>state.users.role

export function* WATCH_GET_ALL_CATEGORIES(action) {
    const response = yield doApiCall(categoryServices.GetAllCategories, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_ALL_CATEGORIES_SUCCESS,
            payload: { allCategories: response },
        });
    }
}

export function* WATCH_GET_ALL_QUESTIONS(action) {
    const response = yield doApiCall(questionServices.GetAllQuestions, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_ALL_QUESTIONS_SUCCESS,
            payload: { allQuestions: response },
        });
    }
}

export function* WATCH_GET_ALL_QUESTIONS_PAGINATED(action) {
    const response = yield doApiCall(questionServices.GetAllQuestionsPaginated, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_ALL_QUESTIONS_SUCCESS,
            payload: { allQuestions: response },
        });
    }
}


export function* WATCH_ADD_A_CATEGORY(action) {
    const response = yield doApiCall(categoryServices.AddACategory, action.payload);
    if (response?.success) {
        notification.success({ message: "Category added successfully!" });
        history.push(getSearchParamsUrl({ q:null, page: 1 }), {});
        yield put({
            type: actions.GET_ALL_CATEGORIES,
        });
    }
}

export function* WATCH_POST_A_QUESTION(action) {
    const response = yield doApiCall(questionServices.PostAQuestion, action.payload);
    if (response?.success) {
        notification.success({ message: "Question added successfully!" });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        const category = urlParams.get("category");
        const level = urlParams.get("level");
        const q = urlParams.get("q");
        let body ={}
        if ( page && Number(page)) body['page']=Number(page)
        if (level && Number(level) !== 0) body["level"] = Number(level);
        if (category && category?.trim()?.length > 0) body["category_id"] = category;
        if (q && q?.trim()?.length > 0) body["q"] = q;
        yield put({
            type: actions.GET_ALL_QUESTIONS,
            // payload: { addACategoryResponse: response },
            payload: body,
        });
    }
}


export function* WATCH_DELETE_A_CATEGORY(action) {
    const response = yield doApiCall(categoryServices.DeleteACategory, action.payload);
    if (response?.success) {
        notification.success({ message: "Category deleted successfully!" });
        let allCategoriesMeta = yield select(allCategories);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        const q = urlParams.get("q");
        let body ={}
        if (page && Number(page)) body['page']=Number(page)
        if (q && q?.trim()?.length > 0) body["q"] = q;
        if (allCategoriesMeta.data.length === 1 && body.page > 1) body.page = body.page - 1
        history.push(getSearchParamsUrl({ q: body.q, page: body.page }), {});
        yield put({
            type: actions.GET_ALL_CATEGORIES,
            payload:body
        });
    }
}
export function* WATCH_EDIT_A_CATEGORY(action) {
    const response = yield doApiCall(categoryServices.EditACategory, action.payload);
    if (response?.success) {
        notification.success({ message: "Category updated successfully!" });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        const q = urlParams.get("q");
        let body ={}
        if (page && Number(page)) body['page']=Number(page)
        if (q && q?.trim()?.length > 0) body["q"] = q;
        yield put({
            type: actions.GET_ALL_CATEGORIES,
            payload:body
        });
    }
}


export function* WATCH_EDIT_A_QUESTION(action) {
    const response = yield doApiCall(questionServices.PatchAQuestionDetail, action.payload);
    if (response?.success) {
        notification.success({ message: "Question details updated successfully!" });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        const category = urlParams.get("category");
        const level = urlParams.get("level");
        const q = urlParams.get("q");
        let body ={}
        if (page && Number(page)) body['page']=Number(page)
        if (level && Number(level) !== 0) body["level"] = Number(level);
        if (category && category?.trim()?.length > 0) body["category_id"] = category;
        if (q && q?.trim()?.length > 0) body["q"] = q;
        yield put({
            type: actions.GET_ALL_QUESTIONS,
            payload: body,
        });
    }
}

export function* WATCH_DELETE_A_QUESTION(action) {
    const response = yield doApiCall(questionServices.DeleteAQuestion, action.payload);
    if (response?.success) {
        notification.success({ message: "Question deleted successfully!" });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        const category = urlParams.get("category");
        const level = urlParams.get("level");
        const q = urlParams.get("q");
        let body ={}
        let allMetaQuestions = yield select(allQuestions);
        if (page && Number(page)) body['page']=Number(page)
        if (level && Number(level) !== 0) body["level"] = Number(level);
        if (category && category?.trim()?.length > 0) body["category_id"] = category;
        if (q && q?.trim()?.length > 0) body["q"] = q;
        if (allMetaQuestions.data.length === 1 && body.page > 1) body.page = body.page - 1
        history.push(getSearchParamsUrl({ q: body?.q, page: body?.page ,category:body?.category ,level:body?.level}), {});
        yield put({
            type: actions.GET_ALL_QUESTIONS,
            // payload: { deleteAQuestionResponse: response },
            payload: body,
        });
    }
}

// ---------------- MAPPINGS FLOW ------------------

// to generate a new question a/q to Category & Jobs
export function* WATCH_GENERATE_A_QUESTION(action) {
    const response = yield doApiCall(questionServices.GenerateAQuestion, action.payload);
    if (response?.success) {
        notification.success({ message: "Question mapping added successfully!" });
        yield put({
            type: actions.GET_ALL_QUESTION_MAPPINGS,
            payload: { page: 1 },
        });
    }
}

export function* WATCH_EDIT_A_QUESTION_MAPPING(action) {
    const response = yield doApiCall(questionServices.EditAQuestionMapping, action.payload);
    if (response?.success) {
        notification.success({ message: "Question mapping updated successfully!" });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        const job = urlParams.get("job");
        let body = {page:1}
        if ( page && Number(page)) {body.page=Number(page)}
        if(job && Number(job)) {body.job=Number(job)}
        yield put({
            type: actions.GET_ALL_QUESTION_MAPPINGS,
            payload: body,
        });
    }
}

// to get all questions for the mappings page
export function* WATCH_GET_ALL_QUESTION_MAPPINGS(action) {
    const response = yield doApiCall(questionServices.GetAllQuestionMappings, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_ALL_QUESTION_MAPPINGS_SUCCESS,
            payload: { allQuestionMappings: response },
        });
    }
}

export function* WATCH_DELETE_A_QUESTION_MAPPING(action) {
    const response = yield doApiCall(questionServices.DeleteAQuestionMapping, action.payload);
    if (response?.success) {
        notification.success({ message: "Question mapping deleted successfully!" });
        let allQuestionMappingsMeta = yield select(allQuestionMappings);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get("page");
        const job = urlParams.get("job");
        let body ={}
        if (page && Number(page)) body['page']=Number(page)
        if(job && Number(job)) body['job']=Number(job)
        if (allQuestionMappingsMeta.data.length === 1 && body.page > 1) body.page = body.page - 1
        history.push(getSearchParamsUrl({ job: body.job, page: body.page }), {});
        yield put({
            type: actions.GET_ALL_QUESTION_MAPPINGS,
            payload: body,
        });
    }
}

export function* WATCH_GET_ALL_INTERVIEWS_FOR_INTERVIEWER(action) {
    const response = yield doApiCall(questionServices.GetAllInterviewsForInterviewers, action.payload);
    if (response?.success) {
        // notification.success({ message: "Question Set Deleted Successfully!" });
        yield put({
            type: actions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER_SUCCESS,
            payload: { allInterviewsForInterviewers: response },
        });
    }
}
export function* WATCH_GET_SINGLE_INTERVIEW_INFO(action) {
    const response = yield doApiCall(questionServices.GetSingleInterviewInfo, action.payload);
    if (response?.success) {
        // notification.success({ message: "Question Set Deleted Successfully!" });
        yield put({
            type: actions.GET_SINGLE_INTERVIEW_INFO_SUCCESS,
            payload: { singleInterviewInfo:response },
        });
    }
}

export function* WATCH_GET_INTERVIEW_QUESTION_SET(action) {
    const response = yield doApiCall(questionServices.GetInterviewQuestionSet, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_INTERVIEW_QUESTION_SET_SUCCESS,
            payload: { getInterviewQuestionSet: response?.data?.[0], questionsList: response?.data[0]?.questions_list },
        });
    }
}

export function* WATCH_SEND_OVERALL_FEEDBACK(action) {
    const response = yield doApiCall(questionServices.PostOverallFeedback, action.payload);
    if (response?.success) {
        notification.success({ message: "Feedback sent" });
        // history.back();
        let role = yield select(user);
        if (role === USER_ROLE.INTERVIEWER){
            history.push(`${INTERVIEWER_INTERVIEWS}`)
        }else{
            history.push(`${DASHBOARD_INTERVIEWS}`)
        }

        yield put({
            type: actions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER_SUCCESS
            // payload: { getInterviewQuestionSet: response },
        });
        yield put({
            type: actions.GET_INTERVIEW_OVERALL_FEEDBACK_SUCCESS,
            payload: { getInterviewOverallFeedback: response },
        });
    }
}

export function* WATCH_SEND_QUESTION_SET_FEEDBACK(action) {
    const response = yield doApiCall(questionServices.PostQuestionSetFeedback, action.payload);
    if (response?.success) {
        notification.success({ message: "Feedback for questions sent" });
        yield put({
            type: actions.GET_INTERVIEW_QUESTION_SET_SUCCESS,
            // payload: { getInterviewQuestionSet: response },
        });
    }
}

export function* WATCH_GET_INTERVIEW_FEEDBACK(action) {
    const response = yield doApiCall(questionServices.GetInterviewFeedback, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_INTERVIEW_FEEDBACK_SUCCESS,
            payload: { getInterviewFeedback: response },
        });
    }
}

export function* WATCH_GET_INTERVIEW_OVERALL_FEEDBACK(action) {
    const response = yield doApiCall(questionServices.GetInterviewOverallFeedback, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_INTERVIEW_OVERALL_FEEDBACK_SUCCESS,
            payload: { getInterviewOverallFeedback: response },
        });
    }
}

export function* WATCH_GET_INTERVIEW_SET_FEEDBACK(action) {
    const response = yield doApiCall(questionServices.GetInterviewSetFeedback, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_INTERVIEW_SET_FEEDBACK_SUCCESS,
            payload: { getInterviewSetFeedback: response?.data[0] },
        });
    }
}

export function* WATCH_GET_ALL_INTERVIEWS_FOR_HR(action) {
    const response = yield doApiCall(questionServices.GetAllInterviewForHr, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_ALL_INTERVIEWS_FOR_HR_SUCCESS,
            payload: { getAllInterviewsForHr: response },
        });
    }
}

export function* WATCH_GET_SINGLE_QUESTION_MAPPING(action) {
    const response = yield doApiCall(questionServices.GetASingleQuestionMapping, action.payload);
    if (response?.success) {
        yield put({
            type: actions.GET_SINGLE_QUESTION_MAPPING_SUCCESS,
            payload: { getSingleQuestionMapping: response, mappingList : response?.data?.mapping_list },
        });
    }
}

export function* WATCH_PUT_EDIT_INTERVIEW_FEEDBACK(action) {
    const response = yield doApiCall(questionServices.EditAInterviewFeedback, action.payload);
    if (response?.success) {
        notification.success({ message: "Feedbacks edited successfully" });
        // history.back()
        let role = yield select(user);
        if (role === USER_ROLE.INTERVIEWER){
            history.push(`${INTERVIEWER_INTERVIEWS}`)
        }else{
            history.push(`${DASHBOARD_INTERVIEWS}`)
        }
        // yield put({
        //     type: actions.GET_SINGLE_QUESTION_MAPPING_SUCCESS,
        //     payload: { getSingleQuestionMapping: response, mappingList : response?.data?.mapping_list },
        // });
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ALL_CATEGORIES, WATCH_GET_ALL_CATEGORIES)]);
    yield all([takeEvery(actions.ADD_A_CATEGORY, WATCH_ADD_A_CATEGORY)]);
    yield all([takeEvery(actions.DELETE_A_CATEGORY, WATCH_DELETE_A_CATEGORY)]);
    yield all([takeEvery(actions.POST_A_QUESTION, WATCH_POST_A_QUESTION)]);
    yield all([takeEvery(actions.GET_ALL_QUESTIONS, WATCH_GET_ALL_QUESTIONS)]);
    yield all([takeEvery(actions.EDIT_A_QUESTION, WATCH_EDIT_A_QUESTION)]);
    yield all([takeEvery(actions.DELETE_A_QUESTION, WATCH_DELETE_A_QUESTION)]);
    yield all([takeEvery(actions.GET_ALL_QUESTIONS_PAGINATED, WATCH_GET_ALL_QUESTIONS_PAGINATED)]);
    yield all([takeEvery(actions.EDIT_A_CATEGORY, WATCH_EDIT_A_CATEGORY)]);
    yield all([takeEvery(actions.GENERATE_A_QUESTION, WATCH_GENERATE_A_QUESTION)]);
    yield all([takeEvery(actions.EDIT_A_QUESTION_MAPPING, WATCH_EDIT_A_QUESTION_MAPPING)]);
    yield all([takeEvery(actions.GET_ALL_QUESTION_MAPPINGS, WATCH_GET_ALL_QUESTION_MAPPINGS)]);
    yield all([takeEvery(actions.DELETE_A_QUESTION_MAPPING, WATCH_DELETE_A_QUESTION_MAPPING)]);
    // yield all([takeEvery(actions.FILTER_A_QUESTION_MAPPING_BY_JOB, WATCH_FILTER_A_QUESTION_MAPPING_BY_JOB)]);
    yield all([takeEvery(actions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER, WATCH_GET_ALL_INTERVIEWS_FOR_INTERVIEWER)]);
    yield all([takeEvery(actions.GET_SINGLE_INTERVIEW_INFO, WATCH_GET_SINGLE_INTERVIEW_INFO)]);
    yield all([takeEvery(actions.GET_INTERVIEW_QUESTION_SET, WATCH_GET_INTERVIEW_QUESTION_SET)]);
    yield all([takeEvery(actions.SEND_OVERALL_FEEDBACK, WATCH_SEND_OVERALL_FEEDBACK)]);
    yield all([takeEvery(actions.SEND_QUESTION_SET_FEEDBACK, WATCH_SEND_QUESTION_SET_FEEDBACK)]);
    yield all([takeEvery(actions.GET_INTERVIEW_FEEDBACK, WATCH_GET_INTERVIEW_FEEDBACK)]);
    yield all([takeEvery(actions.GET_INTERVIEW_OVERALL_FEEDBACK, WATCH_GET_INTERVIEW_OVERALL_FEEDBACK)]);
    yield all([takeEvery(actions.GET_INTERVIEW_SET_FEEDBACK, WATCH_GET_INTERVIEW_SET_FEEDBACK)]);
    yield all([takeEvery(actions.GET_ALL_INTERVIEWS_FOR_HR, WATCH_GET_ALL_INTERVIEWS_FOR_HR)]);
    yield all([takeEvery(actions.GET_SINGLE_QUESTION_MAPPING, WATCH_GET_SINGLE_QUESTION_MAPPING)]);
    yield all([takeEvery(actions.PUT_EDIT_INTERVIEW_FEEDBACK, WATCH_PUT_EDIT_INTERVIEW_FEEDBACK)]);
} 
