import actions from './actions';

const initialState = {
  loading: false,
  allLocations: {},
  singleLocation: {},
  allDepartments: {},
  singleDepartment: {},
  allJobs: {},
  singleJob: {},
  organizations: {},
  formErrors: {}
};

export default function atsJobsReducers(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case actions.SET_STATE_LOCATIONS: {
      return { ...state, allLocations: { ...payload } };
    }
    case actions.SET_SINGLE_LOCATION: {
      return { ...state, singleLocation: { ...payload } };
    }
    case actions.RESET_SINGLE_LOCATION: {
      return { ...state, singleLocation: {} };
    }
    case actions.RESET_STATE: {
      return { ...initialState };
    }
    case actions.SET_STATE_DEPARTMENTS: {
      return { ...state, allDepartments: { ...payload } };
    }
    case actions.SET_SINGLE_DEPARTMENT: {
      return { ...state, singleDepartment: { ...payload } };
    }
    case actions.RESET_SINGLE_DEPARTMENT: {
      return { ...state, singleDepartment: {} };
    }
    case actions.SET_SINGLE_JOB: {
      return { ...state, singleJob: { ...payload } };
    }
    case actions.SET_STATE_ORGANIZATIONS: {
      return { ...state, organizations: { ...payload } };
    }
    case actions.SET_STATE: {
      return { ...state, formErrors: { ...payload } };
    }
    case actions.RESET_STATE: {
      return {
        ...state,
        singleJob: {}
      };
    }
    case actions.RESET_SINGLE_JOB: {
      return { ...state, singleJob: {} };
    }
    default: {
      return state;
    }
  }
}
