const actions = {
    GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
    GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
    ADD_A_CATEGORY: "ADD_A_CATEGORY",
    DELETE_A_CATEGORY: "DELETE_A_CATEGORY",
    POST_A_QUESTION: "POST_A_QUESTION",
    GET_ALL_QUESTIONS: "GET_ALL_QUESTIONS",
    GET_ALL_QUESTIONS_SUCCESS: "GET_ALL_QUESTIONS_SUCCESS",
    EDIT_A_QUESTION: "EDIT_A_QUESTION",
    DELETE_A_QUESTION: "DELETE_A_QUESTION",
    GET_ALL_QUESTIONS_PAGINATED: "GET_ALL_QUESTIONS_PAGINATED",
    EDIT_A_CATEGORY: "EDIT_A_CATEGORY",
    GENERATE_A_QUESTION: "GENERATE_A_QUESTION",
    EDIT_A_QUESTION_MAPPING: "EDIT_A_QUESTION_MAPPING",
    GET_ALL_QUESTION_MAPPINGS: "GET_ALL_QUESTION_MAPPINGS",
    GET_ALL_QUESTION_MAPPINGS_SUCCESS: "GET_ALL_QUESTION_MAPPINGS_SUCCESS",
    DELETE_A_QUESTION_MAPPING: "DELETE_A_QUESTION_MAPPING",
    FILTER_A_QUESTION_MAPPING_BY_JOB: "FILTER_A_QUESTION_MAPPING_BY_JOB",
    GET_ALL_INTERVIEWS_FOR_INTERVIEWER: "GET_ALL_INTERVIEWS_FOR_INTERVIEWER",
    GET_ALL_INTERVIEWS_FOR_INTERVIEWER_SUCCESS: "GET_ALL_INTERVIEWS_FOR_INTERVIEWER_SUCCESS",
    GET_INTERVIEW_QUESTION_SET: "GET_INTERVIEW_QUESTION_SET",
    GET_INTERVIEW_QUESTION_SET_SUCCESS: "GET_INTERVIEW_QUESTION_SET_SUCCESS",
    RESET_INTERVIEW_QUESTION_SET: "RESET_INTERVIEW_QUESTION_SET",
    RESET_INTERVIEW_FEEDBACK_QUESTION_SET: "RESET_INTERVIEW_FEEDBACK_QUESTION_SET",
    RESET_INTERVIEW_OVERALL_FEEDBACK: "RESET_INTERVIEW_OVERALL_FEEDBACK",
    SEND_OVERALL_FEEDBACK: "SEND_OVERALL_FEEDBACK",
    HANDLE_QUESTION_CHANGE: "HANDLE_QUESTION_CHANGE",
    SEND_QUESTION_SET_FEEDBACK: "SEND_QUESTION_SET_FEEDBACK",
    GET_INTERVIEW_FEEDBACK: "GET_INTERVIEW_FEEDBACK",
    GET_INTERVIEW_FEEDBACK_SUCCESS: "GET_INTERVIEW_FEEDBACK_SUCCESS",
    GET_INTERVIEW_OVERALL_FEEDBACK: "GET_INTERVIEW_OVERALL_FEEDBACK",
    GET_INTERVIEW_OVERALL_FEEDBACK_SUCCESS: "GET_INTERVIEW_OVERALL_FEEDBACK_SUCCESS",
    GET_INTERVIEW_SET_FEEDBACK: "GET_INTERVIEW_SET_FEEDBACK",
    GET_INTERVIEW_SET_FEEDBACK_SUCCESS: "GET_INTERVIEW_SET_FEEDBACK_SUCCESS",
    GET_ALL_INTERVIEWS_FOR_HR: "GET_ALL_INTERVIEWS_FOR_HR",
    GET_ALL_INTERVIEWS_FOR_HR_SUCCESS: "GET_ALL_INTERVIEWS_FOR_HR_SUCCESS",
    GET_SINGLE_QUESTION_MAPPING: "GET_SINGLE_QUESTION_MAPPING",
    GET_SINGLE_QUESTION_MAPPING_SUCCESS: "GET_SINGLE_QUESTION_MAPPING_SUCCESS",
    HANDLE_MAPPING_LIST_CHANGE: "HANDLE_MAPPING_LIST_CHANGE",
    PUT_EDIT_INTERVIEW_FEEDBACK: "PUT_EDIT_INTERVIEW_FEEDBACK",
    GET_SINGLE_INTERVIEW_INFO:"GET_SINGLE_INTERVIEW_INFO",
    GET_SINGLE_INTERVIEW_INFO_SUCCESS:"GET_SINGLE_INTERVIEW_INFO_SUCCESS",
    GET_SINGLE_INTERVIEW_INFO_RESET:"GET_SINGLE_INTERVIEW_INFO_RESET"
};

export default actions;