import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import AddReferral from "../../components/Modal/AddReferral";
import EmployeeReferralTable from "../../components/Table/EmployeeReferralTable";

const EmployeeReferrals = () => {
  return (
    <div className="w-full  h-screen  overflow-y-auto bg-ghost-white relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Referrals</title>
      </Helmet>
      <Header />

      <div className="flex justify-end mr-[32px] mb-4">
        <AddReferral heading="Add Referral" />
      </div>

      <EmployeeReferralTable />
    </div>
  );
};

export default EmployeeReferrals;
