import { Fragment, useState } from 'react';
import QueryString from 'qs';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import Yes from './yes';
import No from './no';
import Rechedule from './rechedule';
import apiClient from '../../utilities/configureAxios';
import Error from './Error';
import Submitted from './Submitted';

const mapStateToProps = (state: any) => ({
  concentLoading: state.applications.concentLoading
});

type Params = {
  id: string;
  uuid: string;
};

const InterviewResponse = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const queryParams = window.location?.search.slice(1);
  const { mr, sdt } = QueryString.parse(queryParams);
  const { id, uuid } = useParams<Params>();
  const objectMap: any = {
    1: <Yes sdt={sdt} />,
    2: <No sdt={sdt} />,
    3: <Rechedule id={id} uuid={uuid} mr={mr} sdt={sdt} />
  };
  const localObj = {
    res: mr,
    timestamp: new Date()
  };
  let body: any;
  if (Number(mr) === 1 || Number(mr) === 2) {
    body = {
      mail_response: mr
    };
  }

  useEffect(() => {
    if (Number(mr) === 1 || Number(mr) === 2) {
      const fetchApi = async () => {
        apiClient
          .post(
            `${process.env.REACT_APP_API_URL}/applications/${id}/interviews-concent/${uuid}`,
            body
          )
          .then((res) => {
            setLoading(false);
            return res;
          })
          .catch((error) => {
            const { response } = error;
            setError(response?.data?.errors);
            setLoading(false);
          });
      };
      fetchApi();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className='bg-[#f3f9fe] min-h-screen h-full w-full'>
      <div className='pt-10 pl-12 mb-4'>
        <img
          src='/SquareboatLogo.png'
          alt='Logo'
          className='h-[45px] mb-[2rem]'
        />
      </div>
      {!loading ? (
        <Fragment>
          {!error?.length && !loading && objectMap[Number(mr)]}
          {error?.length && <Error error={error} />}
          {Number(mr) !== 1 && Number(mr) !== 2 && Number(mr) !== 3 && (
            <Error error={error} />
          )}
        </Fragment>
      ) : (
        <div className='flex justify-center items-center min-h-[70vh] w-full'>
          <Spin size='large' spinning={loading} />
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(InterviewResponse);
