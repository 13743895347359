import actions from './actions';

const initialState = {
  jobs: {},
  jobsForFilter: {},
  jobsForAddApplicant: {},
  search: '',
  formErrors: {},
  skills: {},
};

export default function jobsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_STATE: {
      return { ...state, ...payload };
    }
    case actions.RESET_STATE: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
}
