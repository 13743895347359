import moment from 'moment';
import { Fragment } from 'react';
import { AlignType } from 'rc-table/lib/interface';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { AiOutlineEye } from 'react-icons/ai';
import { APPLICATION_STATUS_SELECT } from '../../../constants/HelperConstants';

const useColumns = ({
  page,
  handleModal
}: {
  page: string | undefined;
  handleModal: (slug: string) => void;
}) => {
  const columns = [
    {
      title: 'Ref ID',
      key: 'ref_id',
      dataIndex: 'ref_id',
      align: 'center' as AlignType,
      width: 120,
      render: (text: string) => (
        <div className='w-[120px] truncate'>
          <Tooltip title={text}>{text}</Tooltip>
        </div>
      )
    },
    {
      title: 'Name',
      key: 'applicant_name',
      dataIndex: 'applicant_name',
      width: 150,
      render: (text: any) => (
        <div className='w-[150px] truncate'>
          <Tooltip title={text}>{text}</Tooltip>
        </div>
      )
    },
    {
      title: 'Job Position',
      key: 'job_title',
      dataIndex: 'job_title',
      width: 250,
      render: (text: string) => (
        <div className='w-[250px] truncate'>
          <Tooltip title={text || ''}>{text || '-'}</Tooltip>
        </div>
      )
    },
    {
      title: 'Hr Assigned',
      key: 'ta_name',
      dataIndex: 'ta_name',
      width: 150,
      render: (text: string) => (
        <div className='w-[150px] truncate'>
          <Tooltip title={text || ''}>{text || '-'}</Tooltip>
        </div>
      )
    },
    {
      title: 'Email',
      key: 'applicant_email',
      dataIndex: 'applicant_email',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => (
        <div className='w-[200px] truncate'>
          <Tooltip title={text || ''}>
            {text ? <Link to={`mailTo:${text}`}>{text}</Link> : '-'}
          </Tooltip>
        </div>
      )
    },
    {
      title: 'Contact Number',
      key: 'applicant_contact',
      dataIndex: 'applicant_contact',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => <Link to={`tel:${text}`}>{text}</Link>
    },
    {
      title: 'Graduation',
      key: 'graduation_year',
      dataIndex: 'graduation_year',
      width: 150,
      align: 'center' as AlignType,
      render: (text: number) => <Fragment>{text || '-'}</Fragment>
    },
    {
      title: 'CTC',
      key: 'ctc_in_rupees',
      dataIndex: 'ctc_in_rupees',
      width: 200,
      align: 'center' as AlignType,
      render: (text: number) => (
        <Fragment>
          {text
            ? text > 200000
              ? `${(text / 100000).toFixed(1)} Lakhs`
              : `${(text / 100000).toFixed(1)} Lakh`
            : '0 Lakh'}
        </Fragment>
      )
    },
    {
      title: 'Status of Application',
      key: 'status',
      dataIndex: 'status',
      width: 200,
      align: 'center' as AlignType,
      render: (text: number) => (
        <div className='w-[200px] truncate'>
          <Tooltip
            title={
              text
                ? text === 1
                  ? 'Pending'
                  : APPLICATION_STATUS_SELECT[text]
                : ''
            }
          >
            {text
              ? text === 1
                ? 'Pending'
                : APPLICATION_STATUS_SELECT[text]
              : '-'}
          </Tooltip>
        </div>
      )
    },
    {
      title: 'Round 1 Date',
      key: 'r1_date',
      dataIndex: 'r1_date',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => (
        <Fragment>
          {text ? moment(text).utc().format('DD-MM-YYYY').toString() : '-'}
        </Fragment>
      )
    },
    {
      title: 'Round 2 Date',
      key: 'r2_date',
      dataIndex: 'r2_date',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => (
        <Fragment>
          {text ? moment(text).utc().format('DD-MM-YYYY').toString() : '-'}
        </Fragment>
      )
    },
    {
      title: 'Round 3 Date',
      key: 'r3_date',
      dataIndex: 'r3_date',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => (
        <Fragment>
          {text ? moment(text).utc().format('DD-MM-YYYY').toString() : '-'}
        </Fragment>
      )
    },
    {
      title: 'Round 4 Date',
      key: 'r4_date',
      dataIndex: 'r4_date',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => (
        <Fragment>
          {text ? moment(text).utc().format('DD-MM-YYYY').toString() : '-'}
        </Fragment>
      )
    },
    {
      title: 'Round 5 Date',
      key: 'r5_date',
      dataIndex: 'r5_date',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => (
        <Fragment>
          {text ? moment(text).utc().format('DD-MM-YYYY').toString() : '-'}
        </Fragment>
      )
    },
    {
      title: 'Sourcing Date',
      key: 'sourcing_date',
      dataIndex: 'sourcing_date',
      width: 200,
      align: 'center' as AlignType,
      render: (text: string) => (
        <Fragment>
          {text ? moment(text).utc().format('DD-MM-YYYY').toString() : '-'}
        </Fragment>
      )
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      align: 'center' as AlignType,
      className: 'pointer-events-none',
      render: (text: string, record: any) => (
        <div className='w-full flex items-center justify-center'>
          <Tooltip title='View Status'>
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (record?.applicant_slug) {
                  handleModal(record?.applicant_slug);
                }
              }}
              className={`w-8 h-8  rounded-md flex items-center justify-center ${
                record?.status === 0
                  ? 'cursor-not-allowed pointer-events-none text-gray-light bg-gray-lighter'
                  : 'cursor-pointer text-light-blue bg-background-blue pointer-events-auto'
              }`}
            >
              <AiOutlineEye className='w-5 h-5 font-semibold' />
            </div>
          </Tooltip>
        </div>
      )
    }
  ];
  return {
    columns
  };
};

export default useColumns;
