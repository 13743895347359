import SocialLinksFooter from "../../components/SocialLinksFooter";
import { Copyrights, ThankYou } from "../../constants/HelperConstants";
const RescheduleSuccess = () => {
  return (
    <>
      <div className="flex justify-center">
        <div className="bg-white mx-4 rounded-[20px] max-w-[1500px]">
          <div className="flex flex-col justify-center">
            <div className="flex justify-center mt-4">
              <img
                className="md:h-[350px] lg:h-[300px]"
                src="/InterviewSchSucces.svg"
                alt=""
              />
            </div>
            <h1 className="text-[2rem] text-center lg:text-[1.5rem] font-bold my-5">
              Proposed Time Request Sent
            </h1>
          </div>
          <div className="text-[1.6rem] md:text-[1.5rem] lg:text-[1rem] md:mx-6 lg:mx-[150px]">
            <p className="flex justify-start">
              Hope you are doing well. Thanks for the proposed time. HR will let
              you know about further details.
            </p>
            <p>
              If you have any questions, please feel free to contact us at
              <a
                href={`mailto:${process.env.REACT_APP_CAREERS_SB}`}
                target="_blank"
                rel="noreferrer"
                className="text-light-blue font-medium"
              >
                {" "}
                careers@squareboat.com.
              </a>
            </p>
            <br />
            {ThankYou}
          </div>
          <SocialLinksFooter />
        </div>
      </div>
      {Copyrights}
    </>
  );
};

export default RescheduleSuccess;
