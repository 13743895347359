import { Dropdown, Input, Menu, Modal } from "antd";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import CancelButton from "../../Buttons/Cancel";
import PrimaryButton from "../../Buttons/Primary";
import { connect, useDispatch } from "react-redux";
import authActions from "../../../redux/auth/actions";
import { useHistory } from "react-router-dom";
import { Cookies } from "react-cookie";
import { notification } from 'antd';

interface IProfile {
  profilePic: any;
  name: any;
  email: any;
  role: any;
}

const mapStateToProps = (state: any) => ({
  profilePic: state.auth.profilePic,
  name: state.auth.name,
  email: state.auth.email,
  role: state.auth.role,
});

const ProfileDropdown: React.FC<IProfile> = ({
  profilePic,
  name,
  email,
  role,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const cookie = new Cookies();
  const handleCancel = () => {
    setIsOpen(false);
  };



  const history = useHistory();

  const dispatch = useDispatch();

  const handleNameChange = () => {};

  const handleLogout = async () => {
    const arr = Object.keys(cookie.getAll());
    for (let i = 0; i < arr.length; i++) {
      cookie.remove(arr[i]);
    }
    window.location.replace('/')
    dispatch({ type: authActions.LOGOUT, payload: { history } });
  };

  const menu = (
    <Menu className="profile-menu space-y-2">
      <Menu.Item
        key="logout"
        className="text-lg text-blue-radic"
        onClick={handleLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown className="cursor-pointer" overlay={menu} trigger={["click"]}>
        <MdKeyboardArrowDown size={30} />
      </Dropdown>

      <Modal
        className="edit-profile"
        visible={isOpen}
        onCancel={handleCancel}
        style={{ top: 20 }}
      >
        <div className="flex justify-between md:pl-10">
          <h3 className="text-lg uppercase font-semibold text-blue-radic">
            EDIT PROFILE
          </h3>
          <div
            className="w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer"
            onClick={handleCancel}
          >
            <AiOutlineClose className="w-5 h-5 text-gray-light" />
          </div>
        </div>
        <div className="flex justify-center items-center space-x-4 my-8">
          <div className="w-[100px] h-[102px]">
            <img
              src={profilePic ? profilePic : null}
              alt=""
              className="profile-pic"
              width="45px"
              height="45px"
            />
          </div>
        </div>
        <div className="mt-10 md:px-10 -space-y-2">
          <p className="text-base text-blue-streak opacity-50">
            NAME<sup className="text-base text-star-red mt-2">*</sup>
          </p>
          <Input
            className="edit-input text-base"
            placeholder="Name"
            type="text"
            onChange={handleNameChange}
            defaultValue={name}
          />
        </div>
        <div className="flex justify-center mt-10 mb-5 lg:px-16 space-x-4 md:space-x-8">
          <PrimaryButton text="Update" />
          <CancelButton onClick={handleCancel} />
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(ProfileDropdown);
