import { Button, Modal } from "antd";
import { useEffect } from "react";
import { FC, useState } from "react";
import { useDispatch, connect } from "react-redux";
import applicationActions from "../../../redux/applications/actions";

const mapStateToProps = (state: any) => ({
  open: state.applications.openCommentModal,
});

interface DeleteProps {
  heading: string;
  commentId: string;
  desc: string;
  userId?: any;
  open: boolean;
}

const DeleteComment: FC<DeleteProps> = ({
  heading,
  desc,
  userId,
  open,
  commentId,
}) => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    if (open){setLoading(false)}
  
  }, [open])
  
  const onDelete = () => {
    setLoading(true)
    dispatch({
      type: applicationActions.DELETE_COMMENT,
      payload: {
        ref_id: userId,
        commentId: commentId,
      },
    });
  };
  return (
    <>
      <Modal
        className="delete-modal"
        centered
        open={open}
        onCancel={() =>
          dispatch({
            type: applicationActions.TOGGLE_COMMENT_MODAL,
            payload: false,
          })
        }
      >
        <div className="flex justify-between">
          <div className="px-4 md:px-10">
            <h3 className="text-xl uppercase font-semibold text-blue-radic">
              {heading}
            </h3>
          </div>
        </div>
        <div className="mt-10 md:mt-10 px-4 md:px-10">
          <p className="text-base text-blue-radic text-center lg:text-left">
            {desc}
          </p>
        </div>
        <div className="flex justify-center md:px-12 pt-4 pb-6 space-x-4 lg:space-x-7 mt-7">
          <Button className="yesBtn" onClick={onDelete} loading={loading}>
            Yes
          </Button>
          <button
            className="noBtn"
            onClick={(e) =>{
              dispatch({
                type: applicationActions.TOGGLE_COMMENT_MODAL,
                payload: false,
              })
            }
            }
          >
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(DeleteComment);
