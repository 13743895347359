import { useEffect, useState } from 'react';
import { Spin, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import moment from 'moment';
import Activity from '../../components/Activities';
import SocialLinksFooter from '../../components/SocialLinksFooter';
import { getCurrentQueryParams } from '../../helper';
import PrimaryButton from '../../components/Buttons/Primary';
import DangerButton from '../../components/Buttons/Danger';
import CancelButton from '../../components/Buttons/Cancel';
import { interviewScheduleURl } from '../../constants/HelperConstants';

type Application = {
  full_name: string;
  id: string;
  job_title: string;
};

interface IActivity {
  action: number;
  application: Application;
  title: string;
  created_at: string;
  id: string;
}

type Params = {
  slug: string;
};

interface ITimelineProps {
  customSlug?: string;
  noPadding?: boolean;
}

const Timeline: React.FC<ITimelineProps> = ({ customSlug, noPadding }) => {
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [show, setShow] = useState(false);
  const { slug } = useParams<Params>();
  const queryParams = getCurrentQueryParams();
  useEffect(() => {
    const fetchApi = async () => {
      let payloadSlug = slug;
      if (customSlug) {
        payloadSlug = customSlug;
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/applications/webview/${payloadSlug}`
      )
        .then((res) => res.json())
        .catch(() => {
          setShow(true);
        });
      if (res.success === true) {
        const { data } = res;
        setActivities(data);
        setOpen(
          Boolean(
            queryParams?.icID?.length &&
              queryParams?.sdt?.length &&
              data?.length > 0
          )
        );
        setShow(true);
      } else {
        setActivities([]);
        setShow(true);
      }
    };
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, customSlug]);

  return (
    <div className={`${customSlug ? '' : 'bg-[#f3f9fe]'} h-full w-full`}>
      <div
        className={
          customSlug
            ? 'p-0'
            : 'px-5 lg:px-[60px] lg:pt-[30px] max-w-[1920px] mx-auto pb-10'
        }
      >
        {/* ---new component using grid */}
        {!customSlug ? (
          <div className='grid grid-cols-3 mb-[60px] '>
            <div className='lg:h-[26px] lg:w-[130px]'>
              <img src='/SquareboatLogo.png' alt='Homepage Icon' />
            </div>
            <h1 className='font-bold text-center text-[#2C7FFF] text-[30px] leading-[36px]'>
              Application Status
            </h1>
            <div />
          </div>
        ) : null}
        {/* --- */}
        <Spin className='hrTable-loader' spinning={!show} size='large'>
          <div
            className={`bg-[#fff] rounded-[10px] ${
              customSlug ? '' : 'min-h-screen'
            }`}
          >
            <div className={`${noPadding ? 'px-[0]' : 'px-[20px]'} pt-[31px]`}>
              {/* ----- */}
              {!customSlug ? (
                <div className='pb-8 flex space-x-4'>
                  <div className='flex items-center justify-center h-[50px] w-[50px] rounded-full bg-[#2D94F31A] relative'>
                    <img
                      className='absolute'
                      src='/Icons/Person.svg'
                      alt='PersonIcon'
                    />
                  </div>
                  <div className=''>
                    <div className='text-[18px] font-bold text-[#303C60] capitalize'>
                      {name}
                    </div>
                    <div className='mt-0 pt-0'>{role}</div>
                  </div>
                </div>
              ) : null}
              {/* ---- */}

              <h2 className='text-[#303c60] text-[20px] font-[600] lg:mb-10'>
                Activities
              </h2>
              <div className=''>
                {activities.length > 0
                  ? activities.map((activity, i) => (
                      <Activity
                        inx={i}
                        name={name}
                        length={activities.length}
                        key={activity.id}
                        title={activity.title}
                        status={activity.action}
                        timestamp={activity.created_at}
                        activity={activity.application}
                        setName={setName}
                        setRole={setRole}
                      />
                    ))
                  : show &&
                    activities.length === 0 && (
                      <h3 className='flex justify-center text-4.5xl'>
                        Candidate not found
                      </h3>
                    )}
              </div>
            </div>
          </div>
        </Spin>
        <Modal
          className='edit-modal'
          onCancel={() => setOpen(false)}
          destroyOnClose={true}
          open={open}
        >
          <div className='flex justify-between items-center	 mb-3'>
            <h3 className='text-lg text-center capitalize font-semibold text-[#233A5C] mb-0'>
              Confirm interview availability
            </h3>

            <div
              className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
              onClick={() => setOpen(false)}
            >
              <AiOutlineClose className='w-5 h-5 text-gray-light' />
            </div>
          </div>
          {`Hello `}
          <b className='capitalize'>{`${
            activities?.length ? activities[0]?.application?.full_name : name
          }`}</b>{' '}
          &#128075;{`,`}
          <p className='py-2'>
            {`
        We are looking forward to our
        interview scheduled at ${moment
          .unix(Number(queryParams?.sdt as string))
          .format('dddd, MMMM Do YYYY h:mm A')}. You are requested to join the
        call a couple of minutes earlier and make sure your camera and
        microphone are working properly. Kindly give us a confirmation of your
        availability. Follow the following links to give your confirmation:`}
          </p>
          <div className='flex flex-wrap justify-between items-center	'>
            <PrimaryButton
              text='Yes'
              onClick={() => {
                interviewScheduleURl(
                  activities[0]?.application?.id || '',
                  queryParams?.icID as string,
                  'YES',
                  queryParams?.sdt as string
                );
              }}
            />
            <DangerButton
              isTextLabel='No'
              onClick={() => {
                interviewScheduleURl(
                  activities[0]?.application?.id || '',
                  queryParams?.icID as string,
                  'NO',
                  queryParams?.sdt as string
                );
              }}
            />
            <CancelButton
              text='Reschedule'
              onClick={() => {
                interviewScheduleURl(
                  activities[0]?.application?.id || '',
                  queryParams?.icID as string,
                  'RESCHEDULE',
                  queryParams?.sdt as string
                );
              }}
            />
          </div>
        </Modal>
        {!customSlug ? <SocialLinksFooter /> : null}
      </div>
    </div>
  );
};

export default Timeline;
