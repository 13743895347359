// export const queryStringToJSON = (queryString:string)=> {
//     if(queryString.indexOf('?') > -1){
//       queryString = queryString.split('?')[1];
//     }
//     var pairs = queryString.split('&');
//     var result:{[key:string]:string} = {};
//     pairs.forEach(function(pair) {
//       let pairArray = pair.split('=');
//       result[pairArray[0]] = decodeURIComponent(pairArray[1] || '');
//     });
//     return result;
// }

export const queryStringToJSON = (queryString: string) => {
  if (queryString.indexOf('?') > -1) {
    queryString = queryString.split('?')[1];
  }
  const pairs = queryString.split('&');
  const result: { [key: string]: any } = {};
  pairs.forEach(function (pair) {
    const pairArray = pair.split('=');
    const key = pairArray[0];
    const value = decodeURIComponent(pairArray[1] || '');

    if (key === 'tags') {
      result[key] = value ? value : [];
    } else if (key.endsWith('_gte') || key.endsWith('_lte')) {
      result[key] = value ? value : null;
    } else if (key === 'page') {
      result[key] = value ? parseInt(value, 10) : 1;
    } else {
      result[key] = value;
    }
  });
  return result;
};
