import { Breadcrumb } from "antd";
import { TbLayoutGridAdd } from "react-icons/tb";

const InterviewGenerationBreadcrumb = () => {
    return (
        <>
            <div className="mt-2">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="flex text-lg lg:text-xl text-primary font-medium">
                        <TbLayoutGridAdd className="w-[24px] h-[24px] mt-[3px] mr-2" />
                        Interview Generation
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
        </>
    );
};

export default InterviewGenerationBreadcrumb;
