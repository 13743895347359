import {
  Input,
  Modal,
  Select,
  DatePicker,
  TimePicker,
  Checkbox,
  Switch
} from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { capitalize } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { IoIosClose, IoIosCloseCircle } from 'react-icons/io';
import CancelButton from '../../Buttons/Cancel';
import PrimaryButton from '../../Buttons/Primary';
import applicationActions from '../../../redux/applications/actions';
import moment from 'moment';
import {
  ACTION_TAKEN_BY,
  APPLICATION_STATUS_SELECT_FOR_INTERVIEW,
  USER_ROLE
} from '../../../constants/HelperConstants';
import { useHistory } from 'react-router-dom';

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  open: state.applications.modalOpen,
  currentStatus: state.applications.currentStatus,
  postInterviewResponse: state.applications.postInterviewResponse,
  generateAQuestionSet: state.generateAQuestionSet.generateAQuestionSet,
  interviewDetails: state.applications.interviewDetails,
  generateAQuestionSetGeneric:
    state.generateAQuestionSetGeneric.generateAQuestionSetGeneric,
  formErros: state.applications.formErros,
  role: state.users.role
});
interface IScheduleInterview {
  interviewers: any;
  singleApplication: any;
  open: boolean;
  interviewId?: string;
  singleInterview?: any;
  loading: boolean;
  currentStatus: any;
  postInterviewResponse: any;
  generateAQuestionSet: any;
  interviewDetails: any;
  generateAQuestionSetGeneric: any;
  formErrors?: any;
  role: number;
}

const getEmails = (value: any) => {
  const emailArray = value
    .replace(/\s*,\s*/g, ',')
    .replace(/\s+/g, ',')
    .replace(/\n/g, ',')
    .split(',');

  return [...new Set(emailArray)];
};

const ScheduleInterview: FC<IScheduleInterview> = ({
  interviewers,
  singleApplication,
  open,
  interviewId,
  singleInterview,
  loading,
  currentStatus,
  postInterviewResponse,
  generateAQuestionSet,
  interviewDetails,
  generateAQuestionSetGeneric,
  formErrors,
  role
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Option } = Select;
  const [interviewer, setInterviewer] = useState<any>([]);
  const [title, setTitle] = useState('');
  const [userName, setName] = useState<string | undefined>('');
  const [userEmail, setEmail] = useState<string | undefined>('');
  const [check, setCheck] = useState(true);
  const [status, setStatus] = useState<number | null>(null);
  const [action, setAction] = useState(1);

  const [date, setDate] = useState<any>('');
  const [comparatorDate, setComparatorDate] = useState<any>('');
  const [time, setTime] = useState<any>('');
  const [endTime, setEndTime] = useState<any>('');
  const [cc, setCc] = useState<any[]>([]);
  const [emails, setEmails] = useState<any[]>([]);
  const [comma, setComma] = useState<boolean>(false);

  const [focused, setFocused] = useState<boolean>(false);
  const [isGenerateQuestionSetCheckbox, setIsGenerateQuestionSetCheckbox] =
    useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<any>('');
  const [id, setId] = useState<string>(postInterviewResponse?.id);
  const [generateId, setGenerateId] = useState<string>('');
  const [isQuestionSetGenerated, setIsQuestionSetGenerated] =
    useState<boolean>();
  const [test, setTest] = useState<any>();

  const [toggleInterview, setToggleInterview] = useState<number>(1);

  const [errors, setErrors] = useState({
    title: '',
    location: '',
    interviewer: '',
    date: '',
    time: '',
    cc: '',
    status: ''
  });
  const [location, setLocation] = useState('');
  useEffect(() => {
    setErrors(formErrors);
  }, [formErrors]);
  useEffect(() => {
    if (singleApplication) {
      setName(singleApplication?.full_name);
      setEmail(singleApplication?.email);
    }
  }, [singleApplication]);

  const idRef = useRef(id);
  idRef.current = id;

  //
  const stripEmojis = (str: any) =>
    str
      .replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
      )
      .replace(/\s+/g, ' ')
      .trim();
  //

  useEffect(() => {
    setId(postInterviewResponse?.id);
  }, [postInterviewResponse?.id, id]);

  useEffect(() => {
    if (interviewId) {
      setTimeRange([
        moment(moment(singleInterview?.scheduled_at).format('LT'), 'h:mm a'),
        moment(moment(singleInterview?.end_at).format('LT'), 'h:mm a')
      ]);
      setTitle(singleInterview?.title);
      setLocation(singleInterview?.location);
      setAction(1);
      setDate(moment(singleInterview?.scheduled_at).format('YYYY-MM-DD'));
      // setDate(singleInterview.scheduled_at)
      setTest(moment(singleInterview?.scheduled_at).format('YYYY'));
      setTime(
        moment(moment(singleInterview?.scheduled_at).format('LT'), 'h:mm a')
      );
      setEndTime(
        moment(moment(singleInterview?.end_at).format('LT'), 'h:mm a')
      );

      setInterviewer(
        singleInterview?.interviewers?.map(
          (item: any) => `${item.id} + ${item.name}`
        )
      );
      setStatus(singleInterview?.round_status);
      setGenerateId(singleInterview?.question_set_id);
      setToggleInterview(singleInterview?.interview_type);
    }
  }, [interviewId, singleInterview, open]);

  useEffect(() => {
    if (!interviewId || open === false) {
      setInterviewer([]);
      setTimeRange([]);
      setStatus(null);
      setDate('');
      setTime('');
      setTitle('');
      setLocation('');
      setCc([]);
      setEmails([]);
      setToggleInterview(1);
    }
  }, [open, interviewId]);

  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const onSave = (e: any) => {
    e.preventDefault();
    let ccError = '';
    if (cc.length > 0) {
      emails?.forEach((item: string) =>
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item)
          ? (ccError = 'Please enter valid email')
          : (ccError = '')
      );
    }
    const errorsObject = {
      title: title === '' ? 'Title is required' : '',
      location:
        location === ''
          ? toggleInterview === 2
            ? 'Location is required'
            : 'Meeting Link is required'
          : toggleInterview === 2
          ? urlRegex.test(location)
            ? 'Enter Valid Location'
            : ''
          : urlRegex.test(location)
          ? ''
          : 'Enter Valid URL',
      date: date === '' || date === null ? 'Date is required' : '',
      time: time === '' || time === null ? 'Time is required' : '',
      interviewer: interviewer.length === 0 ? 'Interviewer is required' : '',
      cc: ccError,
      status: status === null ? 'Status is required' : ''
    };

    if (
      !errorsObject.title &&
      !errorsObject.location &&
      // !errorsObject.location &&
      !errorsObject.time &&
      !errorsObject.date &&
      !errorsObject.interviewer &&
      !errorsObject.cc &&
      !errorsObject.status
    ) {
      let payloadWithCc:
        | {
            title: string;
            scheduled_at: string;
            end_at: string;
            interviewers: any;
            location: string;
            cc: any[];
            rescheduled_by?: number;
            interview_type: number;
            update_status: number | null;
          }
        | {
            title: string;
            scheduled_at: string;
            end_at: string;
            interviewers: any;
            location: string;
            cc: any[];
            rescheduled_by?: number;
            interview_type: number;
            update_status?: number | null;
          }
        | {
            title: string;
            scheduled_at: string;
            end_at: string;
            interviewers: any;
            location: string;
            rescheduled_by?: number;
            interview_type: number;
            update_status: number | null;
            cc?: undefined;
          }
        | {
            title: string;
            scheduled_at: string;
            end_at: string;
            interviewers: any;
            location: string;
            rescheduled_by?: number;
            cc?: undefined;
            interview_type: number;
            update_status?: number | null;
          };
      let payload:
        | {
            title: string;
            scheduled_at: string;
            end_at: string;
            interviewers: any;
            location: string;
            cc: any[];
            rescheduled_by?: number;
            interview_type: number;
            update_status?: number | null;
          }
        | {
            title: string;
            scheduled_at: string;
            end_at: string;
            interviewers: any;
            location: string;
            rescheduled_by?: number;
            interview_type: number;
            update_status: number | null;
          }
        | {
            title: string;
            scheduled_at: string;
            end_at: string;
            interviewers: any;
            location: string;
            rescheduled_by?: number;
            interview_type: number;
            update_status?: number | null;
          };
      if (check) {
        payloadWithCc = {
          title: stripEmojis(title),
          scheduled_at: moment(
            `${date} ${time.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_at: moment(
            `${date} ${endTime.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          interviewers: interviewer.map((item: string) => item.split(' ')[0]),
          location:
            toggleInterview === 1
              ? location.startsWith('https') || location.startsWith('http')
                ? location
                : 'https://' + location
              : location,
          cc: emails,
          update_status: status,
          rescheduled_by: Number(action),
          interview_type: toggleInterview,
          // @ts-ignore
          question_set_id: !!(generateId?.trim()?.length > 0)
            ? generateId
            : null
        };
        payload = {
          title: stripEmojis(title),
          scheduled_at: moment(
            `${date} ${time.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_at: moment(
            `${date} ${endTime.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          interviewers: interviewer.map((item: string) => item.split(' ')[0]),
          location:
            toggleInterview === 1
              ? location.startsWith('https') || location.startsWith('http')
                ? location
                : 'https://' + location
              : location,
          update_status: status,
          rescheduled_by: Number(action),
          interview_type: toggleInterview,
          // @ts-ignore
          question_set_id: !!(generateId?.trim()?.length > 0)
            ? generateId
            : null
        };
      } else {
        payloadWithCc = {
          title: stripEmojis(title),
          scheduled_at: moment(
            `${date} ${time.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_at: moment(
            `${date} ${endTime.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          interviewers: interviewer.map((item: string) => item.split(' ')[0]),
          location:
            toggleInterview === 1
              ? location.startsWith('https') || location.startsWith('http')
                ? location
                : 'https://' + location
              : location,
          cc: emails,
          rescheduled_by: Number(action),
          interview_type: toggleInterview,
          // @ts-ignore
          question_set_id: !!(generateId?.trim()?.length > 0)
            ? generateId
            : null
        };
        payload = {
          title: stripEmojis(title),
          scheduled_at: moment(
            `${date} ${time.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_at: moment(
            `${date} ${endTime.format('hh:mm a')}`,
            'YYYY-MM-DD hh:mm a'
          )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          interviewers: interviewer.map((item: string) => item.split(' ')[0]),
          location:
            toggleInterview === 1
              ? location.startsWith('https') || location.startsWith('http')
                ? location
                : 'https://' + location
              : location,
          rescheduled_by: Number(action),
          interview_type: toggleInterview,
          // @ts-ignore
          question_set_id: !!(generateId?.trim()?.length > 0)
            ? generateId
            : null
        };
      }

      if (singleInterview?.id) {
        dispatch({
          type: applicationActions.UPDATE_SCHEDULED_INTERVIEW,
          payload: {
            ref_id: singleApplication?.id,
            interviewId: singleInterview?.id,
            body:
              emails.length > 0
                ? {
                    ...payloadWithCc,
                    location: payloadWithCc?.location?.length
                      ? payloadWithCc?.location
                      : null
                  }
                : {
                    ...payload,
                    location: payload.location?.length ? payload.location : null
                  }
          }
        });
        dispatch({
          type: applicationActions.UPDATE_CURRENT_STATUS,
          payload: status
        });
        setCheck(true);
        setIsGenerateQuestionSetCheckbox(false);
      } else {
        dispatch({
          type: applicationActions.SCHEDULE_INTERVIEW,
          payload: {
            ref_id: singleApplication?.id,
            // @ts-ignore
            body:
              emails.length > 0
                ? {
                    ...payloadWithCc,
                    location: payloadWithCc?.location?.length
                      ? payloadWithCc?.location
                      : null
                  }
                : {
                    ...payload,
                    location: payload.location?.length ? payload.location : null
                  }
          }
        });
        dispatch({
          type: applicationActions.UPDATE_CURRENT_STATUS,
          payload: status
        });
        setCheck(true);
        setIsGenerateQuestionSetCheckbox(false);
      }
    }
    setErrors(errorsObject);
  };
  const handleChange = (value: string[]) => {
    setInterviewer(value);
  };

  function onDateChange(date: any) {
    setComparatorDate(date);
    if (role !== USER_ROLE.HR_LEAD) {
      if (moment(date).isSame(moment(), 'day')) {
        const startTime = moment().add(5, 'minutes');
        const endTime = moment().add(1, 'hour').add(5, 'minutes');
        setTimeRange([startTime, endTime]);
        setTime(startTime);
        setEndTime(endTime);
      } else {
        setTimeRange('');
        setTime('');
        setEndTime('');
      }
    }
    setDate(date ? moment(date).format('YYYY-MM-DD') : null);
  }

  const handleTime = (value: any) => {
    setTimeRange(value);
    setTime(value[0]);
    setEndTime(value[1]);
  };

  const handleModalClose = () => {
    setErrors({
      title: '',
      location: '',
      interviewer: '',
      date: '',
      time: '',
      cc: '',
      status: ''
    });
    dispatch({
      type: applicationActions.TOGGLE_MODAL,
      payload: false
    });
    setCheck(true);
    setIsGenerateQuestionSetCheckbox(false);
  };

  const children: React.ReactNode[] = [];

  const commaFunction = useCallback((event: any) => {
    if (event.keyCode === 188 || event.keyCode === 13) {
      setComma(true);
    } else {
      setComma(false);
    }
  }, []);

  const backFunction = useCallback(
    (event: any) => {
      if (event.keyCode === 8 && (cc.length === 0 || cc[0] === '')) {
        const updatedEmails = emails.slice(0, -1);
        setEmails(updatedEmails);
      }
    },
    [cc, emails]
  );

  useEffect(() => {
    let hasError = false;
    for (let i = 0; i < emails.length; i++) {
      let email = emails[i];
      if (
        email !== '' &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ) {
        hasError = true;
        break;
      }
    }
    if (hasError) {
      setErrors({
        ...errors,
        cc: 'Please enter valid email'
      });
    } else {
      setErrors({
        ...errors,
        cc: ''
      });
    }
  }, [emails]);

  useEffect(() => {
    if (focused) {
      document.addEventListener('keydown', commaFunction);
      document.addEventListener('keydown', backFunction);
    }

    return () => {
      dispatch({
        type: applicationActions.SET_STATE,
        payload: {
          formErros: {}
        }
      });
      document.removeEventListener('keydown', backFunction);
      document.removeEventListener('keydown', commaFunction);
    };
  }, [commaFunction, backFunction, focused]);

  interviewers &&
    interviewers.map((item: any) =>
      children.push(
        <Option key={`${item.id} + ${item.name}`}>
          {capitalize(item.name)}
        </Option> // to search by name in interviewers
      )
    );

  const handleCc = (value: any) => {
    setCc(getEmails(value.trim()));
    if (comma) {
      const email = getEmails(value.trim()).filter((item: any) => item !== '');
      setEmails([...emails, ...email]);
      setCc([]);
    }
  };

  const handleCheckbox = (e: any) => {
    setCheck(e.target.checked);
  };

  const handleClose = (value: any) => {
    setEmails(emails.filter((item: any) => item !== value));
  };

  const handleStatusChange = (value: any) => {
    setStatus(Number(value));
  };

  useEffect(() => {
    if (!check) {
      setStatus(null);
    }
  }, [check]);

  const handleActionTakenByChange = (value: any) => {
    setAction(value);
  };

  const handleBlur = () => {
    setFocused(false);
    setEmails([...emails, ...cc]);
    setCc([]);
  };

  const handleGenerateQuestionSetCheckbox = (e: any) => {
    setIsGenerateQuestionSetCheckbox(e.target.checked);
    setGenerateId('');
    if (e.target.checked) {
      dispatch({
        type: applicationActions.GENERATE_A_QUESTION_SET,
        payload: { ref_id: singleApplication.id }
      });
    }
  };

  useEffect(() => {
    if (isGenerateQuestionSetCheckbox) {
      setGenerateId(generateAQuestionSet?.data?.uuid);
    }
  }, [generateAQuestionSet]);

  const isSendInviteBtnValidate = () => {
    if (
      isGenerateQuestionSetCheckbox === false &&
      isQuestionSetGenerated === false
    ) {
      return false; //enable
    } else if (
      isGenerateQuestionSetCheckbox === true &&
      isQuestionSetGenerated === false
    ) {
      return true; //disable
    } else {
      return false; // enable
    }
  };

  useEffect(() => {
    setIsQuestionSetGenerated(generateAQuestionSetGeneric?.success);
  }, [generateAQuestionSetGeneric.success]);

  const dateFormat = 'YYYY/MM/DD';

  const disabledHours = () => {
    if (comparatorDate && comparatorDate?.isSame(moment(), 'day')) {
      const hours = [];
      for (let i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
      return hours;
    }
    return [];
  };

  const disabledMinutes = (selectedHour: number) => {
    if (
      comparatorDate &&
      comparatorDate?.isSame(moment(), 'day') &&
      selectedHour === moment().hour()
    ) {
      const minutes = [];
      for (let i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
      return minutes;
    }
    return [];
  };

  const handleToggleChange = (value: any) => {
    if (value) {
      setToggleInterview(1);
    } else {
      setToggleInterview(2);
    }
  };

  return (
    <>
      <Modal
        className='interview-modal'
        open={open}
        onCancel={handleModalClose}
        destroyOnClose={true}
        width='620px'
      >
        <div className='flex justify-between '>
          <div className='md:px-4'>
            <h3 className='text-base text-center capitalize font-semibold text-[#233A5C]'>
              {interviewId ? 'Reschedule Interview' : 'Schedule Interview'}
            </h3>
          </div>

          <div
            className='w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer'
            onClick={handleModalClose}
          >
            <AiOutlineClose className='w-5 h-5 text-gray-light' />
          </div>
        </div>

        <div className='mx-4'>
          <p className='text-blue-streak text-base mb-0 capitalize opacity-50'>
            Interview Type: {toggleInterview === 2 ? 'Offline' : 'Online'}
          </p>
          <Switch
            checked={
              toggleInterview ? (toggleInterview === 2 ? false : true) : true
            }
            onChange={handleToggleChange}
          />
        </div>

        <div className='flex justify-between'>
          <div className='pt-8 md:px-4 -space-y-2 w-full'>
            <p className='text-blue-streak text-base capitalize opacity-50'>
              {'Name'}
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            <Input
              disabled
              onChange={(e) => {
                let val = e.target.value;
                setName(val);
              }}
              className='edit-input text-base'
              value={userName}
              type='text'
            />
          </div>

          <div className='pt-8 md:px-4 -space-y-2 w-full'>
            <p className='text-blue-streak text-base capitalize opacity-50'>
              {'Email'}
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            <Input
              disabled
              onChange={(e) => {
                let val = e.target.value;
                setEmail(val);
              }}
              className='edit-input text-base'
              value={userEmail}
            />
          </div>
        </div>

        <div className='pt-8 md:px-4 -space-y-2 w-full'>
          <p className='text-blue-streak text-base capitalize opacity-50'>
            {'Title'}
            <span className='text-lg text-star-red pl-1'>*</span>
          </p>
          <Input
            onChange={(e) => {
              let val = e.target.value;
              setTitle(val);
            }}
            className='edit-input text-base'
            value={title}
          />
          {errors.title !== '' && (
            <div className='error-message absolute bottom-[-20px]'>
              {errors.title}
            </div>
          )}
        </div>

        {toggleInterview === 2 ? (
          <div className='pt-8 md:px-4 -space-y-2 w-full'>
            <p className='text-blue-streak text-base capitalize opacity-50'>
              {'Location'}
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            <Input
              onChange={(e) => {
                let val = e.target.value;
                setLocation(val);
              }}
              className='edit-input text-base'
              value={location}
            />
            {errors.location !== '' && (
              <div className='error-message absolute bottom-[-20px]'>
                {errors.location}
              </div>
            )}
          </div>
        ) : (
          <div className='pt-8 md:px-4 -space-y-2 w-full'>
            <p className='text-blue-streak text-base capitalize opacity-50'>
              {'Meeting Link'}
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            <Input
              onChange={(e) => {
                let val = e.target.value;
                setLocation(val);
              }}
              className='edit-input text-base'
              value={location}
            />
            {errors.location !== '' && (
              <div className='error-message absolute bottom-[-20px]'>
                {errors.location}
              </div>
            )}
          </div>
        )}

        <div className='pt-8 md:px-4 -space-y-2 relative'>
          <p className='text-blue-streak text-base capitalize opacity-50'>
            Interviewer
            <span className='text-lg text-star-red pl-1'>*</span>
          </p>
          <Select
            mode='multiple'
            allowClear
            className='edit-input text-base'
            placeholder='Please select'
            onChange={handleChange}
            value={interviewer}
            showSearch
          >
            {children}
          </Select>
          {errors.interviewer !== '' && (
            <div className='error-message absolute bottom-[-20px]'>
              {errors.interviewer}
            </div>
          )}
        </div>

        <div className='pt-8 md:px-4 -space-y-2 w-full'>
          <p className='text-blue-streak text-base capitalize opacity-50'>
            {'CC'} (Enter comma separated emails)
          </p>
          <div className='flex edit-input text-base items-center relative w-full'>
            <div className='flex flex-wrap w-full gap-1 p-2'>
              {emails.map((item: string, indx: number) => (
                <div className='ml-2 bg-[#f5f5f5] flex items-center' key={indx}>
                  <span style={{ fontSize: '14px', padding: '4px' }}>
                    {item}
                  </span>
                  <IoIosClose
                    opacity='0.5'
                    fontSize='20px'
                    cursor='pointer'
                    onClick={() => handleClose(item)}
                  />
                </div>
              ))}
              <Input
                className='cc-input text-base flex-1 !min-w-[120px]'
                name='cc'
                value={cc}
                onChange={(e) => {
                  handleCc(e.target.value);
                }}
                onFocus={() => setFocused(true)}
                onBlur={handleBlur}
                onPressEnter={() => {
                  setEmails([...emails, ...cc]);
                  setCc([]);
                }}
              />
            </div>

            {emails.length > 0 && (
              <IoIosCloseCircle
                className='m-2 min-w-fit absolute right-1'
                cursor='pointer'
                opacity='0.5'
                fontSize='16px'
                onClick={() => setEmails([])}
              />
            )}
          </div>
          {errors.cc !== '' && (
            <div className='error-message absolute bottom-[-20px]'>
              {errors.cc}
            </div>
          )}
        </div>

        <div className='flex justify-between mb-8'>
          <div className='pt-8 md:px-4 -space-y-2 w-full'>
            <p className='text-blue-streak text-base capitalize opacity-50'>
              Date of Interview
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            {/* <DatePicker
              className="edit-input text-base"
              onChange={onChange}
              value={dayjs(date, dateFormat)}
              disabledDate={(current) =>
                //@ts-ignore
                current.isBefore(moment().subtract(1, "day"))
              }
            /> */}
            {date?.length > 0 ? (
              <DatePicker
                className='custom-date-picker edit-input text-base'
                onChange={onDateChange}
                value={moment(date)}
                disabledDate={(current) =>
                  //@ts-ignore
                  role === USER_ROLE.HR_LEAD
                    ? current.isBefore(moment().subtract(4, 'day'))
                    : current.isBefore(moment().subtract(1, 'day'))
                }
                // allowClear={false}
              />
            ) : (
              <DatePicker
                className='custom-date-picker edit-input text-base'
                onChange={onDateChange}
                disabledDate={(current) =>
                  //@ts-ignore
                  role === USER_ROLE.HR_LEAD
                    ? current.isBefore(moment().subtract(4, 'day'))
                    : current.isBefore(moment().subtract(1, 'day'))
                }
                // allowClear={false}
              />
            )}

            {errors.date !== '' && (
              <div className='error-message absolute bottom-[-20px]'>
                {errors.date}
              </div>
            )}
          </div>
          <div className='pt-8 md:px-4 -space-y-2 w-full'>
            <p className='text-blue-streak text-base capitalize opacity-50'>
              Time
              <span className='text-lg text-star-red pl-1'>*</span>
            </p>
            <TimePicker.RangePicker
              use12Hours
              disabled={!date}
              format='h:mm a'
              className='custom-date-picker edit-input text-base'
              onChange={handleTime}
              //@ts-ignore
              defaultValue={timeRange}
              disabledTime={
                role === USER_ROLE.HR_LEAD
                  ? undefined
                  : () => ({
                      disabledHours,
                      disabledMinutes
                    })
              }
              value={timeRange}
            />
            {errors.time !== '' && (
              <div className='error-message absolute bottom-[-20px]'>
                {errors.time}
              </div>
            )}
          </div>
        </div>
        <div className='flex justify-between md:px-4'>
          <p className='text-blue-streak text-base capitalize opacity-50'>
            Action Taken By
          </p>
          <div className='test w-[255px]'>
            <Select
              defaultValue={'1'}
              className={`filter-select w-full border rounded-lg ${
                !interviewId ? 'border-gray-300 opacity-30' : 'border-[#F4F7FC]'
              }`}
              disabled={!interviewId}
              onChange={handleActionTakenByChange}
              placeholder={'Select Role'}
            >
              {Object.entries(ACTION_TAKEN_BY).map((item) => (
                <Option className='' value={item[0]} key={item[0]}>
                  {item[1]}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className='flex justify-between md:px-4'>
          <div className='flex flex-col justify-center'>
            <Checkbox
              disabled={singleInterview?.round_status}
              defaultChecked
              onChange={handleCheckbox}
            >
              Update Status
              <span className='text-lg text-star-red pl-1'>*</span>
            </Checkbox>
            {!check && errors.status !== '' && (
              <div className='error-message'>{errors.status}</div>
            )}
          </div>
          {check && (
            <div className='test w-[255px]'>
              <Select
                className={`filter-select w-full border rounded-lg ${
                  singleInterview?.round_status
                    ? 'border-gray-300 opacity-30'
                    : 'border-[#F4F7FC]'
                }`}
                onChange={handleStatusChange}
                placeholder={'Select applicant status'}
                disabled={singleInterview?.round_status}
                value={status ? `${status}` : undefined}
              >
                {Object.entries(APPLICATION_STATUS_SELECT_FOR_INTERVIEW).map(
                  (item) => (
                    <Option className='' value={item[0]} key={item[0]}>
                      {item[1]}
                    </Option>
                  )
                )}
              </Select>
              {check && errors.status !== '' && (
                <div className='error-message absolute bottom-[-20px]'>
                  {errors.status}
                </div>
              )}
            </div>
          )}
        </div>
        <div className='flex justify-between md:px-4'>
          <Checkbox
            defaultChecked={
              singleInterview?.question_set_status === 3 ? true : false
            }
            onChange={handleGenerateQuestionSetCheckbox}
            disabled={
              singleInterview?.feedback_status !== 1 &&
              singleInterview?.feedback_status !== undefined
                ? true
                : false
            }
          >
            Generate Question Set
          </Checkbox>
        </div>
        <div className='flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2'>
          <CancelButton onClick={handleModalClose} disabled={loading} />
          <PrimaryButton
            text='Send Invite'
            loading={loading}
            disabled={isSendInviteBtnValidate() || loading}
            onClick={onSave}
          />
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(ScheduleInterview);
