import { Switch, Route, Redirect } from "react-router-dom";
import { ADMIN_HR_MANAGEMENT } from "../constants/RouteConstants";
import ErrorPage from "../page/404Error";
import TooManyRequest from "../page/429Error";
import HR from "../page/hr";
import { history } from "../utilities/configureAxios";

function Admin() {
  return (
    <Switch location={history.location}>
      <Route path={ADMIN_HR_MANAGEMENT} component={HR} exact />
      <Route
        path="/429"
        render={() => (
          <div className="page-container flex">
            <TooManyRequest />
          </div>
        )}
      />
      {/* <Redirect to="/429" /> */}
      <Route
        path="/404"
        render={() => (
          <div className="page-container flex">
            <ErrorPage />
          </div>
        )}
      />
      <Redirect to="/404" />
    </Switch>
  );
}

export default Admin;
