import { Input, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../Buttons/Primary";
import taskActions from "../../../redux/tasks/actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AiOutlineClose } from "react-icons/ai";
import FileUpload from "../../FileUpload/TaskFileUpload";

const AddTaskModal = ({ heading }: { heading: string }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [valid, setValid] = useState(false);
  const ref = useRef<any>(null);
  useEffect(() => {
    if (taskTitle?.trim().length > 0 && taskDescription.trim().length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [taskTitle, taskDescription]);

  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    if (taskTitle !== "" || taskDescription !== "") {
      const res = window.confirm("Are you sure ?");
      if (res) {
        setIsOpen(false);
      }
    } else {
      setIsOpen(false);
    }
  };

  const handleChange = () => {
    setTaskDescription(ref.current?.unprivilegedEditor.getHTML());
  };

  const [errors, setErrors] = useState({
    taskTitle: "",
    description: "",
  });

  const onSave = () => {
    const errorsObject = {
      taskTitle: taskTitle === "" ? "Job Title is required" : "",
      description: taskDescription === "" ? "Description is required" : "",
    };
    if (!errorsObject.taskTitle && !errorsObject.description) {
      dispatch({
        type: taskActions.ADD_TASK,
        payload: {
          body: {
            title: taskTitle,
            description: taskDescription,
            attachment: fileName,
            attachment_mime_type: fileType,
          },
        },
      });
      setIsOpen(false);
      setTaskTitle("");
      setTaskDescription("");
    }
    setErrors(errorsObject);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  return (
    <>
      <PrimaryButton text="Add Task" onClick={showModal} />
      <Modal
        className="edit-task-modal"
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
        width="1099px"
        footer={null}
      >
        <div className="bg-[#FAFDFF]">
          <div className="flex justify-between ">
            <div className="md:px-8">
              <h3 className="text-xl text-center font-semibold text-blue-zodiac">
                {heading}
              </h3>
            </div>
            <div
              className="w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer"
              onClick={handleCancel}
            >
              <AiOutlineClose className="w-5 h-5 text-gray-light" />
            </div>
          </div>

          <div className="pt-8 md:px-8 -space-y-2 relative">
            <p className="text-blue-streak text-base opacity-50">
              {"Task Title"}
              <span className="text-lg text-star-red pl-1">*</span>
            </p>
            <Input
              onChange={(e) => {
                let val = e.target.value;
                setTaskTitle(val);
              }}
              className="edit-input text-base capitalize"
            />

            {errors.taskTitle !== "" && (
              <div className="error-message absolute bottom-[-20px]">
                {errors.taskTitle}
              </div>
            )}
          </div>

          <div className="pt-8 md:px-8 -space-y-2 relative">
            <p className="text-blue-streak text-base uppercase opacity-50">
              {"Description"}
              <span className="text-lg text-star-red pl-1">*</span>
            </p>
            <div className="editor">
              <ReactQuill
                modules={modules}
                formats={formats}
                ref={ref}
                onChange={handleChange}
                placeholder="Description..."
              />
            </div>

            {errors.description !== "" && (
              <div className="error-message absolute bottom-[-20px]">
                {errors.description}
              </div>
            )}
          </div>

          <div className="pt-8 md:px-8 -space-y-2 relative">
            <p className="text-blue-streak text-base uppercase opacity-50">
              {"Attachment"}
            </p>
            <div className="cursor-pointer">
              <FileUpload
                setFileName={setFileName}
                fileType={fileType}
                setFileType={setFileType}
              />
            </div>
          </div>

          <div className="flex justify-center md:px-12 py-4 space-x-4 md:space-x-7 mt-2">
            <PrimaryButton disabled={!valid} text="Save" onClick={onSave} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddTaskModal;
