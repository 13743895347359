import { Modal } from "antd";
import React, { FC, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import DangerButton from "../../Buttons/Danger";
import CancelBtn from "../../Buttons/Cancel";
import { useDispatch } from "react-redux";
import interviewerActions from "../../../redux/interviewers/actions";

interface DeleteProps {
  heading: string;
  desc: string;
  jobId?: string;
  userId?: any;
  role?: any;
}

const DeleteModal: FC<DeleteProps> = ({ heading, desc, userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const onDelete = () => {
    dispatch({
      type: interviewerActions.DELETE_INTERVIEWERS,
      payload: {
        userId,
      },
    });
    handleCancel();
  };

  return (
    <>
      <div
        className="w-8 h-8 bg-background-red rounded-md flex items-center justify-center cursor-pointer"
        onClick={showModal}
      >
        <MdDeleteOutline className="w-5 h-5 text-tamato" />
      </div>
      <Modal
        className="delete-modal"
        visible={isOpen}
        onCancel={handleCancel}
        centered
      >
        <div className="flex justify-between">
          <div className="px-4 md:px-10">
            <h3 className="text-xl uppercase font-semibold text-blue-radic">
              {heading}
            </h3>
          </div>

          <div
            className="w-9 h-9 flex items-center justify-center bg-gray-lighter rounded-lg cursor-pointer"
            onClick={handleCancel}
          >
            <AiOutlineClose className="w-5 h-5 text-gray-light" />
          </div>
        </div>
        <div className="mt-10 md:mt-10 px-4 md:px-10">
          <p className="text-base text-blue-radic text-center lg:text-left">
            {desc}
          </p>
        </div>
        <div className="flex justify-center md:px-12 pt-4 pb-6 space-x-4 lg:space-x-7 mt-7">
          <DangerButton onClick={onDelete} />
          <CancelBtn onClick={handleCancel} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
