import { Tooltip } from 'antd';
import moment from 'moment';
import EditAllotedTask from '../Modal/Edit/EditAllotedTask';

interface ITask {
  data: any;
  tasks: any;
  id: any;
}

const Task: React.FC<ITask> = ({ data, id }) => {
  return (
    <div className='my-4 p-4 bg-[#F3F9FE] flex rounded-[10px] relative'>
      <div className='w-full lg:w-1/2 pr-4'>
        <div className='flex flex-col'>
          <Tooltip placement='topLeft' title={data.applicant_name}>
            <span className='text-base capitalize text-[#233A5C] font-medium truncate max-w-[150px]'>
              {data.applicant_name}
            </span>
          </Tooltip>
          <span className=' text-[#233A5C] opacity-50'>
            {moment(data?.expiry).format('MMMM Do YYYY, h:mm a')}
          </span>
        </div>
      </div>
      <div className='pr-4 w-full lg:w-1/2'>
        <Tooltip placement='topLeft' title={data.task_title}>
          <h6 className='text-[14px] mb-0 font-normal max-w-[150px] truncate'>
            {data.task_title}
          </h6>
        </Tooltip>
        <p className='text-[#233A5C] pb-0 opacity-50 text-[13px] mb-0'>Task</p>
      </div>
      <EditAllotedTask id={id} data={data} />
    </div>
  );
};

export default Task;
