import { all, takeEvery, put } from 'redux-saga/effects';
import { doApiCall } from '../../helper';
import actions from './actions';
import hiringTrackerService from '../../services/hiringTracker';
import { notification } from 'antd';

const services = new hiringTrackerService();

export function* WATCH_GET_ALL_HIRING_TRACKER_DATA(action) {
  const response = yield doApiCall(
    services.GetHiringData,
    action.payload,
    actions.SET_STATE
  );
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { hiringData: response }
    });
  }
}
export function* WATCH_POST_EXPORT_CSV(action) {
  const response = yield doApiCall(
    services.ExportCSV,
    action.payload,
    actions.EXPORT_CSV_LOADING
  );
  if (response?.success) {
    notification.success({ message: response.data.message });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.GET_ALL_HIRING_TRACKER_DATA,
      WATCH_GET_ALL_HIRING_TRACKER_DATA
    )
  ]);
  yield all([takeEvery(actions.EXPORT_CSV, WATCH_POST_EXPORT_CSV)]);
}
