import moment from "moment";
import { useRef, useState } from "react";
import {
  MdDeleteOutline,
  MdOutlineModeEditOutline,
} from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import applicationActions from "../../redux/applications/actions";
import { TiTick } from "react-icons/ti";
import { BsClockHistory, BsQuestion } from "react-icons/bs";
import { Modal, Tooltip } from "antd";
import { history } from "../../utilities/configureAxios";
import interviewActions from "../../redux/interviewers/actions";
import { FiClock } from "react-icons/fi";
import { RxCheck, RxCross2 } from "react-icons/rx";
import { TbHistory, TbNotes } from "react-icons/tb";

interface IInterview {
  data: any;
  getInterviewId: (value: string) => void;
  postInterviewResponse: any;
}

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  postInterviewResponse: state.applications.postInterviewResponse,
});

const Interviews: React.FC<IInterview> = ({
  data,
  getInterviewId,
  postInterviewResponse,
}) => {
  const dispatch = useDispatch();

  const interviewResponseIcons: any = {
    1: (
      <RxCheck className="w-4 h-4 text-[#5ab604] font-semibold" />
    ),
    2: (
      <RxCross2 className="w-4 h-4 text-[#DE0220] font-semibold" />
    ),
    3: (
      <Tooltip
        title={moment(data.reschedule_response).format(
          "dddd, MMMM Do | h:mm A"
        )}
      >
        <FiClock className="w-4 h-4 text-[#D7800F] font-semibold" />
      </Tooltip>
    ),
  };

  function handleFeedbackRoute(data: any) {
    if (data?.feedback_status === 3) {
      dispatch({
        type: interviewActions.SET_SELECTED_INTERVIEW,
        payload: data,
      });
      history.push(`/dashboard/view-feedback/${data?.id}`);
    }
  }

  function handleViewQuestionSetRoute(data: any) {
    if (data?.question_set_status === 3) {
      history.push(`/dashboard/view-question-set/${data?.id}`);
    }
  }

  return (
    <div
      className={`my-4 p-4 rounded-[10px] relative ${
        data.status === 2 ? "bg-[#F3F9FE]/50" :'bg-[#F3F9FE]'
      }`}
    >
      <div className={`w-full border-b border-[#233A5C] border-opacity-20 flex justify-between text-sm flex-wrap pb-1 ${
        data.status === 2 ? "opacity-50" : null
      }`} >
        <span className="text-[#233A5C] opacity-50 ">
          {moment(data.scheduled_at).format("MMMM Do YYYY")}
        </span>
        <span className="text-[#233A5C] opacity-50">
          {`${moment(data.scheduled_at).format("h:mm a")} - ${moment(
            data.end_at
          ).format("h:mm a")}`}
        </span>
      </div>
      <h3 className={`text-base capitalize pt-1 mb-0 pb-0 font-semibold line-clamp-1 w-full break-all mt-1 ${
        data.status === 2 ? "opacity-50" : null
      }` }>
        <Tooltip title={data.title}>
        {data.title}
        </Tooltip>
      </h3>
      {data.mail_response && (
        <div className={`flex gap-1  text-sm mt-1 ${
          data.status === 2 ? "opacity-50 pointer-events-none" : null
        }`}>
          <p className="mb-0 pb-0 text-[#233A5C] opacity-50">Status:</p>
          <div className="self-center">{interviewResponseIcons[Number(data.mail_response)]}</div>
        </div>
      )}
      <div className={`flex gap-1  ${
          data.status === 2 ? "opacity-50 pointer-events-none" : null
        }`}>
        {/* {interviewResponseIcons[3]} */}
        <div className="flex gap-1 text-sm w-full">
          <p className="mb-0 pb-0 text-[#233A5C] opacity-50">Interviewer:</p>
          <span className="text-[#233A5C] opacity-50 line-clamp-1  break-all mb-0 pb-0">
            {data.interviewers
              ?.slice(0, 2)
              ?.map(
                (item: any) =>
                  item?.name?.charAt(0)?.toUpperCase() +
                  item?.name?.slice(1) 
              )
              .join(", ")}
            {"  "}
          </span>
          <Tooltip
            title={data.interviewers
              ?.map(
                (item: any) =>
                  item?.name?.charAt(0)?.toUpperCase() + item?.name?.slice(1)
              )
              .join(", ")}
          >
            <p className="text-light-blue opacity-80 min-w-[4rem] cursor-default mb-0 pb-0">
              view all
            </p>
          </Tooltip>
        </div>
        {/* <BsExclamationSquareFill className="w-[30px] h-[30px] text-tamato font-semibold" /> */}
      </div>
      <div className="flex justify-between mt-2">
        <div className="flex gap-2">
          <div
            className="w-8 h-8 bg-[#17A2B8]/20 rounded-md flex items-center justify-center cursor-pointer"
            onClick={() => {
              dispatch({
                type: applicationActions.TOGGLE_INTERVIEW_HISTORY_MODAL,
                payload: {status:true,id:data.id},
              });
              getInterviewId(data.id);
            }}
          >
            <TbHistory className="w-5 h-5 font-semibold text-[#17A2B8]" />
          </div>
          <div
            className={`w-8 h-8 rounded-md flex items-center justify-center ${
              data?.feedback_status === 3
                ? "bg-[#E83E8C]/20 cursor-pointer"
                : "bg-[#F6B6D4]/20 cursor-not-allowed"
            }
            ${
              data.status === 2 ? "opacity-50 pointer-events-none" : null
            }
         `}
            onClick={() => handleFeedbackRoute(data)}
          >
            <TbNotes
              className={`w-5 h-5  font-semibold text-[#E83E8C] ${
                data?.feedback_status === 3
                  ? "text-[#E83E8C] "
                  : "text-[#F6B6D4] "
              }`}
            />
          </div>
          {data?.question_set_status === 3 && (
            <div
              className={`w-8 h-8 bg-[#EDE5FF]  rounded-md flex items-center justify-center  ${
                data.status === 2 ? "opacity-50 pointer-events-none" : null
              }`}
              onClick={() => handleViewQuestionSetRoute(data)}
            >
              <BsQuestion className="w-7 h-7 cursor-pointer font-semibold text-[#8D5AFF]" />
            </div>
          )}
        </div>
        <div className="flex gap-4 h-8 items-center">
          <div
            className={` rounded-md flex items-center justify-center ${
              data.status === 2
                ? "cursor-none pointer-events-none opacity-50"
                : "cursor-pointer"
            }`}
            onClick={(e) => {
              dispatch({
                type: applicationActions.TOGGLE_MODAL,
                payload: true,
              });
              getInterviewId(data.id);
            }}
          >
            <MdOutlineModeEditOutline className="w-5 h-5 text-light-blue font-semibold" />
          </div>
          <div
            className={`  rounded-md flex items-center justify-center ${
              data.status === 2
                ? "pointer-events-none cursor-none opacity-50"
                : "cursor-pointer"
            }
            ${ data?.feedback_status === 3 ? " cursor-not-allowed opacity-50 ":"cursor-pointer"}
            `}
            onClick={(e) => {
              if ( data.status !== 2 || data?.feedback_status !== 3 ){
                dispatch({
                  type: applicationActions.TOGGLE_INTERVIEW_MODAL,
                  payload: true,
                });
                getInterviewId(data.id);
              }
            }}
          >
            <MdDeleteOutline className={`w-5 h-5 text-tamato`} />
          </div>
        </div>
      </div>      
    </div>
  );
};

export default connect(mapStateToProps)(Interviews);
