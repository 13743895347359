import apiClient from "../utilities/configureAxios";
import * as APIs from "../constants/ApiConstants";

class ReferralsServices {
  constructor() {
    this.client = apiClient;
  }

  GetAllReferrals = (payload) => {
    return this.client
      .get(APIs.ALL_REFERRALS, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  // ==============Added a Filter Referral==========================
  FilterReferrals = (payload) => {
    return this.client
      .get(APIs.FILTER_REFERRALS, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  // ===============================================================
  AddReferral = (payload) => {
    const { body } = payload;
    return this.client
      .post(APIs.ALL_REFERRALS, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetEmployeeReferrals = (payload) => {
    return this.client
      .get(APIs.EMPLOYEE_REFERRALS, { params: { ...payload } })
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  EditEmployeeReferrals = (payload) => {
    const { id, body } = payload;
    return this.client
      .patch(`${APIs.EMPLOYEE_REFERRALS}/${id}`, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  // =====Added a new API patch for ADMIN=====
  EditAdminReferrals = (payload) => {
    const { id, body } = payload;
    return this.client
      .patch(`${APIs.ADMIN_REFERRALS}/${id}`, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  // =============================================
  DeleteEmployeeReferrals = (payload) => {
    const { userId } = payload;
    return this.client
      .delete(`${APIs.EMPLOYEE_REFERRALS}/${userId}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  // ==========Soft delete for hrs ================
  SoftDeleteEmployeeReferrals = (payload) => {

    const userId = payload.userId;
    const body = payload.body;
    
    return this.client
      .patch(`${APIs.ADMIN_REFERRALS}/${userId}`, body)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  // ==============================================
  GetSingleReferral = (payload) => {
    const { id } = payload;
    return this.client
      .get(`${APIs.ALL_REFERRALS}/${id}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
  GetUrlForUpload = (file_name, mime_type) => {
    return this.client
      .get(`${APIs.GET_REFERRAL_UPLOAD_URL}?mime_type=${mime_type}&file_name=${file_name}`)
      .then((res) => res)
      .catch((error) => {
        return error;
      });
  };
}

export default ReferralsServices;
