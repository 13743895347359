import { Breadcrumb } from "antd";
import React from "react";
import { FaUsers } from "react-icons/fa";

const HRBreadcrumb = () => {
  return (
    <div className="mt-2 pl-2 lg:pl-0">
      <Breadcrumb separator=">">
        <Breadcrumb.Item className="flex text-lg lg:text-xl text-primary font-medium">
          <FaUsers className="w-[24px] h-[24px] mt-[3px] mr-2" />
          HR Management
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default HRBreadcrumb;
