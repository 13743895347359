import actions from "./actions";

const initialState = {
    allCategories: [],
    allQuestions: [],
    allQuestionMappings: [],
    allInterviewsForInterviewers: [],
    getInterviewQuestionSet: [],
    questionsList: [],
    getInterviewFeedback: [],
    getInterviewSetFeedback: [],
    getSingleQuestionMapping: [],
    mappingList: [],
    getInterviewOverallFeedback:{},
    singleInterviewInfo: {},
};

export default function allCategories(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_ALL_CATEGORIES_SUCCESS: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
}

export function allQuestions(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_ALL_QUESTIONS_SUCCESS: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
}

export function allQuestionMappings(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_ALL_QUESTION_MAPPINGS_SUCCESS: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
}
export function allInterviewsForInterviewers(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_ALL_INTERVIEWS_FOR_INTERVIEWER_SUCCESS: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
}

export function getSingleInterviewInfo(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_SINGLE_INTERVIEW_INFO_SUCCESS: {
            return { ...state,...payload };
        }
        case actions.GET_SINGLE_INTERVIEW_INFO_RESET: {
            return { ...state, singleInterviewInfo:{}};
        }
        default: {
            return state;
        }
    }
}


export function getInterviewQuestionSet(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_INTERVIEW_QUESTION_SET_SUCCESS: {
            return { ...state, ...payload };
        }
        case actions.HANDLE_QUESTION_CHANGE: {
            return { ...state, questionsList: payload };
        }
        case actions.RESET_INTERVIEW_QUESTION_SET: {
            return { ...state, questionsList: initialState.questionsList};
        }
        default: {
            return state;
        }
    }
}
export function getInterviewFeedback(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_INTERVIEW_FEEDBACK_SUCCESS: {
            return { ...state, ...payload };
        }
        case actions.RESET_INTERVIEW_FEEDBACK_QUESTION_SET: {
            return { ...state,getInterviewFeedback:initialState.getInterviewFeedback };
        }
        default: {
            return state;
        }
    }
}
export function getInterviewOverallFeedback(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_INTERVIEW_OVERALL_FEEDBACK_SUCCESS: {
            return { ...state, ...payload };
        }
        case actions.RESET_INTERVIEW_OVERALL_FEEDBACK: {
            return { ...state,getInterviewOverallFeedback:initialState.getInterviewOverallFeedback };
        }
        default: {
            return state;
        }
    }
}



export function getInterviewSetFeedback(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_INTERVIEW_SET_FEEDBACK_SUCCESS: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
}

export function getAllInterviewsForHr(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_ALL_INTERVIEWS_FOR_HR_SUCCESS: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
}

export function getSingleQuestionMapping(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_SINGLE_QUESTION_MAPPING_SUCCESS: {
            return { ...state, ...payload };
        }
        case actions.HANDLE_MAPPING_LIST_CHANGE: {
            return { ...state, mappingList: payload };
        }
        default: {
            return state;
        }
    }
}

