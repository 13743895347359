import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CandidateBreadcrumb from '../Breadcrumb/CandidateBreadcrumb';
import HRBreadcrumb from '../Breadcrumb/HrBreadcrumb';
import IndividualBreadcrumb from '../Breadcrumb/IndividualBreadcrumb';
import SettingBreadcrumb from '../Breadcrumb/SettingsBreadcrumb';
import Profile from '../Profile';
import SearchBox from '../SearchBox';
import ReferralSearch from '../ReferralSearch';
import { BiMenu } from 'react-icons/bi';
import { Drawer } from 'antd';
import HRSidebar from '../HRSidebar';
import { AiOutlineSearch } from 'react-icons/ai';
import InterviewerBreadcrumb from '../Breadcrumb/InterviewerBreadcrumb/index';
import ReferralsBreadcrumb from '../Breadcrumb/ReferralsBreadcrumb';
import TasksBreadcrumb from '../Breadcrumb/TasksBreadcrumb';
import CategoryBreadcrumb from '../Breadcrumb/CategoryBreadcrumb';
import QuestionsBreadcrumb from '../Breadcrumb/QuestionsBreadcrumb';
import {
  ADMIN_HR_MANAGEMENT,
  DASHBOARD_ALL_CANDIDATES,
  DASHBOARD_INTERVIEWER,
  DASHBOARD_JOBS,
  DASHBOARD_REFERRALS,
  DASHBOARD_TASKS,
  EMPLOYEE_HOME,
  EMPLOYEE_REFERRALS,
  DASHBOARD_ANALYTICS,
  DASHBOARD_CATEGORIES,
  DASHBOARD_QUESTIONS,
  DASHBOARD_INTERVIEW_GENERATION,
  DASHBOARD_JOB_LOCATON,
  DASHBOARD_JOB_DEPARTMENT,
  DASHBOARD_HIRING_TRACKER,
  DASHBOARD_EMAILS,
  DASHBOARD_INTERVIEWS,
  DASHBOARD_ALL_FEEDBACK,
  INTERVIEWER_INTERVIEWS
  // EMPLOYEE_INTERVIEWS
} from '../../constants/RouteConstants';
import InterviewGenerationBreadcrumb from '../Breadcrumb/InterviewGenerationBreadcrumb';
import AllFeedbackBreadcrumb from '../Breadcrumb/AllFeedbackBreadcrumb';
import CategorySearch from '../CategorySearch';
import InterviewQuestionSearch from '../Interview/Search';
import Sidebar from '../Sidebar';
import DummyBreadCrumb from '../Breadcrumb/DummyBreadcrumb';
import { RiBuilding4Fill, RiUserSearchLine } from 'react-icons/ri';
import { MdLocationOn } from 'react-icons/md';
import { CgTemplate } from 'react-icons/cg';
import { VscFeedback } from 'react-icons/vsc';
import InterviewsSearch from '../InterviewsSearch';
import FeedbacksSearch from '../FeedbacksSearch';

interface Props {
  isAllCandidate?: boolean;
}

const Header: FC<Props> = ({ isAllCandidate }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchBarShow, setSearchBarShow] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const { id } = useParams<{ id: any }>();

  const showDrawer = () => {
    setIsVisible(true);
  };

  const closeDrawer = () => {
    setIsVisible(false);
  };

  const showSearch = () => {
    setSearchBarShow(true);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSearchBarShow(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  return (
    <>
      <div className='lg:w-full px-0 lg:pl-8 lg:pr-7 pt-5 lg:pt-10 flex items-center bg-ghost-white'>
        <div className='w-1/7 block xl:hidden'>
          <div className='flex'>
            <BiMenu
              onClick={showDrawer}
              className='w-10 h-10 text-blue-zodiac'
            />
          </div>
          {location.pathname !== `${ADMIN_HR_MANAGEMENT}` ? (
            <Drawer placement='left' onClose={closeDrawer} open={isVisible}>
              {/* <HRSidebar /> */}
              <Sidebar />
            </Drawer>
          ) : (
            <Drawer placement='left' onClose={closeDrawer} visible={isVisible}>
              {/* <HRSidebar /> */}
              <Sidebar />
            </Drawer>
          )}
        </div>
        <div className='w-[50%] flex items-center'>
          {location.pathname === `${DASHBOARD_ALL_CANDIDATES}` && (
            <CandidateBreadcrumb />
          )}
          {location.pathname === `${DASHBOARD_TASKS}` && <TasksBreadcrumb />}
          {/* {location.pathname === `${DASHBOARD_JOBS}` && <SettingBreadcrumb />} */}
          {location.pathname === `${DASHBOARD_ALL_CANDIDATES}/${id}` && (
            <IndividualBreadcrumb />
          )}
          {/* {location.pathname === `${'/dashboard/view-feedback'}/${id}` && (
            <IndividualBreadcrumb />
          )} */}
          {location.pathname === `${ADMIN_HR_MANAGEMENT}` && <HRBreadcrumb />}
          {location.pathname === `${EMPLOYEE_REFERRALS}` && (
            <ReferralsBreadcrumb />
          )}
          {location.pathname === `${DASHBOARD_INTERVIEWER}` && (
            <InterviewerBreadcrumb />
          )}
          {location.pathname === `${DASHBOARD_REFERRALS}` && (
            <ReferralsBreadcrumb />
          )}
          {location.pathname === `${'/dashboard/categories'}` && (
            <CategoryBreadcrumb />
          )}
          {location.pathname === `${'/dashboard/questions'}` && (
            <QuestionsBreadcrumb />
          )}
          {location.pathname === `${'/dashboard/interview-generation'}` && (
            <InterviewGenerationBreadcrumb />
          )}
          {location.pathname === `${'/employee/interviews'}` && (
            <InterviewGenerationBreadcrumb />
          )}
          {location.pathname === `${'/dashboard/all-feedbacks'}` && (
            <AllFeedbackBreadcrumb />
          )}

          {location.pathname === `${DASHBOARD_JOB_LOCATON}` && (
            <DummyBreadCrumb
              title={'Locations'}
              icon={
                <MdLocationOn className='w-[24px] h-[24px] mt-[3px] mr-2' />
              }
            />
          )}
          {location.pathname === `${DASHBOARD_JOB_DEPARTMENT}` && (
            <DummyBreadCrumb
              title={'Departments'}
              icon={
                <RiBuilding4Fill className='w-[24px] h-[24px] mt-[3px] mr-2' />
              }
            />
          )}
          {location.pathname === `${DASHBOARD_JOBS}` && (
            <DummyBreadCrumb
              title={'All Jobs'}
              icon={
                <RiUserSearchLine className='w-[24px] h-[24px] mt-[3px] mr-2' />
              }
            />
          )}
          {location.pathname === `${DASHBOARD_HIRING_TRACKER}` && (
            <DummyBreadCrumb
              title={'Hiring Tracker'}
              icon={
                <RiUserSearchLine className='w-[24px] h-[24px] mt-[3px] mr-2' />
              }
            />
          )}
          {location.pathname === `${DASHBOARD_INTERVIEWS}` && (
            <DummyBreadCrumb
              title={'Interviews'}
              icon={<VscFeedback className='w-[24px] h-[24px] mt-[3px] mr-2' />}
            />
          )}
          {location.pathname === `${DASHBOARD_EMAILS}` && (
            <DummyBreadCrumb
              title={'Custom Email Templates'}
              icon={<CgTemplate className='w-[24px] h-[24px] mt-[3px] mr-2' />}
            />
          )}
        </div>
        <div className='hidden w-[60%] lg:flex flex-wrap'>
          <div className='w-3/4'>
            {location.pathname === `${DASHBOARD_ALL_CANDIDATES}` && (
              <SearchBox
                inputClassName='input-search'
                placeholder={
                  isAllCandidate
                    ? 'Search for email, name...'
                    : 'Search by job title'
                }
              />
            )}
            {location.pathname === `${DASHBOARD_REFERRALS}` && (
              <ReferralSearch />
            )}
            {location.pathname === `${DASHBOARD_CATEGORIES}` && (
              <CategorySearch />
            )}
            {location.pathname === `${DASHBOARD_QUESTIONS}` && (
              <InterviewQuestionSearch />
            )}
            {location.pathname === `${DASHBOARD_JOB_LOCATON}` && (
              <SearchBox
                inputClassName='input-search'
                placeholder={'Search by location name'}
              />
            )}
            {location.pathname === `${DASHBOARD_JOB_DEPARTMENT}` && (
              <SearchBox
                inputClassName='input-search'
                placeholder={'Search by department name'}
              />
            )}
            {location.pathname === `${DASHBOARD_JOBS}` && (
              <SearchBox
                inputClassName='input-search'
                placeholder={'Search by job title'}
              />
            )}
            {location.pathname === `${DASHBOARD_HIRING_TRACKER}` && (
              <SearchBox
                inputClassName='input-search'
                placeholder={'Search by name, email, refId'}
              />
            )}
            {location.pathname === `${DASHBOARD_ALL_FEEDBACK}` && (
              <FeedbacksSearch />
            )}
            {location.pathname === `${DASHBOARD_INTERVIEWS}` && (
              <InterviewsSearch />
            )}
          </div>
          <div className='w-1/4 flex items-end justify-end '>
            <Profile /> <i className='fi fi-settings' />
          </div>
        </div>
        <div className='lg:hidden flex items-center justify-end lg:items-end w-[40%]  pt-2'>
          {location.pathname !== `${ADMIN_HR_MANAGEMENT}` &&
            location.pathname !== `${DASHBOARD_ALL_CANDIDATES}/${id}` && (
              <div className='w-1/3'>
                <AiOutlineSearch
                  className='w-9 h-9 text-light-blue'
                  onClick={showSearch}
                />
              </div>
            )}

          <div className='w-2/3 flex justify-end px-2'>
            <Profile /> <i className='fi fi-settings' />
          </div>
        </div>
        {searchBarShow && (
          <div
            ref={ref}
            className='w-full lg:hidden absolute top-0 px-1 bg-white'
          >
            {location.pathname !== `${ADMIN_HR_MANAGEMENT}` &&
              location.pathname !== `${DASHBOARD_ALL_CANDIDATES}/${id}` && (
                <SearchBox inputClassName='input-search' />
              )}
          </div>
        )}
      </div>
      <hr className='my-7 border border-dark-blue opacity-[0.05]' />
    </>
  );
};

export default Header;
