import { Input } from 'antd';
import { FC, forwardRef } from 'react';
import InputWrapper from '../InputWrapper';
import { IInputfieldProps } from '../../Interfaces/common';

const Inputfield: FC<IInputfieldProps> = forwardRef(
  (
    {
      value,
      placeholder,
      label,
      name,
      onChange,
      onBlur,
      required,
      helperText,
      type,
      className,
      prefix,
      suffix,
      disabled,
      autocomplete
    },
    ref
  ) => {
    return (
      <InputWrapper
        className={className}
        label={label}
        required={required}
        helperText={helperText}
      >
        <Input
          // @ts-ignore
          ref={ref}
          id={label}
          type={type}
          size='large'
          className={className}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          prefix={prefix}
          suffix={suffix}
          disabled={disabled}
          autocomplete={autocomplete}
          status={!!helperText ? 'error' : undefined}
        />
      </InputWrapper>
    );
  }
);

export default Inputfield;
