import { Button, Steps } from 'antd';
import React, { useState, useEffect } from 'react';
import SelectTemplate from './Steps/SelectTemplate';
import AddContentForm from './Steps/AddContentForm';
import Send from './Steps/Send';
import { history } from '../../utilities/configureAxios';
import { getSearchParamsUrl } from '../../helper';
import { connect } from 'react-redux';
import emailActions from '../../redux/emailTemplates/actions';

const { Step } = Steps;

const mapStateToProps = (state: any) => ({
  currentStepStore: state.emailTemplates.currentStep
});

interface IEmailSteps {
  currentStepStore: number;
  onStepChange: any;
}

const EmailSteps: React.FC<IEmailSteps> = ({
  currentStepStore,
  onStepChange
}) => {
  const [current, setCurrent] = useState(currentStepStore);

  useEffect(() => {
    setCurrent(currentStepStore);
  }, [currentStepStore]);

  const next = () => {
    const newCurrent = current + 1;
    setCurrent(newCurrent);
    onStepChange(newCurrent);
    history.push(
      getSearchParamsUrl({
        step: newCurrent + 1
      }),
      {}
    );
  };

  const prev = () => {
    const newCurrent = current - 1;
    setCurrent(newCurrent);
    onStepChange(newCurrent);
    history.push(
      getSearchParamsUrl({
        step: newCurrent + 1
      }),
      {}
    );
  };

  const steps = [
    // {
    //   title: 'Select Template',
    //   content: <SelectTemplate next={next} />
    // },
    {
      title: 'Select Template and Fill Data',
      content: <AddContentForm next={next} />
    },
    {
      title: 'Preview and Send mail',
      content: <Send prev={prev} />
    }
  ];

  return (
    <>
      <Steps size='small' current={current}>
        {steps.map((step) => (
          <Step className='step-class' key={step.title} title={step.title} />
        ))}
      </Steps>
      {/* Steps content render here */}
      <div className='my-5'>{steps[current]?.content}</div>
    </>
  );
};

export default connect(mapStateToProps)(EmailSteps);
