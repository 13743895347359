import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../components/Buttons/Primary';
import SocialLinksFooter from '../../components/SocialLinksFooter';
import { Copyrights, ThankYou } from '../../constants/HelperConstants';
import apiClient from '../../utilities/configureAxios';
import Error from './Error';
import RescheduleSuccess from './rescheduleSuccess';
import {
  formatsDate2,
  formatsDateTime,
  formatsDateTimeBackend,
  formatsTime
} from '../../helper';
interface IInterviewResponsee {
  id?: any;
  uuid?: any;
  mr?: any;
  sdt?: any;
}

const Rechedule: React.FC<IInterviewResponsee> = ({ id, uuid, mr, sdt }) => {
  const [endDate, setEndDate] = useState<any>('');
  const [endTime, setEndTime] = useState<any>('');
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [locStorageData, setLocStorageData] = useState(false);
  useEffect(() => {
    if (endDate && endTime) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [endDate, endTime]);

  useEffect(() => {
    if (localStorage.getItem(uuid) !== null) {
      setLocStorageData(true);
    }
  }, [uuid]);

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, []);
  const handleDateChange = (date: any) => {
    setEndDate(date);
  };
  const handleTimeChange = (time: any) => {
    setEndTime(time);
  };
  const localObj = {
    res: mr,
    timestamp: new Date()
  };

  //   @ts-ignore
  const prevDateStamp = JSON.parse(localStorage.getItem(uuid))?.timestamp;
  const prevDate = new Date(prevDateStamp).getDate();
  const onSend = () => {
    const body = {
      mail_response: mr,
      reschedule_response: moment(
        `${endDate?.format(formatsDate2)} ${endTime.format(formatsTime)}`,
        formatsDateTime
      )
        .utc()
        .format(formatsDateTimeBackend)
    };
    const fetchApi = async () => {
      apiClient
        .post(
          `${process.env.REACT_APP_API_URL}/applications/${id}/interviews-concent/${uuid}`,
          body
        )
        .then((res) => {
          setSuccess(true);
          // localStorage.setItem(uuid, JSON.stringify(localObj));
          return res;
        })
        .catch((error) => {
          const { response } = error;
          setError(response?.data?.errors);
        });
    };
    fetchApi();
  };

  const form = (
    <>
      <div className='flex justify-center'>
        <div className='bg-white mx-10 rounded-[20px] max-w-[1200px]'>
          <div className='flex flex-col justify-center'>
            <h1 className='flex justify-center font-bold md:text-[3rem] lg:text-[1.75rem] my-5'>
              Reschedule Interview
            </h1>
          </div>
          <div className='lg:mx-[150px] md:mx-[100px] sm:mx-[50px] mx-10 text-lg'>
            <div className='space-y-3'>
              <div className='text-[#6F7070CC] font-medium md:text-[2.25rem] lg:text-[1.25rem]'>
                Suggest a new time
              </div>
              <div className='md:text-[2.25rem] lg:text-[1.25rem]'>
                Current event time:{' '}
                <div className='text-[#6F7070CC] md:text-[2.5rem] lg:text-[1.25rem] lg:inline'>
                  {moment.unix(sdt).format('dddd, MMMM Do | h:mm A')}
                </div>
              </div>
              <div>
                <div className='font-bold mb-3 md:text-[2.25rem] lg:text-[1.25rem]'>
                  Your suggested time:
                </div>
                <div className='flex justify-between space-x-3'>
                  <form className='w-full space-y-5'>
                    <div className='space-y-3'>
                      <p className='text-blue-streak text-base capitalize opacity-50 mb-0 md:text-[2.25rem] lg:text-[1.25rem]'>
                        Start Time
                        <span className='text-lg text-star-red pl-1'>*</span>
                      </p>
                      <div className='flex lg:w-[40%] md:w-[80%] space-x-4 date_reschedule'>
                        <DatePicker
                          className='custom-date-picker edit-input md:text-[3rem] lg:text-[1.75rem] md:w-[480px] lg:w-[280px]'
                          onChange={handleDateChange}
                          disabledDate={(current) =>
                            //@ts-ignore
                            current.isBefore(moment().subtract(1, 'day'))
                          }
                        />
                        <TimePicker
                          use12Hours
                          format='h:mm a'
                          className='edit-input text-base'
                          onChange={handleTimeChange}
                        />
                      </div>
                    </div>
                    <div className='mb-3'>
                      <PrimaryButton
                        disabled={!valid || prevDate === new Date().getDate()}
                        text='Send'
                        onClick={onSend}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <p className='text-[1.6rem] md:text-[1.5rem] lg:text-[1rem]'>
              Thanks again for your interest in Squareboat’s careers and unique
              culture; we hope you will remain enthusiastic about our company.
              If you have any questions, please feel free to contact us at
              <a
                href={process.env.REACT_APP_CAREERS_SB}
                target='_blank'
                rel='noreferrer'
                className='text-light-blue font-medium'
              >
                {' '}
                careers@squareboat.com.
              </a>
            </p>
            {ThankYou}
          </div>
          <SocialLinksFooter />
        </div>
      </div>
      {Copyrights}
    </>
  );

  return (
    <>
      {/* {!success && !error && !locStorageData && form} */}
      {!success && !error && form}
      {success && <RescheduleSuccess />}
      {error?.length && <Error error={error} />}
    </>
  );
};

export default Rechedule;
